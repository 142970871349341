import React from 'react';
import PropTypes from 'prop-types';
import PrivateRoute from './PrivateRoute';
/* eslint-disable react/jsx-props-no-spreading */
const Page = ({ id, store, ...rest }) => <PrivateRoute store={store} {...rest} allowed={store[id]} />;

export default Page;

Page.propTypes = {
    id: PropTypes.any,
    store: PropTypes.any
};

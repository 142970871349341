/* eslint-disable react/jsx-indent, padded-blocks */
import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import initializeI18N from './i18n/init';
import theme from './theme/theme';
import ApplicationProvider from './context/ApplicationContext';
import AuthProvider from './context/AuthContext';
import Main from './Main';
import { PageContextProvider } from './pages/context/PageContextProvider';
import { checkAndSetDeviceId } from './util/util';

initializeI18N();

const App = () => {

    useEffect(() => {
        checkAndSetDeviceId();
    }, []);

    return (
        <BrowserRouter>
            <ApplicationProvider>
                <AuthProvider>
                    <ThemeProvider theme={theme}>
                        <PageContextProvider>
                            <Main />
                        </PageContextProvider>
                    </ThemeProvider>
                </AuthProvider>
            </ApplicationProvider>
        </BrowserRouter>
    );
}

export default App;

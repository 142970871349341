import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import moment from 'moment';

const AInput = ({ inputField, fieldProps, text, errors, disabled, verticallyPositionedLabels }) => {
    let field = null;
    const Component = inputField.render.type;

    if (inputField.fieldType === 'date') {
        fieldProps.value = fieldProps.value ? moment(fieldProps.value) : moment();
    }

    field = (
      <Component
        {...inputField.render.props}
        value={fieldProps.value}
        onChange={fieldProps.onChange}
        disabled={disabled}
      />
    );

    return (
      <Container>
        <InputContainer verticallyPositionedLabels={verticallyPositionedLabels}>
          <Text>{text}</Text>
          {field}
        </InputContainer>
        <ErrorSpace>{errors && <ErrorText>{errors.message}</ErrorText>}</ErrorSpace>
      </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: ${(props) => (props?.verticallyPositionedLabels ? 'column' : 'row')};
`;

const Text = styled.div`
  display: flex;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 15px;
  align-items: center;
  width: 35%; 
`;

const ErrorText = styled.div`
  display: flex;
  min-height: 11px;
  align-items: flex-start;
  color: red;
  font-size: 11px;
  // margin-left: 30%;
 // margin-left: 185px;  
  margin-left: 40%;
  width: 240px;
`;

const ErrorSpace = styled.div`
  display: flex;
  min-height: 11px;
  width: 100%;
`;

AInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    type: PropTypes.string,
    text: PropTypes.string,
    errors: PropTypes.arrayOf(
        PropTypes.shape({
            message: PropTypes.string
        })
    ),
    selectOptions: PropTypes.array,
    inputField: PropTypes.object,
    fieldProps: PropTypes.object,
    disabled: PropTypes.bool
};

export default AInput;

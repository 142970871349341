/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import withModal from '../withModals';
import FormModalStyled, { StyledButton, ButtonContainer, Permission, TitleText, SpanText, ItemText, ContainerPermisions, Column, Row, StyledFullName, StyledWrapper, Container } from './FormModalStyled';
import { useAuthStateValue } from '../../../context/AuthContext';
import useUsers from '../../../pages/users/hooks/user';

let firstTime = true;

const UserProfileModal = ({ modalProps, closeDialog }) => {
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const { loggedUser, setLoggedUser } = useAuthStateValue();

  const { fetchLicences, licenseSource } = useUsers();
  const userModalData = loggedUser;
  useEffect(() => {
    if (firstTime) {
      firstTime = false
      return
    }
    const userId = loggedUser?.id;
    if (userId) {
      fetchLicences(userId);
    }
  }, [loggedUser]);
  let fullName = '';
  if (userModalData?.fatherName === '' || null || undefined || '-') {
    fullName = `${userModalData?.firstName} ${userModalData?.lastName}`;
  } else {
    fullName = `${userModalData?.firstName} (${userModalData?.fatherName}) ${userModalData?.lastName}`;
  }
  const JMBG = '0708992157532';
  return (
    <FormModalStyled
      centered
      maskClosable={false}
      width={520}
      footer={[]}
      {...modalProps}
      title={t('users.employeeFile')}
    >
      <Container>
        <StyledFullName>{fullName}</StyledFullName>
        <StyledWrapper>
          <TitleText>
            {t('users.email') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.username}
          </SpanText>
        </StyledWrapper>
        <StyledWrapper>
          <TitleText>
            {t('users.workplace') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.extendUser?.workplace}
          </SpanText>
        </StyledWrapper>
        <StyledWrapper>
          <TitleText>
            {t('users.organizationalUnit') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.extendUser?.organizationalUnitName}
          </SpanText>
        </StyledWrapper>
        <StyledWrapper>
          <TitleText>
            {t('users.location') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.extendUser?.locationName}
          </SpanText>
        </StyledWrapper>
      </Container>
      <Permission>{t('users.permits') + ':'}</Permission>
      <ContainerPermisions>
        {(licenseSource?.length !== 0)
          ? (licenseSource?.map((item, index) => {
            let color;
            const colorFunction = () => {
              if (item?.licenseStatus?.name === 'Validno') {
                color = themeContext.colors.mainColors.green;
              } else if (item?.licenseStatus?.name === 'Završena obuka' || item?.licenseStatus?.name === 'Uskoro ističe' || item?.licenseStatus?.name === 'Predato' || item?.licenseStatus?.name === 'Primljeno' || item?.licenseStatus?.name === 'Poslato u BHDCA') {
                color = themeContext.colors.mainColors.yellow;
              } else if (item?.licenseStatus?.name === 'Isteklo' || item?.licenseStatus?.name === 'Suspendovano' || item?.licenseStatus?.name === 'Poništeno' || item?.licenseStatus?.name === 'Nepotpuno') {
                color = themeContext.colors.mainColors.red;
              }
              return color;
            }
            return (
              <Row key={index}>
                <Column>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.permitsName') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.licenseName}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.approval') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.warrantName}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.authorization') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.warrantName}
                    </SpanText>
                  </StyledWrapper>
                </Column>
                <Column>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.unit') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.unitName}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.dateExpiration') + ':'}
                    </ItemText>
                    <SpanText>
                      {moment(item?.expirationDate).format('DD.MM.YYYY')}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.statusModal') + ':'}
                    </ItemText>
                    <SpanText style={{ color: colorFunction() }}>
                      {item?.licenseStatus?.name}
                    </SpanText>
                  </StyledWrapper>
                </Column>
              </Row>
            );
          })) : (<Permission>{t('users.noPermits')}</Permission>) }
      </ContainerPermisions>
      <ButtonContainer>
        <StyledButton onClick={closeDialog}>{t('buttons.close')}</StyledButton>
      </ButtonContainer>
    </FormModalStyled>
  );
};

export default withModal()(UserProfileModal);

import React from 'react';
import 'antd/dist/antd.min.css';
import { Layout, Result, Spin } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import BasicHeader from '../BasicHeader/BasicHeader';
import SideMenu from '../SideMenu/SideMenu';
import Page from '../../core/router/Page';
import GlobalErrorBoundary from '../../util/errors/GlobalErrorBoundary';
import { AuthContext } from '../../context/AuthContext';
import MonthlyDetailedView from '../../pages/detailedMonthlySchedule/MonthlyDetailedView';
import { MonthlyPlan } from '../../pages/monthlyPlan/MonthlyPlan';
import DetailedDailySchedule from '../../pages/detailedDailySchedule/DetailedDailySchedule';

const { Content } = Layout;
let dom = {};
try {
    // eslint-disable-next-line no-undef
    dom = require('react-router-dom');
} catch (e) {
    console.error(e);
}
const { Route, Switch } = dom;

const ForbiddenComponent = () => (
  <div className="page-container result-item">
    <Result status="403" title="403" />
  </div>
  );

const NotFoundComponent = () => (
  <div className="page-container result-item">
    <Result status="404" title="404" />
  </div>
);

class BasicLayout extends React.Component {
    static contextType = AuthContext;

    showAvailableRoutes = () => {
        const { menuItems, pages, otherItems } = this.props;
        const store = {};
        let firstItemKey = null;
        let firstItem = true;
        if (menuItems) {
            menuItems.forEach((el) => {
                if (el.subItems && el.subItems.length > 0) {
                    el.subItems.forEach((sub) => {
                        if (firstItem) {
                            firstItem = false;
                            firstItemKey = sub.key;
                        }
                        store[sub.key] = sub.rules || {};
                    });
                } else {
                    if (firstItem) {
                        firstItem = false;
                        firstItemKey = el.key;
                    }
                    store[el.key] = el.rules || {};
                }
            });
        }
        if (otherItems) {
            otherItems.forEach((el) => {
                if (firstItem) {
                    firstItem = false;
                    firstItemKey = el.key;
                }
                store[el.key] = el.rules || {};
            });
        }
        const userGroupAuthRules = this.context.loggedUser.permittedRoutes;
        if (userGroupAuthRules) {
            return pages.reduce((filtered, el) => {
                const rule = userGroupAuthRules.find((rule) => {
                    let submenuItemRule = null;
                    if (rule.subMenuItems && rule.subMenuItems.length > 0) {
                        submenuItemRule = rule.subMenuItems.find((smi) => smi.key === el.key);
                    }
                    return submenuItemRule || rule.key === el.key;
                });
                let isAdditionalPageRule = false;
                if (!rule && userGroupAuthRules.additionalPages) {
                    isAdditionalPageRule = userGroupAuthRules.additionalPages.findIndex((additionalPageRuleKey) => additionalPageRuleKey === el.key) !== -1;
                }
                if (rule || isAdditionalPageRule) {
                    const path = [`/${el.key}${el.hasParam ? '/:param' : ''}`];
                    if (firstItemKey === el.key) {
                        path.push('/');
                    }
                    if (el?.key === 'monthlyPlan') {
                        path.push('/monthlyPlan/:atcu/:year/:version');
                        path.push('/monthlyPlan');
                    }

                    filtered.push(
                      <Page
                        key={el.key}
                        exact
                        component={el.component}
                        path={path}
                        id={el.key}
                        store={store}
                        redirectUrl="/403"
                        {...el.componentProps}
                      />
                    );
                }
                return filtered;
            }, []);
        }
        return null;
    };

    render() {
        const { location } = this.props;
        if (this.props.loading) {
            const customSpinnerPage = this.props.loadingPage || (
            <StyledLoadingContainer className="basic-layout-spinner-container">
              <Spin className="basic-layout-spinner" />
            </StyledLoadingContainer>
            );
            return customSpinnerPage;
        }
        const authenticated = this.props.authenticated || false;
        const CustomLoginComponent = this.props.loginPage;
        if (!authenticated) {
            return (
                // eslint-disable-next-line react/jsx-pascal-case
              <CustomLoginComponent.type
                onLogin={this.props.onLogin}
                logo={this.props.loginLogo}
                loginLogoStyle={this.props.loginLogoStyle}
                {...this.props.loginPage.props}
              />
            );
        }
        const { logo, dropdownItems, applicationName, menuItems, user, notFound, forbidden } = this.props;
        const forbiddenPage = forbidden || ForbiddenComponent;
        const notFoundPage = notFound || NotFoundComponent;
        return (
          <GlobalErrorBoundary>
            <Layout style={{ minHeight: '100vh' }}>
              {((!location.pathname.startsWith('/daily/') && location.pathname !== '/shiftsChange' && !location.pathname.startsWith('/monthlyPlan/ATCU')) || (location?.pathname?.startsWith('/monthlyPlan/ATCU') && (!location.pathname?.includes('edit') && !location.pathname?.includes('view')))) && (
              <BasicHeader
                user={user}
                applicationName={applicationName}
                menuItems={dropdownItems}
                logo={logo}
                userImage={this.props.userImage}
              >
                {this.props.headerComponents}
              </BasicHeader>
              )}
              <MainLayout>
                {((!location.pathname.startsWith('/daily/') && location.pathname !== '/shiftsChange' && !location.pathname.startsWith('/monthlyPlan/ATCU')) || (location?.pathname?.startsWith('/monthlyPlan/ATCU') && (!location.pathname?.includes('edit') && !location.pathname?.includes('view')))) && menuItems && user && menuItems.length > 0 && <SideMenu user={user} menuItems={menuItems} />}
                <Content>
                  <Switch>
                    <Route path="/403" exact component={forbiddenPage} />
                    <Route path="/monthlyPlan/:location/:year/:annualPlanVersion/:month/:version/:mode" exact component={MonthlyDetailedView} />
                    <Route path="/monthly/:locationFromAnnualPlan/:yearFromAnnualPlan/:versionFromAnnualPlan" exact component={MonthlyPlan} />
                    <Route path="/daily/:year/:month/:day/:version/:mode" exact component={DetailedDailySchedule} />
                    {this.showAvailableRoutes()}
                    <Route component={notFoundPage} />
                  </Switch>
                </Content>
              </MainLayout>
            </Layout>
          </GlobalErrorBoundary>
        );
    }
}

let whatToExport;
if (dom.Route) {
    whatToExport = BasicLayout;
} else {
    whatToExport = () => <div>No React Router</div>;
}

const StyledLoadingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const MainLayout = styled(Layout)`
  background: ${({ theme: { colors } }) => colors.grayColors.panelBackgroundGray};
`;

BasicLayout.propTypes = {
    pages: PropTypes.array,
    otherItems: PropTypes.array,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            component: PropTypes.element
        })
    ),
    loading: PropTypes.bool,
    authenticated: PropTypes.bool,
    onLogin: PropTypes.func,
    loadingPage: PropTypes.element,
    loginLogo: PropTypes.element,
    loginLogoStyle: PropTypes.element,
    dropdownItems: PropTypes.array,
    applicationName: PropTypes.string,
    user: PropTypes.object,
    notFound: PropTypes.element,
    forbidden: PropTypes.element,
    headerComponents: PropTypes.array,
    loginPage: PropTypes.element,
    logo: PropTypes.string,
    userImage: PropTypes.any
};

export default withRouter(whatToExport);

/* eslint-disable*/
import api from './base.service';
import { getBrowserName } from '../util/util';

export const login = ({ username, password }) => api().post('/login', {
        email: username,
        password,
        deviceType: 'WEB',
        devicePassword: localStorage.getItem('device_id'),
        deviceName: getBrowserName()
    });

export const refresh = ({ accessToken, refreshToken }) => api(true).post('/refresh', {
        accessToken,
        refreshToken,
        devicePassword: localStorage.getItem('device_id')
    });

export const logout = () => api().get('/logout');

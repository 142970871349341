import styled from 'styled-components';
import { Button, Select } from 'antd';

export const PageContent = styled.div`
  display: flex;
  padding: 24px;
  overflow: hidden;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  overflow-y: scroll;
  color: ${({ theme: { colors } }) => (colors.grayColors.gray)};
`;

export const HeaderActionButton = styled(Button)`
  &.ant-btn {
    color: #312f2f;
    padding: 5px 30px;
    border-radius: 10px;
    background: white;
    min-width: 96px;
    min-height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 16px;
    outline: none;
    border: none;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
  }
  &.ant-btn:hover {
    border: none !important;
    background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
    color: #312f2f !important;
  }
`;

export const HeaderActionButtonTwo = styled(Button)`
  &.ant-btn {
    color: #312f2f;
    padding: 5px 30px;
    border-radius: 10px;
    background: white;
    //min-width: 96px;
   // min-height: 33px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 16px;
    outline: none;
    border: none;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
    @media only screen and (max-width: 1500px) {
      width: 100px;
    }
  }
  &.ant-btn:hover {
    border: none !important;
    background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
    color: #312f2f !important;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  }
  &.ant-btn:disabled {
    background-color: ${({ theme: { colors } }) => (colors.grayColors.grayDark)};
    color: #858585;
    border: none !important;
    &:hover {
          background-color: ${({ theme: { colors } }) => (colors.grayColors.grayDark)} !important;
          color: #858585 !important;
        }
  }
`;

export const FilterSelectInput = styled(Select)`
    &.ant-select {
    border-radius: 20px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    width: 470px;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
    @media only screen and (max-width: 1400px) {
    width: 318px !important;
  }
  @media only screen and (max-width: 1600px) {
    width: 360px;
  }
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 14px;
        width: 100%;
        
      }
    }
  }
  box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.15);
`;

export const FilterSelectInputTwo = styled(Select)`
    &.ant-select {
    border-radius: 20px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    width: 130px;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
    @media only screen and (max-width: 1500px) {
      width: 110px;
    }
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
    //  padding-top: 4px;
      padding-left: 25px;
      white-space: inherit;
      @media only screen and (max-width: 1500px) {
        padding-left: 8px;
  }
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 16px;
        width: 100%;
      }
    }
  }
  margin-right: 15px;
  @media only screen and (max-width: 1700px) {
    margin-right: 12px;
  }
`;
export const FilterSelectInputChangeAtcu = styled(Select)`
    &.ant-select {
    border-radius: 10px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    width: 250px;
    // box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
    //  padding-top: 4px;
      padding-left: 25px;
      white-space: inherit;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 16px;
        width: 100%;
      }
    }
  }
  margin-right: 15px;
  @media only screen and (max-width: 1700px) {
    margin-right: 12px;
  }
`;

export const FilterSelectInputSmall = styled(Select)`
    &.ant-select {
    border-radius: 20px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    width: 170px;
    box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 14px;
        margin-left: 10px;
        width: 100%;
        @media only screen and (max-width: 1400px) {
          font-size: 10px;
        }
      }
    }
  }
`;
export const FilterSelectVersion = styled(Select)`
  &.ant-select {
    border-radius: 20px;
    width: ${(props) => props?.filterlenght};
    height: 30px;
    overflow: hidden;
    border: 1px solid gainsboro;

    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
        }
      &.currentDay {
    color: white !important;
    background: #0073AE;
    //border: 1px solid white;
  }
  &.currentDay:hover{
    background-color: white;
    color: #0073AE !important;
  }
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 12px;
        margin-left: 5px;
        width: 100%;
        @media only screen and (max-width: 1400px) {
          font-size: 10px;
        }
      }
    }
    `;

    export const FilterSelectAddAnnualPlan = styled(Select)`
    &.ant-select {
    border-radius: 5px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    width: 170px;
    color: rgba(0,0,0,.85);
    margin-left: 100px;
    @media only screen and (max-width: 650px) {
      margin-left: 0px;
    }
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 14px;
        width: 100%;
        
      }
    }
  }
`;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import styled from 'styled-components';
import withModal from '../../../core/components/withModals';
import FormModalStyled from '../../../core/components/modals/FormModalStyled';
import StatusCode from '../../../services/statusCode';
import { FilterSelectInputChangeAtcu } from '../../Page.styled';
import AButton from '../../../core/components/buttons/AButton';
import { useAuthStateValue } from '../../../context/AuthContext';
import { changeScheduledAtcu } from '../../../services/user.service';

const ChangeAtcuModal = (props) => {
  const { modalData, modalProps, closeDialog, dataSource, setDataSource } = props;

  const { loggedUser } = useAuthStateValue();
  const [selectedAtcu, setSelectedAtcu] = useState(null);
  const [selectedShiftManager, setSelectedShiftManager] = useState(null);
  const [error, setError] = useState(null);

  const { t } = useTranslation();
  const id = modalData?.id;
  const newAtcu = selectedAtcu;
  const newRole = selectedShiftManager
  const onOk = async () => {
    try {
      const res = await changeScheduledAtcu(id, newAtcu, newRole);
      if (res.status === StatusCode.OK) {
        message.success(t('users.changeAtcuSuccess'));
        props.onSave();
      }
      props.closeDialog();
    } catch (err) {
      setError(err);
      if (err.response.status === StatusCode.CONFLICT) {
        message.error(t('users.changeAtcuConflict'));
      } else {
        message.error(t('users.changeAtcuError'));
}
      props.closeDialog();
    }
    props.closeDialog();
  }
  const arrayForSelectAtcu = [];
  loggedUser?.locationsData?.forEach((element) => {
    arrayForSelectAtcu?.push({
      title: element.identificator,
      key: element.id,
      label: element.identificator,
      value: element.identificator,
    })
  })
const arrayForSelectRole = [];
  arrayForSelectRole?.push(
{
    title: 'NONE',
    key: 1,
    label: 'Nema',
    value: 'NONE'
  },
  {
    title: 'SHIFT_MANAGER',
    key: 2,
    label: 'Šef smjene',
    value: 'SHIFT_MANAGER'
  },
  {
    title: 'SATCO',
    key: 3,
    label: 'Satco',
    value: 'SATCO'
  }
  )

  arrayForSelectAtcu?.push({
    title: 'NONE',
    key: 1,
    label: 'Nema',
    value: 'NONE'
  })

  return (
    <FormModalStyled
      width={620}
      footer={[]}
      centered
      maskClosable={false}
      {...modalProps}
      title={t('users.changeAtcuTitle')}
    >
      <Container>
        <Wrapper>
          <Title>{t('users.changeAtcuTitle')}</Title>
          <FilterSelectInputChangeAtcu options={arrayForSelectAtcu} onChange={(value) => setSelectedAtcu(value)} defaultValue={modalData?.scheduledAtcuIdentificator === null ? 'Nema' : modalData?.scheduledAtcuIdentificator} placeholder={t('users.changeAtcuPlaceholder')} />
        </Wrapper>
        <Wrapper>
          <Title>{t('users.changeRoleTitle')}</Title>
          <FilterSelectInputChangeAtcu options={arrayForSelectRole} onChange={(value) => setSelectedShiftManager(value)} defaultValue={modalData?.specialRole} placeholder={t('users.changeRolePlaceholder')} />
        </Wrapper>
      </Container>
      <ModalFooter>
        <ModalButton onClick={closeDialog}>{t('buttons.cancel')}</ModalButton>
        <ModalButton onClick={onOk}>{t('buttons.confirm')}</ModalButton>
      </ModalFooter>
    </FormModalStyled>
  );
};

ChangeAtcuModal.propTypes = {
  modalData: PropTypes.any,
  modalProps: PropTypes.any,
  closeDialog: PropTypes.func,
  dataSource: PropTypes.array,
  setDataSource: PropTypes.func,
};

const ModalButton = styled(AButton)`
  border-radius: 5px !important;
  box-sizing: border-box;
  &:hover {
    border: none !important;
  }
  &:disabled {
    background-color: #858585 !important;
    &:hover {
      background-color: #858585 !important;
      border: none !important;
    }
  }
`;

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 150px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 10px;
  align-items: center;

`;

const Title = styled.div`
  font-size: 16px;
  padding-right: 20px;
  min-width: 250px;
  `;

const ModalFooter = styled.div`
  border-top: 1px solid #eaeaea;
  width: 100%;
  display:flex;
  justify-content: right;
  padding: 10px 15px 0px 0px;
  gap: 15px;
`;

export default withModal()(ChangeAtcuModal);

import styled from 'styled-components';
import { Input } from 'antd';

const { TextArea } = Input;

const ATextArea = styled(TextArea)`
  width: 300px;
  border-radius: 5px;
`;

export default ATextArea;

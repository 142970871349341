/* eslint-disable react/jsx-one-expression-per-line */
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import {
  DemandCell,
  ExpandFooterButtonCell,
  ExpandFooterIcon,
  FooterContainer,
  FooterTableContainer
} from './ExpandableFooter.style';
import arrowIcon from '../../../../assets/images/icons/arrow-up-circle.svg';
import { DayInMonthCell, SheetHeadingContainer, TableComponent } from '../MonthlySheet.style';

const ExpandableFooter = (props) => {
  const {
    expanded,
    toggleFooter,
    daysInMonthNumber,
    year,
    month,
    demandSituation
  } = props;

  const { t } = useTranslation();

  const renderFooterHeading = () => {
    const daysInMonthRow = [<td key="demand-day-in-month-cell-0" style={{ width: '180px' }} rowSpan={2}>SMJENE</td>];
    const daysInWeekRow = [];

    for (let i = 1; i <= daysInMonthNumber; i += 1) {
      const dayInWeek = new Date(year, month - 1, i - 1).getDay() + 1;
      daysInMonthRow.push(<DayInMonthCell key={'demand-day-in-month-cell' + i} className={t('daysInWeek.' + dayInWeek).substring(0, 3).toUpperCase()}>{i}</DayInMonthCell>);
      daysInWeekRow.push(<DayInMonthCell key={'demand-day-in-week-ceell' + i} className={t('daysInWeek.' + dayInWeek).substring(0, 3).toUpperCase()}>{t('daysInWeek.' + dayInWeek).substring(0, 3).toUpperCase()}</DayInMonthCell>);
    }
    daysInMonthRow.push(<td key={'demand-day-in-month-cell-' + (daysInMonthRow + 1)} rowSpan={2} style={{ width: '120px' }} />)
    return [<tr key="footer-header-row-0">{daysInMonthRow}</tr>, <tr key="footer-header-row-1">{daysInWeekRow}</tr>];
  }

  const renderTableRows = () => {
    const rowsArray = [];
    demandSituation?.forEach((shift, index) => {
    const shiftRow = [<td key={'demand-shift-row' + index}><b>{shift.shiftName}</b> ({shift?.timeFrom + '-' + shift?.timeTo})</td>]
    shift?.perDay?.forEach((dayEntry, dayEntryIndex) => {
      shiftRow.push(
        <Tooltip
          key={'demand-cell-tooltip-index-' + index + '-' + dayEntryIndex}
          title={(
            <>
              <span>Potrebno: {dayEntry?.totalPeopleNeeded}</span><br />
              <span>Raspodijeljeno: {dayEntry?.peopleAllocated}</span>
            </>
          )}
        >
          <DemandCell key={'demand-cell-index-' + index + '-' + dayEntryIndex} className={dayEntry?.demand > 0 ? 'over' : (dayEntry?.demand < 0 ? 'under' : 'zero')}>
            {dayEntry?.demand > 0 ? '+' : ''}
            {dayEntry?.demand}
          </DemandCell>
        </Tooltip>
      );
    });
    if (index === 0) shiftRow.push(<td key={'demand-shift-row-' + shift?.id + '-' + index} rowSpan={demandSituation.length} />)
    rowsArray.push(<tr key={'demand-shift-row-' + index}>{shiftRow}</tr>);
    })
    return rowsArray;
  }
  return (
    <FooterContainer rowsNumber={demandSituation?.length} expanded={expanded ? 1 : 0}>
      <ExpandFooterButtonCell expanded={expanded ? 1 : 0} onClick={toggleFooter}>
        Potražnja
        <ExpandFooterIcon expanded={expanded ? 1 : 0} src={arrowIcon} />
      </ExpandFooterButtonCell>
      <FooterTableContainer expanded={expanded ? 1 : 0}>
        <TableComponent id="footerTable" cellWidth="35" style={{ marginLeft: '-5px' }}>
          <SheetHeadingContainer>
            {renderFooterHeading()}
          </SheetHeadingContainer>
          <tbody>
            {renderTableRows()}
          </tbody>
        </TableComponent>
      </FooterTableContainer>
    </FooterContainer>
  );
}

export default ExpandableFooter;

import styled from 'styled-components';

export const PanelWrapper = styled.div`
`;

export const PanelHeader = styled.div`
    height: 40px;
    display: flex;
    justify-content: space-between;
    width: ${({ notificationsPanel }) => (notificationsPanel ? 'calc(96% + 20px)' : '100%')};
`;

export const PanelHeaderTitle = styled.div`
    font-size: 23px;
    color: ${({ theme: { colors } }) => (colors.grayColors.gray)};
`;

export const PanelSubTitle = styled.span`
   font-weight: lighter;
`;

export const Sign = styled.span`
    font-weight: lighter;
`;

export const PanelHeaderActionBox = styled.div`
    height: 40px;
`;

export const PanelContent = styled.div`
    background-color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    height: 20.5vh;
    @media only screen and (min-width: 1700px) {
        height: 23vh;
    }
    width: 100%;
    border-radius: 5px;
    box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
    display: flex;
    flex-direction: row;
    /* justify-content: start !important; */
    padding : 15px 20px 0px 20px;
    &.coloredDiv {
        background-color: transparent !important;
        box-shadow: none;
        padding: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        // dodate izmjene za verziju 1 aplikacije
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
`;

/* eslint-disable no-unused-vars */
import { React, useState, useEffect, useRef, version } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';
import { QuestionOutlined, UploadOutlined } from '@ant-design/icons';
import { NavLink, useHistory } from 'react-router-dom';
import { FilterSelectVersion } from '../../Page.styled';
import {
    ActionIconsBox,
    ThumbnailFooter,
    ThumbnailHeader,
    ThumbnailWrapper,
    VersionText,
    VersionTextSelect,
    YearCaption,
    ActionIcon,
} from './PlanThumbnail.style';
import planSeeIcon from '../../../assets/images/icons/eyeIcon.svg';
import planEditIcon from '../../../assets/images/icons/penIcon.svg';
import deletePlanIcon from '../../../assets/images/icons/planRemoveIcon.svg';
import { useAuthStateValue } from '../../../context/AuthContext';
import planStatusTypes from '../../../util/planStatusTypes.json';
import { useAnnualPlans } from '../hooks/annualPlan';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import { removeAnnualPlan } from '../../../services/annualPlan.service';
import UpdateAnnualPlanModal from '../modals/UpdateAnnualPlanModal';

const PlanThumbnail = ({ year, versions, location, fetchAnnualPlans, fetchMonthlyPlans }) => {
  const { loggedUser } = useAuthStateValue();
  const { t } = useTranslation();
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [planStatusTypeResult, setPlanStatusTypeResult] = useState(null);
  const [planVersion, setPlanVersion] = useState(null);
  const [planVersionNumber, setPlanVersionNumber] = useState(null);
  const [seeStatus, setSeeStatus] = useState(false);
  // const [editStatus, setEditStatus] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const { loading, dataSource } = useAnnualPlans();
  const updateAnnualPlanModalRef = useRef();

  const changeVersionHandler = (event) => {
    const result = versions?.find((item) => item.id === event);
    setPlanVersion(result?.status);
    versions?.map((option, index) => {
    if (event === option?.id) {
      setPlanVersionNumber(option?.version);
    }
    });
    };
  useEffect(() => {
    if (planVersion === null) {
      setPlanVersion(versions[0]?.status);
      setPlanVersionNumber(versions[0]?.version);
    }
      setPlanStatusTypeResult(planStatusTypes?.annual?.find((element) => element?.key === planVersion));
    }, [planVersion]);

    const matchForSelectedLocation = loggedUser?.locationsData?.find((item) => item.identificator === location);

  useEffect(() => {
      setPermissionStatus(loggedUser?.localPermissions[matchForSelectedLocation?.key]?.annualPlan);
    if (permissionStatus === 'APPROVE_AND_ACTIVATE') {
      setSeeStatus(true);
     // setEditStatus(true);
      setDeleteStatus(true);
      setUpdateStatus(true);
    } else if (permissionStatus === 'DISPLAY') {
     // setEditStatus(true);
      setSeeStatus(true);
      setDeleteStatus(false);
      setUpdateStatus(false);
    } else if (permissionStatus === 'GENERATE_AND_MODIFY') {
      setSeeStatus(true);
     // setEditStatus(true);
      setDeleteStatus(true);
      setUpdateStatus(true);
    } else if (permissionStatus === 'NONE') {
     // setEditStatus(false);
      setSeeStatus(false);
      setDeleteStatus(false);
      setUpdateStatus(false);
    }
  }, [permissionStatus]);

  const TitleCardHandler = () => {
    return (
      <Tooltip placement="bottom">
        {(planStatusTypeResult?.key === planVersion) ? (planStatusTypeResult?.title?.sr) : ''}
      </Tooltip>
    )
  }
  const currentYear = new Date().getFullYear();
  const deleteAnnualPlan = (atcu, year) => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('annualPlan.deletePlan'),
      content: t('annualPlan.deletePlanQuestion'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: async () => {
         await removeAnnualPlan(atcu, year);
         fetchAnnualPlans(atcu);
      }
    })
  }
  const deleteAnnualPlanFunction = () => {
    deleteAnnualPlan(location, year);
  }

  const changeVersionNumberHandler = (event) => {
    setPlanVersionNumber(event);
  }

  const getLocation = () => {
    return location;
  };

  const showUpdateAnnualPlanDialog = () => {
    updateAnnualPlanModalRef.current.showDialog();
  }

    return (
      <ThumbnailWrapper>
        <ThumbnailHeader className={(planVersion === planStatusTypeResult?.key) ? status : ''} style={{ backgroundColor: (planVersion === planStatusTypeResult?.key) ? planStatusTypeResult?.headerColor : '' }}>
          {TitleCardHandler()}
        </ThumbnailHeader>
        <YearCaption>{year}</YearCaption>
        <ThumbnailFooter>
          {
          (planStatusTypeResult?.key !== 'BEING_GENERATED')
          && (
            (permissionStatus === 'DISPLAY' || permissionStatus === 'NONE' || versions?.length === 1) ? (

              <VersionText>
                {' '}
                {t('annualPlan.currentVersion') + ' '}
                <b>{versions[0]?.version}</b>
              </VersionText>

                ) : (
                  <VersionTextSelect>
                    <FilterSelectVersion onChange={changeVersionHandler} defaultValue={t('annualPlan.currentVersion') + ' ' + versions[0]?.version}>
                      {versions?.map((option, index) => (
                        <option key={index} value={option.id}>
                          {t('annualPlan.currentVersion') + ' ' + option?.version}
                        </option>
                  ))}
                    </FilterSelectVersion>
                  </VersionTextSelect>

                )
            )
          }
          <ActionIconsBox>
            {
            ((planStatusTypeResult?.key === planVersion) && planStatusTypeResult?.actions?.includes('SEE') && seeStatus)
            && (
            <Tooltip placement="top" title={t('annualPlan.see')}>
              <NavLink to={'/monthlyPlan/' + location + '/' + year + '/' + planVersionNumber}>
                <ActionIcon>
                  <ReactSVG src={planSeeIcon} />
                </ActionIcon>
              </NavLink>
            </Tooltip>
             )
            }
            {
            ((planStatusTypeResult?.key === planVersion) && planStatusTypeResult?.actions?.includes('EDIT') && updateStatus)
            && (
            <Tooltip placement="top" title={t('annualPlan.update')}>
              <ActionIcon onClick={showUpdateAnnualPlanDialog}>
                <UploadOutlined style={{ fontSize: '18px' }} />
              </ActionIcon>
            </Tooltip>
            )
            }
            {/* {
              ((planStatusTypeResult?.key === planVersion) && planStatusTypeResult?.actions?.includes('EDIT') && editStatus && year >= currentYear)
              && (
              <Tooltip placement="top" title={t('annualPlan.edit')}>
                <ActionIcon>
                  <ReactSVG src={planEditIcon} />
                </ActionIcon>
              </Tooltip>
              )
            } */}
            {
             ((planStatusTypeResult?.key === planVersion) && planStatusTypeResult?.actions?.includes('DELETE') && deleteStatus && year > currentYear)
          && (
          <Tooltip placement="top" title={t('annualPlan.delete')}>
            <ActionIcon className="deleteIcon" onClick={deleteAnnualPlanFunction}>
              <ReactSVG src={deletePlanIcon} />
            </ActionIcon>
          </Tooltip>
            )
          }
          </ActionIconsBox>
        </ThumbnailFooter>
        <UpdateAnnualPlanModal
          ref={updateAnnualPlanModalRef}
          fetchAnnualPlans={fetchAnnualPlans}
          chosenYear={year}
        />
      </ThumbnailWrapper>
  );
}

export default PlanThumbnail;

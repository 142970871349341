import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { ChangelogList, ChangeLogItem, ChangeLogWrapper, ChangeLogItemWrapper, ChangeLogTime, ChangeLogListItem, ChangeLogItemList, StyledList, PanelHeaderContent, NoChange, PanelHeader } from './ChangeLog.style';

const ChangeLog = ({ changelogData }) => {
  const { Panel } = Collapse;
  const { t } = useTranslation();

  const ColorHandler = (value) => {
    if (value === 'ADD') {
      return 'ADD';
    } if (value === 'REMOVE') {
      return 'REMOVE';
    } if (value === 'CHANGE') {
      return 'CHANGE';
    }
  }

  const reverseChangeLogData = changelogData?.changelog?.slice()?.reverse();

    const assignUniqueKeys = (array) => {
    let key = 0;
    return array.map((obj) => {
      // eslint-disable-next-line no-plusplus
      obj.key = key++;
      return obj;
    });
  }
  // eslint-disable-next-line no-unused-vars
  const objectsWithKeys = assignUniqueKeys(reverseChangeLogData);

  return (
    <ChangelogList>
      {(reverseChangeLogData?.length === 0) ? (<NoChange>{t('monthlyPlan.noChange')}</NoChange>) : (reverseChangeLogData?.map((item) => {
            return (
              <Collapse collapsible="header" defaultActiveKey={[0]}>
                <Panel
                  key={item.key}
                  header={(
                    <PanelHeader>
                      <PanelHeaderContent>{'Ver. ' + item.version}</PanelHeaderContent>
                      {' '}
                      <PanelHeaderContent>{'Br. izmjena: ' + item.entries.length}</PanelHeaderContent>
                    </PanelHeader>
                  )}
                >
                  <ChangeLogWrapper>
                    <ChangeLogItemWrapper>
                      <ChangeLogItem>
                        {item.userFullName}
                      </ChangeLogItem>
                      <ChangeLogItem>
                        {'Ver. ' + item.version}
                      </ChangeLogItem>
                    </ChangeLogItemWrapper>

                    <ChangeLogItemList>
                      {item.entries?.map((el) => {
                  return (
                    <ChangeLogListItem className={ColorHandler(el.type)}>
                      <StyledList>
                        <li style={{ paddingLeft: '-20px' }}>
                          {el.content}
                        </li>
                      </StyledList>
                    </ChangeLogListItem>
                    )
                })}
                    </ChangeLogItemList>
                    <ChangeLogTime>
                      {moment(item.time).format('DD.MM.YYYY HH:mm')}
                    </ChangeLogTime>
                  </ChangeLogWrapper>
                </Panel>
              </Collapse>
            )
        }))}
    </ChangelogList>
  )
}

export default ChangeLog;

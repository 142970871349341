/* eslint-disable default-case */
/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';
import { message, Upload, Button, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { UploadOutlined, CheckCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import withModal from '../../../core/components/withModals';
import FormModalStyled, { TitleText, StyledWrapper, Container, TableContainer, ResponseMessage, ResponseWrapper } from '../../../core/components/modals/FormModalStyled';
import { useAuthStateValue } from '../../../context/AuthContext';
import AButton from '../../../core/components/buttons/AButton';
// import { FilterSelectInput } from '../../Page.styled';
import { FilterSelectAddAnnualPlan } from '../../Page.styled';
import { engineApi } from '../../../services/engine.service';
import config from '../../../util/config';
import StatusCode from '../../../services/statusCode';

const UpdateAnnualPlanModal = ({ modalProps, closeDialog, fetchAnnualPlans, chosenYear }) => {
    const { t } = useTranslation();

    const { loggedUser, setLoggedUser } = useAuthStateValue();
    const [response, setResponse] = useState();
    const [showTableContainer, setShowTableContainer] = useState(true);
    const [file, setFile] = useState(null);
    const [generateButtonActive, setGenerateButtonActive] = useState(false);
    const location = loggedUser?.locationsData[0]?.value;

    const currentYear = new Date().getFullYear();
    // const yearOptions = [currentYear, currentYear + 1, currentYear + 2];
    const yearOptions = [chosenYear];
    const [year, setYear] = useState(chosenYear);

    const optionsArray = [];
    const optionsFunction = () => {
        yearOptions.forEach((item) => {
            optionsArray.push({
                key: item,
                value: item,
                label: item
            })
        });
        return optionsArray;
    }

    const fileUploadRef = useRef();

    const optionDefault = [];
    const defaultYearFunction = () => {
        optionDefault.push({
            key: chosenYear,
            value: chosenYear,
            label: chosenYear
        })
        return optionDefault;
    }

  const yearFunction = (value) => {
   setYear(value);
  }

  const props = {
    name: 'file',
    action: config.ENGINE_ENDPOINT_URL + `engine/sector-opening-schemes/upload-file/${year}`,
    headers: {
       authorization: 'authorization-text',
    },
    onChange(info) {
      setFile(info.file);
      if (info.file.status !== 'uploading') {
       setResponse(info?.file?.response);
      }
      if (info?.file?.status === 'error' || info?.file?.status === 'done') {
        setShowTableContainer(true);
      }
      if (info?.file?.status === 'done') {
        setGenerateButtonActive(true);
      } else {
        setGenerateButtonActive(false);
      }
    },
    fileList: file ? [file] : null
  };

  // /engine/sector-opening-schemes/set-approve-status/{id}
  const onQuit = () => {
    const schemeId = response?.id;
    closeDialog();
    const status = 'DISCARD';
    engineApi().patch(`/engine/sector-opening-schemes/set-approve-status/${schemeId}?approveStatus=${status}`);
    // patchApproveStatus(id, 'DISCARD');
  }

  // engine/sector-opening-schemes/save-and-regenerate-schedule/{schemeId}/{year}/{userId}
  const onAccept = () => {
    const schemeId = response?.id;
    const loggedUserId = loggedUser?.id;
    // engineApi().patch(`/engine/sector-opening-schemes/save-and-regenerate-schedule/${schemeId}/${year}/${loggedUserId}`);

    const getAnswer = async () => {
      try {
        const res = await engineApi().patch(`/engine/sector-opening-schemes/save-and-regenerate-schedule/${schemeId}/${year}/${loggedUserId}`);
        if (res?.status === StatusCode.OK) {
          closeDialog();
          message.success(t('annualPlan.updateSuccess'));
        }
        } catch (err) {
        closeDialog();
        if (err?.status === StatusCode.CONFLICT) {
          closeDialog();
          message.error('Ažuriranje godišnjeg plana za ' + year + ' godinu je već u toku. Molimo pokušajte kasnije.');
      }
      }
    };
    getAnswer();
    setTimeout(() => {
      fetchAnnualPlans(location);
    }, 500);
  }

  const userModalData = { id: loggedUser?.id };

  const fileStatus = (record) => {
    switch (record?.status) {
      case 'SUCCESS':
        return (
          <ResponseWrapper>
            <CheckCircleOutlined style={{ fontSize: '22px', color: 'green' }} />
            <ResponseMessage>{t('annualPlan.success')}</ResponseMessage>
          </ResponseWrapper>
        );
      case 'WORN':
        return (
          <ResponseWrapper>
            <ExclamationCircleOutlined style={{ fontSize: '22px', color: 'orange' }} />
            <ResponseMessage>{t('annualPlan.warning')}</ResponseMessage>
          </ResponseWrapper>
        );
      case 'ERROR':
        if (record?.data?.length > 15) {
        return (
          <ResponseWrapper>
            <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} />
            <ResponseMessage>{t('annualPlan.wrongFileType')}</ResponseMessage>
          </ResponseWrapper>
        );
        }
        return (
          <ResponseWrapper>
            <CloseCircleOutlined style={{ fontSize: '22px', color: 'red' }} />
            <ResponseMessage>{t('annualPlan.error')}</ResponseMessage>
          </ResponseWrapper>
        );
    }
  }

  let showTable;

  const showTableFunction = (record) => {
    switch (record?.status) {
      case 'SUCCESS':
        showTable = false;
        break;
      case 'WORN':
        showTable = true;
        break;
      case 'ERROR':
        if (record?.data?.length > 15) {
          showTable = false;
        } else {
          showTable = true;
        }
    }
    return showTable;
  }

  const closeFunction = () => {
    setShowTableContainer(false);
    setFile(null);
  }

  useEffect(() => {
    setFile(null);
    setShowTableContainer(false);
  }, [year]);

  useEffect(() => {
    if (file === null) {
      setGenerateButtonActive(false);
    }
  }, [file]);

  const ModalFooter = styled.div`
  border-top: 1px solid #eaeaea;
  width: 100%;
  display:flex;
  justify-content: right;
  padding: 10px 15px 0px 0px;
  gap: 15px;
`;

const ModalButton = styled(AButton)`
  border-radius: 5px !important;
  box-sizing: border-box;
  &:hover {
    border: none !important;
  }
  &:disabled {
    background-color: #858585 !important;
    &:hover {
      background-color: #858585 !important;
      border: none !important;
    }
  }
`;

const columns = [
  {
    title: 'ATCU',
    dataIndex: 'atcu',
    key: 'atcu',
    width: '2px'
  },
  {
    title: 'Datum od',
    dataIndex: 'dateFrom',
    key: 'dateFrom',
    width: '105px'
  },
  {
    title: 'Datum do',
    dataIndex: 'dateTo',
    key: 'dateTo',
    width: '105px'
  },
  {
    title: 'Vrijeme od',
    dataIndex: 'timeFrom',
    key: 'timeFrom',
    width: '110px'
  },
  {
    title: 'Vrijeme do',
    dataIndex: 'timeTo',
    key: 'timeTo',
    width: '110px'
  },
  {
    title: 'Poruka',
    dataIndex: 'description',
    key: 'description',
  }
]

  return (
    <FormModalStyled
      centered
      width="900px"
      footer={[]}
      {...modalProps}
      maskClosable={false}
      title={t('annualPlan.updateTitle')}
      afterClose={closeFunction}
    >
      <Container style={{ justifyContent: 'left', borderBottom: '1px solid #eaeaea' }}>
        <StyledWrapper style={{ justifyContent: 'left', marginLeft: '10px' }}>
          <TitleText>
            {t('annualPlan.year')}
          </TitleText>
          <FilterSelectAddAnnualPlan
            defaultValue={defaultYearFunction()}
            options={optionsFunction()}
            onChange={yearFunction}
          />
        </StyledWrapper>
        <StyledWrapper style={{ margin: '10px 0 5px 10px', justifyContent: 'left' }}>
          <TitleText>
            {t('annualPlan.openSchemeMessage')}
          </TitleText>
          <Upload
            ref={fileUploadRef}
            {...props}
            accept=".csv"
            maxCount={1}
          >
            {/* <ButtonStyled icon={<UploadOutlined />} style={{ width: '170px', borderRadius: '5px', height: '35px', marginLeft: '100px' }}>{t('annualPlan.importMessage')}</ButtonStyled> */}
            <ButtonStyledAddAnnualPlan icon={<UploadOutlined />}>{t('annualPlan.importMessage')}</ButtonStyledAddAnnualPlan>
          </Upload>
        </StyledWrapper>
      </Container>
      {showTableContainer && (
      <TableContainer>
        { fileStatus(response) }
        { showTableFunction(response) && <Table columns={columns} dataSource={response?.data} pagination={false} /> }
      </TableContainer>
) }
      <ModalFooter>
        <ButtonStyled style={{ marginRight: '3px' }} onClick={onQuit}>{t('annualPlan.cancel')}</ButtonStyled>
        <ButtonStyledSecond disabled={!generateButtonActive} onClick={onAccept}>{t('annualPlan.accept')}</ButtonStyledSecond>
      </ModalFooter>
    </FormModalStyled>
  );
};

UpdateAnnualPlanModal.propTypes = {
    modalProps: PropTypes.object,
    closeDialog: PropTypes.func.isRequired
  };

  export default withModal()(UpdateAnnualPlanModal);

  export const ButtonStyled = styled(Button)`
    &.ant-btn {
        border-radius: 5px !important;
        font-size: 15px;
        background-color: white !important;
        color: rgba(0,0,0,.85) !important;
        &:hover {
            background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
            color: rgba(0,0,0,.85) !important;
          //  border: none !important;
        }
        &:active{
            background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
            color: rgba(0,0,0,.85) !important;
          //  border: none !important;
        }
    }
  `;

  export const ButtonStyledSecond = styled(Button)`
  margin-right: '5px';
  &.ant-btn {
      border-radius: 5px !important;
      font-size: 15px;
      color: white !important;
      &:hover {
          background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
          color: rgba(0,0,0,.85) !important;
        //  border: none !important;
      }
      &:active{
          background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
          color: white !important;
        //  border: none !important;
      }
      &:disabled {
        background-color: #858585 !important;
        &:hover {
          background-color: #858585 !important;
         // border: none !important;
          color: white !important;
        }
      }
  }
  `;
  export const ButtonStyledAddAnnualPlan = styled(Button)`
    &.ant-btn {
        border-radius: 5px !important;
        font-size: 15px;
        background-color: white !important;
        color: rgba(0,0,0,.85) !important;
        width: 170px;
        height: 35px;
        margin-left: 100px;
        &:hover {
            background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
            color: rgba(0,0,0,.85) !important;
          //  border: none !important;
        }
        &:active{
            background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
            color: rgba(0,0,0,.85) !important;
          //  border: none !important;
        }
        @media only screen and (max-width: 650px) {
          margin-left: 0px;
        }
    }
  `;

import styled from 'styled-components';

export const StyledSheetCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  filter: ${(props) => (props?.isWeekend ? 'brightness(0.80) saturate(2)' : '')};
  opacity: ${(props) => (props?.isDisabled ? '0.3' : '1')};
  color: ${(props) => ((props?.alreadyMatterOfRequest) ? 'white' : '#393939')};
  position: relative;
  font-size: 85%;
  &:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    border-radius: 5px;
    left:-1px;
    right: -1px;
    margin: auto;
    z-index: -99;
    box-shadow: ${(props) => (props?.isSelected ? '0px 0px 0px 5.5px rgba(17, 78, 109, 1)' : '')};
  }
  background-color: ${(props) => (props?.shiftType ? props?.shiftType.color : (props?.absenceType ? '#FCF1A3' : 'white'))};
  background-color: ${(props) => {
    if (props?.alreadyMatterOfRequest) {
        return props?.alreadyMatterOfRequest ? 'red' : (props?.shiftType ? props?.shiftType.color : (props?.absenceType ? '#FCF1A3' : 'white'));
    }
    return props?.shiftType ? props?.shiftType.color : (props?.absenceType ? (props?.absenceType?.name === 'SB' ? '#dc9900' : '#FCF1A3') : 'white');
}};
  border: ${(props) => {
    if (props?.sheetMode === 'edit') {
        return props?.isModified ? '2px solid red !important' : 'none';
    }
    return 'none';
}};
  &.isSelectedForShiftSwap
  {
    opacity: 1 !important;
    background: linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(90deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%), linear-gradient(0deg, red 50%, transparent 50%), ${(props) => (props?.shiftType ? props?.shiftType.color : (props?.absenceType ? '#FCF1A3' : 'white'))};
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 8px 2px, 8px 2px, 2px 8px, 2px 8px;
    background-position: 0 0, 0 100%, 0 0, 100% 0;
    padding: 0px;
    animation: border-dance 4s infinite linear;
    @keyframes border-dance {
      0% {
        background-position: 0 0, 0 100%, 0 0, 100% 0;
      }
      100% {
        background-position: calc(400% + 3px) 0, calc(400% + 12px) 100%, 0 calc(-400% - 8px), 100% calc(400% + 12px);
      }
    }
  }
  &.hasWarning {
    background-color: red !important;
    color: white;
    font-weight: bold;
  }
`;

export const ShiftChangeSymbol = styled.div`
  width: 10px;
  height: 10px;
  top: -8px;
  left: 2px;
  position: absolute;
  & > * > * > svg {
    width: 10px;
  }
`;

export const StandbyIcon = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #dc9900;
  color: white;
  top: 0;
  left: 0;
  padding-top: 7px;
   & > * > * > svg {
    width: 17px;
  }
`;

export const ZSymbol = styled.div`
  width: 10px;
  height: 10px;
  bottom: 5px;
  left: 2px;
  position: absolute;
  color: ${(props) => (props?.pending ? '#a87d32' : 'green')};
  & > * > * > svg {
    width: 8px;
  }
`;

export const WarningSymbol = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  & div > div > svg {
    width: 12px;
  }
`;

export const BadgeSymbol = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 8px;
  right: 3px;
  color: RGBA(0, 0, 0, 0.3);
  & div > div > svg {
    width: 12px;
  }
`;

import styled from 'styled-components';

export const AnnualPlansFlexbox = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme: { spacings } }) => (spacings.horizontalSpacing)};
`;

export const NoAnnualPlansMessage = styled.div`
  font-size: x-large;
`;

export const NoAnnualPlansWrapper = styled.div`
  height: 75vh; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

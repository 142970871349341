import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Page } from '../Page';
import { WeeklySchedule } from './weeklySchedule/WeeklySchedule';
import { DailySchedule } from './dailySchedule/DailySchedule';
import { NotificationsPanel } from './notificationsPanel/NotificationsPanel';
import { AllNotificationsView } from './allNotificationsView/AllNotificationsView';
import { useAuthStateValue } from '../../context/AuthContext';
import NoPermissionView from './NoPermissionView/NoPermissionView';

const PAGE_KEY = 'dashboard';

const Dashboard = () => {
  const [seeAllNotifications, setSeeAllNotifications] = useState(false);
  const { t } = useTranslation();
  const pageTitle = t('main.dashboard');
  const { loggedUser } = useAuthStateValue();

  return (
    <Page
      title={pageTitle}
      pageKey={PAGE_KEY}
      content={
        (loggedUser?.permittedRoutes?.find((element) => element.key === 'dashboard') && loggedUser?.permittedRoutes?.length > 1) ? (
          seeAllNotifications
            ? (
              <>
                <AllNotificationsView
                  seeAllNotifications={seeAllNotifications}
                  setSeeAllNotifications={setSeeAllNotifications}
                />
              </>
            ) : (
              <>
                <WeeklySchedule />
                <DailySchedule />
                <NotificationsPanel
                  seeAllNotifications={seeAllNotifications}
                  setSeeAllNotifications={setSeeAllNotifications}
                />
              </>
            )
        ) : (
          <NoPermissionView />
        )
      }
    />
  )
}

export default Dashboard;

import styled from 'styled-components';
import { DatePicker } from 'antd';

const DateRangePickerSearchInput = styled(DatePicker.RangePicker)`
  &.ant-picker {
    // max-width: 240px;
    border-radius: 15px;
    // min-width: 190px;
    width: 290px;
    padding: 0px 11px;

    .ant-picker-input > input {
      font-size: 13px;
      height: 24px;
    }

    :hover {
      border-color: #d9d9d9;
      outline: none;
      overflow: hidden;
      box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
    }
  }
  &.ant-picker-focused {
    border-color: #d9d9d9;
    outline: none;
    overflow: hidden;
    box-shadow: none;
  }
`;

export default DateRangePickerSearchInput;

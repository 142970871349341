import styled from 'styled-components';

export const ThumbnailWrapper = styled.div`
  width: 24%;
  height: 180px;
  background-color: white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  position: relative;
  @media only screen and (max-width: 1700px) {
    width: 23% !important;
  }
  @media only screen and (max-width: 1400px) {
    width: 23% !important;
  }
`;

export const ThumbnailHeader = styled.div`
  height: 31px;
  width: 100%;
  color: white;
  padding: 5px 15px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  &.CHANGED {
    background-color: #F5853F;
  }
  &.GENERATED {
    background-color: #FAB10B;
  }
  @media only screen and (max-width: 1400px) {
    font-size: 10px;
    padding-top: 8px;
  }
`;

export const YearCaption = styled.div`
 font-size: 49px;
 text-align: center;
 padding-top: 40px !important;
 // font-family: MontserratWeb, regular, sans-serif !important;
 font-weight: 600;
 @media only screen and (max-width: 2100px) {
    font-size: 36px;
  }
 @media only screen and (max-width: 1900px) {
    font-size: 35px;
    height: 63%;
  }
  @media only screen and (max-width: 1400px) {
    font-size: 30px;
    height: 63%;
  }
  /* @media only screen and (max-width: 1400px) {
    font-size: 22px;
    height: 63%;
    padding-top: 45px !important;
  } */
`;

export const ThumbnailFooter = styled.div`
 height: 25%;
 width: 100%;
 position: absolute;
 bottom: 0;
 display: flex;
 justify-content: space-between;
 color: ${({ theme: { colors } }) => (colors.grayColors.darkGray)};
 @media only screen and (max-width: 1700px) {
    margin-bottom: 3px;
 }
`;

export const VersionTextSelect = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  padding: 8px 15px;
  span {
    color: ${({ theme: { colors } }) => (colors.grayColors.gray)} !important;
  }
  .ant-select-arrow {
    @media only screen and (max-width: 1700px) {
    margin-top: -4px;
    font-size: 11px;
 }
  }
  &.currentDay {
    span {
      color: white !important;
    }
    color: white;
    .ant-select-arrow {
      color: white;
    }
    .ant-select-selector {
      background: #0073AE;
    }
    }
`;
export const VersionText = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  padding: 13px 15px;
  span {
    color: ${({ theme: { colors } }) => (colors.grayColors.gray)} !important;
  }
    /* @media only screen and (max-width: 1400px) {
    padding: 10px 3px;
    } */
  &.currentDay {
    span {
      color: white !important;
    }
    color: white;
    .ant-select-arrow {
      color: white;
    }
    .ant-select-selector {
      background: #0073AE;
    }
    }
`;

export const ActionIconsBox = styled.div`
  display: flex;
  padding: 8px;
  gap: 10px;
    @media only screen and (max-width: 1400px) {
    padding: 10px 5px;
    gap: 5px;
    }
`;

export const ActionIcon = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  border: 1px solid ${({ theme: { colors } }) => (colors.grayColors.actionIconGray)};
  border-radius: 30px;
  background: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  color: ${({ theme: { colors } }) => (colors.grayColors.actionIconGray)} !important;
  @media only screen and (max-width: 1400px) {
   width: 25px;
   height: 25px;
   padding: 2px;
  }
  &:hover {
   color: white !important;
   background-color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
   border: none !important;
  }
  
  &.deleteIcon {
    background-color: ${({ theme: { colors } }) => (colors.secondaryColors.darkRed)};
    color: white !important;
    border: 1px solid white !important;
    &:hover {
      color: ${({ theme: { colors } }) => (colors.secondaryColors.darkRed)} !important;
      background-color: white;
      border: 1px solid ${({ theme: { colors } }) => (colors.secondaryColors.darkRed)} !important;
    }
  }
`;

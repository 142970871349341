const theme = {
  colors: {
    mainColors: {
      blue: '#0073ae',
      yellow: '#faba00',
      lightBlue: '#4d9dc6',
      white: '#FFF',
      green: '#0F8F3E',
      red: 'red',
      orange: 'orange',
      notificationsGreen: 'green'
    },
    secondaryColors: {
      lightRed: '#fe9397',
      lightBlue: '#549bea',
      darkRed: '#c30b12',
      darkBlue: '#124885'
    },
    grayColors: {
      panelBackgroundGray: '#EFEFEF',
      gray: '#393939',
      grayDark: '#c4c4c4',
      lightGray: '#e6e6e6',
      lighterGray: '#DEDEDE',
      borderLineGray: '#b6c7cf',
      black: '#312f2f',
      mediumGray: '#a7a7a7',
      mediumDarkGray: '#a5a5a5',
      darkGray: '#707070',
      actionIconGray: '#7D7D7D'
    }
  },
  fontSizes: [10, 12, 13, 16, 18, 21, 24, 32, 40, 44, 48],
  fontWeights: {
    light: 200,
    semiLight: 300,
    normal: 400,
    semiBold: 600,
    bold: 700
  },
  spacings: {
    verticalSpacing: '20px',
    horizontalSpacing: '20px'
  },
  sizes: [12, 14, 16, 18, 20, 24, 30, 32]
}

export default theme;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  background: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 50%;
  height: 100vh;
  width: 100%;
  flex-direction: row;

  @media (max-width: 1400px) {
    background: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 50%;
  }
`;

export const Panel = styled.div`
  margin-top: 200px;
  display: flex;
  width: 100%;
  height: 200px;
  flex-direction: row;
  justify-content: center;
`;

export const AppInfoContainer = styled.div`
  height: 200px;
  padding: 50px;
  box-sizing: border-box;
  color: white;
  & > h1 {
    color: white;
  }
`;

import styled from 'styled-components';

export const DayContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const TimelineHeader = styled.div`
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    user-select: none;
`;

export const HourCaption = styled.div`
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
    font-weight: bold;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: center;
`;

export const ScaleMark = styled.div`
     color: ${({ theme: { colors } }) => (colors.grayColors.darkGray)};
    display: flex;
    flex-direction: row;
    font-size: 8px;
    justify-content: center;
    @media only screen and (min-width: 1700px) {
    margin-left: 3px;
    }
`;

export const ObligationsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    height: 70%;
    margin-top: 2px;
    width: 100%;
    align-self: center;
    user-select: none;
    overflow: hidden;
    @media only screen and (max-width: 1700px) {
    width: 98%;
    margin-top: 5px;
    }
`;

export const ObligationsContainer = styled.div`
    background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)};
    color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    height: 70%;
    width: 100%;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    height: 80%;
    overflow: hidden;
    position: relative;
`;

export const ObligationCard = styled.div`
    width: ${(props) => (props?.duration * props?.hourCellWidth)}px;
    position: absolute;
    background-color: ${(props) => (props?.sectorRoleType === 'EXE' ? '#0F8F3E' : '#0073ae')};
    border-radius: 9px;
    font-weight: bold;
    font-size: 25px;
    height: 100%;
    box-sizing: border-box;
    color: white;
    left: ${(props) => (props?.timeFrom - props?.intervalFrom) * props?.hourCellWidth}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled.div`
    font-size: 20px;
    line-height: 20px;
    margin: 0 auto;
`;

export const RoleTypeLabel = styled.div`
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
`;

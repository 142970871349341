import styled from 'styled-components';
import { Input } from 'antd';

const TextSearchInput = styled(Input)`
  &.ant-input-affix-wrapper {
    border-radius: 15px;
    outline: none;
    border-color: ${({ theme: { colors } }) => colors.grayLight};
   // width: 100%;
    max-width: 300px;

    :hover,
    :active,
    :focus {
      border-color: ${({ theme: { colors } }) => colors.grayLight};
      box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
    }
  }

  &.ant-input-affix-wrapper-focused {
    box-shadow: none;
    outline: none;
    overflow: hidden;
    //position: absolute;
  }
`;

export default TextSearchInput;

import styled from 'styled-components';

export const DateTimeWrapper = styled.div`
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 3%;
    margin-right: 4%;
`;

export const DayDate = styled.div`
    font-size: 18px;
    display: flex;
    flex-direction: row;
`;

export const Day = styled.div`
    margin-right: 6px;
    font-weight: bold;
`;

export const TodaysDate = styled.div`
`;

export const Time = styled.div`
    margin-top: -10px;
    font-size: 50px;
    text-align: right;
`;

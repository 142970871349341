import { Button } from 'antd';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const NotificationPanel = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    display: inline-flex;
    justify-content: start;
    gap: 10px;
    overflow-y: scroll;
    height: 83vh;
    align-content: baseline;
    width: 100%;
    /* @media only screen and (max-width: 1700px) {
        height: 80vh;
    } */
    @media only screen and (max-width: 1500px) {
      //  height: 80vh;
      height: 77vh;
    }
`;

export const NotificationButtonWrapper = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: row;
    gap: 5px;
`;

export const NotificationButton = styled(Button)`
    &.ant-btn {
        background-color: ${({ theme: { colors } }) => (colors.mainColors.white)};
        color: ${({ theme: { colors } }) => (colors.grayColors.gray)};
        box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
        display: flex;
        padding: 0 25px;
        align-items: center;
        outline: none;
        border: none;

        div {
            display: flex;
        }

        span {
            margin-left: 8px;
        }
    }
    &.ant-btn:hover {
    border: none !important;
    background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)} !important;
    color: #312f2f !important;
  }
`;

export const NotificationWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    color: ${({ theme: { colors } }) => (colors.mainColors.black)};
    font-size: 16px;
    width: 24%;
    height: 20vh;
    border-radius: 10px;
    box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 20vh;
    @media only screen and (max-width: 1550px) {
       font-size: 14px;
    }
`;

export const NotificationHeader = styled.div`
    border-radius: 10px 10px 0px 0px;
    height: 20px;
`;

export const NotificationContent = styled.div`
    margin: 10px;
    overflow: hidden;
    height: 13vh;
`;

export const NotificationFooter = styled.div`
    position: absolute;
    bottom: 5px;
    right: 10px;
    display: flex;
   // justify-content: space-between;
    gap: 10px;
`;

export const StyledTrashCan = styled(ReactSVG)`
    cursor: pointer;
    display: inherit;
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
`;

export const CreatedTime = styled.div`
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
    font-size: 14px;
`;

import { useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { getAllDailySchedulesByMonth, generateDailySchedule } from '../../../services/dailyPlan.service';

const useDailyPlan = () => {
  const [loading, setLoading] = useState();
  const [dataSource, setDataSource] = useState();
  const { t } = useTranslation();

  const getAllSchedulesInMonth = async (payload) => {
    setDataSource(null);
    try {
      setLoading(true);
      const res = await getAllDailySchedulesByMonth(payload);
      setDataSource(res?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const generateSchedule = async (payload) => {
    setLoading(true);
    try {
      await generateDailySchedule(payload);
      message.success(t('dailySchedule.generateSuccess'));
    } catch (e) {
      console.log(e);
      message.error(t('dailySchedule.generateFail'));
    } finally {
      setLoading(false);
    }
  }

  return {
    dataSource,
    loading,
    getAllSchedulesInMonth,
    generateSchedule
  }
};

export default useDailyPlan;

import React, { Component } from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { CellPopupContent, StyledColumnCellSpan } from './Datatable.style';

export default class Cell extends Component {
    constructor(props) {
        super(props);
        this.cellRef = React.createRef();
        this.state = {
            hover: false
        };
    }

    handleMouseEnter = (e) => {
        this.setState((state) => !state.hover);
        e.preventDefault();
        e.stopPropagation();
    };

    handleMouseLeave = (e) => {
        this.setState((state) => !state.hover);
        e.preventDefault();
        e.stopPropagation();
    };

    render() {
        const { text, columnTitle, showHover } = this.props;

        const content = <CellPopupContent>{text}</CellPopupContent>;

        let shouldShow = false;

        if (this.cellRef.current) {
            if (this.cellRef.current.offsetWidth >= 500) {
                shouldShow = true;
            } else shouldShow = false;
        }

        return showHover && text && shouldShow && text.trim() !== '' ? (
          <Popover overlayStyle={{ maxWidth: 300 }} content={content} title={columnTitle}>
            <StyledColumnCellSpan
              ref={this.cellRef}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
              {this.state.hover ? 'Hoverovano' : text}
            </StyledColumnCellSpan>
          </Popover>
        ) : (
          <StyledColumnCellSpan
            ref={this.cellRef}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            {text}
          </StyledColumnCellSpan>
        );
    }
}

Cell.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    columnTitle: PropTypes.string,
    showHover: PropTypes.bool
};

// eslint-disable-next-line import/no-named-as-default
import engineApi from './engine.service';

// http://localhost:7025/engine/schedule/yearly/ATCU1/

export const getAllAnnualPlans = (atcu) => {
    return engineApi().get(`/engine/schedules/yearly/${atcu}`);
};

// localhost:7025/engine/yearly-plans/ATCU1/2024
export const removeAnnualPlan = (atcu, year) => {
    return engineApi().delete(`/engine/yearly-plans/${atcu}/${year}`);
}

// /engine/sector-opening-schemes/set-approve-status/{id}
// localhost:7025/engine/sector-opening-schemes/set-approve-status/68?approveStatus=DISCARD
export const setApproveStatus = (id, status) => {
    return engineApi().patch(`/engine/sector-opening-schemes/set-approve-status/${id}?approveStatus=${status}`);
}

import styled from 'styled-components';

export const SheetHeadingContainer = styled.thead`
  width: 100%;
  position: sticky !important;
  cursor: pointer;
  top: 5px;
  z-index: 5;
  & > tr {
    border: 1px solid red !important;
    height: 35px !important;
  }
`;

export const SideExpandContainer = styled.div`
  width: ${(props) => (props?.expanded ? (180 + (props?.columnsNumber * 40)) : '0')}px;
  opacity: ${(props) => (props?.expanded ? '1' : '0')};
  height: calc(100%);
  position: absolute;
  right: 135px;
  transition: all 0.5s;
  margin-top: 6px;
  background-color: #EFEFEF;
  padding-bottom: 6px;
  z-index: 9;
  overflow-y: scroll;
  overflow-x: hidden;
  outline: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
`;

export const SiderHeaderBackgroundMask = styled.div`
  opacity: ${(props) => (props?.visible ? '1.0' : '0.0')};
  background-color: #EFEFEF;
  position: sticky;
  width: ${(props) => (180 + (props?.columnsNumber * 40))}px;
  top: 0px;
  margin-top: -5px;
  height: 85px;
  z-index: 3;
`;

export const VerticalHighLightBarTip = styled.div`
  display: ${(props) => (props?.position && !props?.sideBarExpanded ? 'inline-block' : 'none')};
  left: ${(props) => props?.position}px;
  width: ${(props) => props?.cellSize + 9}px;
  position: fixed;
  height: 85.5px;
  background-color: #0073AE;
  border-radius: 5px 5px 0 0;
  z-index: 4;
  transition: all 0.2s;
`;

export const SidebarCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  height: 35px !important;
  background-color: white;
`;

export const HoursNumberCell = styled.th`
  background-color: #5F5F5F;
  width: 180px;
  color: white;
  border-radius: 5px;
  border: 1px solid #C5C5C5;
`;

export const TitleCell = styled.th`
  font-family: MontserratWeb-SemiBold;
  background-color: #5F5F5F;
  color: white;
  border-radius: 5px;
  border: 1px solid #C5C5C5;
  font-weight: 500;
  font-size: 90%;
`;

export const SideBarFooterCell = styled.td`
  border: 1px solid #C5C5C5;
  border-radius:5px;
  height: 30px;
`;

export const VerticalHighLightBar = styled.div`
  display: ${(props) => (props?.position && !props?.sideBarExpanded ? 'inline-block' : 'none')};
  margin-left: ${(props) => props?.position}px;
  width: ${(props) => props?.cellSize + 9}px;
  position: fixed;
  height: calc(100% - 280px);
  background-color: #0073AE;
  border-radius: 0 0 5px 5px;
  z-index: 2;
  transition: all 0.2s;
`;

export const HorizontalHighLightBar = styled.div`
  display: ${(props) => (props?.position && !props?.sideBarExpanded ? 'inline-block' : 'none')};
  top: ${(props) => props?.position}px;
  position: absolute;
  width: 100%;
  background-color: #0073AE;
  border-radius: 5px 0 0 5px;
  z-index: 2;
  height: 43px;
  transition: all 0.2s;
`;

export const SideBarFooter = styled.tfoot`
 width: 100%;
 position: sticky;
 bottom: 5px;
 background-color: #EFEFEF;
 z-index: 9;
 padding: 0 4px;
`;

export const SideBarFooterMask = styled.div`
  width: ${(props) => (props?.expanded ? (180 + (props?.columnsNumber * 40)) : '0')}px;
  visibility: ${(props) => (props?.visible ? 'visible' : 'hidden')};
  height: 45px;
  position: fixed;
  bottom: 20px;
  transition: all 0.5s;
  margin-bottom: 0px;
  background-color: #EFEFEF;
  z-index: 2;
`;

export const ShiftTypeCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  background-color: ${(props) => props?.color};
  &.selected {
    background-color: #0073AE !important;
    color: white;
    border: none;
    transition: all 0.5s;
  }
  height: 35px !important;
`;

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import AInput from '../inputs/AInput';
import AButton from '../buttons/AButton';
import StatusCode from '../../../services/statusCode';

const AForm = ({
  fieldsPerCol,
  cols,
  fields,
  okButtonText,
  modalData,
  onAddHandler,
  onEditHandler,
  closeDialog,
  dataSource,
  setDataSource,
  validationSchema,
  addAPI,
  editAPI,
  translationFieldsPrefix,
  modalAddHandler,
  modalEditHandler,
  addField,
  changePasswordHandler,
  changeUserNameHandler,
  name,
  verticallyPositionedLabels
}) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur'
  });

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  if (modalData) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(modalData)) {
      setValue(key, value);
    }
  }
  }, [modalData]);

  const defaultEditHandler = (obj, id) => {
    (async function () {
      try {
        const res = await editAPI(obj, id);
        if (res.status === StatusCode.OK) {
          obj.id = id;
          if (dataSource) {
            const array = [...dataSource];
            array[array.findIndex((obj) => obj.id === id)] = res.data;
            setDataSource(array);
          }
          closeDialog();
          message.success(t('messages.editOk'));
        } else {
          message.error(t('messages.editNotOk'));
        }
      } catch (err) {
        if (err.status === StatusCode.CONFLICT) {
          const translatedErrors = err.data.map((error) => t(`${translationFieldsPrefix}.${error}`, error));
          message.error(t('errors.uniqueFields', { fields: translatedErrors?.join(',') }));
        } else message.error(t('errors.operationFailed'));
        setLoading(false);
      }
    }());
  };

  const defaultAddHandler = (obj) => {
    (async function () {
      try {
        const res = await addAPI(obj);
        if (res.status === StatusCode.CREATED) {
          if (dataSource) {
            const array = [...dataSource];
            array.push(res.data);
            setDataSource(array);
          }
          closeDialog();
          message.success(t('messages.addOk'));
        } else {
          message.error(t('messages.addNotOk'));
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.status === StatusCode.CONFLICT) {
          const translatedErrors = err.data.map((error) => t(`${translationFieldsPrefix}.${error}`, error));
          if (translatedErrors) {
            message.error(t('errors.uniqueFields', { fields: translatedErrors?.join(',') }));
          } else {
            message.error(t('errors.operationFailed'));
          }
        } else message.error(t('errors.operationFailed'));
      }
    }());
  };

  const fabricalAddHandler = (obj) => {
    if (modalData === null) {
      if (onAddHandler) {
        onAddHandler(obj);
      } else if (modalAddHandler) {
        modalAddHandler(obj, setLoading);
      } else {
        defaultAddHandler(obj);
      }
    } else if (addField) {
      addField(obj, setLoading);
    } else if (onEditHandler) {
      onEditHandler(obj, setLoading);
    } else if (modalEditHandler) {
      modalEditHandler(obj, setLoading);
    } else if (changePasswordHandler) {
      changePasswordHandler(obj, setLoading);
    } else if (changeUserNameHandler) {
      changeUserNameHandler(obj, setLoading);
    } else if (modalData) {
      modalAddHandler(obj, setLoading);
    } else {
      defaultEditHandler(obj, setLoading);
    }
  };
  const onSubmit = (obj) => {
    setLoading(true);
    setTimeout(() => {
      reset();
    }, 2000);
    fabricalAddHandler(obj);
  };

  const columns = [];
  let tempArr = [];

  fieldsPerCol = fieldsPerCol || 9;
  cols = cols || 1;

  fields.forEach((field, index) => {
    if (index % fieldsPerCol === 0) {
      tempArr = [];
      columns.push(tempArr);
    }
    tempArr.push(field);
  });

  return (
    <Form name={name} cols={cols} onSubmit={handleSubmit(onSubmit)}>
      <FormBox>
        {columns.map((column, index) => (
          <FormColumn key={index}>
            {column.map((inputField) => (
              <Controller
                key={inputField.id}
                name={inputField.id}
                defaultValue={modalData ? modalData[inputField.id] : ''}
                control={control}
                render={({ field }) => (
                  <AInput
                    inputField={inputField}
                    errors={errors[inputField.id]}
                    text={inputField.name}
                    fieldProps={field}
                    disabled={!!((modalData && inputField.disableOnEdit) || inputField.disabled)}
                    verticallyPositionedLabels={verticallyPositionedLabels}
                  />
                )}
              />
                ))}
          </FormColumn>
          ))}
      </FormBox>
      <Footer>
        <FormButton disabled={loading} htmlType="submit">
          {okButtonText}
        </FormButton>
        <SpinnerBox>
          <Spin spinning={loading} />
        </SpinnerBox>
      </Footer>
    </Form>
  );
};

const FormBox = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-right: 2px dashed gainsboro;
  :last-child {
    border-right: none;
  }
  padding-left: 25px;
 // padding-right: 8px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
 // gap: 14px;
  width: ${(props) => props.cols * 520};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #f0f0f0;
 // margin-top: 15px;
  padding-top: 10px;
  padding-right: 20px;
`;

export const FormButton = styled(AButton)`
  &.ant-btn {
   // border-radius: 14px;
    border-radius: 5px !important;
    font-size: 15px;
    &:hover {
      color: white !important;
    }
  }
`;

const SpinnerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 33px;
  padding-top: 4px;
`;

AForm.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
      id: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.number,
          value: PropTypes.string
        })
      )
    })
  ),
  okButtonText: PropTypes.string,
  // values: PropTypes.any,
  onAddHandler: PropTypes.func,
  onEditHandler: PropTypes.func,
  closeDialog: PropTypes.func,
  dataSource: PropTypes.arrayOf(PropTypes.object),
  setDataSource: PropTypes.func,
  validationSchema: PropTypes.object,
  fieldsPerCol: PropTypes.number,
  cols: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  modalData: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  addAPI: PropTypes.func,
  editAPI: PropTypes.func,
  translationFieldsPrefix: PropTypes.string
};

export default AForm;

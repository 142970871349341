import { useRef } from 'react';
import { ShiftContainerWrapper, ObligationsContainer } from './ShiftContainer.style';
import ObligationCard from '../obligationCard/ObligationCard';
import ContextMenu from '../contextMenu/ContextMenu';
import { hoursCountToTimeString, timeStringToHoursCount } from '../../../../util/util';

const ShiftContainer = (props) => {
  const {
    controllerId,
    timeFrom,
    timeTo,
    hourCellWidth,
    timeLineWidth,
    modifiedData,
    setModifiedData,
    obligations,
    setTooltipContent,
    setTooltipVisible,
    interval,
    atcu,
    dataSource,
    editModeOn
  } = props;

  const shiftContainerRef = useRef();

  const partiallyTomorrow = timeFrom > timeTo;

  const from = timeStringToHoursCount(timeFrom, false);
  let to = timeStringToHoursCount(timeTo, true);
  if (partiallyTomorrow) to += 24;

  const shiftDurationInHours = to - from;

  const addObligationHandler = () => {
    const mouseX = window.event.clientX;
    const shiftContainerX = Math.floor(shiftContainerRef?.current?.getBoundingClientRect()?.x);
    const position = mouseX - shiftContainerX;
    const addTimeInt = ((Math.floor(position / ((hourCellWidth) / 2))) * 0.5) + from
    const addTimeInteger = addTimeInt < 24 ? addTimeInt : (addTimeInt - 24);
    const addTimeHours = Math.floor(addTimeInteger);
    const addTimeMinutes = (addTimeInteger - addTimeHours) * 60;
    const addTimeToMinutes = addTimeMinutes === 0 ? 29 : 59;
    let addTimeToHours;
    if (addTimeToMinutes === 59) addTimeToHours = Math.floor(addTimeInteger);
    else if (addTimeToMinutes === 29) addTimeToHours = Math.floor(addTimeInteger);
    if (addTimeToHours >= 24) addTimeToHours -= 24;
    let addTimeToInteger = addTimeInteger + 0.5;
    if (partiallyTomorrow && addTimeToInteger < 15) addTimeToInteger += 24;

    const lastHalfHour = addTimeToInteger > to;

    const addTimeFromString = (addTimeHours?.toString()?.length === 1 ? ('0' + addTimeHours?.toString()) : addTimeHours) + ':' + (addTimeMinutes === 0 ? '00' : addTimeMinutes) + ':00';
    const addTimeToString = (addTimeToHours?.toString()?.length === 1 ? ('0' + addTimeToHours?.toString()) : addTimeToHours) + ':' + addTimeToMinutes + ':00';

    const occupiedInModifiedData = !!modifiedData?.[modifiedData?.length - 1]?.find((el) => {
      let elTimeFrom = timeStringToHoursCount(el?.timeFrom, false);
      let elTimeTo = timeStringToHoursCount(el?.timeTo, true);
      if (partiallyTomorrow && elTimeFrom < 15) elTimeFrom += 24;
      if (partiallyTomorrow && elTimeTo < 15) elTimeTo += 24;

      return (elTimeFrom - 0.5) < addTimeInteger && elTimeTo > addTimeInteger && el?.controllerId === controllerId && el?.type !== 'REMOVE';
    });

    const occupiedInOriginalData = obligations?.find((el) => {
      let elTimeFrom = timeStringToHoursCount(el?.timeFrom, false);
      let elTimeTo = timeStringToHoursCount(el?.timeTo, true);
      if (partiallyTomorrow && elTimeFrom < 15) elTimeFrom += 24;
      if (partiallyTomorrow && elTimeTo < 15) elTimeTo += 24;
      const elTimeFromString = hoursCountToTimeString(elTimeFrom, false);
      const elTimeToString = hoursCountToTimeString(elTimeTo, true);
      const startTimeInRange = [elTimeFromString, addTimeFromString]?.sort()?.[1] === addTimeFromString && [elTimeToString, addTimeFromString]?.sort()?.[0] === addTimeFromString;
      const endTimeInRange = [elTimeFromString, addTimeToString]?.sort()?.[1] === addTimeToString && [elTimeToString, addTimeToString]?.sort()?.[0] === addTimeToString;

      const obligationDeleted = !!modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => modifiedEl?.type === 'REMOVE' && modifiedEl?.controllerId === controllerId && modifiedEl?.timeFrom === el?.timeFrom && modifiedEl?.timeTo === el?.timeTo);
      return !obligationDeleted && (startTimeInRange || endTimeInRange);
    });
    const occupied = occupiedInModifiedData || occupiedInOriginalData;
    if (!occupied && !lastHalfHour) {
      setModifiedData((prevState) => [
        ...(prevState ? prevState : []),
        [
          ...(prevState?.[prevState?.length - 1] ? prevState?.[prevState?.length - 1] : []),
          {
            controllerId,
            timeFrom: addTimeFromString,
            timeTo: addTimeToString,
            type: 'ADD',
            sectorId: undefined,
            sectorRoleId: undefined
          }
        ]
      ]);
    }
  };

  const renderObligations = () => {
    const obligationsArray = [];
    obligations?.forEach((el) => {
      const isRemoved = modifiedData?.[modifiedData.length - 1]?.find((modifiedEl) => (
          (modifiedEl?.timeFrom === el?.timeFrom && modifiedEl?.timeTo === el?.timeTo)
          && (modifiedEl?.type === 'REMOVE')
          && (modifiedEl?.controllerId === controllerId)
      ));
      if (!isRemoved) {
        obligationsArray.push(el);
      }
    });
    modifiedData?.[modifiedData.length - 1]?.forEach((el) => {
      if (el?.controllerId === controllerId && el?.type !== 'REMOVE') {
        obligationsArray.push(el);
      }
    });
    const obligationsArraySorted = obligationsArray.sort((a, b) => {
      let aTimeFromNumber = timeStringToHoursCount(a?.timeFrom, false);
      let bTimeFromNumber = timeStringToHoursCount(b?.timeFrom, false);
      if (partiallyTomorrow && aTimeFromNumber < 10) aTimeFromNumber += 24;
      if (partiallyTomorrow && bTimeFromNumber < 10) bTimeFromNumber += 24;
      return (aTimeFromNumber - bTimeFromNumber);
    });

    const arrayToBeRendered = [];
    let previousOblInterval;
    let previousOblStreak = 0;
    obligationsArraySorted?.forEach((el, index) => {
      let elTimeFromNumber = timeStringToHoursCount(el?.timeFrom, false);
      let elTimeToNumber = timeStringToHoursCount(el?.timeTo, true);
      if (partiallyTomorrow && elTimeFromNumber < 10) elTimeFromNumber += 24;
      if (partiallyTomorrow && elTimeToNumber < 10) elTimeToNumber += 24;
      const oblDuration = elTimeToNumber - elTimeFromNumber;

      arrayToBeRendered.push(
        <ContextMenu
          key={'cm-' + controllerId + '-' + from + '-' + to + '-' + index}
          modifiedData={modifiedData}
          setModifiedData={setModifiedData}
          modified={(el?.sectorId && !el?.sector?.id) || (!el?.sectorId && !el?.sector?.id)}
          controllerId={controllerId}
          timeFrom={el?.timeFrom}
          timeTo={el?.timeTo}
          sectorId={el?.sectorId ? el?.sectorId : el?.sector?.id}
          sectorRoleId={el?.sectorRoleId ? el?.sectorRoleId : el?.sectorRole?.id}
          shiftTimeFrom={from}
          shiftTimeTo={to}
          obligations={obligations}
          editModeOn={editModeOn}
        >
          <ObligationCard
            key={'oc-' + controllerId + '-' + from + '-' + to + '-' + index}
            modified={(el?.sectorId && !el?.sector?.id) || (!el?.sectorId && !el?.sector?.id)}
            controllerId={controllerId}
            timeFrom={el?.timeFrom}
            timeTo={el?.timeTo}
            sectorId={el?.sectorId ? el?.sectorId : el?.sector?.id}
            sectorRoleId={el?.sectorRoleId ? el?.sectorRoleId : el?.sectorRole?.id}
            shiftTimeFrom={from}
            shiftTimeTo={to}
            hourCellWidth={hourCellWidth}
            shiftDurationInHours={shiftDurationInHours}
            modifiedData={modifiedData}
            setModifiedData={setModifiedData}
            obligations={obligations}
            setTooltipVisible={setTooltipVisible}
            setTooltipContent={setTooltipContent}
            interval={interval}
            shiftPartiallyTomorrow={partiallyTomorrow}
            atcu={atcu}
            dataSource={dataSource}
            editModeOn={editModeOn}
            timeStreak={(previousOblInterval?.[1] === elTimeFromNumber) ? (oblDuration + previousOblStreak) : oblDuration}
          />
        </ContextMenu>
      );
      previousOblStreak = (previousOblInterval?.[1] === elTimeFromNumber) ? (oblDuration + previousOblStreak) : oblDuration;
      previousOblInterval = [elTimeFromNumber, elTimeToNumber];
    });

    return (<>{arrayToBeRendered}</>);
  };

  return (
    <ShiftContainerWrapper
      interval={interval}
      ref={shiftContainerRef}
      from={from}
      to={to}
      hourCellWidth={hourCellWidth}
      timeLineWidth={timeLineWidth}
      shiftDuration={shiftDurationInHours}
      onMouseDown={(event) => {
        if (event.detail === 2 && editModeOn) addObligationHandler()
      }}
    >
      <ObligationsContainer>
        {renderObligations()}
      </ObligationsContainer>
    </ShiftContainerWrapper>
  );
};

export default ShiftContainer;

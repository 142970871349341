/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Tooltip, message } from 'antd';
import { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { QuestionOutlined } from '@ant-design/icons'
import {
    ActionIconsBox,
    ThumbnailFooter,
    ThumbnailHeader,
    ThumbnailWrapper,
    VersionText,
    VersionTextSelect,
    MonthCaption,
    ActionIcon,
} from './PlanThumbnail.style';
import planSeeIcon from '../../../assets/images/icons/eyeIcon.svg';
import planEditIcon from '../../../assets/images/icons/penIcon.svg';
import planAddIcon from '../../../assets/images/icons/Group 137.svg';
import regenerateIcon from '../../../assets/images/icons/wand-magic-sparkles-solid.svg';
import planStatusTypes from '../../../util/planStatusTypes.json';
import { useApplicationStateValue } from '../../../context/ApplicationContext';
import { useAuthStateValue } from '../../../context/AuthContext';
import { FilterSelectVersion } from '../../Page.styled';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import { regenerateMonth } from '../../../services/monthlyPlan.service';
import StatusCode from '../../../services/statusCode';

const PlanThumbnail = ({ atcu, month, versions, year, version, userId, monthlyPlanRefreshHandler, annualPlanVersion }) => {
  const { t } = useTranslation();
  const { language } = useApplicationStateValue();
  const { loggedUser } = useAuthStateValue();
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [correspondingPlanStatusType, setCorrespondingPlanStatusType] = useState(null);

  const [planVersion, setPlanVersion] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);

  const [regenerate, setRegenerate] = useState(false);
  const [seeStatus, setSeeStatus] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [generateStatus, setGenerateStatus] = useState(false);
  const history = useHistory();

  const changeVersionHandler = (event) => {
    const result = versions?.find((item) => item.id === event);
    setPlanVersion(result?.version);
    versions?.map((option, index) => {
    if (event === option?.id) {
      setPlanStatus(option?.status);
    }
    });
    };
  useEffect(() => {
    if (planVersion === null) {
      setPlanVersion(versions?.[0]?.version);
      setPlanStatus(versions?.[0]?.status);
    }
      setCorrespondingPlanStatusType(planStatusTypes?.monthly?.find((element) => element?.key === planStatus));
    }, [planVersion]);

    const matchForSelectedLocation = loggedUser?.locationsData?.find((item) => item.identificator === atcu);

  // Available values : APPROVE_AND_ACTIVATE, DISPLAY, GENERATE_AND_MODIFY, NONE
  useEffect(() => {
    if (planVersion === null) {
      setPlanVersion(versions?.[0]?.version);
      setPlanStatus(versions?.[0]?.status);
    }
    setPermissionStatus(loggedUser?.localPermissions[matchForSelectedLocation?.key]?.monthlyPlan);
    switch (permissionStatus) {
      case 'APPROVE_AND_ACTIVATE':
        setSeeStatus(true);
        setEditStatus(true);
        setRegenerate(true);
        setGenerateStatus(false);
        break;
      case 'DISPLAY':
        setSeeStatus(true);
        setEditStatus(false);
        setRegenerate(false);
        setGenerateStatus(false);
        break;
      case 'GENERATE_AND_MODIFY':
        setSeeStatus(true);
        setRegenerate(true);
        setEditStatus(true);
        setGenerateStatus(true);
        break;
      case 'NONE':
        setSeeStatus(false);
        setEditStatus(false);
        setRegenerate(false);
        setGenerateStatus(false);
        break;
      default:
        setSeeStatus(false);
        setEditStatus(false);
        setGenerateStatus(false);
    }
  }, [permissionStatus]);

  let justifyContent;
  let textColor;

  const seePlanHandler = (year, month) => {
    history.push('/monthlyPlan/' + year + '/' + month + '/view');
  };

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  let footerTextColor;
  let panelBackgroundColor = correspondingPlanStatusType?.panelColor;
  let iconBorder;
  let headerBottomBorder = '0px';
  if (month === currentMonth && year === currentYear) {
    panelBackgroundColor = '#0073ae';
    footerTextColor = 'white';
    textColor = 'white';
    iconBorder = '1px solid white';
    headerBottomBorder = '1px solid white';
  }

const TitleCardHandler = () => {
  return (
    <Tooltip placement="bottom">
      {(correspondingPlanStatusType?.key === planStatus) ? (correspondingPlanStatusType?.title?.sr) : ''}
    </Tooltip>
  )
}

const regenerateQuestion = (
  <div>
    <p>
      Da li ste sigurni da želite ponovo generisati mjesečni raspored za
      {' '}
      <b>{t('monthlyPlan.' + month) + ' ' + year}</b>
      .? Ova operacija će kreirati novu verziju mjesečnog rasporeda.
    </p>
  </div>
)

const RegenerateHandler = () => {
  const versionFound = versions?.find((item) => item?.version === version);
  confirmModal({
    centered: true,
    icon: <QuestionOutlined />,
    title: t('monthlyPlan.regenerateTitle'),
    content: regenerateQuestion,
    okText: t('buttons.yes'),
    cancelText: t('buttons.no'),
    onOk: async () => {
      try {
        const res = await regenerateMonth(versionFound?.id, userId);
        if (res.status == StatusCode.OK) {
          message.success(t('monthlyPlan.regenerationStarted'))
          setTimeout(() => {
            monthlyPlanRefreshHandler();
          }, 1000);
        } else {
          message.error(t('monthlyPlan.regenerationFailed'))
        }
      } catch (err) {
        if (err?.status === StatusCode.CONFLICT) {
          message.error('Regenerisanje mjesečnog rasporeda za ' + (t('monthlyPlan.' + month)) + ' ' + year + '. godine je već u toku. Molimo pokušajte kasnije.')
        }
        console.log('err');
      }
},
  });
}
  return (
    <ThumbnailWrapper className={(month === currentMonth && year === currentYear) ? 'currentMonth' : ''} style={{ backgroundColor: panelBackgroundColor }}>
      <ThumbnailHeader style={{ backgroundColor: correspondingPlanStatusType?.headerColor, borderBottom: headerBottomBorder }}>
        {TitleCardHandler()}
      </ThumbnailHeader>
      <MonthCaption style={{ color: textColor }}>{t('monthlyPlan.' + month) + ' ' + year + '.'}</MonthCaption>
      <ThumbnailFooter className={versions?.status} style={{ justifyContent, color: footerTextColor }}>
        {
          (correspondingPlanStatusType?.key !== 'BEING_GENERATED') && (
        (
          (permissionStatus === 'DISPLAY' || permissionStatus === 'NONE' || versions?.length === 1)
          ? (

            <VersionText className={(month === currentMonth && year === currentYear) ? 'currentMonth' : ''}>
              {' '}
              {t('monthlyPlan.currentVersion') + ' '}
              <b>{versions[0]?.version}</b>
            </VersionText>
          ) : (
            <VersionTextSelect className={(month === currentMonth && year === currentYear) ? 'currentMonth' : ''}>
              <FilterSelectVersion
                onChange={changeVersionHandler}
                defaultValue={t('monthlyPlan.currentVersion') + ' ' + versions[0]?.version}
              >
                {versions?.map((option, index) => (
                  <option key={index} value={option.id}>
                    {t('monthlyPlan.currentVersion') + ' ' + option?.version}
                  </option>
                  ))}
              </FilterSelectVersion>
            </VersionTextSelect>
              )
        )
        )
}
        <ActionIconsBox className={(month === currentMonth && year === currentYear) ? 'currentMonth' : ''}>
          { ((((month >= currentMonth && year === currentYear) || year > currentYear) && correspondingPlanStatusType?.key === planStatus) && correspondingPlanStatusType?.actions?.includes('REGENERATE') && regenerate)
           && (
           <Tooltip placement="top" title={t('monthlyPlan.regenerate')}>
             <ActionIcon style={{ border: iconBorder }} onClick={RegenerateHandler}>
               <ReactSVG src={regenerateIcon} style={{ color: footerTextColor, width: '20px', height: '20px' }} />
             </ActionIcon>
           </Tooltip>
          )}
          { ((correspondingPlanStatusType?.key === planStatus) && correspondingPlanStatusType?.actions?.includes('SEE') && seeStatus)
           && (
           <Tooltip placement="top" title={t('monthlyPlan.see')}>
             <NavLink to={'/monthlyPlan/' + atcu + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + planVersion + '/view'}>
               <ActionIcon style={{ border: iconBorder }}>
                 <ReactSVG src={planSeeIcon} style={{ color: footerTextColor }} />
               </ActionIcon>
             </NavLink>
           </Tooltip>
          )}
          {((correspondingPlanStatusType?.key === planStatus) && correspondingPlanStatusType?.actions?.includes('EDIT') && editStatus)
           && (
           <Tooltip placement="top" title={t('monthlyPlan.edit')}>
             <NavLink to={'/monthlyPlan/' + atcu + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + planVersion + '/edit'}>
               <ActionIcon style={{ border: iconBorder }}>
                 <ReactSVG src={planEditIcon} style={{ color: footerTextColor }} />
               </ActionIcon>
             </NavLink>
           </Tooltip>
          )}
          { ((correspondingPlanStatusType?.key === planStatus) && correspondingPlanStatusType?.actions?.includes('GENERATE') && generateStatus)
          && (
          <Tooltip placement="top" title={t('monthlyPlan.generate')}>
            <ActionIcon className={versions?.status + 'generateIcon'}>
              <ReactSVG src={planAddIcon} />
            </ActionIcon>
          </Tooltip>
          )}
        </ActionIconsBox>
      </ThumbnailFooter>
    </ThumbnailWrapper>
  );
}

export default PlanThumbnail;

import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { ShiftWrapper, ShiftHeader, DayText, DateText, ShiftContent } from './WeeklySchedule.style';
import { Panel } from '../../../core/components/panel/Panel';
import { useWeeklySchedule } from './hooks/weeklySchedule';
import { useAuthStateValue } from '../../../context/AuthContext';
import { formater } from '../../../util/util';
import standbyIcon from '../../../assets/images/icons/clock-regular.svg';

export const WeeklySchedule = () => {
  const { loggedUser } = useAuthStateValue();
  const { t } = useTranslation();
  const weekTitle = t('weeklySchedule.title');
  const weekSubtitle = t('weeklySchedule.subTitle');

  const { fetchWeeklySchedule, dataSource } = useWeeklySchedule();

  const date = new Date();
  const { formatDate } = formater();
  const dateFinal = formatDate(date);

  useEffect(() => {
    fetchWeeklySchedule(loggedUser?.id, dateFinal, 10);
  }, []);

  return (
    <Panel
      title={weekTitle}
      subTitle={weekSubtitle}
      hasBackgroundColor
      arrowSignVisible
      actionBoxVisible={false}
    >
      {dataSource?.map((item, index) => {
        const newItem = item.date.slice(0, 10);
        const [year, month, day] = newItem.split('-');
        const date = new Date(year, month - 1, day);
        function addLeadingZero(n) {
          return n < 10 ? `0${n}` : n;
        }
        const dateFinal = addLeadingZero(date.getDate()) + '.' + addLeadingZero(1 + date.getMonth()) + '.';
        let dayFinal;

        // eslint-disable-next-line default-case
        switch (date.getDay()) {
          case 0:
            dayFinal = t('weeklySchedule.sunday');
            break;
          case 1:
            dayFinal = t('weeklySchedule.monday');
            break;
          case 2:
            dayFinal = t('weeklySchedule.tuesday');
            break;
          case 3:
            dayFinal = t('weeklySchedule.wednesday');
            break;
          case 4:
            dayFinal = t('weeklySchedule.thursday');
            break;
          case 5:
            dayFinal = t('weeklySchedule.friday');
            break;
          case 6:
            dayFinal = t('weeklySchedule.saturday');
            break;
          }

          let scheduleT;
          let colored = false;
          let coloredAbsence = false;
          let isStandby = false;
          if (item.isShift) {
            scheduleT = item.shiftName;
            colored = true;
          } else if (item.isAbsence) {
            if (item?.absenceName === 'SB') {
              isStandby = true;
              scheduleT = 'SB';
            } else {
              scheduleT = item.absenceName;
              coloredAbsence = true;
            }
          }

            return (
              <ShiftWrapper key={index}>
                <ShiftHeader>
                  <DayText>{dayFinal}</DayText>
                  <DateText>{', ' + dateFinal}</DateText>
                </ShiftHeader>
                <ShiftContent className={colored ? 'coloredDiv' : (coloredAbsence ? 'coloredAbsence' : (isStandby ? 'coloredStandBy' : ''))}>
                  {scheduleT === 'none' ? ' ' : (scheduleT === 'SB' ? (<ReactSVG src={standbyIcon} />) : scheduleT)}
                </ShiftContent>
              </ShiftWrapper>
            )
          })}
    </Panel>
  )
}

import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
`;

export const SheetHeadingContainer = styled.thead`
  width: 100%;
  position: sticky !important;
  top: 5px;
  z-index: 5;
  cursor: pointer;
  & > tr {
    height: 35px !important;
  }
`;

export const DayInMonthCell = styled.th`
  position: relative;
  border: 1px solid #C5C5C5;
  border-radius: 5px !important;
  background-color: ${(props) => (props?.hasWarning ? 'red !important' : '#EFEFEF')};
  color: ${(props) => (props?.hasWarning ? 'white' : 'black')} !important;
  font-size: 80%;
  &.NED, &.SUB {
    border: 1px solid #C5C5C5 !important;
    color: #C5C5C5 !important;
  };
  &.selected {
    background: ${(props) => (props?.hasWarning ? 'red !important' : '#0073AE')};;
    color: white !important;
    border: none !important;
  }
  &.selected.NED, &.selected.SUB {
    color: #6c97ad !important;
  }
  transition: all 0.5s;
`;

export const TableComponent = styled.table`
 position: relative;
 width: 100%;
 table-layout: fixed;
 border-collapse:separate;
 border-spacing: 5px;
 font-family: Montserrat;
 overflow-x: hidden;
 & > th {
   cursor: pointer;
 }
 & td {
  font-weight: 500;
  text-align: center;
  border: 1px solid #C5C5C5;
  border-radius: 5px !important;
  cursor: pointer;
  height: 35px;
 }
 & tr {
   position: relative;
   z-index: 2 !important;
 }
`;

export const HoursNumberCell = styled.th`
  background-color: #5F5F5F;
  width: 180px;
  color: white;
  border-radius: 5px;
  border: 1px solid #C5C5C5;
  font-weight: 500;
`;

export const ExpandSiderButtonCell = styled.th`
  font-family: MontserratWeb-SemiBold;
  background-color: #5F5F5F;
  width: 120px;
  color: white;
  border-radius: 5px;
  border: 1px solid #C5C5C5;
  font-weight: 500;
  text-align: center;
`;

export const MainSheetContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  outline: none;
  padding-top: 5px;
`;
export const WhiteOverlay = styled.div`
  position: fixed;
  width: ${(props) => (props?.mode === 'sidebar' ? 'calc(100% - 260px)' : 'calc(100% - 110px)')};
  display: ${(props) => (props?.mode === 'sidebar' || props?.mode === 'footer' ? 'inline-block' : 'none')};
  height: ${(props) => (props?.mode === 'footer' ? 'calc(100% - 200px)' : '100%')};
  background-color: rgba(239, 239, 239, 0.7);
  transition: all 0.5s;
  z-index: 8;
`;

export const EmployeeNameCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  width: auto;
  text-wrap: no-wrap;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${(props) => (props?.isShiftManager ? '#F6C8BD' : (props?.isSatco ? '#E9D6FD' : '#efefef'))};
  border: 1px solid #C5C5C5;
  padding: 0 5px;
  text-align: left !important;
  font-weight: 500;
  color: #393939;
  &.selected {
    color: white;
    background: transparent !important;
    border: 0px !important;
  }
  transition: all 0.5s;
`;

export const HorizontalHighLightBar = styled.div`
  display: ${(props) => (props?.position && !props?.hidden ? 'inline-block' : 'none')};
  top: ${(props) => props?.position}px;
  position: absolute;
  width: 100%;
  background-color: #0073AE;
  border-radius: 5px;
  z-index: 1;
  height: 42px;
  transition: all 0.2s;
`;

export const VerticalHighLightBar = styled.div`
  display: ${(props) => (props?.position && !props?.hidden ? 'inline-block' : 'none')};
  left: ${(props) => props?.position}px;
  width: ${(props) => props?.cellSize + 7}px;
  position: fixed;
  height: ${(props) => props?.height - 20}px;
  background-color: #0073AE;
  border-radius: 5px;
  z-index: 2;
  transition: all 0.2s;
`;

export const VerticalHighLightBarTip = styled.div`
  display: ${(props) => (props?.position && !props?.hidden ? 'inline-block' : 'none')};
  left: ${(props) => props?.position}px;
  width: ${(props) => props?.cellSize + 7}px;
  position: fixed;
  height: 80px;
  background-color: #0073AE;
  border-radius: 5px 5px 0 0;
  z-index: 4;
  transition: all 0.2s;
`;

export const VerticalHighLightBarBottomTip = styled.div`
  display: ${(props) => (props?.position && !props?.hidden ? 'inline-block' : 'none')};
  left: ${(props) => props?.position}px;
  width: ${(props) => props?.cellSize + 7}px;
  position: fixed;
  height: 45px;
  background-color: #0073AE;
  border-radius: 0 0 5px 5px;
  bottom: 20px;
  z-index: 4;
  transition: all 0.2s;
`;

export const HeaderBackgroundMask = styled.div`
  opacity: ${(props) => (props?.visible ? '1.0' : '0.0')};
  background-color: #EFEFEF;
  width: calc(100% - 120px);
  height: 90.5px;
  position: fixed;
  margin-top: -5px;
  z-index: 3;
`;

export const MainTableFooter = styled.tfoot`
  position: sticky;
  bottom: 5px;
  height: 35px;
  z-index: 4;
`;

export const FooterBackgroundMask = styled.div`
  background-color: #EFEFEF;
  width: calc(100% - 120px);
  height: 45px;
  visibility: ${(props) => (props?.visible ? 'visible' : 'hidden')};
  position: fixed;
  bottom: 20px;
  z-index: 3;
`;

export const UserTotalHoursCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  color: white;
  font-weight: 500;
  &.over {
    background-color: #C44536;
  }
  &.equal {
    background-color: #0F8F3E;
  }
  &.under {
    background-color: #DC9900;
  }
`;

export const DashedWeekSeparator = styled.div`
  height: ${(props) => props?.height}px;
  border-right: 2px dashed #8B8B8B;
  width: 3px;
  position: fixed;
  z-index: 6;
  left: 402px;
`;

export const SidebarHighlightTip = styled.div`
  background-color: #0073AE;
  height: 43px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  right: 0;
  display: ${(props) => (props?.position ? 'inline-block' : 'none')};
  top: ${(props) => (props?.position) - 139}px;
  width: 140px;
  transition: all 0.2s;
`;

export const FooterTotalSumCell = styled.td`
  font-weight: 600;
  background-color: #EFEFEF;
`;

export const EmptyRowCell = styled.td`
  background-color: #DFDFDF !important;
  border: none !important;
`;

export const WarningSymbol = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  & div > div > svg {
    width: 12px;
  }
`;

export const DemandCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  cursor: pointer;
  &.over {
    background-color: #0F8F3E;
    color: white;
  }
  &.under {
    background-color: #C44536;
    color: white;
  }
  &.zero {
    background-color: #6B6B6B;
    color: white;
  }
`;

export const DemandCellWarningTriangle = styled.div`
  width: 0;
  height: 0;
  border-top: 15px solid #C44536;
  border-left: 15px solid transparent;
  border-radius: 0 5px 0 0;
  position: absolute;
  top: 0;
  margin-left: ${(props) => (props?.cellWidth - 17)}px;
`;

import { useState } from 'react';
// import { mockedData } from '../mockedData';
import { useTranslation } from 'react-i18next';
import {
  getMonthlyScheduleChangelog,
  getPlanStatus,
  getScheduleContent,
  getScheduleDemand,
  getScheduleVersions
} from '../../../services/monthlyPlan.service';
import { getAllShifChangeRequests } from '../../../services/shiftsChange.service';

const useDetailedMonthlySchedule = () => {
  const [loading, setLoading] = useState(false);
  const [versions, setVersions] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);
  const [demand, setDemand] = useState(null);
  const [dataSource, setDataSource] = useState(null);
  const [changeLog, setChangelog] = useState(null);
  const [optimalNumberOfWorkingHours, setOptimalNumberOfWorkingHours] = useState(null);
  const [shiftSwapRequestList, setShiftSwapRequestList] = useState(null);

  const { t } = useTranslation();

  const getMonthlySchedule = async (atcu, year, month, version) => {
    setDataSource(null);
    try {
      const res = await getScheduleContent(atcu, year, month, version);
      setDataSource(res?.data);
    } catch (e) {
      console.log(e);
      setDataSource(null);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const getMonthlyScheduleVersions = async (atcu, year, month) => {
    try {
      const res = await getScheduleVersions(atcu, year, month);
      const mappedVersions = res?.data?.[0]?.versions?.map((version) => {
        return {
          id: version?.id,
          key: version?.id,
          value: version?.version,
          label: t('monthlyPlan.currentVersion') + ' ' + version?.version,
          status: version?.status,
          monthNumber: version?.monthNumber,
          year: version?.year,
          created: version?.created,
          updated: version?.updated
        };
      });
      setVersions(mappedVersions);
    } catch (e) {
      console.log(e);
      setVersions(null);
      setLoading(false);
    }
  }

  const getScheduleStatus = async (atcu, year, month, version) => {
    setPlanStatus(null);
    setLoading(true);
    try {
      const res = await getPlanStatus(atcu, year, month, version);
      setPlanStatus(res?.data?.status);
      setOptimalNumberOfWorkingHours(res?.data?.optimalNumberOfWorkingHours);
    } catch (e) {
      setPlanStatus('PLAN_NOT_FOUND');
      console.log(e);
      setLoading(false);
    }
  }

  const getDemand = async (atcu, year, month, version) => {
    try {
      const res = await getScheduleDemand(atcu, year, month, version);
      setDemand(res?.data?.shifts);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const getChangelog = async (atcu, year, month, version) => {
    try {
      const res = await getMonthlyScheduleChangelog(atcu, year, month, version);
      setChangelog(res?.data);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  const getAllShiftSwapRequests = async (scheduleId) => {
    try {
      const res = await getAllShifChangeRequests(scheduleId);
      setShiftSwapRequestList(res?.data);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  return {
    dataSource,
    setDataSource,
    loading,
    planStatus,
    setPlanStatus,
    optimalNumberOfWorkingHours,
    getMonthlySchedule,
    getMonthlyScheduleVersions,
    shiftSwapRequestList,
    getAllShiftSwapRequests,
    versions,
    setVersions,
    getScheduleStatus,
    getDemand,
    setDemand,
    demand,
    getChangelog,
    changeLog
  }
}

export default useDetailedMonthlySchedule;

import axios from 'axios';
import StatusCode from './statusCode';
import config from '../util/config';
import { refresh } from './auth.service';

export const engineApi = (dontUseAuthorizationHeader) => {
    const baseConfig = {
        baseURL: config.ENGINE_ENDPOINT_URL
    };
    const createInstance = (baseConfig) => {
        const instance = axios.create(baseConfig);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.interceptors.request.use(
            async (config) => {
                if (dontUseAuthorizationHeader) return config;

                const token = await sessionStorage.getItem('accessToken');
                if (token && config.url !== '/login') {
                    config.headers = {
                        ...config.params,
                        Authorization: `Bearer ${token}`
                    };
                    config.headers['Content-Type'] = 'application/json';
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );
        return instance;
    };

    const instance = createInstance(baseConfig);

    instance.interceptors.response.use(
        async (response) =>
            // eslint-disable-next-line implicit-arrow-linebreak
            response,
        async (error) => {
            if (error.config.url !== '/refresh') {
                const originalRequest = error.config;
                switch (error.response.status) {
                    case StatusCode.UNAUTHORIZED:
                        // eslint-disable-next-line no-case-declarations
                        try {
                            const {
                                data: { accessToken, refreshToken }
                            } = await refresh({
                                accessToken: sessionStorage.getItem('accessToken'),
                                refreshToken: localStorage.getItem('refreshToken')
                            });
                            sessionStorage.setItem('accessToken', accessToken);
                            localStorage.setItem('refreshToken', refreshToken);
                            return engineApi()(originalRequest);
                        } catch (err) {
                            sessionStorage.removeItem('accessToken');
                            localStorage.removeItem('refreshToken');
                            delete instance.defaults.headers.common.Authorization;
                            window.location.href = '/login';
                        }
                        break;
                    default:
                        throw error.response;
                }
            } else if (error.response.status === StatusCode.UNAUTHORIZED) {
                sessionStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                delete instance.defaults.headers.common.Authorization;
                window.location.href = '/login';
            }
        }
    );

    return instance;
};

export default engineApi;

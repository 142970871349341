import styled from 'styled-components';
import { Button, Spin, Table } from 'antd';
import { BarsOutlined, CloseOutlined, MehOutlined, MoreOutlined } from '@ant-design/icons';

export const GenericTableFilterContainer = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
`;

export const GenericTableFilterTitle = styled.div`
  color: ${({ theme: { colors } }) => colors.darkLight};
  display: flex;
  flex: 1;
  align-items: center;
`;

export const GlobalTableSearchBox = styled.div`
  display: flex;
`;

export const SearchColumnBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 5;
`;

export const SearchButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchColumnInputBox = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const ActiveFiltersContainer = styled.div`
  display: flex;
  flex: 1;
  position: absolute;
  div:not(:first-child) {
    margin-left: 10px;
  }
`;

export const ColumnManagementBox = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const ColumnManagementInnerBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const ColumnManagementItemBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledBarsOutlined = styled(BarsOutlined)`
  &.ant-popover-open {
    color: ${({ theme: { colors } }) => colors.darkLight};
  }
`;

export const GlobalTableSearchButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

export const HoveredActionBox = styled.div`
  display: flex;
  z-index: 10;
  background-color: transparent;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  gap: 6px;
`;

export const HoveredItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 20px;
`;

export const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TableHeaderTitle = styled.div`
  display: flex;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
  font-size: 22px;
`;

export const NoDataBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 300px;
  width: 100%;
  left: 0px;
`;

export const NoDataInnerBox = styled.div`
  display: flex;
  position: fixed;
  left: 50%;
  //transform: translate(-50%, 0);
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const NoDataMessageBox = styled.div`
  display: flex;
  font-size: 26px;
  width: 100%;
  && {
    margin-top: 20px;
  }
`;

export const NoDataIconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
`;

export const StyledMehOutlined = styled(MehOutlined)`
  color: #acacac;
`;

export const StyledMoreOutlined = styled(MoreOutlined)`
  svg {
    color: ${({ theme: { colors } }) => colors.darkLight};
    transform: rotate(90deg);
  }
`;

export const LoadingSpin = styled(Spin)`
  position: relative;
  left: 80px;
`;

export const StyledMoreButton = styled(Button)`
  border: 0px;
  border-radius: 20px;
  :hover {
    background-color: gainsboro;
  }
  transform: rotate(90deg);
  background-color: transparent;
  transition: none;
  font-weight: bold;
  span {
    svg {
      color: ${({ theme: { colors } }) => colors.black};
    }
  }
`;

export const ClearAllFiltersBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloseIconNumberBox = styled.div`
  display: flex;
  position: absolute;
  right: 32px;
  top: 10px;
  z-index: 2;
`;

export const StyledCloseOutlined = styled(CloseOutlined)`
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
`;

export const StyledColumnCellSpan = styled.span`
  //width: 100%;
`;

export const CellPopupContent = styled.div`
  word-break: break-all;
`;

export const StyledTable = styled(Table)`
  .rowHover > td {
    background-color: ${({ theme: { colors } }) => colors.primary} !important;
    //  opacity: 0.5;
  }

  .rowActionSelected > td {
    background-color: ${({ theme: { colors } }) => colors.primary} !important;
    color: ${({ theme: { colors } }) => colors.darkLight} !important;
  }

  & {
    border-radius: ${({ theme: { borderRadiusDefault } }) => borderRadiusDefault}px;
  }

  .ant-table-container {
    color: ${({ theme: { colors } }) => colors.darkLight + ' !important'};
    height: 100%;
    
    .ant-table-body {
      height: 100%;
      overflow: auto !important;
    }
  }

  .ant-table {
    flex-grow: 1;
    overflow: hidden;
  }

  colgroup:last-child {
    width: unset !important;
    min-width: unset !important;
  }

  colgroup > col:nth-last-child(2) {
    width: unset !important;
    min-width: unset !important;
  }

  .ant-table-content {
    overflow: auto;
    height: 100%;
  }
  .ant-table-content thead th {
    min-width: 0px !important;
    width: 220px !important;
    min-width: 220px !important;
  }

  .ant-table-content thead th:first-child {
    width: 100px !important;
    min-width: 100px !important;
    max-width:100px !important;
  }

  tr:nth-child(2n) td {
    //background-color: #eee;
  }

  .ant-table-tbody > tr:hover {
    > td {
      cursor: pointer;
    }
    .ant-table-content > td:last-child {
      padding-left: 0px;
      padding-right: 14px;
      > div {
        display: flex;
      }
    }
  }
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;

  .ant-table-thead > tr {
    :first-of-type {
      ${({ activeFiltersOn }) => activeFiltersOn
    && `
      
        position: sticky;
        display: flex;
        height: 50px;
        margin-top: 4px;
        margin-bottom: 12px;
      `};
    }

    > th {
      background-color: ${({ theme: { colors } }) => colors.white}; //heading background
      font-size: 20px;
      min-width: 190px;

      .ant-table-filter-column-title {
        padding: 0;
      }

      &.ant-table-column-has-sorters:hover {
        background: #e5e5e5;
      }
    }
  }

  .ant-table-tbody > .ant-table-placeholder:hover {
    td {
      background-color: unset;
      cursor: default;
      padding-left: 20px !important;
    }
  }

  .ant-table-tbody > tr {
    > td {
      height: 38px !important;
      padding: 4px 14px 4px 16px;
      color: ${({ theme: { colors } }) => colors.darkLight};
      font-size: 17px;
      box-sizing: border-box;
      white-space: nowrap;

      > span {
        display: inline-block;
        overflow: hidden !important;
        text-overflow: ellipsis;
        height: 100%;
        vertical-align: middle;
        padding-right: 60px;
      }
    }
  }

  .ant-table-thead > tr:last-child > th:last-child {
    width: ${({ lastColumnWidth }) => lastColumnWidth};
    padding-bottom: 15px;
    z-index: 3;
  }

  .ant-table-content thead th {
    position: sticky !important;
    top: 0 !important;
    z-index: 1;
   // min-width: 130px;
    min-width: 50px;
    //padding: 15px 5px;
    //width: 580px;
    white-space: nowrap;
    user-select: none;

    :last-child {
      min-width: ${({ lastColumnWidth }) => lastColumnWidth};
    }
  }

  .ant-table-column-sorters {
    display: flex;
    flex-direction: row-reverse;
    padding: 0px 10px;

    span {
      display: flex;
      flex: 1;
    }

    .ant-table-column-sorter-full {
      flex: 0;
    }

    .ant-table-column-sorter-full {
      margin-top: 1px;
    }

    span.ant-table-column-sorter {
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-table-column-sorter-up,
      .ant-table-column-sorter-down {
        color: #a0a0a0;
        font-size: 12.5px;
      }

      span.active {
        color: ${({ theme: { colors } }) => colors.darkLight};
      }
    }
  }

  .ant-table-pagination.ant-pagination {
    position: sticky;
    bottom: 0;
    z-index: 10;
    width: 100%;
    border-top: 1px solid #f0f0f0;
    background-color: #fafafa;
    margin: 0;
    padding: 4px 0;
    padding-left: 4px;
  }

  .ant-spin-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .ant-spin-nested-loading {
    height: 100%;
  }

  .ant-table-column-sorters,
  .generic-table-filter-select,
  .generic-table-filter-multiselect,
  .generic-table-filter-select .ant-select-selection-item,
  .ant-table-column-sorters span:first-of-type {
    //width: 100%;

    .ant-input-suffix {
      width: 20px;
    }
  }

  .ant-select-selector {
    border: 0;
    outline: 0;
    box-shadow: unset !important;
  }

  .generic-table-filter-multiselect .ant-select-selection-item {
    width: auto !important;
  }

  .ant-table-filter-trigger-container {
    display: none;
  }
  .ant-table-filter-trigger.active {
    display: none;
  }

  .ant-table-pagination.ant-pagination {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 14px;

    height: 50px;

    li {
      border: none;
      width: 10px;
      min-width: 10px;

      .ant-pagination-item-link {
        height: 32px;
        display: flex;
        align-items: center;
        background-color: #fafafa;
        border: none;
        margin: 0px;
        span {
          svg {
            color: ${({ theme: { colors } }) => colors.black};
            font-weight: bold;
            font-size: 14px;
          }
        }
      }

      &.ant-pagination-item {
        min-width: 20px;
        width: 30px;
        align: center;
        border-radius: 10px;
        // background-color:${({ theme: { colors } }) => colors.primaryHover};
        background-color: #fafafa;

        a {
          margin: 0 auto;
        }
      }

      &.ant-pagination-item:hover a {
        color: ${({ theme: { colors } }) => colors.mainColors.blue};
        font-weight: bold;
        font-size: 18px;
      }

      &.ant-pagination-item-active {
        a {
          color: ${({ theme: { colors } }) => colors.mainColors.blue};
          font-size: 14px;
          width: 20px;
          padding-left: 8px;
          font-weight: bold;
        }
        &:hover {
          color: ${({ theme: { colors } }) => colors.black};
          font-size: 16px;
        }
      }
    }
  }

  li:last-child {
    .ant-select-selector {
      border: none;
      background-color: #fafafa;
    }

    .ant-select-arrow {
      span {
        svg {
          font-weight: bold;
          color: ${({ theme: { colors } }) => colors.black};
        }
      }
    }
    position: absolute;
    left: 46%;

    div {
      border: none;
    }
  }

  .ant-select-dropdown {
    border-radius: 20px;
    background-color: ${({ theme: { colors } }) => colors.white};
  }
`;

export const ActionIcon = styled.div`
  display: flex;
  color: ${({ theme: { colors } }) => colors.grayColors.actionIconGray};
  background-repeat: no-repeat;
  width: 18px;
  height: 27px;
  cursor: pointer;
  &.disabledActionRow {
    cursor: not-allowed;
  }
  & > * {
    width: 30px;
  }
`;

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding-left: 0px;
  &:hover {
    background-color: ${({ theme: { colors } }) => colors.white};
  }
  &.disabledActionRow {
    cursor: not-allowed;
  }
`;

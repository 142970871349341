/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  DailyCardWrapper,
  HeaderWrapper,
  DayCaption,
  FooterWrapper,
  ActionIcon,
  ActionIconsBox,
  VersionText,
  NotInRangeOverlay,
  FilterSelectVersion
} from './DailyPlanCard.style';
import planSeeIcon from '../../../assets/images/icons/eyeIcon.svg';
import planEditIcon from '../../../assets/images/icons/penIcon.svg';
import planStatusTypes from '../../../util/planStatusTypes.json';
import magicWandIcon from '../../../assets/images/icons/wand-magic-sparkles-solid.svg';
import { useApplicationStateValue } from '../../../context/ApplicationContext';
import { useAuthStateValue } from '../../../context/AuthContext';

const DailyCard = ({ day, versions, month, currentMonth, year, loading, generateDailyScheduleHandler }) => {
  const { language } = useApplicationStateValue();
  const [versionsList, setVersionsList] = useState(null);
  const [latestVersion, setLatestVersion] = useState(null);
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(versionsList?.[0]?.key)

  const planStatusTypeResult = planStatusTypes?.daily?.find((el) => el?.key === selectedVersion?.status);
  const latestVersionIsSelected = latestVersion === selectedVersion?.key;

  const [hasSeePermission, setHasSeePermission] = useState(false);
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [hasRegeneratePermission, setHasRegeneratePermission] = useState(false);
  const { t } = useTranslation();
  const { loggedUser } = useAuthStateValue();

  const presentDay = new Date().getDate();
  const presentDayMonth = new Date().getMonth();
  const locationIdentificator = loggedUser?.extendUser?.locationIdentificator;
  const locationId = loggedUser?.locationsData?.find((el) => (el?.identificator === locationIdentificator))?.key;
  let currentDay;

  useEffect(() => {
    setPermissionStatus(loggedUser?.localPermissions[locationId]?.dailyPlan);
      if (permissionStatus === 'DISPLAY') {
        setHasSeePermission(true);
        setHasEditPermission(false);
        setHasRegeneratePermission(false);
      } else if (permissionStatus === 'GENERATE_AND_MODIFY') {
        setHasEditPermission(true);
        setHasSeePermission(true);
        setHasRegeneratePermission(true);
      } else if (permissionStatus === 'APPROVE_AND_ACTIVATE') {
        setHasSeePermission(true);
        setHasEditPermission(true);
        setHasRegeneratePermission(true);
      } else if (permissionStatus === 'NONE') {
        setHasEditPermission(false);
        setHasSeePermission(false);
        setHasRegeneratePermission(false);
      }
  }, [permissionStatus, versionsList, selectedVersion]);

  const generateVersionSelectOptions = () => {
    const versionSelectOptionsArr = [];
    versions?.forEach((versionEl) => {
      if (!latestVersion) {
        setLatestVersion(versionEl?.version);
      } else if (latestVersion && latestVersion < versionEl?.version) {
        setLatestVersion(versionEl);
      }
      versionSelectOptionsArr.push({
        key: versionEl?.version,
        value: versionEl?.version,
        label: (
          <span>
            v.
            <b>{versionEl?.version}</b>
          </span>
        ),
        status: versionEl?.status
      });
    });
    setVersionsList(versionSelectOptionsArr);
  };

  const versionChangeHandler = (value) => {
    setSelectedVersion(versionsList?.find((el) => el?.key === value));
  };

  useEffect(() => {
    generateVersionSelectOptions()
  }, [versions]);

  useEffect(() => {
    setSelectedVersion(versionsList?.[versionsList?.length - 1]);
  }, [versionsList])

  if (day === presentDay && month === presentDayMonth + 1) {
      currentDay = 'currentDay';
  }
  const TitleCardHandler = () => {
      return (
        <Tooltip placement="bottom" title={planStatusTypeResult?.title?.sr}>
          {planStatusTypeResult?.title?.sr ? planStatusTypeResult?.title?.sr : 'NIJE GENERISAN' }
        </Tooltip>
    )
  };

  return (
    <DailyCardWrapper notInRange={month !== currentMonth}>
      <HeaderWrapper className={status} style={{ backgroundColor: month === currentMonth ? (planStatusTypeResult?.headerColor ? planStatusTypeResult?.headerColor : '#8f8f8f') : '#8f8f8f' }}>
        {month === currentMonth && TitleCardHandler()}
      </HeaderWrapper>
      <DayCaption currentDay={currentDay} className={month !== currentMonth ? 'lastMonth' : ''}>{day}</DayCaption>
      { month === currentMonth && (
      <FooterWrapper>
        {
          (planStatusTypeResult?.key !== 'BEING_GENERATED' && versionsList?.length > 0)
          && (
            (
              (loggedUser?.localPermissions.dailyPlan !== 'DISPLAY' || loggedUser?.localPermissions.dailyPlan !== 'NONE') ? (

                <VersionText>
                  <FilterSelectVersion
                    onChange={versionChangeHandler}
                    value={selectedVersion}
                    options={versionsList}
                  />
                </VersionText>
                  ) : (

                    <VersionText className={currentDay ? 'currentDay' : ''}>
                      {' '}
                      {t('annualPlan.currentVersion') + ' '}
                      <b>{selectedVersion}</b>
                    </VersionText>
                  )
            )
          )
        }
        <ActionIconsBox>
          {
            (planStatusTypeResult?.actions?.includes('SEE') && hasSeePermission)
            && (
              <NavLink to={'/daily/' + year + '/' + month + '/' + day + '/' + selectedVersion?.value + '/view'}>
                <Tooltip placement="top" title={t('dailyPlan.see')}>
                  <ActionIcon className={currentDay ? 'currentDay' : ''}>
                    <ReactSVG src={planSeeIcon} />
                  </ActionIcon>
                </Tooltip>
              </NavLink>
            )
          }
          {
            (planStatusTypeResult?.actions?.includes('EDIT') && hasEditPermission && latestVersionIsSelected)
            && (
              <NavLink to={'/daily/' + year + '/' + month + '/' + day + '/' + selectedVersion?.value + '/edit'}>
                <Tooltip placement="top" title={t('dailyPlan.edit')}>
                  <ActionIcon className={currentDay ? 'currentDay' : ''}>
                    <ReactSVG src={planEditIcon} />
                  </ActionIcon>
                </Tooltip>
              </NavLink>
            )
          }
          {
            (versionsList?.length === 0 && !loading && hasRegeneratePermission)
            && (
              <Tooltip placement="top" title={t('dailySchedule.generate')}>
                <ActionIcon onClick={() => generateDailyScheduleHandler(year, month, day)} className={currentDay ? 'currentDay' : ''}>
                  <ReactSVG src={magicWandIcon} />
                </ActionIcon>
              </Tooltip>
            )
          }
        </ActionIconsBox>
      </FooterWrapper>
)}
    </DailyCardWrapper>
  );
};

export default DailyCard;

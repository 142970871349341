/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { Tooltip } from 'antd';
import { SeeAllNotificationsButton, NotificationWrapper, NotificationHeader, NotificationContent, NotificationFooter, StyledTrashCan, NoNotificationsMessage, CreatedTime, NewestNotificationsWrapper } from './NotificationsPanel.style';
import { Panel } from '../../../core/components/panel/Panel';
import trashCan from '../../../assets/images/icons/icon_trash-can.svg';
import { formater } from '../../../util/util';
import { useNotifications } from './hooks/notifications';
import { useAuthStateValue } from '../../../context/AuthContext';

const PAGE_KEY = 'notificationsPanel';

export const NotificationsPanel = ({ seeAllNotifications, setSeeAllNotifications }) => {
  const { t } = useTranslation();

  const { loggedUser, setLoggedUser } = useAuthStateValue();

  const { fetchNotifications, deleteNotification, onNotificationRemove, loading, dataSource } = useNotifications();
  const newestNotifications = dataSource?.slice(0, 4);

  useEffect(() => {
    const userId = loggedUser?.id;
    fetchNotifications(userId);
  }, []);

  const notificationsTitle = t('notificationsPanel.title');
  const notificationsSubtitle = '(' + dataSource?.length + ')';
  const seeAllNotificationsButton = t('notificationsPanel.button');
  const noNewNotifications = t('notificationsPanel.noNotificationsMessage');

  const themeContext = useContext(ThemeContext);

  const callBackFunction = () => {
    setSeeAllNotifications(!seeAllNotifications);
  }

  let emptyNotificationsList = false;
  if (dataSource?.length === 0) {
    emptyNotificationsList = true;
  }

  return (
    <Panel
      title={notificationsTitle}
      subTitle={notificationsSubtitle}
      hasBackgroundColor={false}
      arrowSignVisible={false}
      actionBoxVisible={!emptyNotificationsList}
      childrenActionBox={(
        <SeeAllNotificationsButton onClick={callBackFunction}>
          {seeAllNotificationsButton}
          &nbsp;&gt;
        </SeeAllNotificationsButton>
      )}
    >
      <NewestNotificationsWrapper>
        {newestNotifications?.map((item, index) => {
          const colorFunction = {
            "HIGH": themeContext.colors.mainColors.red,
            "MEDIUM": themeContext.colors.mainColors.orange,
            "LOW": themeContext.colors.mainColors.green
          }

          const dateAndTime = (moment(item.created).format('DD.MM.YYYY HH:mm:ss'));
          //const date = (moment(item.created).format('DD.MM.YYYY'));
          //const dateAndTime = date + ' &nbsp' + item.created.slice(11, 19);
          const content = item?.content;
          const stripHtmlTags = (html) => {
            return html.replace(/<\/?[^>]+(>|$)/g, "");
          };

          return (
            <NotificationWrapper key={index}>
              <NotificationHeader style={{ backgroundColor: colorFunction[item?.priority] }} />
              <Tooltip title={stripHtmlTags(content)} placement="top">
                <NotificationContent dangerouslySetInnerHTML={{ __html: item?.content }} />
              </Tooltip>
              <NotificationFooter>
                <CreatedTime dangerouslySetInnerHTML={{ __html: dateAndTime }} />
                <StyledTrashCan
                  src={trashCan}
                  onClick={() => {
                    onNotificationRemove(dataSource[index]);
                  }}
                />
              </NotificationFooter>
            </NotificationWrapper>
          )
        })}
        {emptyNotificationsList
          && (
            <NoNotificationsMessage>{noNewNotifications}</NoNotificationsMessage>
          )}
      </NewestNotificationsWrapper>
    </Panel>
  )
}

import React from 'react';
import PropTypes from 'prop-types';

const withModal = (defaultModalData) => (Component) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modalData: defaultModalData || null,
        open: false
      };
    }

    showDialog = (modalData) => {
      if (modalData && modalData !== undefined) {
        this.setState({
          open: true,
          modalData
        });
      } else {
        this.setState({
          open: true,
          modalData: defaultModalData || null
        });
      }
    };

    closeDialog = () => new Promise((resolve) => {
        this.setState(
          {
            open: false,
            modalData: defaultModalData || null
          },
          () => resolve()
        );
      });

    render() {
      const { modalData, open } = this.state;
      const modalProps = {
        open,
        destroyOnClose: true,
        onCancel: (e) => {
          this.closeDialog().then(() => {
            if (this.props.onCancel) this.props.onCancel(e);
          });
        }
      };
      return (
        // eslint-disable-next-line react/jsx-filename-extension
        <Component
          {...this.props}
          closeDialog={this.closeDialog}
          showDialog={this.showDialog}
          modalProps={modalProps}
          modalData={modalData}
        />
      );
    }
  }

  HOC.propTypes = {
    onCancel: PropTypes.any
  };

  return HOC;
};

withModal.propTypes = {
  defaultModalData: PropTypes.any
};

export default withModal;

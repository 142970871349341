import { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';
import {
  ObligationCardWrapper,
  LeftResizeBox,
  RightResizeBox,
  SectorTitleBox,
  RoleTypeBox, DragOverlay, WarningSymbol, StartTimeText, EndTimeText
} from './ObligationCard.style';
import { useAuthStateValue } from '../../../../context/AuthContext';
import { hoursCountToTimeString, timeStringToHoursCount } from '../../../../util/util';
import warningIcon from '../../../../assets/images/icons/triangle-exclamation-solid.svg';

const ObligationCard = (props) => {
  const {
    controllerId,
    timeFrom,
    timeTo,
    hourCellWidth,
    shiftTimeTo,
    shiftTimeFrom,
    modifiedData,
    setModifiedData,
    modified,
    sectorId,
    sectorRoleId,
    obligations,
    setTooltipVisible,
    setTooltipContent,
    shiftPartiallyTomorrow,
    atcu,
    dataSource,
    editModeOn,
    timeStreak
  } = props;

  const { loggedUser } = useAuthStateValue();

  const cardRef = useRef();
  const [sliding, setSliding] = useState();
  const [isDragging, setIsDragging] = useState();
  const [leftModified, setLeftModified] = useState(0);
  const [rightModified, setRightModified] = useState(0);
  const [positionModified, setPositionModified] = useState(0);
  const [warnings, setWarnings] = useState([]);
  const [overlapping, setOverlapping] = useState([]);

  let timeFromInteger = timeStringToHoursCount(timeFrom, false);
  let timeToInteger = timeStringToHoursCount(timeTo, true);

  if (shiftPartiallyTomorrow && timeFromInteger < 15) timeFromInteger += 24;
  if (shiftPartiallyTomorrow && timeToInteger < 15) timeToInteger += 24;

  const duration = timeToInteger - timeFromInteger;

  const sectorShortName = loggedUser?.sectors?.find((el) => el?.id === sectorId)?.shortName;
  const sectorRoleShortName = loggedUser?.sectorRoles?.find((el) => el?.id === sectorRoleId)?.shortName;

  useEffect(() => {
    if (overlapping?.length > 0) {
      const alreadyHasThatWarning = warnings?.find((el) => el?.startsWith('Postoji preklapanje'));
      setWarnings((prevState) => {
        if (!alreadyHasThatWarning) return [...prevState, ('Postoji preklapanje sa kontrolorima: ' + overlapping?.join(', '))];
        if (alreadyHasThatWarning) {
          const newStateArr = [];
          prevState?.forEach((prevStateEl) => {
            if (!prevStateEl?.startsWith('Postoji preklapanje')) newStateArr.push(prevStateEl);
          });
          newStateArr.push(('Postoji preklapanje sa kontrolorima: ' + overlapping?.join(', ')));
          return newStateArr;
        }
      });
    } else if (overlapping?.length === 0) {
      setWarnings((prevState) => {
        const newStateArr = [];
        prevState?.forEach((el) => {
          if (!el.startsWith('Postoji preklapanje')) newStateArr.push(el);
        });
        return newStateArr;
      });
    }
  }, [overlapping]);

  // SEARCH FOR OVERLAPPING
  useEffect(() => {
    const overlappingControllers = [];
    // Search in original data
    dataSource?.controllers?.forEach((controller) => {
      if (controller?.id !== controllerId) {
        const overlappingObl = controller?.obligations?.find((el) => {
          const removedInModifiedData = modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => modifiedEl?.type === 'REMOVE' && modifiedEl?.timeFrom === el?.timeFrom && modifiedEl?.timeTo === el?.timeTo);
          return (
              (!removedInModifiedData)
              && (!!sectorId)
              && (el?.sector?.id === sectorId && el?.sectorRole?.id === sectorRoleId)
              && ((el?.timeFrom <= timeFrom && el?.timeTo >= timeFrom)
                  || (el?.timeFrom <= timeTo && el?.timeTo >= timeTo)
                  || (timeFrom <= el?.timeFrom && timeTo >= el?.timeFrom)
                  || (timeFrom <= el?.timeTo && timeTo >= el?.timeTo))
          );
        });
        if (overlappingObl) overlappingControllers.push(controller?.firstName + ' ' + controller?.lastName);
      }
    });
    // Search in modified data
    modifiedData?.[modifiedData?.length - 1]?.forEach((modifiedEl) => {
      if (
          (modifiedEl?.type !== 'REMOVE' && modifiedEl?.controllerId !== controllerId)
          && (!!sectorId)
          && (modifiedEl?.sectorId === sectorId && modifiedEl?.sectorRoleId === sectorRoleId)
          && ((modifiedEl?.timeFrom <= timeFrom && modifiedEl?.timeTo >= timeFrom)
              || (modifiedEl?.timeFrom <= timeTo && modifiedEl?.timeTo >= timeTo)
              || (timeFrom <= modifiedEl?.timeFrom && timeTo >= modifiedEl?.timeFrom)
              || (timeFrom <= modifiedEl?.timeTo && timeTo >= modifiedEl?.timeTo))
      ) {
        const matchingControllerInOriginalData = dataSource?.controllers?.find((el) => el?.id === modifiedEl?.controllerId);
        overlappingControllers.push(matchingControllerInOriginalData?.firstName + ' ' + matchingControllerInOriginalData?.lastName);
      }
    });
    setOverlapping(overlappingControllers);
  }, [timeFrom, timeTo, positionModified, sectorId, sectorRoleId, modifiedData, dataSource]);

  useEffect(() => {
    if ((atcu === 'ATCU2') && (sectorShortName === 'T' || sectorShortName === 'HT' || sectorShortName === 'UHT' || sectorShortName === 'X' || sectorShortName === 'TX' || sectorShortName === 'HTX' || sectorShortName === 'UHTX')) {
      setWarnings((prevState) => {
        const alreadyHasThatWarning = prevState?.find((el) => el === 'Odabrani sektor nije dozvoljen za ATCU2');
        if (!alreadyHasThatWarning) return [...prevState, 'Odabrani sektor nije dozvoljen za ATCU2'];
        return prevState;
      })
    } else {
      const hasForbiddenSectorWarning = warnings?.find((el) => el === 'Odabrani sektor nije dozvoljen za ATCU2');
      if (hasForbiddenSectorWarning) {
        setWarnings((prevState) => {
          const newStateArr = [];
          prevState?.forEach((el) => {
            if (el !== 'Odabrani sektor nije dozvoljen za ATCU2') newStateArr.push(el);
          });
          return newStateArr;
        });
      }
    }
  }, [sectorId, sectorRoleShortName]);

  // Find previous obligation in original data
  let previousOblInOriginalData;
  obligations?.forEach((el) => {
    let elTimeFrom = timeStringToHoursCount(el?.timeFrom, false);
    if (shiftPartiallyTomorrow && elTimeFrom < 15) elTimeFrom += 24;
    let elTimeTo = timeStringToHoursCount(el?.timeTo, true);
    if (shiftPartiallyTomorrow && elTimeTo < 15) elTimeTo += 24;
    const isRemoved = !!modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => {
      let modifiedElTimeFrom = timeStringToHoursCount(modifiedEl?.timeFrom, false);
      let modifiedElTimeTo = timeStringToHoursCount(modifiedEl?.timeTo, true);
      if (shiftPartiallyTomorrow && modifiedElTimeFrom < 15) modifiedElTimeFrom += 24;
      if (shiftPartiallyTomorrow && modifiedElTimeTo < 15) modifiedElTimeTo += 24;
      return (modifiedEl?.controllerId === controllerId && modifiedElTimeFrom === elTimeFrom && modifiedElTimeTo === elTimeTo && modifiedEl?.type === 'REMOVE');
    });
    if (!previousOblInOriginalData) {
      if (!isRemoved && (elTimeTo <= timeFromInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) {
        previousOblInOriginalData = el;
      }
    } else if (!isRemoved && (previousOblInOriginalData) && (elTimeTo <= timeFromInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) {
      if (timeStringToHoursCount(previousOblInOriginalData?.timeTo, true) < elTimeTo) previousOblInOriginalData = el;
    }
  });
  // Find previous obligation in modified data
  let previousOblInModifiedData;
  modifiedData?.[modifiedData?.length - 1]?.forEach((el) => {
    let elTimeFrom = timeStringToHoursCount(el?.timeFrom, false);
    if (shiftPartiallyTomorrow && elTimeFrom < 15) elTimeFrom += 24;
    let elTimeTo = timeStringToHoursCount(el?.timeTo, true);
    if (shiftPartiallyTomorrow && elTimeTo < 15) elTimeTo += 24;
    if (!previousOblInModifiedData && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
      if ((elTimeTo <= timeFromInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) previousOblInModifiedData = el;
    } else if ((previousOblInModifiedData && el?.controllerId === controllerId) && (elTimeTo <= timeFromInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger) && el?.type !== 'REMOVE') {
      if (timeStringToHoursCount(previousOblInModifiedData?.timeTo, true) < elTimeTo) previousOblInModifiedData = el;
    }
  });
  let previousObligation;
  if (previousOblInOriginalData && !previousOblInModifiedData) previousObligation = previousOblInOriginalData;
  if (previousOblInModifiedData && !previousOblInOriginalData) previousObligation = previousOblInModifiedData;
  if (previousOblInModifiedData && previousOblInOriginalData) {
    if (previousOblInOriginalData?.timeTo > previousOblInModifiedData?.timeTo) previousObligation = previousOblInOriginalData;
    if (previousOblInModifiedData?.timeTo > previousOblInOriginalData?.timeTo) previousObligation = previousOblInModifiedData;
  }
  let previousOblTimeTo = timeStringToHoursCount(previousObligation?.timeTo, true);
  if (shiftPartiallyTomorrow && previousOblTimeTo < 15) previousOblTimeTo += 24;

  const leftSideResizeHandler = () => {
    setSliding(true);
    setTooltipVisible(true);
    document.getElementById('mainWrapper').style.cursor = 'w-resize';
    const mouseX = window.event.clientX;
    const cardX = Math.floor(cardRef?.current?.getBoundingClientRect()?.x);
    const cardWidth = cardRef?.current?.offsetWidth;
    const resizeAmount = (cardX + cardWidth) - mouseX;
    const stepped = Math.floor(((Math.floor(resizeAmount / ((hourCellWidth + 5) / 2)) / 2) - duration) / 0.5) * 0.5;
    if (((timeFromInteger - stepped) >= shiftTimeFrom) && ((!!previousObligation && (timeFromInteger - stepped) >= previousOblTimeTo) || !previousObligation) && ((timeFromInteger - stepped) <= (timeToInteger - 0.5))) {
      setLeftModified(stepped);
      const newTimeFrom = timeFromInteger - stepped;
      const newTimeFromString = hoursCountToTimeString(newTimeFrom, false);
      setTooltipContent(newTimeFromString + ' - ' + timeTo);
    }
  };

  useEffect(() => {
    if (timeStreak + rightModified + leftModified > 2) {
      setWarnings((prevState) => {
      const alreadyHasThatWarning = prevState?.find((el) => el === 'Kontrolor može da radi maksimalno 2 sata, nakon čega je potrebno bar pola sata pauze.');
      if (!alreadyHasThatWarning) return [...prevState, 'Kontrolor može da radi maksimalno 2 sata, nakon čega je potrebno bar pola sata pauze.'];
      return prevState;
    });
    }
    if (timeStreak + rightModified + leftModified <= 2) {
      setWarnings((prevState) => {
        const newStateArr = [];
        prevState?.forEach((warning) => {
          if (warning !== 'Kontrolor može da radi maksimalno 2 sata, nakon čega je potrebno bar pola sata pauze.') newStateArr.push(warning);
        });
        return newStateArr;
      })
    }
  }, [duration, leftModified, rightModified, modifiedData]);

  const rightSideResizeHandler = () => {
    setSliding(true);
    setTooltipVisible(true);
    document.getElementById('mainWrapper').style.cursor = 'w-resize';
    const mouseX = window.event.clientX;
    const cardX = cardRef?.current?.getBoundingClientRect()?.x;
    const resizeAmount = mouseX - cardX;
    const stepped = (Math.floor(resizeAmount / ((hourCellWidth + 5) / 2)) / 2) - duration;
    // Find next obligation in original data
    let nextOblInOriginalData;
    obligations?.forEach((el) => {
      let elTimeFrom = timeStringToHoursCount(el?.timeFrom, false);
      if (shiftPartiallyTomorrow && elTimeFrom < 15) elTimeFrom += 24;
      let elTimeTo = timeStringToHoursCount(el?.timeTo, true);
      if (shiftPartiallyTomorrow && elTimeTo < 15) elTimeTo += 24;
      const isRemoved = !!modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => {
        let modifiedElTimeFrom = timeStringToHoursCount(modifiedEl?.timeFrom, false);
        let modifiedElTimeTo = timeStringToHoursCount(modifiedEl?.timeTo, true);
        if (shiftPartiallyTomorrow && modifiedElTimeFrom < 15) modifiedElTimeFrom += 24;
        if (shiftPartiallyTomorrow && modifiedElTimeTo < 15) modifiedElTimeTo += 24;
        return (modifiedEl?.controllerId === controllerId && modifiedElTimeFrom === elTimeFrom && modifiedElTimeTo === elTimeTo && modifiedEl?.type === 'REMOVE');
      });
      if (!nextOblInOriginalData) {
        if (!isRemoved && (elTimeFrom >= timeToInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) {
          nextOblInOriginalData = el;
        }
      } else if (!isRemoved && (nextOblInOriginalData) && (elTimeFrom >= timeToInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) {
        if (timeStringToHoursCount(nextOblInOriginalData?.timeFrom, false) > elTimeFrom) nextOblInOriginalData = el;
      }
    });
    // Find next obligation in modified data
    let nextOblInModifiedData;
    modifiedData?.[modifiedData?.length - 1]?.forEach((el) => {
      let elTimeFrom = timeStringToHoursCount(el?.timeFrom, false);
      if (shiftPartiallyTomorrow && elTimeFrom < 15) elTimeFrom += 24;
      let elTimeTo = timeStringToHoursCount(el?.timeTo, true);
      if (shiftPartiallyTomorrow && elTimeTo < 15) elTimeTo += 24;
      if (!nextOblInModifiedData && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
        if ((elTimeFrom >= timeToInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) nextOblInModifiedData = el;
      } else if (el?.type !== 'REMOVE' && (nextOblInModifiedData && el?.controllerId === controllerId) && (elTimeFrom >= timeToInteger) && (elTimeFrom !== timeFromInteger && elTimeTo !== timeToInteger)) {
        if (timeStringToHoursCount(nextOblInModifiedData?.timeFrom, false) >= elTimeFrom) nextOblInModifiedData = el;
      }
    });
    let nextObligation;
    if (nextOblInOriginalData && !nextOblInModifiedData) nextObligation = nextOblInOriginalData;
    if (nextOblInModifiedData && !nextOblInOriginalData) nextObligation = nextOblInModifiedData;
    if (nextOblInModifiedData && nextOblInOriginalData) {
      if (nextOblInOriginalData?.timeFrom < nextOblInModifiedData?.timeFrom) nextObligation = nextOblInOriginalData;
      if (nextOblInModifiedData?.timeFrom < nextOblInOriginalData?.timeFrom) nextObligation = nextOblInModifiedData;
    }
    let nextOblTimeFrom = timeStringToHoursCount(nextObligation?.timeFrom);
    if (shiftPartiallyTomorrow && nextOblTimeFrom < 15) nextOblTimeFrom += 24;
    const newTimeTo = timeToInteger + stepped;
    const newTimeToString = hoursCountToTimeString(newTimeTo >= 24 ? (newTimeTo - 24) : newTimeTo, true);
    if (((newTimeTo) >= (timeFromInteger + 0.5)) && ((!!nextObligation && (newTimeTo) <= nextOblTimeFrom) || !nextObligation) && ((newTimeTo) <= shiftTimeTo)) {
      setRightModified(stepped);
      setTooltipContent(timeFrom + ' - ' + newTimeToString);
    }
  };

  const dragHandler = (startPos) => {
    setTooltipVisible(true);
    document.getElementById('mainWrapper').style.cursor = 'grabbing';
    const mouseX = window.event.clientX;
    const dragAmount = mouseX - startPos;
    const stepped = Math.floor(dragAmount / ((hourCellWidth + 5) / 2)) / 2;
    const newTimeFrom = timeFromInteger + stepped;
    const newTimeTo = timeToInteger + stepped;
    const newTimeFromString = hoursCountToTimeString(newTimeFrom, false);
    const newTimeToString = hoursCountToTimeString(newTimeTo, true);
    if (shiftPartiallyTomorrow) {
      if ((newTimeFrom >= shiftTimeFrom || newTimeFrom < 15) && (newTimeTo <= shiftTimeTo)) {
        setPositionModified(stepped);
        setTooltipContent(newTimeFromString + ' - ' + newTimeToString);
      }
    } else if ((newTimeFrom >= shiftTimeFrom) && (newTimeTo <= shiftTimeTo)) {
        setPositionModified(stepped);
        setTooltipContent(newTimeFromString + ' - ' + newTimeToString);
    }
  }

  const onLeftResizeMouseDown = (e) => {
    setTooltipVisible(true);
    setTooltipContent(timeFrom + ' - ' + timeTo);
    if (e.detail === 1) {
      window.addEventListener('mousemove', leftSideResizeHandler);
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', leftSideResizeHandler);
        document.getElementById('mainWrapper').style.cursor = 'pointer';
        setSliding(false);
        setTooltipVisible(false);
      });
    }
  };

  const onRightResizeMouseDown = (e) => {
    setTooltipVisible(true);
    setTooltipContent(timeFrom + ' - ' + timeTo);
    if (e.detail === 1) {
      window.addEventListener('mousemove', rightSideResizeHandler);
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', rightSideResizeHandler);
        document.getElementById('mainWrapper').style.cursor = 'pointer';
        setSliding(false);
        setTooltipVisible(false);
      });
    }
  };

  const dragMouseDownHandler = (event) => {
    setTooltipVisible(true);
    setTooltipContent(timeFrom + ' - ' + timeTo);
    if (!event?.target?.className?.includes('resize-trigger') && event.detail === 1) {
      setIsDragging(true);
      const mouseX = window.event.clientX;
      const handler = () => {
        dragHandler(mouseX);
      }
      window.addEventListener('mousemove', handler);
      window.addEventListener('mouseup', () => {
        window.removeEventListener('mousemove', handler);
        document.getElementById('mainWrapper').style.cursor = 'pointer';
        setIsDragging(false);
        setTooltipVisible(false);
      });
    }
  };

  useEffect(() => {
    if (!isDragging && positionModified) {
      const newTimeFrom = timeFromInteger + positionModified;
      const newTimeTo = timeToInteger + positionModified;
      const newTimeFromString = hoursCountToTimeString(newTimeFrom, false);
      const newTimeToString = hoursCountToTimeString(newTimeTo, true);
      const overlappingElementInOriginalData = obligations?.find((el) => {
        const removedInModifiedData = modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => modifiedEl?.type === 'REMOVE' && modifiedEl?.controllerId === controllerId && modifiedEl?.timeFrom === el?.timeFrom && modifiedEl?.timeTo === el?.timeTo);
        return (
          (el?.timeFrom !== timeFrom && el?.timeTo !== timeTo)
          && (!removedInModifiedData)
          && ((el?.timeFrom <= newTimeFromString && el?.timeTo >= newTimeFromString)
          || (el?.timeFrom <= newTimeToString && el?.timeTo >= newTimeToString)
          || (newTimeFromString <= el?.timeFrom && newTimeToString >= el?.timeFrom)
          || (newTimeFromString <= el?.timeTo && newTimeToString >= el?.timeTo))
        );
      })
      const overlappingElementInModifiedData = modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => {
        return (
          (modifiedEl?.type !== 'REMOVE' && modifiedEl?.timeFrom !== timeFrom && modifiedEl?.timeTo !== timeTo && modifiedEl?.controllerId === controllerId)
          && ((modifiedEl?.timeFrom <= newTimeFromString && modifiedEl?.timeTo >= newTimeFromString)
          || (modifiedEl?.timeFrom <= newTimeToString && modifiedEl?.timeTo >= newTimeToString)
          || (newTimeFromString <= modifiedEl?.timeFrom && newTimeToString >= modifiedEl?.timeFrom)
          || (newTimeFromString <= modifiedEl?.timeTo && newTimeToString >= modifiedEl?.timeTo))
        );
      });
      if (!modified && !overlappingElementInModifiedData && !overlappingElementInOriginalData) {
        setModifiedData((prevState) => [
          ...(prevState ? prevState : []),
          [
            ...(prevState?.[prevState?.length - 1] ? prevState?.[prevState?.length - 1] : []),
            {
              controllerId,
              timeFrom,
              timeTo,
              type: 'REMOVE',
              sectorId,
              sectorRoleId
            },
            {
              controllerId,
              timeFrom: newTimeFromString,
              timeTo: newTimeToString,
              type: 'ADD',
              sectorId,
              sectorRoleId
            }
          ]
        ]);
      } else if (modified && !overlappingElementInModifiedData && !overlappingElementInOriginalData) {
        setModifiedData((prevState) => {
          const newStateArray = [...prevState];
          const newestModifiedDataArray = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.timeFrom === timeFrom && el?.timeTo === timeTo && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
              newestModifiedDataArray.push({
                controllerId: el?.controllerId,
                timeFrom: newTimeFromString,
                timeTo: newTimeToString,
                type: 'ADD',
                sectorId: el?.sectorId,
                sectorRoleId: el?.sectorRoleId
              });
              setLeftModified(0);
            } else newestModifiedDataArray.push(el);
          });
          newStateArray.push(newestModifiedDataArray);
          return newStateArray;
        });
      }
      setPositionModified(0);
    }
  }, [isDragging]);

  useEffect(() => {
    // LEFT RESIZE HANDLER
    if (leftModified !== 0 && !sliding) {
      const newTimeFromTimeString = hoursCountToTimeString(timeFromInteger - leftModified);
      if (!modified) {
        setModifiedData((prevState) => [
          ...(prevState ? prevState : []),
          [
            ...(prevState?.[prevState?.length - 1] ? prevState?.[prevState?.length - 1] : []),
            {
              controllerId,
              timeFrom,
              timeTo,
              type: 'REMOVE',
              sectorId,
              sectorRoleId
            },
            {
              controllerId,
              timeFrom: newTimeFromTimeString,
              timeTo,
              type: 'ADD',
              sectorId,
              sectorRoleId
            }
          ]
        ]);
        setLeftModified(0);
      } else if (modified) {
        setModifiedData((prevState) => {
          const newStateArray = [...prevState];
          const newestModifiedDataArray = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.timeFrom === timeFrom && el?.timeTo === timeTo && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
              newestModifiedDataArray.push({
                controllerId: el?.controllerId,
                timeFrom: newTimeFromTimeString,
                timeTo: el?.timeTo,
                type: 'ADD',
                sectorId: el?.sectorId,
                sectorRoleId: el?.sectorRoleId
              });
              setLeftModified(0);
            } else newestModifiedDataArray.push(el);
          });
          newStateArray.push(newestModifiedDataArray);
          return newStateArray;
        });
      }
    }
    // RIGHT RESIZE HANDLER
    if (rightModified !== 0 && !sliding) {
      const newTimeTo = timeToInteger + rightModified;
      const newTimeToTimeString = hoursCountToTimeString(newTimeTo, true);
      if (!modified) {
        setModifiedData((prevState) => [
          ...(prevState ? prevState : []),
          [
            ...(prevState?.[prevState?.length - 1] ? prevState?.[prevState?.length - 1] : []),
            {
              controllerId,
              timeFrom,
              timeTo,
              type: 'REMOVE',
              sectorId,
              sectorRoleId
            },
            {
              controllerId,
              timeFrom,
              timeTo: newTimeToTimeString,
              type: 'ADD',
              sectorId,
              sectorRoleId
            }
          ]
        ]);
        setRightModified(0);
      } else if (modified) {
        setModifiedData((prevState) => {
          const newStateArray = [...prevState];
          const newestModifiedDataArray = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.timeFrom === timeFrom && el?.timeTo === timeTo && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
              newestModifiedDataArray.push({
                controllerId: el?.controllerId,
                timeFrom: el?.timeFrom,
                timeTo: newTimeToTimeString,
                type: 'ADD',
                sectorId: el?.sectorId,
                sectorRoleId: el?.sectorRoleId
              });
              setRightModified(0);
            } else newestModifiedDataArray.push(el);
          });
          newStateArray.push(newestModifiedDataArray);
          return newStateArray;
        });
      }
    }
  }, [leftModified, rightModified, sliding]);

  const sectorName = sectorId ? loggedUser?.sectors?.find((el) => el?.id === sectorId)?.shortName : ' ';
  const roleTypeName = loggedUser?.sectorRoles?.find((el) => el?.id === sectorRoleId)?.shortName;

  return (
    <ObligationCardWrapper
      modified={modified}
      sliding={sliding}
      isDragging={isDragging}
      leftModified={leftModified}
      rightModified={rightModified}
      positionModified={positionModified}
      timeFromInteger={timeFromInteger}
      timeToInteger={timeToInteger}
      shiftTimeFrom={shiftTimeFrom}
      shiftTimeTo={shiftTimeTo}
      sectorRoleShortName={sectorRoleShortName}
      hoursCellWidth={hourCellWidth}
      shiftPartiallyTomorrow={shiftPartiallyTomorrow}
      ref={cardRef}
      onMouseDown={dragMouseDownHandler}
      editModeOn={editModeOn}
      {...props}
    >
      <StartTimeText length={duration + rightModified - leftModified} noPause={warnings?.find((el) => el === 'Nije ispoštovana pauza od minimalno pola sata')}>{timeFrom.substring(0, 5)}</StartTimeText>
      <DragOverlay onMouseDown={editModeOn ? dragMouseDownHandler : null} />
      {!isDragging && <LeftResizeBox editModeOn={editModeOn} className="resize-trigger" sliding={sliding} onMouseDown={editModeOn ? onLeftResizeMouseDown : null} />}
      <SectorTitleBox onMouseDown={dragMouseDownHandler} charLength={sectorName?.length} length={duration + rightModified - leftModified}>
        {sectorName}
      </SectorTitleBox>
      <RoleTypeBox charLength={roleTypeName?.length} length={duration + rightModified - leftModified} onMouseDown={dragMouseDownHandler}>{roleTypeName}</RoleTypeBox>
      {!isDragging && <RightResizeBox editModeOn={editModeOn} className="resize-trigger" sliding={sliding} onMouseDown={editModeOn ? onRightResizeMouseDown : null} />}
      {warnings?.length > 0
        && (
          <Tooltip
            title={() => {
              const warningsArray = [];
              warnings?.forEach((warning) => warningsArray.push(<li>{warning}</li>));
              return (<ul>{warningsArray}</ul>)
            }}
          >
            <WarningSymbol duration={duration}>
              <ReactSVG src={warningIcon} />
            </WarningSymbol>
          </Tooltip>
        )}
      <EndTimeText length={duration + rightModified - leftModified}>{timeTo.substring(0, 5)}</EndTimeText>
    </ObligationCardWrapper>
  );
};

export default ObligationCard;

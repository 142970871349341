import { useTranslation } from 'react-i18next';
import { Tabs, Form, Card, message } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import FormModalStyled, { Container } from '../../../core/components/modals/FormModalStyled';
import withModal from '../../../core/components/withModals';
import {
  getAllGlobalPermissions,
  getAllLocationPermissions,
  updateGlobalPermissions,
  updateLocationPermissions
} from '../../../services/permissions.service';
import ASelect from '../../../core/components/select/ASelect';
import { usersPermissionsValues,
  settingsPermissionsValues,
  reportsPermissionsValues,
  annualPlanPermissionsValues,
  monthlyPlanPermissionsValues,
  dailyPlanPermissionsValues,
  changeWorkShiftsPermissionsValues
} from '../../../util/permissionsValuesEnum';
import AButton from '../../../core/components/buttons/AButton';
import { useAuthStateValue } from '../../../context/AuthContext';

const UserPermissionsModal = (props) => {
  const { t } = useTranslation();
  const { dataSource } = props;
  const [form] = Form.useForm();
  const [isFormsTouched, setIsFormTouched] = useState(false);
  const { loggedUser } = useAuthStateValue();
  const handleOk = async () => {
    try {
      await form.validateFields();
      // Update Global Permissions API Call
      await updateGlobalPermissions(dataSource?.id, {
        permissionReport: form.getFieldValue('reports'),
        permissionSettingSystem: form.getFieldValue('settings'),
        settingUserAccountsDataAndStaffPermissions: form.getFieldValue('users'),
        state: 'ACTIVE'
      });
      message.success(t('permissions.globalPermissionsSavedSuccessfully'));
      // Map locations permissions form data & Update Location Permissions API Call
      const LocPayload = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(form.getFieldsValue())) {
        if (key.startsWith('loc')) {
          const locationId = key.split('-')[1];
          const correspondingLocation = LocPayload.find((el) => el.locationId === locationId);
          if (correspondingLocation) {
            correspondingLocation[key.split('-')[2]] = value;
          } else {
            LocPayload.push({
              state: 'ACTIVE',
              locationId,
              [key.split('-')[2]]: value
            });
          }
        }
      }
      await updateLocationPermissions(dataSource?.id, LocPayload);
      message.success(t('permissions.localPermissionsSavedSuccessfully'));
      props.onCancel();
    } catch (e) {
      if (e.config.url.startsWith('/user-location')) {
        message.error(t('permissions.locationPermissionsFail'));
      } else {
        message.error(t('permissions.permissionsModifyFail'));
      }
      props.onCancel();
      console.log(e);
    }
  }
  const globalPermissionsFormFields = [
    {
      name: t('main.users'),
      id: 'users',
      render: <ASelect options={usersPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    },
    {
      name: t('main.settings'),
      id: 'settings',
      render: <ASelect options={settingsPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    },
    {
      name: t('main.reports'),
      id: 'reports',
      render: <ASelect options={reportsPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    }
  ];

  const locationPermissionsFormFields = [
    {
      name: (t('main.annualPlan')),
      id: 'annualPlan',
      render: <ASelect options={annualPlanPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    },
    {
      name: (t('main.monthlyPlan')),
      id: 'monthlyPlan',
      render: <ASelect options={monthlyPlanPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    },
    {
      name: (t('main.dailyPlan')),
      id: 'dailyPlan',
      render: <ASelect options={dailyPlanPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    },
    {
      name: (t('main.shiftsChange')),
      id: 'changeWorkShift',
      render: <ASelect options={changeWorkShiftsPermissionsValues.map((el) => { return { key: el, title: t('permissions.' + el), value: el } })} />
    }
  ];

  useEffect(() => {
    form.resetFields();
    const getGlobalPermissions = async () => {
      const res = await getAllGlobalPermissions({ userId: dataSource?.id });
      if (res?.data?.length > 0) {
        form.setFieldsValue({
          users: res?.data[0]?.settingUserAccountsDataAndStaffPermissions,
          settings: res?.data[0]?.permissionSettingSystem,
          reports: res?.data[0]?.permissionReport
        })
      } else {
        form.setFieldsValue({
          users: 'NONE',
          settings: 'NONE',
          reports: 'NONE'
        });
      }
    };
    const getLocalPermissions = async () => {
      const locationRes = await getAllLocationPermissions({ userId: dataSource?.id });
      if (locationRes?.data?.length > 0) {
        locationRes.data.forEach((el) => {
          form.setFieldValue('loc-' + el.location.id + '-annualPlan', el.annualPlan);
          form.setFieldValue('loc-' + el.location.id + '-monthlyPlan', el.monthlyPlan);
          form.setFieldValue('loc-' + el.location.id + '-dailyPlan', el.dailyPlan);
          form.setFieldValue('loc-' + el.location.id + '-changeWorkShift', el.changeWorkShift);
        })
      } else {
        loggedUser?.locationsData.forEach((el) => {
          form.setFieldValue('loc-' + el.id + '-annualPlan', 'NONE');
          form.setFieldValue('loc-' + el.id + '-monthlyPlan', 'NONE');
          form.setFieldValue('loc-' + el.id + '-dailyPlan', 'NONE');
          form.setFieldValue('loc-' + el.id + '-changeWorkShift', 'NONE');
        });
      }
    }
    getGlobalPermissions();
    getLocalPermissions();
  }, [dataSource]);
  const closeHandler = () => {
    props.onCancel();
    setIsFormTouched(false);
  }
  return (
    <FormModalStyled
      title={t('user.changeUserPermissions') + ' - ' + dataSource?.firstName + ' ' + dataSource?.lastName}
      centered
      maskClosable={false}
      width={560}
      {...props}
    >
      <Container style={{ height: '500px' }}>
        <TabsWrapper>
          <Form
            form={form}
            onFieldsChange={() => {
              setIsFormTouched(true);
            }}
          >
            <Tabs
              type="card"
              items={[
                {
                  label: t('user.globalPermissions'),
                  key: 1,
                  forceRender: true,
                  children: (
                    <FlexContainer>
                      {globalPermissionsFormFields.map((el) => {
                        return (
                          <StyledFormItem label={el.name} name={el.id} rules={[{ required: true, message: t('errors.required') }]}>
                            {el.render}
                          </StyledFormItem>
                        );
                      })}
                    </FlexContainer>
                  )
                },
                {
                  label: t('user.locationPermissions'),
                  key: 2,
                  forceRender: true,
                  children: (
                    <LocationsCardContainer>
                      {loggedUser?.locationsData.map((location) => (
                        <Card size="small" title={location.name} style={{ width: '100%' }}>
                          {locationPermissionsFormFields.map((el) => (
                            <StyledFormItem label={el.name} name={'loc-' + location.id + '-' + el.id} rules={[{ required: true, message: t('errors.required') }]}>
                              {el.render}
                            </StyledFormItem>
                          ))}
                        </Card>
                      ))}
                    </LocationsCardContainer>
                  )
                }
              ]}
            />
          </Form>
        </TabsWrapper>
      </Container>
      <ModalFooter>
        <ModalButton onClick={closeHandler}>{t('buttons.cancel')}</ModalButton>
        <ModalButton onClick={handleOk} disabled={!isFormsTouched}>{t('buttons.save')}</ModalButton>
      </ModalFooter>
    </FormModalStyled>
  );
};

const TabsWrapper = styled.div`
  height: 250px;
`;

const ModalButton = styled(AButton)`
  border-radius: 5px !important;
  box-sizing: border-box;
  &:hover {
    border: none !important;
  }
  &:disabled {
    background-color: #858585 !important;
    &:hover {
      background-color: #858585 !important;
      border: none !important;
    }
  }
`;

const ModalFooter = styled.div`
  border-top: 1px solid #eaeaea;
  width: 100%;
  display:flex;
  justify-content: right;
  padding: 10px 15px 0px 0px;
  gap: 15px;
`;

const StyledFormItem = styled(Form.Item)`
& label {
  width: 150px;
}
& .ant-form-item-required::before {
  display: none !important;
}
`;

const LocationsCardContainer = styled.div`
 display: flex;
 flex-direction: column;
 gap: 20px;
 padding: 20px;
 height: 400px;
 overflow-y: scroll;
`;

const FlexContainer = styled.div`
  padding: 20px;
`;

export default withModal()(UserPermissionsModal);

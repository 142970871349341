import { Dropdown } from 'antd';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { DropdownContainer, Column, ColumnsContainer, ColumnHeading, SectorCell, ButtonsContainer, ActionButton } from './ContextMenu.style';
import { useAuthStateValue } from '../../../../context/AuthContext';
// import revertIcon from '../../../../assets/images/icons/awesome-undo.svg';
import xMarkIcon from '../../../../assets/images/icons/xmark-solid.svg';

const ContextMenu = (props) => {
  const [open, setOpen] = useState();
  const { children } = props;
  const { loggedUser } = useAuthStateValue();

  const { modified, controllerId, timeFrom, timeTo, sectorId, sectorRoleId, setModifiedData, editModeOn } = props;

  const exeRoleId = loggedUser?.sectorRoles?.find((el) => el?.shortName === 'EXE')?.id;
  const plcRoleId = loggedUser?.sectorRoles?.find((el) => el?.shortName === 'PLC')?.id;

  const handleOpenChange = (flag) => {
    setOpen(flag);
  }

  const changeSector = (value, role) => {
    if (!modified) {
      setModifiedData((prevState) => [
        ...(prevState ? prevState : []),
        [
          ...(prevState?.[prevState?.length - 1] ? prevState?.[prevState?.length - 1] : []),
          {
            controllerId,
            timeFrom,
            timeTo,
            type: 'REMOVE',
            sectorId,
            sectorRoleId
          },
          {
            controllerId,
            timeFrom,
            timeTo,
            type: 'ADD',
            sectorId: value,
            sectorRoleId: role === 'EXE' ? exeRoleId : plcRoleId
          }
        ]
      ]);
    } else {
      setModifiedData((prevState) => {
        const newStateArray = [...prevState];
        const newestModifiedDataArray = [];
        prevState?.[prevState?.length - 1]?.forEach((el) => {
          if (el?.timeFrom === timeFrom && el?.timeTo === timeTo && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
            newestModifiedDataArray.push({
              controllerId: el?.controllerId,
              timeFrom: el?.timeFrom,
              timeTo: el?.timeTo,
              type: 'ADD',
              sectorId: value,
              sectorRoleId: role === 'EXE' ? exeRoleId : plcRoleId
            });
          } else newestModifiedDataArray.push(el);
        });
        newStateArray.push(newestModifiedDataArray);
        return newStateArray;
      });
    }
    setOpen(false);
  }

  const removeHandler = () => {
    if (!modified) {
      setModifiedData((prevState) => [
        ...(prevState ? prevState : []),
        [
          ...(prevState?.[prevState?.length - 1] ? prevState?.[prevState?.length - 1] : []),
          {
            controllerId,
            timeFrom,
            timeTo,
            type: 'REMOVE',
            sectorId,
            sectorRoleId
          }
        ]
      ]);
    } else if (modified) {
      setModifiedData((prevState) => {
        const newStateArray = [...prevState];
        const newestModifiedDataArray = [];
        prevState?.[prevState?.length - 1]?.forEach((el) => {
          if (el?.timeFrom === timeFrom && el?.timeTo === timeTo && el?.controllerId === controllerId && el?.type !== 'REMOVE') {
            return;
          }
          newestModifiedDataArray.push(el);
        });
        newStateArray.push(newestModifiedDataArray);
        return newStateArray;
      });
    }
  };

  // const revertHandler = () => {
  //   setModifiedData((prevState) => {
  //     const newModifiedData = [];
  //     prevState?.slice().reverse().forEach((el) => {
  //       const stepState = [];
  //       el?.forEach((stepEl) => {
  //         if (
  //           (stepEl?.type === 'ADD')
  //           && ((stepEl?.controllerId !== controllerId)
  //           || (stepEl?.timeFrom !== timeFrom)
  //             || (stepEl?.timeTo !== timeTo)
  //             || (stepEl?.sectorId !== sectorId)
  //             || (stepEl?.sectorRoleId !== sectorRoleId))
  //         ) {
  //           stepState.push(stepEl);
  //         }
  //       });
  //       if (stepState?.length > 0) newModifiedData.push(stepState);
  //     });
  //     return newModifiedData?.slice()?.reverse();
  //   });
  //   setOpen(false);
  // }

  const renderExeSectors = () => {
    const sectorCellsArr = [];
    loggedUser?.sectors?.forEach((sector) => {
      sectorCellsArr.push(<SectorCell sectorId={sector?.id} onClick={() => { changeSector(sector?.id, 'EXE') }}>{sector?.shortName}</SectorCell>);
    })
    return sectorCellsArr;
  }

  const renderPlcSectors = () => {
    const sectorCellsArr = [];
    loggedUser?.sectors?.forEach((sector) => {
      sectorCellsArr.push(<SectorCell sectorId={sector?.id} onClick={() => { changeSector(sector?.id, 'PLC') }}>{sector?.shortName}</SectorCell>);
    })
    return sectorCellsArr;
  }

  return (
    <Dropdown
      open={open}
      trigger={editModeOn ? ['contextMenu'] : ['']}
      onOpenChange={handleOpenChange}
      dropdownRender={() => {
        return (
          <DropdownContainer>
            <ColumnsContainer>
              <Column>
                <ColumnHeading>PLC</ColumnHeading>
                {renderPlcSectors()}
              </Column>
              <Column>
                <ColumnHeading>EXE</ColumnHeading>
                {renderExeSectors()}
              </Column>
            </ColumnsContainer>
            <ButtonsContainer>
              {/* <ActionButton className={modified ? 'enabled' : 'disabled'} onClick={modified ? revertHandler : null}><ReactSVG src={revertIcon} /></ActionButton> */}
              <ActionButton className="enabled" onClick={removeHandler}><ReactSVG src={xMarkIcon} /></ActionButton>
            </ButtonsContainer>
          </DropdownContainer>
        )
      }}
    >
      {children}
    </Dropdown>
  )
}

export default ContextMenu;

import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const FooterContainer = styled.div`
  width: calc(100% - 115px);
  height: auto;
  max-height: ${(props) => (props?.expanded ? 600 : 45)}px;
  position: fixed;
  bottom: 20px;
  z-index: ${(props) => (props?.expanded ? '12' : '5')};
  padding: 5px 0px 0px 5px;
  transition: all 0.5s;
  overflow: hidden;
  cursor: pointer;
`;

export const ExpandFooterButtonCell = styled.div`
  background-color: #5F5F5F;
  color: white;
  font-weight: 600;
  width: ${(props) => (props?.expanded ? '100%' : '180px')};
  height: 35px;
  border-radius: 5px;
  cursor: pointer;
  justify-content: ${(props) => (props?.expanded ? 'right' : 'center')};
  padding: 5px 15px;
  display: flex;
  gap: 10px;
  transition: all 0.5s;
  z-index: 18 !important;
  box-sizing: border-box;
`;

export const ExpandFooterIcon = styled(ReactSVG)`
  width: 20px;
  transform: ${(props) => (props?.expanded ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 0.5s all;
`;

export const FooterTableContainer = styled.div`
  width: 100%;
  height: auto;
  opacity: ${(props) => (props?.expanded ? '1' : '0')};
  background-color: #EFEFEF;
  margin-bottom: 40px;
  transition: ${(props) => (props?.expanded ? 'all 1s' : 'all 0.2s')};
`;

export const DemandCell = styled.td`
  font-family: MontserratWeb-SemiBold;
  &.over {
    background-color: #78D59A;
  }
  &.under {
    background-color: #E18C82;
  }
  &.zero {
    background-color: #C7C7C7;
  }
`;

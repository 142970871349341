import styled from 'styled-components';

export const ShiftWrapper = styled.div`
    width: 10%;
    font-size: 18px;
    margin-right: 8px;
    margin-left: 8px;
    @media only screen and (max-width: 1600px) {
        font-size: 15px;
    }
    @media only screen and (max-width: 1420px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 1360px) {
        font-size: 13px;
    }
    @media only screen and (max-width: 1300px) {
        font-size: 11.6px;
    }
`;

export const ShiftHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const DayText = styled.div`
    font-weight: 600;
`;

export const DateText = styled.div`
    text-align: center;
`;

export const ShiftContent = styled.div`
    background-color: ${({ theme: { colors } }) => (colors.grayColors.lightGray)};
    height: 70%;
    border-radius: 9px;
    font-size: 45px;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-around;
    color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    font-weight: bold;
    text-align: center;
    &.coloredDiv {
        background-color: ${({ theme: { colors } }) => (colors.mainColors.blue)} !important;
    }
    &.coloredAbsence {
        background-color: ${({ theme: { colors } }) => (colors.mainColors.yellow)} !important;
    }
    &.coloredStandBy {
      background-color: #dc9900;
    }
    & > * > * > svg {
      width: 40%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
`;

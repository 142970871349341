import styled from 'styled-components';
import bhansaLogo from '../../../assets/images/bhansa-logo-full-inverted.svg';
import antEngineLogo from '../../../assets/images/antEngineLogoInverted.png';

export const Container = styled.div`
 border: none;
`;

export const BHANSALogo = styled.a`
  position: absolute;
  bottom: 20px;
  left: 50px;
  height: 200px;
  width: 220px;
  background-image: url(${bhansaLogo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
`;

export const AntEngineLogo = styled.a`
  position: absolute;
  right: 50px;
  bottom: 50px;
  background-image: url(${antEngineLogo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 38px;
  width: 170px;
`;

export const Logo = styled.div`
  display: flex;
  position: absolute;
  top: 17%;
  background-image: url(${(props) => props.appImage});
  width: 234px;
  height: 196px;
  background-repeat: no-repeat;
  align-items: center;
  background-size: contain;
`;

// eslint-disable-next-line import/no-named-as-default
import engineApi from './engine.service';

export const getAllDailySchedulesByMonth = (params) => {
  const { year, month } = params;
  return engineApi().get(`/engine/daily-schedules/${year}/${month}`);
};

export const getDailyScheduleContent = (params) => {
  const { year, month, day, version } = params;
  return engineApi().get(`/engine/daily-schedules/${year}/${month}/${day}/${version}/content`);
};

export const saveChanges = (params) => {
  const { dailyScheduleId, userId, changes } = params;
  return engineApi().put(`/engine/daily-schedules/${dailyScheduleId}/${userId}`, { changes });
};

export const saveChangesAsNewVersion = (params) => {
  const { dailyScheduleId, userId, changes } = params;
  return engineApi().post(`/engine/daily-schedules/${dailyScheduleId}/${userId}/version`, { changes });
};

export const sendScheduleToReview = (params) => {
  const { scheduleId, userId } = params;
  return engineApi().patch(`/engine/daily-schedules/${scheduleId}/${userId}/submit`);
};

export const approveDailySchedule = (params) => {
  const { scheduleId, userId } = params;
  return engineApi().patch(`/engine/daily-schedules/${scheduleId}/${userId}/approve`);
};

export const rejectDailySchedule = (params) => {
  const { scheduleId, userId } = params;
  return engineApi().patch(`/engine/daily-schedules/${scheduleId}/${userId}/reject`);
};

export const generateDailySchedule = (params) => {
  const { userId, year, month, day } = params;
  const payload = {
   year,
   monthNumber: month,
   day
  }
  return engineApi().post(`/engine/daily-schedules/${userId}/generate`, payload);
}

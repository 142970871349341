import styled from 'styled-components';
import { Spin } from 'antd';
import { ReactSVG } from 'react-svg';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  padding: 20px;
  width: 100%;
`;

export const Header = styled.div`
  width: calc(100% - 72px);
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BackButton = styled.div`
  width: 47px;
  height: 47px;
  border-radius: 100%;
  border: 1px solid #7D7D7D;
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
  color: #5F5F5F;
`;

export const BackButtonText = styled.div`
  font-size: 24px;
  padding: 5px 10px;
  color: #393939;
`;

export const TitleContainer = styled.div`
 display: flex;
 flex-direction: column;
`;

export const MonthTitleBox = styled.div`
  background-color: white;
  border-radius: 20px;
  margin-top: 1px;
  width: 490px;
  height: 66px;
  text-align: center;
  font-size: 22px;
  overflow: hidden;
  padding: 15px;
  font-weight: 600;
  box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
  position: relative;
  & > a > div {
  text-decoration: none;
  color: #5F5F5F !important;
  }
`;

export const PlanStatusBox = styled.div`
  position: relative;
  margin-left: 74px;
  width: 342px;
  height: 28px;
  border-radius: 0 0 10px 10px;
  color: white;
  text-align: center;
  padding-top: 2px;
  font-weight: 500;
`;

export const StatusBoxSpinner = styled(Spin)`
  height: 10px;
  margin-top: -10px;
  .ant-spin-dot-item {
    background-color: white;
  }
`;

export const RightSideMenu = styled.div`
  width: ${(props) => (props?.requestListExpanded ? 225 : 72)}px;
  height: 100vh;
  background-color: ${(props) => (props?.requestListExpanded ? '#dbdbdb' : '#0073AE')};
  /* background-color: #0073AE; */
  position: fixed;
  right: 0;
  z-index: 99;
  transition: 0.5s width;
`;

export const SheetContainer = styled.div`
  width: calc(100% - 72px);
  height: calc(100vh - 140px);
  padding-top: 15px;
`;

export const SideMenuContainer = styled.div`
  display: flex;
  width: 72px;
  position: fixed;
  bottom: 0px;
  padding: 15px 13px;
  flex-direction: column;
  gap: 10px;
`;

export const MenuItem = styled.div`
  width: 45px;
  height: 40px;
  border-radius: 7px;
  border: 2px solid white;
  text-align: center;
  padding-top: 5px;
  color: white;
  cursor: pointer;
  & svg {
    width: 20px;
    margin-top: 2px
  }
  &:hover {
    background-color: white;
    color: #0073AE;
  }
  &.disabled {
    color: rgba(255, 255, 255, 0.5) !important;
    border-color: rgba(255, 255, 255, 0.5);
  }
  &.disabled:hover {
    color: rgba(0, 115, 174, 0.5) !important;
  }
`;

export const CreateRequestInfoBox = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  width: 350px;
  opacity: ${(props) => (props?.isVisible ? 1 : 0)};
  color: #c44536;
  text-align: center;
`;

export const SelectYourShiftText = styled.p`
  color: #5b5b5b;
  font-weight: 600;
  margin-bottom: 0px;
  &.pulsatingText {
    animation: pulsating 1s infinite;
  }  
  @keyframes pulsating {
    0% {
      color: #f55442;
    }
    50% {
      color: #c44536;
    }
    100% {
      color: #f55442;
    }
  }
`;

export const SwapIconContainer = styled.div`
  opacity: ${(props) => (props?.visible ? 1 : 0)};
  transition: 1s all;
  color: #5b5b5b;
  transform: rotate(90deg);
  line-height: 10px;
  & > * > * > svg {
    width: 15px
  }
`;

export const RequestListContainer = styled.div`
{
  display: flex;
  height: 100%;
  flex-direction: column;
  color: white;
  font-size: 16px;
  background-color: #dbdbdb;
  user-select: none; 
}
`;

export const RequestListTitleContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 75px;
  text-wrap: wrap;
  flex-direction: row;
  text-align: center;
  width: 100%;
  background-color: #0073AE;
  //line-height: 50px;
  justify-content: space-between;
  & > div > div > svg {
    width: 15px;
    cursor: pointer;
  }
`;

export const RequestListTitle = styled.div`
  width: 180px;
  min-width: 180px;
  padding: 10px;
`;

export const CloseRequestListIcon = styled(ReactSVG)`
  width: 45px;
  min-width: 45px;
  height: 30px;
`;

export const RequestListItemsContainer = styled.div`
  width: 218px;
  min-width: 218px;
  height: 100%;
  align-items: center;
  overflow-y: auto;
  color:  #5F5F5F;
  text-align: center;
  scrollbar-gutter: stable both-edges;
  padding-bottom: 10px;
`;

export const RequestListItem = styled.div`
  width: 100%;
  transition: all 0.5s;
  max-height: ${(props) => (props?.expanded ? '900px' : '140px')};
  background-color: #efefef;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px;
  gap: 8px;
  align-items: center;
  overflow: hidden;
  margin-top: 10px;
`;

export const ListItemShiftContainer = styled.div`
  width: 45%;
  height: 90px;
  border-radius: 5%;
  border: 1px solid #D9D9D9;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const ListItemShiftName = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const ListItemDate = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

export const ListItemControllerName = styled.div`
  height: 30px;
  font-size: 11px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-wrap: balance;
`;

export const ListSwapIcon = styled(ReactSVG)`
  width: 10%;
  z-index: 9;
`;

export const RequestStatusLabel = styled.div`
  position: relative;
  color: white;
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  background-color: #0073AE;
  border-radius: 5px 5px 0 0;
  text-align: center;
`;

export const ListItemShiftsContainer = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center;
 width: 100%;
`;

export const ListItemCommentContainer = styled.div`
  border-radius: 5px;
  padding: 5px;
  border: 1px solid #D9D9D9;
  background-color: white;
  display: flex;
  width: 100%;
  text-align: left;
  font-size: 12px;
`;

export const ListItemActionBox = styled.div`
  width: 100%;
  // border-radius: 5px;
  // padding: 5px;
  // border: 1px solid #D9D9D9;
  // background-color: white;
  display: flex;
  justify-content: space-between;
`;

export const ListItemActionButton = styled.div`
  width: 49%;
  cursor: pointer;
  background-color: #cccccc;
  border: 1px solid #cccccc;
  height: 30px;
  border-radius: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: all 0.5s;
  &:hover {
    background-color: #efefef;
    border: 1px solid #cccccc;
    color: ${(props) => (props?.type === 'approve' ? '#0F8F3E' : '#C44536')};
  }
`;

export const RequestResponseBox = styled.div`
  width: 100%;
  // background-color: white;
  padding: 0 0 5px 5px;
  border-radius: 5%;
  // border: 1px solid #cccccc;
  font-size: 12px;
  text-align: left;
`;

export const UserResponseLabel = styled.span`
  color: ${(props) => (props?.status === 'NONE' ? '#DC9900' : (props?.status === 'APPROVED' ? '#0f8f3e' : '#C44536'))};
  font-weight: 600;
`;

export const RequestListItemCaret = styled(ReactSVG)`
  height: 30px;
  cursor: pointer;
  position: absolute;
  transition: all 0.5s;
  transform-origin: center; 
  transform: ${(props) => (props?.expanded ? 'rotate(90deg) translate(-5px, -5px)' : '')};
`;

export const RequestTimestampsBox = styled.div`
  width: 100%;
  font-size: 10px;
  text-align: left;
  padding: 0 0 0 5px;
`;

export const NoRequestsLabel = styled.div`
  text-align: center;
  font-size: 22px;
  margin-top: 30px;
  width: 200px;
`;

import { useState } from 'react';
import { getAllMonthlyPlans, getScheduleAll } from '../../../services/monthlyPlan.service';

export const useMonthlyPlans = () => {
    const [loadingMonthlyPlans, setLoading] = useState(false);
    const [dataSourceMonthly, setDataSourcemonthly] = useState();
    const [dataScheduleAll, setDataScheduleAll] = useState();

    const fetchMonthlyPlans = async (atcu, year, version) => {
        try {
            setLoading(true);
            const response = await getAllMonthlyPlans(atcu, year, version);
            setDataSourcemonthly(response.data);
        } catch (e) {
            setDataSourcemonthly(null);
        } finally {
            setLoading(false);
        }
    }
    const fetchScheduleAll = async (atcu, year) => {
        try {
            setLoading(true);
            const response = await getScheduleAll(atcu, year);
            setDataScheduleAll(response.data);
        } catch {
            setDataScheduleAll(null);
        } finally {
            setLoading(false);
        }
    }
    return {
        fetchMonthlyPlans,
        loadingMonthlyPlans,
        dataSourceMonthly,
        dataScheduleAll,
        fetchScheduleAll
    }
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import JwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import { login as loginApi, logout as logoutAPI } from '../services/auth.service';
import { useApplicationStateValue } from './ApplicationContext';
import { getAllGlobalPermissions, getAllLocationPermissions } from '../services/permissions.service';
import { getLocations } from '../services/locations.service';
import { getShiftTypes, getAbsenceTypes } from '../services/monthlyPlan.service';
import { getAllSectors } from '../services/sector.service';
import { getSectorRoles } from '../services/sectorRole.service';

const defaultState = {
    isLoggedIn: null,
    accessToken: null,
    refreshToken: null,
    loggedUser: null
};

const defaultActions = {
    login: () => {},
    logout: () => {},
    setLoggedIn: () => {}
};

export const AuthContext = React.createContext({
    ...defaultState,
    ...defaultActions
});

export const AuthConsumer = AuthContext.Consumer;

const AuthProvider = (props) => {
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [accessToken, setAccessToken] = useState(null);
    const [refreshToken, setRefreshToken] = useState(null);
    const [loggedUser, setLoggedUser] = useState({
      firstName: null,
      lastName: null,
      username: null,
      id: null,
      globalPermissions: null,
      locationPermissions: null,
      extendUser: null,
      locationsData: null,
      shiftTypes: null,
      absenceTypes: null
    });
    const { setLoading } = useApplicationStateValue();
    const history = useHistory();

  useEffect(() => {
    const accessToken = sessionStorage.getItem('accessToken');
    const globalPermissions = JSON.parse(sessionStorage.getItem('globalPermissions'));
    const localPermissions = JSON.parse(sessionStorage.getItem('locationPermissions'));
    const extendUser = JSON.parse(sessionStorage.getItem('extendUser'));
    const locationsData = JSON.parse(sessionStorage.getItem('locationsData'));
    const shiftTypes = JSON.parse(sessionStorage.getItem('shiftTypes'));
    const absenceTypes = JSON.parse(sessionStorage.getItem('absenceTypes'));
    const sectors = JSON.parse(sessionStorage.getItem('sectors'));
    const sectorRoles = JSON.parse(sessionStorage.getItem('sectorRoles'));
    const permittedRoutes = [
      {
        key: 'dashboard'
      }
    ];
    if (localPermissions) {
      // eslint-disable-next-line no-restricted-syntax,no-unused-vars
      for (const [key, value] of Object.entries(localPermissions)) {
        if (value.annualPlan && value.annualPlan !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'annualPlan') === -1) {
          permittedRoutes.push({ key: 'annualPlan' });
        }
        if (value.monthlyPlan && value.monthlyPlan !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'monthlyPlan') === -1) {
          permittedRoutes.push({ key: 'monthlyPlan' });
        }
        if (value.dailyPlan && value.dailyPlan !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'dailyPlan') === -1) {
          permittedRoutes.push({ key: 'dailyPlan' });
        }
        if (value.changeWorkShift && value.changeWorkShift !== 'NONE' && permittedRoutes.findIndex((el) => el.key === 'shiftsChange') === -1) {
          permittedRoutes.push({ key: 'shiftsChange' });
        }
      }
    }
    if (globalPermissions?.settingUserAccountsDataAndStaffPermissions && globalPermissions?.settingUserAccountsDataAndStaffPermissions !== 'NONE') {
      permittedRoutes.push({ key: 'users' });
    }
    if (globalPermissions?.permissionSettingSystem && globalPermissions?.permissionSettingSystem !== 'NONE') {
      permittedRoutes.push({ key: 'settings' });
    }
    if (globalPermissions?.permissionReport && globalPermissions?.permissionReport !== 'NONE') {
      permittedRoutes.push({ key: 'reports' });
    }
    if (accessToken) {
      try {
        const tokenObj = JwtDecode(accessToken);
        setLoggedUser({
          firstName: tokenObj.first_name,
          lastName: tokenObj.last_name,
          username: tokenObj.sub,
          id: tokenObj.jti,
          globalPermissions,
          localPermissions,
          permittedRoutes,
          extendUser,
          locationsData,
          shiftTypes,
          absenceTypes,
          sectors,
          sectorRoles
        });
        setLoggedIn(true);
      } catch (e) {
        setLoggedIn(false);
        setLoggedUser(false);
        console.error(e);
      }
      // TODO Send request to backend to check jwt or?
    }
    setLoading(false);
  }, [isLoggedIn]);

  const fetchAllGlobalPermissions = async (userId) => {
    const res = await getAllGlobalPermissions({ userId });
    return {
      settingUserAccountsDataAndStaffPermissions: res?.data[0]?.settingUserAccountsDataAndStaffPermissions,
      permissionReport: res?.data[0]?.permissionReport,
      permissionSettingSystem: res?.data[0]?.permissionSettingSystem
    }
  }

  const fetchLocations = async (userId) => {
     const locationsDataArray = [];
     const res = await getLocations(userId);
     res?.data?.forEach((element) => {
      locationsDataArray.push({
        key: element.id,
        label: element.name,
        value: element.identificator,
        ...element
      });
     });
    return locationsDataArray;
  }

  const fetchAllLocationPermissions = async (userId) => {
    const locationPermissionsObj = {};
    const res = await getAllLocationPermissions({ userId });
    res?.data?.forEach((locationPermission) => {
      locationPermissionsObj[locationPermission.location.id] = {
        annualPlan: locationPermission.annualPlan,
        monthlyPlan: locationPermission.monthlyPlan,
        dailyPlan: locationPermission.dailyPlan,
        changeWorkShift: locationPermission.changeWorkShift
      }
    })
    return locationPermissionsObj;
  }

    const login = async ({ username, password }) => {
      try {
        const response = await loginApi({
          username,
          password
        });
        const { accessToken, refreshToken, user } = response.data;
        if (accessToken) {
          setLoading(true);
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          setLoggedUser({
            ...user,
            usergroupKey: user.userGroup?.key
          });
          sessionStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);
          sessionStorage.setItem('extendUser', JSON.stringify(user.extendUser));
          const tokenObj = JwtDecode(accessToken);
          // Fetch global and local permissions of logged user and store it in session storage
          const globalPermissions = await fetchAllGlobalPermissions(tokenObj.jti);
          sessionStorage.setItem('globalPermissions', JSON.stringify(globalPermissions));
          // Map fetched location permissions for each location and store it to sessions storage
          const locationPermissions = await fetchAllLocationPermissions(tokenObj.jti);
          sessionStorage.setItem('locationPermissions', JSON.stringify(locationPermissions));
          // Fetch locations of logged user and store it in session storage
          const locationsData = await fetchLocations(tokenObj.jti);
          sessionStorage.setItem('locationsData', JSON.stringify(locationsData));
          // Fetch shift types and store it to local storage
          const shiftTypes = {};
          // eslint-disable-next-line no-restricted-syntax
          for (const location of locationsData) {
            // eslint-disable-next-line no-await-in-loop
            const locationShifts = await getShiftTypes(location?.identificator);
            shiftTypes[location?.identificator] = locationShifts?.data;
          }
          sessionStorage.setItem('shiftTypes', JSON.stringify(shiftTypes));
          // Fetch absence types and store it to local storage
          const absenceTypes = await getAbsenceTypes();
          sessionStorage.setItem('absenceTypes', JSON.stringify(absenceTypes?.data));
          // Fetch all sectors
          const sectors = await getAllSectors();
          sessionStorage.setItem('sectors', JSON.stringify(sectors?.data));
          // Fetch all sector roles
          const sectorRoles = await getSectorRoles();
          sessionStorage.setItem('sectorRoles', JSON.stringify(sectorRoles?.data));
          // Set loggedIn state to true
          setLoggedIn(true);
          setTimeout(() => {
            setLoading(false);
          }, 50);
        }
      } catch (error) {
        console.log(error);
        setAccessToken(null);
        setRefreshToken(null);
        setLoggedUser(null);
        setLoggedIn(false);
        setLoading(false);
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        throw error;
      }
    };

    const logout = async () => {
      await (async function () {
        try {
          await logoutAPI();
          sessionStorage.removeItem('accessToken');
          setLoggedIn(false);
          setLoggedUser(null);
          history.push('/');
        } catch (err) {
          console.error(err);
        }
      }());
    };
    const state = {
      isLoggedIn,
      accessToken,
      refreshToken,
      loggedUser,
      setLoggedIn,
      login,
      logout,
      setLoggedUser
    };

    return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>;
  }

  AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
  };

export const useAuthStateValue = () => React.useContext(AuthContext);

export default AuthProvider;

import React from 'react';
import { message, Input } from 'antd';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import withModal from '../withModals';
// import { ATextInput } from '../inputs/textInput/ATextInput';
import AForm from '../form/AForm';
import StatusCode from '../../../services/statusCode';
import FormModalStyled from './FormModalStyled';
import { useAuthStateValue } from '../../../context/AuthContext';
import { changePassword } from '../../../services/user.service';

require('yup-password')(yup);

const ChangePasswordModal = ({ modalProps, closeDialog }) => {
    const { t } = useTranslation();

 const { loggedUser, setLoggedUser } = useAuthStateValue();

    const formFields = [
        {
          name: t('user.currentPassword'),
          id: 'oldPassword',
          render: <Input.Password
            placeholder={t('user.enterPassword')}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            style={{ width: '300px', margin: '0 20px 0 20px', borderRadius: '5px' }}
          />
        },
        {
          name: t('user.newPassword'),
          id: 'password',
          render: <Input.Password
            placeholder={t('user.enterPassword')}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            style={{ width: '300px', margin: '0 20px 0 20px', borderRadius: '5px' }}
          />
        },
        {
          name: t('user.confirmNewPassword'),
          id: 'newPasswordConfirm',
          render: <Input.Password
            placeholder={t('user.enterPassword')}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            style={{ width: '300px', margin: '0 20px 0 20px', borderRadius: '5px' }}
          />
        }
      ];

      const min = 8;
  const max = 32;

  const validationSchema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(t('errors.required')),
      // .min(min, `${t('errors.minLength')} ${min}.`)
      // .max(max, `${t('errors.maxLength')} ${max}.`),
    password: yup
      .string()
      .required(t('errors.required'))
      .min(min, `${t('errors.minLength')} ${min}.`)
      .minSymbols(1, t('user.policy'))
      .minLowercase(1, t('user.policy'))
      .minUppercase(1, t('user.policy'))
      .minNumbers(1, t('user.policy'))
      .notOneOf([yup.ref('oldPassword'), null], t('user.samePasswords'))
      .max(max, `${t('errors.maxLength')} ${max}.`),
    newPasswordConfirm: yup
      .string()
      .required(t('errors.required'))
      .oneOf([yup.ref('password'), null], t('user.confirmPasswordInvalid'))
  });

  const changePasswordHandlerWrapper = (obj, id, setLoading) => {
    (async function () {
      try {
        // const res = await changePassword(obj, id);
        const res = await changePassword(obj, id); // add service for changePassword
        if (res.status === StatusCode.OK) {
          obj.id = id;
          setLoggedUser({
            ...loggedUser,
            firstName: obj.firstName,
            lastName: obj.lastName,
            username: obj.username
          });
          closeDialog();
          message.success(t('user.passwordChangeSuccess'));
        } else {
          message.error(t('user.passwordChangeError'));
        }
      } catch (err) {
        if (err.status === StatusCode.CONFLICT) {
          const translatedErrors = err.data.map((error) => t(`user.${error}`, error));
          message.error(t('errors.uniqueFields', { fields: translatedErrors?.join(',') }));
        } else message.error(t('errors.error'));
        setLoading(false);
      }
    }());
  };

  const userModalData = { id: loggedUser?.id };

  return (
    <FormModalStyled
      centered
      width="550px"
      footer={[]}
      {...modalProps}
      maskClosable={false}
      title={t('user.passwordChange')}
    >
      <AForm
        fields={formFields}
        okButtonText={t('buttons.confirm')}
        modalData={userModalData}
        validationSchema={validationSchema}
        changePasswordHandler={(obj, setLoading) => {
          changePasswordHandlerWrapper(obj, userModalData.id, setLoading);
        }}
      />
    </FormModalStyled>
  );
};

ChangePasswordModal.propTypes = {
    modalProps: PropTypes.object,
    closeDialog: PropTypes.func.isRequired
  };

  export default withModal()(ChangePasswordModal);

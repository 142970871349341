import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const SearchButton = ({ clickHandler }) => <SearchColumnButton onClick={clickHandler} shape="circle" icon={<SearchOutlined />} />;

const SearchColumnButton = styled(Button)`
  border-radius: 10px;
  margin-left: 10px;

  :hover {
    background-color: #e8e8e8;
  }

  span {
    display: flex;
    justify-content: center;
  }

  span svg {
    color: #2b2b2b;
  }
`;

SearchButton.propTypes = {
    clickHandler: PropTypes.func.isRequired
};

export default SearchButton;

import { useEffect, useState } from 'react';
import { TooltipWrapper } from './DailySheetTooltip.style';

const DailySheetTooltip = (props) => {
  const { visible, content, sheetRef } = props;
  const [mouseX, setMouseX] = useState();
  const [mouseY, setMouseY] = useState();
  const [placementX, setPlacementX] = useState('right');
  const [placementY, setPlacementY] = useState('bottom');

  const mouseMoveHandler = () => {
    const sheetWidth = sheetRef?.current?.offsetWidth;
    const sheetHeight = sheetRef?.current?.offsetHeight;
    const mouseXInsideSheet = sheetWidth - (sheetWidth - (window.event.clientX - sheetRef?.current?.offsetLeft));
    const mouseYInsideSheet = sheetHeight - (sheetHeight - (window.event.clientY - sheetRef?.current?.offsetTop));
    if (mouseXInsideSheet > 0 && mouseXInsideSheet <= sheetWidth && mouseYInsideSheet <= sheetHeight) {
      setMouseX(window.event.clientX - sheetRef?.current?.offsetLeft + 10);
      setMouseY(window.event.clientY - sheetRef?.current?.offsetTop + 15 + sheetRef?.current?.scrollTop);
      const percentageX = Math.round(mouseXInsideSheet / (sheetWidth / 100));
      const percentageY = Math.round(mouseYInsideSheet / (sheetHeight / 100));
      if (percentageX > 85) setPlacementX('left');
      else setPlacementX('right');
      if (percentageY > 90) setPlacementY('top');
      else setPlacementY('bottom');
    }
  };

  useEffect(() => {
    if (visible) {
      window.addEventListener('mousemove', mouseMoveHandler);
    } else {
      window.removeEventListener('mousemove', mouseMoveHandler);
    }
  }, [visible]);

  useEffect(() => {
    window.addEventListener('mousemove', mouseMoveHandler);
  }, []);

  return (
    <TooltipWrapper
      visible={visible}
      left={mouseX}
      top={mouseY}
      placementX={placementX}
      placementY={placementY}
    >
      {content}
    </TooltipWrapper>
  );
}

export default DailySheetTooltip;

import { useTranslation } from 'react-i18next';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  DayContent,
  HourCaption,
  ObligationCard,
  ObligationsContainer,
  ObligationsWrapper,
  RoleTypeLabel,
  ScaleMark,
  TimelineHeader,
  Title
} from './DailySchedule.style';
import { Panel } from '../../../core/components/panel/Panel';
import { useAuthStateValue } from '../../../context/AuthContext';
import useDetailedDailySchedule from '../../detailedDailySchedule/hooks/useDetailedDailySchedule';
import { timeStringToHoursCount } from '../../../util/util';
import { getDailyScheduleContent } from '../../../services/dailyPlan.service';

export const DailySchedule = () => {
  const { t } = useTranslation();
  const dayTitle = t('dailySchedule.title');
  const daySubtitle = t('dailySchedule.subTitle');
  const timeArray = [];
  const { loggedUser } = useAuthStateValue();
  const [latestActiveVersion, setLatestActiveVersion] = useState();
  const [loggedUserTodayObligations, setLoggedUserTodayObligations] = useState();
  const [loggedUserNextDayObligations, setLoggedUserNextDayObligations] = useState();
  const [nextDayShiftInterval, setNextDayShiftInterval] = useState();
  const [windowSize, setWindowSize] = useState();
  const [hourCellWidth, setHourCellWidth] = useState();

  const todayDate = new Date();
  const shiftContainerRef = useRef();

  const { getDailyPlanVersions, getDailyPlanContent, versions, dataSource, nextDayLatestActiveVersion } = useDetailedDailySchedule({
    year: todayDate?.getFullYear(),
    month: parseInt(todayDate?.getMonth(), 10) + 1,
    day: todayDate?.getDate(),
    version: latestActiveVersion?.value
  });

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize(window.innerWidth);
    });
  }, []);

  const getNextDayScheduleContent = async () => {
    try {
      const res = await getDailyScheduleContent({
        year: nextDayLatestActiveVersion?.year,
        month: nextDayLatestActiveVersion?.monthNumber,
        day: nextDayLatestActiveVersion?.day,
        version: nextDayLatestActiveVersion?.version
      });
      const nextDayObligations = res?.data?.controllers?.find((el) => el?.id === parseInt(loggedUser?.id, 10));
      setLoggedUserNextDayObligations(nextDayObligations?.obligations);
      setNextDayShiftInterval([timeStringToHoursCount(nextDayObligations?.shift.timeFrom, false), timeStringToHoursCount(nextDayObligations?.shift.timeTo, true)]);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    setWindowSize(window.innerWidth);
  }, []);

  useEffect(() => {
    setHourCellWidth(shiftContainerRef?.current?.offsetWidth / 24);
  }, [windowSize]);

  const timeFunction = () => {
    for (let i = 0; i < 25; i += 1) {
      timeArray[i] = new Date(new Date().setHours(new Date().getHours() + i - 2)).toLocaleTimeString(['sr'], { hour: '2-digit' });
    }
    return timeArray;
  }

  useEffect(() => {
    if (nextDayLatestActiveVersion) {
      getNextDayScheduleContent();
    }
  }, [nextDayLatestActiveVersion]);

  useEffect(() => {
    getDailyPlanVersions();
  }, []);

  useEffect(() => {
    if (versions) {
      let latestVersion;
      versions?.forEach((version) => {
        if (!latestVersion && version?.status === 'APPROVED_AND_ACTIVE') latestVersion = version;
        else if (latestVersion && latestVersion?.value < version?.value && version?.status === 'APPROVED_AND_ACTIVE') latestVersion = version;
      });
      setLatestActiveVersion(latestVersion);
    }
  }, [versions]);

  useEffect(() => {
    if (latestActiveVersion) {
      getDailyPlanContent()
    }
  }, [latestActiveVersion]);

  useEffect(() => {
    if (dataSource) {
      const loggedUserTodaySchedule = dataSource?.controllers?.find((el) => el?.id === parseInt(loggedUser?.id, 10))?.obligations;
      setLoggedUserTodayObligations(loggedUserTodaySchedule);
    }
  }, [dataSource]);

  return (
    <Panel
      title={dayTitle}
      subTitle={daySubtitle}
      hasBackgroundColor
      arrowSignVisible
      actionBoxVisible={false}
    >
      <DayContent>
        <TimelineHeader>
          {timeFunction()?.map((item, index) => {
            return (
              <div key={index}>
                <HourCaption key={index}>{item + 'h'}</HourCaption>
                <ScaleMark>|</ScaleMark>
              </div>
            )
          })}
        </TimelineHeader>
        <ObligationsWrapper>
          <ObligationsContainer ref={shiftContainerRef}>
            {
              loggedUserTodayObligations?.map((el) => {
                const currentHour = new Date().getHours();
                const timeFromInteger = timeStringToHoursCount(el?.timeFrom, false);
                const timeToInteger = timeStringToHoursCount(el?.timeTo, true);
                const duration = timeToInteger - timeFromInteger;
                if (timeToInteger > currentHour - 2) {
                  return (
                    <ObligationCard
                      hourCellWidth={hourCellWidth}
                      timeFrom={timeFromInteger}
                      timeTo={timeToInteger}
                      duration={duration}
                      intervalFrom={new Date().getHours() - 2}
                      intervalTo={new Date().getHours() + 24 - 2}
                      sectorRoleType={el?.sectorRole?.shortName}
                    >
                      <Title>{el?.sector?.shortName}</Title>
                      <RoleTypeLabel>{el?.sectorRole?.shortName}</RoleTypeLabel>
                    </ObligationCard>
                  );
                }
              })
            }
            {
              loggedUserNextDayObligations?.map((el) => {
                const currentHour = new Date().getHours();
                const timeFromInteger = timeStringToHoursCount(el?.timeFrom, false) + 24 - 2;
                const timeToInteger = timeStringToHoursCount(el?.timeTo, true) + 24 - 2;
                const duration = timeToInteger - timeFromInteger;
                if (nextDayShiftInterval?.[0] < currentHour - 2) {
                  return (
                    <ObligationCard
                      hourCellWidth={hourCellWidth}
                      timeFrom={timeFromInteger}
                      timeTo={timeToInteger}
                      duration={duration}
                      intervalFrom={new Date().getHours() - 2}
                      intervalTo={new Date().getHours() + 24 - 2}
                      sectorRoleType={el?.sectorRole?.shortName}
                    >
                      <Title>{el?.sector?.shortName}</Title>
                      <RoleTypeLabel>{el?.sectorRole?.shortName}</RoleTypeLabel>
                    </ObligationCard>
                  );
                }
              })
            }
          </ObligationsContainer>
        </ObligationsWrapper>
      </DayContent>
    </Panel>
  )
};

export default DailySchedule;

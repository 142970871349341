import styled from 'styled-components';

export const ChangelogList = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  background-color: #dbdbdb;
  width: 100%;
  max-height: 90vh;
  overflow: auto;
  &::-webkit-scrollbar{
    display: none;
  }
  .ant-collapse {
    border-radius: 10px;
    width: 100%;
    margin-top: 10px;
  }
  .ant-collapse-content>.ant-collapse-content-box {
    padding: 1px !important;
  }
  .ant-space-vertical {
    width: 100%;
  }
  .ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
    flex: auto !important;
  }
  .ant-collapse-item:last-child>.ant-collapse-content {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-collapse>.ant-collapse-item:last-child {
    border-radius: 0 0 10px 10px;
  }
  .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    vertical-align: 0px;
  }
`;

export const ChangeLogWrapper = styled.div`
width: 100%;
`;

export const ChangeLogItem = styled.div`
  font-size: 12px;
  color: black;
  padding-left: 5px;
  font-weight: 600;
  margin-bottom: 10px;
  `;

export const ChangeLogItemList = styled.div`
  font-size: 12px;
`;

export const ChangeLogListItem = styled.div`
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  &.ADD {
    color: #1da850 !important;
  }
  &.CHANGE {
    color: #DC9900;
  }
  &.REMOVE {
    color: #C44536;
  }
`;
export const ChangeLogItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0px 5px;
  `;

export const ChangeLogTime = styled.div`
  font-size: 12px;
  text-align: right;
  color: #a7a7a7;
  padding-right: 5px;
  margin-top: 10px;

`;

export const NoChange = styled.div`
  text-align: center;
  font-size: 22px;
  margin-top: 30px;
  width: 200px;
`;

export const PanelHeader = styled.div`
  font-size: 12px;
  color: black;
  display: flex;
  text-align: center;
  justify-content: space-between;

  `;

export const PanelHeaderContent = styled.div`
  text-align: center;
`;

export const StyledList = styled.ul`
  margin-left: -25px;
  margin-bottom: 0px;
`;

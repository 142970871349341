import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import editIcon from '../../../assets/images/icons/icon_edit.svg';

const useSettingsColumns = ({ pageKey, settingsFormRef }) => {
  const { t } = useTranslation();

  const columns = useMemo(() => [
    {
      title: t('settings.name'),
      dataIndex: 'nameSetting',
      key: 'nameSetting',
      type: 'string',
      sort: 'string',
      filter: 'string',
      columnVisible: true
    },
    {
      title: t('settings.value'),
      dataIndex: 'valueSetting',
      key: 'valueSetting',
      type: 'string',
      sort: 'string',
      filter: 'string',
      showHover: true,
      columnVisible: true
    },
    {
      title: t('settings.description'),
      dataIndex: 'description',
      key: 'description',
      type: 'string',
      sort: 'string',
      filter: 'string',
      columnVisible: true
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: 170,
      columnVisible: true,
      actionItems: [
        {
          onClick: (record) => {
            settingsFormRef.current.showDialog(record, pageKey);
          },
          tooltip: t('buttons.edit'),
          icon: editIcon,
        },
      ],
    },
  ], [pageKey, t]);

  return { columns };
}

export default useSettingsColumns;

import React from 'react';
import styled from 'styled-components';
import { Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

const ASelect = ({ options, value, onChange, disabled, placeholder, onSelect, id }) => (
  <StyledSelect
    value={value}
    disabled={disabled}
    onChange={onChange}
    defaultValue={value}
    placeholder={placeholder}
    onSelect={onSelect}
    filterlenght={id === 'smallFilter' ? '160px' : '300px'}
  >
    {options?.map((option) => (
      <Option key={option.key} value={option.key}>
        {option.title ? option.title : option.value}
      </Option>
        ))}
  </StyledSelect>
  );

const StyledSelect = styled(Select)`
  &.ant-select {
    border-radius: 5px;
    width: ${(props) => props?.filterlenght};
    overflow: hidden;
    border: 1px solid gainsboro;
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 14px;
        width: 100%;
      }
    }
  }
`;

ASelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])
    })
  ),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool
};

export default ASelect;

import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getPagination, resetPagination } from '../../util/pagination';
import { Page } from '../Page';
import useSettings from './hooks/settings';
import useSettingsColumns from './hooks/settings.column';
import Datatable from '../../core/components/datatable/Datatable';
import SettingsFormModal from './modals/SettingsFormModal';

const PAGE_KEY = 'settings';

const Settings = () => {
    const settingsFormRef = useRef();
    const { t } = useTranslation();

    const { loading, dataSource, totalElements, currentPage, getAppSettings, setDataSource } = useSettings();

    const { columns } = useSettingsColumns({ pageKey: PAGE_KEY, settingsFormRef });

    const pageTitle = t('settings.title');

    useEffect(() => {
        resetPagination(PAGE_KEY);
        const { page, pageSize } = getPagination(PAGE_KEY);
        getAppSettings({ pagination: { page, size: pageSize } });
    }, []);

    const paginationHandler = (payload) => {
        getAppSettings({ pagination: {
            page: payload.page,
            size: payload.pageSize
          },
          filters: payload.filters,
          sort: payload.sort
        });
      };

    const onModalSaveHandler = () => {
        const { page, pageSize } = getPagination(PAGE_KEY);
        getAppSettings({ pagination: { page, size: pageSize } });
    }
    return (
      <Page
        title={pageTitle}
        headerVisible
        pageKey="settings"
        content={(
          <>
            <Datatable
              dtKey={PAGE_KEY}
              uniqueColumn="keySetting"
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              total={totalElements}
              currentPage={currentPage}
              onPagination={paginationHandler}
              serverSideFiltering
              serverSideSorting
              dataFetchingFunction={getAppSettings}
            />
            <SettingsFormModal
              ref={settingsFormRef}
              dataSource={dataSource}
              setDataSource={setDataSource}
              onSave={onModalSaveHandler}
            />
          </>
      )}
      />
    )
};

export default Settings;

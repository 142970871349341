import styled from 'styled-components';
import { Select } from 'antd';

export const DailyCardWrapper = styled.div`
  user-select: none;
  cursor: ${(props) => (!props?.notInRange ? 'pointer' : 'auto')};
  display: flex;
  flex-wrap: wrap;
  opacity: ${(props) => (props?.notInRange ? '0.5' : '1')};
  width: 12.8%;
  height: 9.6vh;
  background-color: white;
  border-radius: 0.5rem;
  box-sizing: border-box;
  overflow: visible;
  box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  position: relative;
  justify-content: space-evenly;
`;

export const HeaderWrapper = styled.div`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 31px;
  width: 100%;
  color: white;
  padding: 5px 0 0 60px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 23px;
  border-bottom:  1px solid white;
  border-radius: 0.5rem 0.5rem 0 0;
  &.PAST {
    background-color: #0073AE;
  }
  &.PRESENT {
    background-color: #FAB10B;
  }
  &.FUTURE {
    background-color: gray;
  }
  @media only screen and (max-width: 2200px) {
    font-size: 9px
  }
  @media only screen and (max-width: 1700px) {
    font-size: 9px;
  }
`;

export const DayCaption = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 60px;
  box-sizing: border-box;
  border-radius: 0.5rem;
  box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.15);
  background-color: ${(props) => (props?.currentDay ? '#0073AE' : 'white')};
  color: ${(props) => (props?.currentDay ? 'white' : '#4d463d')};
  text-align: center;
  position: absolute !important;
  top: -10px;
  left: -10px;
  height: 60px;
  width: 60px;
  @media only screen and (max-width: 2200px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 1700px) {
    font-size: 26px;
  }
  &.currentDay {
    background-color: #0073AE;
    color: white;
  }
`;

export const FooterWrapper = styled.div`
  color: ${({ theme: { colors } }) => (colors.grayColors.darkGray)};
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: right;
  position: absolute;
  padding-top: 25px;
`;

export const VersionCaption = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  padding: 20px 15px;
  &.currentDay {
    background-color: #0073AE;
    color: white !important;
  }
`;

export const ActionIconsBox = styled.div`
  display: flex;
  padding: 12px 5px 0 0;
  gap: 2px;
`;

export const ActionIcon = styled.div`
  cursor: pointer;
  overflow: hidden;
  padding: 2px;
  display: flex;
  justify-content: space-around;
  width: 25px;
  height: 25px;
  border: 1px solid ${({ theme: { colors } }) => (colors.grayColors.actionIconGray)};
  border-radius: 30px;
  background: url(${(props) => props.icon});
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;
  color: ${({ theme: { colors } }) => (colors.grayColors.actionIconGray)} !important;
  &:hover {
   color: white !important;
   background-color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
   border: none !important;
  }
  & > * > * > svg {
    width: 14px;
  }
`;

export const NotInRangeOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.8);
`;

export const VersionText = styled.div`
  font-size: 14px;
  box-sizing: border-box;
  padding: 12px 2px;
  span {
    color: ${({ theme: { colors } }) => (colors.grayColors.gray)} !important;
  }
  &.currentDay {
    span {
      color: white !important;
    }
    color: white;
    .ant-select-arrow {
      color: white;
    }
    .ant-select-selector {
      background: #0073AE;
    }
    }
`;

export const FilterSelectVersion = styled(Select)`
  &.ant-select {
    border-radius: 20px;
    width: ${(props) => props?.filterlenght};
    height: 25px;
    overflow: hidden;
    border: 1px solid gainsboro;

    .ant-select-selector {
      border: none;
      outline: none;
      height: 25px;
        }
      &.currentDay {
    color: white !important;
    background: #0073AE;
    //border: 1px solid white;
  }
  &.currentDay:hover{
    background-color: white;
    color: #0073AE !important;
  }
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 12px;
        line-height: 26px;
        margin-left: 5px;
        box-sizing: border-box
        width: 100%;
        @media only screen and (max-width: 1400px) {
          font-size: 10px;
        }
      }
    }
    `;

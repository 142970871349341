/* eslint-disable react/jsx-one-expression-per-line */
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';
import {
  DashedWeekSeparator,
  DayInMonthCell,
  DemandCell,
  DemandCellWarningTriangle,
  EmployeeNameCell,
  EmptyRowCell,
  ExpandSiderButtonCell,
  FooterBackgroundMask,
  FooterTotalSumCell,
  HeaderBackgroundMask,
  HorizontalHighLightBar,
  HoursNumberCell,
  MainSheetContainer,
  MainTableFooter,
  MainWrapper,
  SheetHeadingContainer,
  SidebarHighlightTip,
  TableComponent,
  UserTotalHoursCell,
  VerticalHighLightBar,
  VerticalHighLightBarBottomTip,
  VerticalHighLightBarTip,
  WarningSymbol,
  WhiteOverlay
} from './MonthlySheet.style';
import { preventKeyboardScrolling, timeStringToHoursCount } from '../../../util/util';
import leftCaretIcon from '../../../assets/images/icons/caret-left.svg';
import SheetSideBar from './sideBar/SheetSideBar';
import ExpandableFooter from './expandableFooter/ExpandableFooter';
import { useAuthStateValue } from '../../../context/AuthContext';
import warningIcon from '../../../assets/images/icons/triangle-exclamation-solid.svg';
import Cell from './cell/Cell';

const MonthlySheet = (props) => {
  const {
    year,
    month,
    version,
    dataSource,
    demand,
    modifiedData,
    setModifiedData,
    mode,
    ATCU,
    previousMonthValidationData,
    nextMonthValidationData,
    cellSelectHandler,
    myShift,
    elseShift,
    createShiftChangeModeOn,
    shiftChangeRequests,
    optimalNumberOfWorkingHours,
    approvedRequestsList,
    loading
  } = props;

  const NUMBER_OF_DAYS = new Date(year, month, 0).getDate();
  const WORKING_HOURS_IN_MONTH = () => {
    let sum = 0
    for (let i = 1; i <= NUMBER_OF_DAYS; i += 1) {
      const dayInWeek = new Date(year, month - 1, i - 1).getDay() + 1;
      if (dayInWeek !== 6 && dayInWeek !== 7) {
        sum += 8;
      }
    }
    return sum;
  };
  const KL_WORKING_HOURS_IN_MONTH = optimalNumberOfWorkingHours;

  const [windowSize, setWindowSize] = useState();
  const [sideBarExpanded, setSideBarExpanded] = useState(false);
  const [footerExpanded, setFooterExpanded] = useState(false);
  const [cellWidth, setCellWidth] = useState(null);
  const [highlightBarsPosition, setHighlightBarsPosition] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [selectedColumn, setSelectedColumn] = useState();
  const [selectedRowAndColumn, setSelectedRowAndColumn] = useState();
  const [dashedLinesPositions, setDashedLinesPositions] = useState();
  const [sidebarHighLightTipPosition, setSidebarHighlightTipPosition] = useState();
  const [headerMaskVisible, setHeaderMaskVisible] = useState(false);
  const [footerBackgroundMaskVisible, setFooterBackgroundMaskVisible] = useState(true);
  const [numberOfEmptyRows, setNumberOfEmptyRows] = useState(0);
  const [demandSituation, setDemandSituation] = useState(null);
  const [demandSituationSum, setDemandSituationSum] = useState();
  const [demandNotSatisfied, setDemandNotSatisfied] = useState(false);
  const [managerRolesOnDays, setManagerRolesOnDays] = useState(null);
  const [newlyUsedShifts, setNewlyUsedShifts] = useState(null);

  const mainSheetRef = useRef();
  const siderSheetRef = useRef();
  const mainTableRef = useRef();

  const { t } = useTranslation();

  const { loggedUser } = useAuthStateValue();
  const loggedUserATCUId = loggedUser?.locationsData.find((el) => el?.identificator === loggedUser?.extendUser?.locationIdentificator)?.key;
  const loggedUserHasEditPermissions = loggedUser?.localPermissions?.[loggedUserATCUId]?.monthlyPlan === 'APPROVE_AND_ACTIVATE' || loggedUser?.localPermissions?.[loggedUserATCUId]?.monthlyPlan === 'GENERATE_AND_MODIFY';

  const toggleSider = () => {
    setSideBarExpanded((prevState) => !prevState);
  }

  const toggleFooter = () => {
    setFooterExpanded((prevState) => {
      return !prevState;
    });
  }

  const scrollHandler = (event) => {
    const h = event.target;
    const st = h.scrollTop || document.body.scrollTop;
    const sh = h.scrollHeight || document.body.scrollHeight;
    const scrollInPercentage = Math.floor((st / (sh - h.clientHeight)) * 100);
    if (event.target.scrollTop > 0) {
      if (headerMaskVisible === false) setHeaderMaskVisible(true);
    } else {
      setHeaderMaskVisible(false);
    }
    if (event.target?.id === 'mainSheet') {
      siderSheetRef.current.scrollTop = event.target.scrollTop;
      if (scrollInPercentage === 100) setFooterBackgroundMaskVisible(false);
      else setFooterBackgroundMaskVisible(true);
    } else if (event.target?.id === 'siderSheet') {
        mainSheetRef.current.scrollTop = event.target.scrollTop;
    }
  }

  const calculateDemandSituation = () => {
    setDemandNotSatisfied(false);
    const currentSituation = [];
    loggedUser?.shiftTypes?.[ATCU]?.forEach((shiftType) => {
      const shiftInDemand = demand?.find((shift) => shift?.id === shiftType.id);
      const shiftInNewlyUsedShifts = newlyUsedShifts?.find((shift) => shift?.id === shiftType?.id);
      if (shiftInDemand || shiftInNewlyUsedShifts) {
        // LOOP THROUGH EVERY SHIFT THAT IS IN DEMAND
        const shiftObj = {
          shiftId: shiftType.id,
          shiftName: shiftType.name,
          perDay: []
        }
        for (let dayInMonth = 1; dayInMonth <= NUMBER_OF_DAYS; dayInMonth += 1) {
          const dayObj = {
            day: dayInMonth,
            count: 0
          }
          dataSource?.controllers?.forEach((controller) => {
            const dayInControllersShifts = controller?.shifts?.find((entry) => entry.date === dayInMonth && entry.shiftId === shiftType.id);
            const dayInModifiedData = modifiedData?.[modifiedData?.length - 1]?.find((entry) => entry?.day === dayInMonth && entry?.controllerId === controller?.id);
            if (dayInModifiedData) {
              if (dayInModifiedData?.newState?.shiftId === shiftType.id) {
                if (dayInModifiedData?.newState?.shiftId) dayObj.count += 1;
              }
            } else if (dayInControllersShifts) dayObj.count += 1;
          })
          shiftObj.perDay.push(dayObj);
        }
        currentSituation.push(shiftObj);
      }
    });
    const demandSituation = [];
    const demandWithNewlyUsedShifts = [
      ...(demand ? demand : []),
      ...(newlyUsedShifts ? newlyUsedShifts : [])
    ];
    if (!loading && demandWithNewlyUsedShifts?.length > 0 && currentSituation?.length > 0) {
      demandWithNewlyUsedShifts?.forEach((demandShift) => {
        const shiftSituation = {
          shiftId: demandShift.id,
          shiftName: demandShift.name,
          timeFrom: demandShift?.timeFrom.substring(0, 5),
          timeTo: demandShift?.timeTo.substring(0, 5),
          perDay: []
        }
        const matchingShiftInCurrent = currentSituation?.find((shift) => shift?.shiftId === demandShift.id);
        demandShift?.demand?.forEach((demandEntry, index) => {
          if (matchingShiftInCurrent?.perDay?.[index]?.count - demandEntry.people < 0) setDemandNotSatisfied(true);
          shiftSituation.perDay.push({
            date: index + 1,
            demand: matchingShiftInCurrent?.perDay?.[index]?.count - demandEntry.people,
            totalPeopleNeeded: demandEntry?.people,
            peopleAllocated: matchingShiftInCurrent?.perDay?.[index]?.count
          })
        })
        demandSituation.push(shiftSituation);
      })
    }
    if (demandSituation?.length > 0 && !loading && currentSituation?.length > 0 && demand?.length > 0) {
      setDemandSituation(demandSituation);
    }
  };

  useEffect(() => {
    if (demandSituation) {
      const demandSituationSum = [];
      for (let dayInMonth = 1; dayInMonth <= NUMBER_OF_DAYS; dayInMonth += 1) {
        let daySum = 0;
        let dayHasDeficit = false;
        demandSituation?.forEach((shift) => {
          const matchingDay = shift?.perDay?.find((entry) => entry.date === dayInMonth);
          daySum += matchingDay?.demand;
          if (matchingDay?.demand < 0) dayHasDeficit = true;
        })
        demandSituationSum.push({
          day: dayInMonth,
          hasDeficit: dayHasDeficit,
          demand: daySum
        })
      }
      setDemandSituationSum(demandSituationSum);
    }
  }, [demandSituation, modifiedData, newlyUsedShifts]);

  const renderSheetHeading = useCallback(() => {
    const daysInMonthRow = [<HoursNumberCell>Br. sati: <b>{ WORKING_HOURS_IN_MONTH() }</b></HoursNumberCell>];
    const daysInWeekRow = [<HoursNumberCell>Br. sati KL: <b>{ KL_WORKING_HOURS_IN_MONTH }</b></HoursNumberCell>];

    for (let i = 1; i <= NUMBER_OF_DAYS; i += 1) {
      const managerRolesOnDay = managerRolesOnDays?.find((el) => el?.date === i);
      let hasAllManagerRoles = true;
      const shiftsWithoutManager = [];
      demand?.forEach((shiftInDemand) => {
        const demandOnDay = shiftInDemand?.demand?.find((el) => el?.date === i);
        if ((demandOnDay?.people > 0) && (shiftInDemand?.name === 'J' || shiftInDemand?.name === 'P' || shiftInDemand?.name === 'N' || shiftInDemand?.name === 'J6' || shiftInDemand?.name === 'P15')) {
          const thisShiftTitle = shiftInDemand?.name;
          const relatedShift = demand?.find((el) => el?.name.startsWith(thisShiftTitle) && el?.id !== shiftInDemand?.id);
          const shiftHasManagerOnDay = managerRolesOnDay?.shiftsWithActiveManagers?.find((el) => el === shiftInDemand?.id);
          const relatedShiftHasManagerOnDay = managerRolesOnDay?.shiftsWithActiveManagers?.find((el) => el === relatedShift?.id);
          if (!shiftHasManagerOnDay && !relatedShiftHasManagerOnDay) {
            hasAllManagerRoles = false;
            shiftsWithoutManager.push(shiftInDemand?.name);
          }
        }
      });
      const selectedClassName = i === selectedColumn && !sideBarExpanded && !footerExpanded ? 'selected' : ' ';
      const dayInWeek = new Date(year, month - 1, i - 1).getDay() + 1;
      daysInMonthRow.push(
        <Tooltip title={hasAllManagerRoles === false ? (
          <ul>
            {shiftsWithoutManager?.map((el) => <li>Za ovaj datum nedostaje {ATCU === 'ATCU1' ? 'šef smjene' : 'SATCO'} za smjenu <strong>{el}</strong>.</li>)}
          </ul>
        ) : null}
        >
          <DayInMonthCell
            hasWarning={hasAllManagerRoles === false}
            className={t('daysInWeek.' + dayInWeek).substring(0, 3).toUpperCase() + ' ' + selectedClassName}
          >
            {(hasAllManagerRoles === false) && <WarningSymbol><ReactSVG src={warningIcon} /></WarningSymbol>}
            {i}
          </DayInMonthCell>
        </Tooltip>
      );
      daysInWeekRow.push(<DayInMonthCell className={t('daysInWeek.' + dayInWeek).substring(0, 3).toUpperCase() + ' ' + selectedClassName}>{t('daysInWeek.' + dayInWeek).substring(0, 3).toUpperCase()}</DayInMonthCell>);
    }
    daysInMonthRow.push(<ExpandSiderButtonCell onClick={toggleSider}><ReactSVG style={{ margin: '5px auto', width: '8px', transition: 'all 0.5s', transform: sideBarExpanded ? 'rotateY(180deg)' : '' }} src={leftCaretIcon} /></ExpandSiderButtonCell>);
    daysInWeekRow.push(<ExpandSiderButtonCell>UKUPNO SATI</ExpandSiderButtonCell>);
    return [<tr>{daysInMonthRow}</tr>, <tr>{daysInWeekRow}</tr>];
  }, [month, year, highlightBarsPosition, sideBarExpanded, managerRolesOnDays]);

  const moveSidebarHighlightTip = (top) => {
    setSidebarHighlightTipPosition(top ? top : null);
  };

  useEffect(() => {
    if (mode === 'shiftsChange') {
      const loggedUserSheetIndex = dataSource?.controllers?.findIndex((el) => el?.id === parseInt(loggedUser?.id, 10));
      if (loggedUserSheetIndex) {
        mainSheetRef.current.scrollTo(0, loggedUserSheetIndex * 30);
        setSelectedRow(loggedUserSheetIndex + 1);
        setSelectedColumn(new Date().getDate());
      }
    }
  }, [dataSource]);

  useEffect(() => {
    if (mode === 'shiftsChange' && createShiftChangeModeOn === true) {
      const loggedUserSheetIndex = dataSource?.controllers?.findIndex((el) => el?.id === parseInt(loggedUser?.id, 10));
      if (loggedUserSheetIndex) {
        mainSheetRef.current.scrollTo(0, loggedUserSheetIndex * 30);
        // setSelectedRow(loggedUserSheetIndex + 1);
        setSelectedColumn(new Date().getDate());
        setSelectedRow(loggedUserSheetIndex + 1);
      }
    }
  }, [createShiftChangeModeOn]);

  useEffect(() => {
    if (mode === 'shiftsChange' && createShiftChangeModeOn === true) {
      if ((myShift && !elseShift) || (myShift && elseShift)) {
        setSelectedColumn(null);
        setSelectedRow(null);
        setHighlightBarsPosition(null);
      } else if (!myShift && !elseShift) {
        const loggedUserSheetIndex = dataSource?.controllers?.findIndex((el) => el?.id === parseInt(loggedUser?.id, 10));
        if (loggedUserSheetIndex) {
          mainSheetRef.current.scrollTo(0, loggedUserSheetIndex * 30);
          setSelectedRow(loggedUserSheetIndex + 1);
          setSelectedColumn(new Date().getDate());
        }
      }
    }
  }, [myShift, elseShift]);

  useEffect(() => {
    setDemandNotSatisfied(false);
    setSideBarExpanded(false);
    setFooterExpanded(false);
    if (setModifiedData) setModifiedData(null);
    calculateDemandSituation();
  }, [year, month, dataSource, demand]);

  useEffect(() => {
    calculateDemandSituation()
  }, [modifiedData, newlyUsedShifts]);

  useEffect(() => {
    const mainTableElement = mainTableRef.current;
    // Calculate week dashed-line separator positions
    const daysInMonthElementsArray = Array.from(mainTableElement.children?.[0]?.children?.[0]?.children);
    const dashedLinesPositionsArray = [];
    daysInMonthElementsArray.forEach((day) => {
      if (day.className.includes('NED')) {
        dashedLinesPositionsArray.push(day.offsetLeft + day?.offsetWidth);
      }
    });
    setDashedLinesPositions(dashedLinesPositionsArray);
  }, [year, month, dataSource, windowSize]);

  useEffect(() => {
    if (modifiedData) {
      const newlyUsedShiftsArr = [];
      const emptyDemandArr = [];
      for (let i = 1; i <= NUMBER_OF_DAYS; i += 1) {
        emptyDemandArr.push({
          day: i,
          people: 0
        })
      }
      modifiedData?.[modifiedData?.length - 1]?.forEach((entry) => {
        if (!!entry?.newState?.shiftId && !demand?.find((el) => el?.id === entry?.newState?.shiftId) && !newlyUsedShiftsArr?.find((el) => el?.id === entry?.newState?.shiftId)) {
          const shiftDetails = loggedUser?.shiftTypes?.[ATCU]?.find((el) => el?.id === entry?.newState?.shiftId);
          const timeFrom = timeStringToHoursCount(shiftDetails?.timeFrom, false);
          const timeTo = timeStringToHoursCount(shiftDetails?.timeTo, true);
          newlyUsedShiftsArr.push({
            id: shiftDetails?.id,
            name: shiftDetails?.name,
            type: shiftDetails?.type,
            timeFrom: shiftDetails?.timeFrom,
            timeTo: shiftDetails?.timeTo,
            duration: (timeTo > timeFrom) ? (timeTo - timeFrom) : ((timeTo + 24) - timeFrom),
            color: shiftDetails?.color,
            demand: emptyDemandArr
          });
        }
      });
      setNewlyUsedShifts(newlyUsedShiftsArr);
    }
  }, [modifiedData]);

  useEffect(() => {
    const mainTableElement = mainTableRef.current;
    const firstSquareCellEl = mainTableElement?.children[1]?.children[0]?.children[1];
    // Move higlihgt bars when window is resized
    if (selectedColumn && selectedRow) {
      const highlightedRowElement = mainTableElement.children?.[1]?.children?.[selectedRow - 1];
      const highLightedColumnElement = mainTableElement?.children[0]?.children?.[0]?.children?.[selectedColumn];
      const highlightedController = dataSource?.controllers?.[selectedRow - 1];
      const selectedCellShift = highlightedController?.shifts?.find((el) => el?.date === selectedColumn)
      if (mode === 'shiftsChange') {
        cellSelectHandler({
          controllerFullName: highlightedController?.firstName + ' ' + highlightedController?.lastName,
          controllerId: highlightedController?.id,
          day: selectedColumn,
          shiftId: selectedCellShift?.shiftId,
          shiftName: selectedCellShift?.shiftName,
          isSatco: selectedCellShift?.isSatco,
          isShiftManager: selectedCellShift?.isShiftManager,
        });
      }
      setHighlightBarsPosition({
        top: highlightedRowElement?.getBoundingClientRect().top + mainSheetRef?.current?.scrollTop - 138,
        left: highLightedColumnElement.offsetLeft + 21
      });
    }
    setCellWidth(firstSquareCellEl?.offsetWidth)
    // Calculate number of empty rows to display if data is not present
    const spareTableSpace = mainTableElement?.parentNode?.offsetHeight - mainTableElement.scrollHeight;
    if (numberOfEmptyRows === 0) {
      if (spareTableSpace / 35 > 0) {
        setNumberOfEmptyRows(Math.floor(spareTableSpace / 35) - 1);
      }
    } else if (numberOfEmptyRows !== 0 && dataSource) {
      setNumberOfEmptyRows(0);
    }
    // const selectedEmployeeNameCell = mainTableElement?.children?.[1]?.children?.[selectedRow - 1]?.children?.[0];
    // if (selectedEmployeeNameCell) {
    //   selectedEmployeeNameCell.classList.add('selected');
    // }
  }, [month, windowSize, selectedRow, selectedColumn, dataSource]);

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize(window.innerWidth);
    });
    document.addEventListener('contextmenu', (event) => event.preventDefault());
  }, []);

  const renderFooter = useCallback(() => {
    const array = [];
    for (let dayInMonth = 1; dayInMonth <= NUMBER_OF_DAYS; dayInMonth += 1) {
      const matchingElementInDemandSum = demandSituationSum?.find((el) => el?.day === dayInMonth);
      array.push(
        <DemandCell key={'demand-indicator-cell' + dayInMonth} className={(matchingElementInDemandSum?.demand > 0 && !loading && demandSituation?.length > 0) ? 'over' : ((matchingElementInDemandSum?.demand < 0 && !loading && demandSituation?.length > 0) ? 'under' : 'zero')}>
          {matchingElementInDemandSum?.demand >= 0 && matchingElementInDemandSum?.hasDeficit && <DemandCellWarningTriangle cellWidth={cellWidth} />}
          {matchingElementInDemandSum?.demand > 0 ? '+' : ''}
          {matchingElementInDemandSum?.demand}
        </DemandCell>
      );
    }
    array.push(<td style={{ backgroundColor: demandNotSatisfied ? '#C44536' : '#0F8F3E', color: 'white', fontFamily: 'MontserratWeb-SemiBold' }}>ver.{version}</td>);
    return array;
  }, [demandSituation, demandSituationSum]);

  const moveHighlightBars = async (event) => {
    let rowElement;
    let cellElement;
    if (event?.currentTarget?.tagName === 'path') {
      rowElement = event?.currentTarget?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
      cellElement = event?.currentTarget?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
    } else if (event?.currentTarget?.tagName === 'svg') {
      rowElement = event?.currentTarget?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode;
      cellElement = event?.currentTarget?.parentNode?.parentNode?.parentNode?.parentNode;
    } else {
      rowElement = event?.currentTarget?.parentNode;
      cellElement = event?.currentTarget;
    }
    const rowNumber = parseInt(rowElement?.id, 10);
    const columnNumber = parseInt(Array.from(cellElement.classList).find((className) => className.startsWith('col-')).substring(4), 10);
    if (event?.button === 2 && selectedRowAndColumn?.find((el) => el[0] === rowNumber && el[1] === columnNumber)) {
      // skip
    } else if (!event?.shiftKey && !event?.ctrlKey) {
        if (mode !== 'shiftsChange' && !!mode) {
          setSelectedRowAndColumn(() => {
            return [
              [rowNumber, columnNumber]
            ];
          });
        }
        setSelectedRow(rowNumber);
        setSelectedColumn(columnNumber);
      } else if (event?.shiftKey && mode !== 'shiftsChange') {
        setSelectedRowAndColumn((prevState) => {
          const fromRow = prevState?.[prevState.length - 1]?.[0];
          const fromColumn = prevState?.[prevState.length - 1]?.[1];
          const toRow = rowNumber;
          const toColumn = columnNumber;
          const newMatrix = [];
          for (let r = fromRow; fromRow <= toRow ? r <= toRow : r >= toRow; fromRow <= toRow ? r += 1 : r -= 1) {
            for (let c = fromColumn; fromColumn <= toColumn ? c <= toColumn : c >= toColumn; fromColumn <= toColumn ? c += 1 : c -= 1) {
              newMatrix.push([r, c]);
            }
          }
          return newMatrix;
        });
        setSelectedRow(rowNumber);
        setSelectedColumn(columnNumber);
      } else if (event?.ctrlKey && mode !== 'shiftsChange') {
        if (selectedRowAndColumn?.find((el) => el?.[0] === rowNumber && el?.[1] === columnNumber)) {
          let secondLastSelected;
          await setSelectedRowAndColumn((prevState) => {
            const newStateArr = [];
            prevState.forEach((el) => {
              if (el?.[0] === rowNumber && el?.[1] === columnNumber) {
                // skip
              } else newStateArr.push(el);
            });
            secondLastSelected = (newStateArr?.length >= 1) ? newStateArr?.[newStateArr?.length - 1] : null;
            return newStateArr;
          });
          if (secondLastSelected) {
            setSelectedRow(secondLastSelected[0]);
            setSelectedColumn(secondLastSelected[1]);
          } else {
            setSelectedRow();
            setSelectedColumn();
          }
        } else {
          setSelectedRowAndColumn((prevState) => {
            return [
              ...(prevState ? prevState : []),
              [rowNumber, columnNumber]
            ];
          });
          setSelectedRow(rowNumber);
          setSelectedColumn(columnNumber);
        }
      }
  }

  const calculateTotalHoursNumber = useCallback((scheduleEntry) => {
    let totalHoursCounter = 0;
    let status = '';
    for (let dayInMonth = 1; dayInMonth <= NUMBER_OF_DAYS; dayInMonth += 1) {
      const matchingShiftEntry = scheduleEntry.shifts.find((shift) => shift.date === dayInMonth);
      const matchingAbsenceEntry = scheduleEntry.absences.find((absence) => absence.date === dayInMonth);
      // eslint-disable-next-line no-loop-func
      const matchingModifiedDataEntry = modifiedData?.[modifiedData?.length - 1]?.find((entry) => entry?.controllerId === scheduleEntry.id && entry?.day === dayInMonth);
      if (matchingModifiedDataEntry) {
        if (matchingModifiedDataEntry.newState.shiftId !== null) {
          const matchingShiftInDemand = loggedUser?.shiftTypes?.[ATCU]?.find((el) => el?.id === matchingModifiedDataEntry.newState.shiftId);
          const timeFromInteger = timeStringToHoursCount(matchingShiftInDemand?.timeFrom, false);
          const timeToInteger = timeStringToHoursCount(matchingShiftInDemand?.timeTo, true);
          let duration;
          if (timeToInteger > timeFromInteger) duration = timeToInteger - timeFromInteger;
          if (timeToInteger < timeFromInteger) duration = (24 - timeFromInteger) + timeToInteger;
          totalHoursCounter += duration;
        } else if (matchingModifiedDataEntry.newState.absenceId !== null) {
          const correspondingAbsenceType = loggedUser.absenceTypes.find((absenceType) => absenceType.id === matchingModifiedDataEntry.newState.absenceId);
          if (correspondingAbsenceType.counts) totalHoursCounter += 8;
        }
      } else if (matchingShiftEntry) {
          const matchingShiftInDemand = loggedUser?.shiftTypes?.[ATCU]?.find((el) => el?.id === matchingShiftEntry?.shiftId);
          const timeFromInteger = timeStringToHoursCount(matchingShiftInDemand?.timeFrom, false);
          const timeToInteger = timeStringToHoursCount(matchingShiftInDemand?.timeTo, true);
          let duration;
          if (timeToInteger > timeFromInteger) duration = timeToInteger - timeFromInteger;
          if (timeToInteger < timeFromInteger) duration = (24 - timeFromInteger) + timeToInteger;
          totalHoursCounter += duration;
        } else if (matchingAbsenceEntry) {
          const matchingAbsenceType = loggedUser.absenceTypes.find((absence) => absence.name === matchingAbsenceEntry.absenceName);
          if (matchingAbsenceType.counts) totalHoursCounter += 8;
        }
    }
    if (totalHoursCounter > KL_WORKING_HOURS_IN_MONTH) status = 'over';
    else if (totalHoursCounter === KL_WORKING_HOURS_IN_MONTH) status = 'equal';
    else if (totalHoursCounter < KL_WORKING_HOURS_IN_MONTH) status = 'under';
    return <UserTotalHoursCell className={status}>{totalHoursCounter}</UserTotalHoursCell>;
  }, [year, month, dataSource, modifiedData]);

  const renderEmptyRows = () => {
    const emptyRow = [];
    const emptyRows = [];
    for (let i = 0; i <= NUMBER_OF_DAYS + 1; i += 1) {
      emptyRow.push(<EmptyRowCell key={'empty-row-cell-' + i} />);
    }
    if (numberOfEmptyRows > 0) {
      for (let i = 0; i <= numberOfEmptyRows - 1; i += 1) {
        emptyRows.push(<tr key={'empty-row-' + i}>{emptyRow}</tr>);
      }
    }
    return emptyRows;
  }

  const renderSheetRows = () => {
    return useMemo(() => {
      const rowsArray = [];
      const managerRolesOnDaysArr = [];
      dataSource?.controllers?.forEach((employee, index) => {
        const userRow = [
          <EmployeeNameCell
            key={'employee-name-cell-' + index}
            isShiftManager={employee?.role === 'SHIFT_MANAGER'}
            isSatco={employee?.role === 'SATCO'}
            className={(index + 1) === selectedRow && !sideBarExpanded && !footerExpanded ? 'selected' : ' '}
          >
            {employee.firstName + ' ' + employee.lastName}
          </EmployeeNameCell>
        ];
        // Previous cell validation parameters
        let cellValidation = {
          workDaysStreak: previousMonthValidationData?.find((el) => el?.controllerId === employee?.id)?.workStreak || 0,
          restDaysStreak: 0,
          nightShiftRestDaysStreak: 0,
          previousCellWasNightShift: false,
          previousCellWasNoonShift: false
        }
        for (let i = 1; i <= NUMBER_OF_DAYS; i += 1) {
          let cellWasMatterOfShiftChangeRequest = false;
          const cellValueInOriginalData = employee.shifts.find((entry) => entry.date === i) ? employee.shifts.find((entry) => entry.date === i) : (employee.absences.find((absence) => absence.date === i) ? employee.absences.find((absence) => absence.date === i) : null);
          const cellValueInModifiedData = modifiedData ? modifiedData?.[modifiedData?.length - 1]?.find((changeEntry) => changeEntry?.controllerId === employee.id && changeEntry?.day === i) : null;
          const cellFinalValue = cellValueInModifiedData ? cellValueInModifiedData?.newState : (cellValueInOriginalData ? cellValueInOriginalData : null);
          if (cellFinalValue?.isShiftManager || cellFinalValue?.isSatco) {
            if (!managerRolesOnDaysArr?.find((el) => el?.date === i)) {
              managerRolesOnDaysArr.push({
                date: i,
                shiftsWithActiveManagers: [cellFinalValue?.shiftId]
              })
            } else managerRolesOnDaysArr?.find((el) => el?.date === i)?.shiftsWithActiveManagers.push(cellFinalValue?.shiftId);
          }
          if (mode === 'shiftsChange') {
            const employeeInShiftChangeRequestList = approvedRequestsList?.find((el) => el?.id === employee?.id);
            const requestOnDate = employeeInShiftChangeRequestList?.data?.find((el) => el?.date === i);
            if ((employeeInShiftChangeRequestList && requestOnDate) && (requestOnDate?.status === 'APPROVED' || requestOnDate?.status === 'IN_PROGRESS')) cellWasMatterOfShiftChangeRequest = true;
          }
          userRow.push(
            <Cell
              key={employee?.firstName + '-' + employee?.lastName + '-' + i}
              date={new Date(year, month - 1, i)}
              rowIndex={index}
              previousMonthValidation={previousMonthValidationData?.find((el) => el?.controllerId === employee?.id)}
              nextMonthValidation={nextMonthValidationData?.find((el) => el?.controllerId === employee?.id)}
              ATCU={ATCU}
              numberOfDays={NUMBER_OF_DAYS}
              sheetMode={mode}
              sheetData={dataSource}
              demand={demand}
              cellValidation={cellValidation}
              isSelected={selectedRowAndColumn?.find((el) => (el?.[0] === index + 1) && el?.[1] === i)}
              cellValueInOriginalData={cellValueInOriginalData}
              cellValueInModifiedData={cellValueInModifiedData}
              controllerId={employee?.id}
              controllerFullName={employee?.firstName + ' ' + employee?.lastName}
              isShiftManager={employee?.role === 'SHIFT_MANAGER'}
              isSatco={employee?.role === 'SATCO'}
              multiselect={selectedRowAndColumn}
              isPartOfMultiselect={(selectedRowAndColumn?.find((el) => el[0] === (index + 1) && el[1] === i)) && selectedRowAndColumn?.length > 1}
              isDisabled={(mode === 'shiftsChange') && ((i < new Date().getDate()) || (createShiftChangeModeOn && !myShift && employee?.id !== parseInt(loggedUser?.id, 10)) || (createShiftChangeModeOn && !!myShift && !elseShift && employee?.id === parseInt(loggedUser?.id, 10)))}
              isMatterOfShiftChangeRequest={mode === 'shiftsChange' && (shiftChangeRequests?.find((el) => ((el?.userApplicant?.id === employee?.id && el?.day1 === i) || (el?.userRecipientRequest?.id === employee?.id && el?.day2 === i))))}
              wasMatterOfShiftChangeRequest={cellWasMatterOfShiftChangeRequest}
              loggedUserHasEditPermissions={loggedUserHasEditPermissions}
              modifiedData={modifiedData}
              setModifiedData={setModifiedData}
              moveHighlightBars={moveHighlightBars}
              mainTableRef={mainTableRef}
              myShift={myShift}
              elseShift={elseShift}
            />
          );
          // Update the validation parameters for the next cell iteration
          cellValidation = {
            workDaysStreak: cellFinalValue?.shiftId ? (cellValidation.workDaysStreak + 1) : 0,
            restDaysStreak: cellFinalValue?.shiftId ? 0 : (cellValidation.restDaysStreak + 1),
            nightShiftRestDaysStreak:
                (cellValidation?.previousCellWasNightShift && !cellFinalValue?.shiftId)
                ? (cellValidation?.nightShiftRestDaysStreak + 1)
                : ((cellValidation?.nightShiftRestDaysStreak > 0 && !cellFinalValue?.shiftId) ? (cellValidation?.nightShiftRestDaysStreak + 1) : 0),
            previousCellWasNightShift: loggedUser?.shiftTypes?.[ATCU]?.find((el) => el?.id === cellFinalValue?.shiftId)?.name === 'N',
            previousCellWasNoonShift: loggedUser?.shiftTypes?.[ATCU]?.find((el) => el?.id === cellFinalValue?.shiftId)?.name.startsWith('P')
          }
        }
        userRow.push(calculateTotalHoursNumber(employee));
        rowsArray.push(<tr key={'sehc-' + employee.id} id={index + 1}>{userRow}</tr>);
      });
      setManagerRolesOnDays(managerRolesOnDaysArr)
      return rowsArray;
    }, [modifiedData, year, month, dataSource, previousMonthValidationData, selectedRowAndColumn, selectedRow, selectedColumn]);
  };

  return (
    <MainWrapper>
      <MainSheetContainer id="mainSheet" onScroll={scrollHandler} tabIndex={0} onKeyDown={preventKeyboardScrolling} ref={mainSheetRef}>
        <WhiteOverlay mode={sideBarExpanded ? 'sidebar' : (footerExpanded ? 'footer' : '')} />
        <HeaderBackgroundMask visible={headerMaskVisible} />
        <HorizontalHighLightBar hidden={sideBarExpanded || footerExpanded || (!selectedRow && !selectedColumn)} position={highlightBarsPosition?.top} />
        <VerticalHighLightBar hidden={sideBarExpanded || footerExpanded || (!selectedRow && !selectedColumn)} height={mainSheetRef.current?.offsetHeight} cellSize={cellWidth} position={highlightBarsPosition?.left} />
        <VerticalHighLightBarTip hidden={sideBarExpanded || footerExpanded || (!selectedRow && !selectedColumn)} cellSize={cellWidth} position={highlightBarsPosition?.left} />
        <VerticalHighLightBarBottomTip hidden={sideBarExpanded || footerExpanded || (!selectedRow && !selectedColumn)} cellSize={cellWidth} position={highlightBarsPosition?.left} />
        <SidebarHighlightTip position={sidebarHighLightTipPosition} />
        {dashedLinesPositions?.map((position) => <DashedWeekSeparator height={mainSheetRef.current?.offsetHeight} style={{ left: position + 25 }} />)}
        <FooterBackgroundMask visible={footerBackgroundMaskVisible} />
        <TableComponent ref={mainTableRef} id="mainTableComponent" cellWidth={cellWidth}>
          <SheetHeadingContainer>
            {renderSheetHeading()}
          </SheetHeadingContainer>
          <tbody style={{ position: 'relative' }}>
            {renderSheetRows()}
            {numberOfEmptyRows > 0 && renderEmptyRows()}
            {/* {!sideBarExpanded && dataSource && renderWildCard()} */}
          </tbody>
          <MainTableFooter>
            <tr>
              <FooterTotalSumCell>Suma</FooterTotalSumCell>
              {renderFooter()}
            </tr>
          </MainTableFooter>
        </TableComponent>
        <ExpandableFooter
          month={month}
          year={year}
          daysInMonthNumber={NUMBER_OF_DAYS}
          workingHoursInMonth={WORKING_HOURS_IN_MONTH()}
          kLWorkingHoursInMonth={KL_WORKING_HOURS_IN_MONTH}
          dataSource={dataSource}
          modifiedData={modifiedData}
          demand={demand}
          expanded={footerExpanded ? 1 : 0}
          toggleFooter={toggleFooter}
          demandSituation={demandSituation}
        />
      </MainSheetContainer>
      <SheetSideBar
        daysInMonthNumber={NUMBER_OF_DAYS}
        expanded={sideBarExpanded ? 1 : 0}
        ATCU={ATCU}
        dataSource={dataSource}
        modifiedData={modifiedData}
        demand={demand}
        siderSheetRef={siderSheetRef}
        scrollHandler={scrollHandler}
        workingHoursNumber={WORKING_HOURS_IN_MONTH}
        kLWorkingHoursNumber={KL_WORKING_HOURS_IN_MONTH}
        highlightTipHandler={moveSidebarHighlightTip}
        headerMaskVisible={headerMaskVisible}
        footerMaskVisible={footerBackgroundMaskVisible}
      />
    </MainWrapper>
  )
}

export default MonthlySheet;

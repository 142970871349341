// eslint-disable-next-line import/no-named-as-default
import engineApi from './engine.service';

export const getShiftTypes = (atcu) => {
  if (atcu) {
    return engineApi().get(`/engine/configs/shifts/${atcu}`);
  }
};

export const getAbsenceTypes = () => {
  return engineApi().get('/engine/configs/absences');
};

export const getAllMonthlyPlans = (atcu, year, version) => {
  if (atcu && year && version) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${version}`);
  }
};

export const getPlanStatus = (atcu, year, month, version) => {
  if (atcu && year && month && version) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/status`);
  }
};

export const getScheduleContent = (atcu, year, month, version) => {
  if (atcu && year && month && version) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/content`);
  }
};

export const getScheduleDemand = (atcu, year, month, version) => {
  if (atcu && year && month && version) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/demand`);
  }
};

export const getScheduleVersions = (atcu, year, month) => {
  if (atcu && year && month) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/all`);
  }
};

export const saveModifiedMonthlySchedule = (scheduleId, userId, modifiedData) => {
  if (scheduleId && userId && modifiedData) {
    return engineApi().post(`/engine/save/monthly/${scheduleId}/${userId}`, modifiedData);
  }
};

export const saveMonthlyScheduleAsNewVersion = (scheduleId, userId, modifiedData) => {
  if (scheduleId && userId && modifiedData) {
    return engineApi().post(`/engine/save/new/monthly/${scheduleId}/${userId}`, modifiedData);
  }
};

export const getScheduleAll = (atcu, year) => {
  if (atcu && year) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/all`);
  }
}

export const regenerateMonth = (monthlyId, userId) => {
  if (monthlyId && userId) {
    return engineApi().patch(`/engine/save/regenerate/monthly/${monthlyId}/${userId}`);
  }
}

export const submitMonthlyPlan = (scheduleId, userId) => {
  if (scheduleId && userId) {
    return engineApi().patch(`/engine/save/submit/monthly/${scheduleId}/${userId}`);
  }
};

export const approveMonthlySchedule = (scheduleId, userId) => {
  if (scheduleId && userId) {
    return engineApi().patch(`/engine/save/approve/monthly/${scheduleId}/${userId}`);
  }
};

export const rejectMonthlySchedule = (scheduleId, userId) => {
  if (scheduleId && userId) {
    return engineApi().patch(`/engine/save/reject/monthly/${scheduleId}/${userId}`);
  }
};

export const getMonthlyScheduleChangelog = (atcu, year, month, version) => {
  if (atcu && year && month && version) {
    return engineApi().get(`/engine/schedules/monthly/${atcu}/${year}/${month}/${version}/changelog`);
  }
};

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const ImageDiv = styled.div`
  background: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  height: 400px;
  width: 600px;
`;

export const Text = styled.div`
display: flex;
flex-direction: column;
`;
export const NoPermissionSpan = styled.span`
padding-top: 5%;
font-size: 24px;
text-align:center;
padding-bottom: 2%;
font-weight: 600;
`;

export const ContactSpan = styled.span`
text-align:center;
font-size: 20px;
`;

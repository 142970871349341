import { useState, useEffect } from 'react';
import { DateTimeWrapper, Day, TodaysDate, Time, DayDate } from './DateTimeWidget.style';

const DateTimeWidget = () => {
  const [time, setTime] = useState();
  const date = new Date();
  function addLeadingZero(n) {
    return n < 10 ? `0${n}` : n;
  }
  const day = date.toLocaleDateString('hr', { weekday: 'long' });
  const dayFinal = day.charAt(0).toUpperCase() + day.slice(1) + ', ';
  const dateFinal = addLeadingZero(date.getDate()) + '.' + addLeadingZero(1 + date.getMonth()) + '.' + date.getFullYear() + '.';

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date().toLocaleTimeString(['sr'], { hour: '2-digit', minute: '2-digit' }));
    }, 1);

    return () => {
      clearInterval(timer);
    };
  }, [time]);
  return (
    <DateTimeWrapper>
      <DayDate>
        <Day>{dayFinal}</Day>
        <TodaysDate>{dateFinal}</TodaysDate>
      </DayDate>
      <Time>{time}</Time>
    </DateTimeWrapper>
  );
};

export default DateTimeWidget;

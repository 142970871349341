/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ThemeContext } from 'styled-components';
import { QuestionOutlined } from '@ant-design/icons';
import { message } from 'antd';
import withModal from '../../../core/components/withModals';
import useUsers from '../hooks/user';
import FormModalStyled, { StyledButton, ButtonContainer, Permission, TitleText, SpanText, ItemText, ContainerPermisions, Column, Row, StyledFullName, StyledWrapper, Container, StyledButtonLogout } from '../../../core/components/modals/FormModalStyled';
import confirmModal from '../../../core/components/modals/ConfirmModal';

let firstTime = true;

const UserFormModal = (props) => {
  const { modalData, modalProps, closeDialog, dataSource } = props;
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);

  const { fetchLicences, licenseSource, LogoutFromAllDevices } = useUsers();
  const userModalData = dataSource;
  useEffect(() => {
    if (firstTime) {
      firstTime = false
      return
    }
      fetchLicences(dataSource?.id);
  }, [dataSource]);
    let fullName = '';
    if (userModalData?.fatherName === '' || userModalData?.fatherName === null || userModalData?.fatherName === undefined || userModalData?.fatherName === '-') {
      fullName = `${userModalData?.firstName} ${userModalData?.lastName}`;
    } else {
      fullName = `${userModalData?.firstName} (${userModalData?.fatherName}) ${userModalData?.lastName}`;
    }
    const showLogoutUserFromAll = () => {
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('users.logOutFromAll'),
        content: t('users.logOutFromAllQuestion'),
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        onOk: () => LogoutFromAllDevices(userModalData?.id),
      });
    };
  return (
    <FormModalStyled
      centered
      maskClosable={false}
      width={480}
      footer={[]}
      {...modalProps}
      title={t('users.employeeFile')}
    >
      <Container>
        <StyledFullName>{fullName}</StyledFullName>
        <StyledWrapper>
          <TitleText>
            {t('users.email') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.email}
          </SpanText>
        </StyledWrapper>
        <StyledWrapper>
          <TitleText>
            {t('users.workplace') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.extendUser?.workplace}
          </SpanText>
        </StyledWrapper>
        <StyledWrapper>
          <TitleText>
            {t('users.organizationalUnit') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.extendUser?.organizationalUnitName}
          </SpanText>
        </StyledWrapper>
        <StyledWrapper>
          <TitleText>
            {t('users.location') + ':'}
          </TitleText>
          <SpanText>
            {userModalData?.extendUser?.locationName}
          </SpanText>
        </StyledWrapper>
      </Container>
      <Permission>{t('users.permits') + ':'}</Permission>
      <ContainerPermisions>
        {(licenseSource === null || licenseSource?.length === 0)
          ? (<Permission>{t('users.noPermits')}</Permission>)
          : (licenseSource?.map((item, index) => {
            let color;
            const colorFunction = () => {
              if (item?.licenseStatus?.name === 'Validno') {
              color = themeContext.colors.mainColors.green;
            } else if (item?.licenseStatus?.name === 'Završena obuka' || item?.licenseStatus?.name === 'Uskoro ističe' || item?.licenseStatus?.name === 'Predato' || item?.licenseStatus?.name === 'Primljeno' || item?.licenseStatus?.name === 'Poslato u BHDCA') {
              color = themeContext.colors.mainColors.yellow;
            } else if (item?.licenseStatus?.name === 'Isteklo' || item?.licenseStatus?.name === 'Suspendovano' || item?.licenseStatus?.name === 'Poništeno' || item?.licenseStatus?.name === 'Nepotpuno') {
              color = themeContext.colors.mainColors.red;
            }
            return color;
          }
            return (
              <Row key={index}>
                <Column>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.permitsName') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.licenseName}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.approval') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.approvalName}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.authorization') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.warrantName}
                    </SpanText>
                  </StyledWrapper>
                </Column>
                <Column>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.unit') + ':'}
                    </ItemText>
                    <SpanText>
                      {item?.unitName}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.dateExpiration') + ':'}
                    </ItemText>
                    <SpanText>
                      {moment(item?.expirationDate).format('DD.MM.YYYY')}
                    </SpanText>
                  </StyledWrapper>
                  <StyledWrapper>
                    <ItemText>
                      {t('users.statusModal') + ':'}
                    </ItemText>
                    <SpanText style={{ color: colorFunction() }}>
                      {item?.licenseStatus?.name}
                    </SpanText>
                  </StyledWrapper>
                </Column>
              </Row>
              )
          })
        )}
      </ContainerPermisions>
      <ButtonContainer>
        <StyledButtonLogout onClick={showLogoutUserFromAll}>{t('buttons.logOut')}</StyledButtonLogout>
        <StyledButton onClick={closeDialog}>{t('buttons.close')}</StyledButton>
      </ButtonContainer>
    </FormModalStyled>
  );
};
UserFormModal.propTypes = {
  modalData: PropTypes.any,
  modalProps: PropTypes.any,
  closeDialog: PropTypes.func,
  dataSource: PropTypes.object
};

export default withModal()(UserFormModal);

import styled from 'styled-components';

export const MonthlyPlansFlexbox = styled.div`
  flex-wrap: wrap;
  flex-direction: row;
  display: inline-flex;
  gap: ${({ theme: { spacings } }) => (spacings.horizontalSpacing)};
  width: 100%;
`;

export const NoMonthlyPlansMessage = styled.div`
  font-size: x-large;
`;

export const NoMonthlyPlansWrapper = styled.div`
  height: 75vh; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const SpinnerBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 33px;
  padding-top: 4px;
`;

export const Text = styled.div`
  font-size: 14px;
  margin-right: -3px;
`;

export const MonthlyPlanTitle = styled.span`
  font-size: 24px;
  @media only screen and (max-width: 1500px) {
   // font-size: 21px;
  }
  @media only screen and (max-width: 1300px) {
    font-size: 17px;
  }
  @media only screen and (max-width: 1220px) {
    font-size: 15px;
  }
`;

import styled from 'styled-components';

export const ShiftContainerWrapper = styled.div`
  background-color: rgba(136, 136, 136, 0.4);
  width: ${(props) => ((props?.hourCellWidth - 1.2) * props?.shiftDuration)}px;
  border-radius: 5px;
  border: 1px solid #C9C9C9;
  position: absolute;
  z-index: 1;
  left: ${(props) => ((props?.interval?.[0] ? (props?.from - props?.interval?.[0]) : props?.from) * (props?.hourCellWidth - 1.2))}px;
  height: 100%;
  box-sizing: border-box;
  padding: 0 !important;
  display: flex;
`;

export const HourTickMarkContainer = styled.div`
  width: ${(props) => (props?.hourCellWidth)}px;
  height: 100%;
  display: flex;
  position: absolute;
  left: ${(props) => ((props?.hour - 1) * (props?.hourCellWidth))}px;
  border-right: 2px solid #E8E8E8;
  color: white;
  z-index: -1;
  box-sizing: border-box;
`;

export const PreviousHourTitle = styled.div`
  color: white;
  transform: rotate(90deg);
  font-size: 11px;
  width: 100%;
  position: absolute;
  left: -15px;
  bottom: 2px;
`;

export const HourTitle = styled.div`
  color: white;
  font-size: 11px;
  position: absolute;
  transform: rotate(270deg);
  right: -15px;
  width: 100%;
`;

export const ObligationsContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
`;

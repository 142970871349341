// import { GlobalOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import srSR from 'antd/lib/locale/sr_RS';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './App.css';
import { useApplicationStateValue } from './context/ApplicationContext';
import { useAuthStateValue } from './context/AuthContext';
import BasicLayout from './layout/BasicLayout/BasicLayout';
import changePasswordIcon from './assets/images/icons/user-lock-solid.svg';
import userProfileIcon from './assets/images/icons/user-circle-solid.svg';
import logoutIcon from './assets/images/icons/sign-out-alt-solid.svg';
import logoImg from './assets/images/bhansa-blue.svg';
import usersIcon from './assets/images/icons/usercogIcon.svg';
import homeIcon from './assets/images/icons/home-icon.svg';
import settingsIcon from './assets/images/icons/gear-solid.svg';
import annualPlanIcon from './assets/images/icons/calendar-icon.svg';
import monthlyPlanIcon from './assets/images/icons/monthly-calendar.svg';
import shiftChangeIcon from './assets/images/icons/awesome-exchange-alt.svg'
import dailyPlanIcon from './assets/images/icons/Icon awesome-clock.svg';
import Users from './pages/users/Users';
import Login from './pages/login/Login';
import Settings from './pages/settings/Settings';
import Dashboard from './pages/dashboard/Dashboard';
import AnnualPlan from './pages/annualPlan/AnnualPlan';
import { MonthlyPlan } from './pages/monthlyPlan/MonthlyPlan';
import ShiftsChange from './pages/shiftsChange/ShiftsChange';
import DailyPlan from './pages/dailyPlan/DailyPlan';
import ChangePasswordModal from './core/components/modals/ChangePasswordModal';
import UserProfileModal from './core/components/modals/UserProfileModal';

const Main = () => {
  const { isLoading } = useApplicationStateValue();
  const { loggedUser, isLoggedIn, logout } = useAuthStateValue();
  const { t } = useTranslation();
  const changePasswordRef = useRef();
  const userProfileRef = useRef();
  const { language } = useApplicationStateValue();

  let lang = '';
  if (language === 'sr') {
    lang = srSR;
  } else {
    lang = null;
  }

  const showUserProfileDialog = () => {
    userProfileRef.current.showDialog();
  };

  const showChangePasswordDialog = () => {
    changePasswordRef.current.showDialog();
  };

  const handleLogout = () => {
    logout();
  };

  const dropdownItems = [
    {
      key: 'settings',
      icon: userProfileIcon,
      value: t('main.userSettings'),
      callback: showUserProfileDialog
    },
    {
      key: 'changePassword',
      icon: changePasswordIcon,
      value: t('main.passwordChange'),
      callback: showChangePasswordDialog
    },
    // {
    //    key: 'language',
    //    icon: <GlobalOutlined />,
    //    value: t('main.language'),
    //    type: 'select'
    // },
    {
      key: 'logout',
      icon: logoutIcon,
      value: t('main.logout'),
      callback: handleLogout
    }
  ];

  return (
    <ConfigProvider locale={lang}>
      <BasicLayout
        loading={isLoading}
        logo={logoImg}
        applicationName={t('main.appName')}
        authenticated={isLoggedIn}
        user={loggedUser}
        pages={[
          {
            key: 'dashboard',
            component: Dashboard
          },
          {
            key: 'annualPlan',
            component: AnnualPlan
          },
          {

            key: 'monthlyPlan',
            component: MonthlyPlan,
            hasParam: true,
          },
          {
            key: 'dailyPlan',
            component: DailyPlan
          },
          {
            key: 'shiftsChange',
            component: ShiftsChange
          },
          {
            key: 'users',
            component: Users
          },
          {
            key: 'settings',
            component: Settings
          }
        ]}
        menuItems={[
          {
            key: 'dashboard',
            value: t('main.dashboard'),
            icon: homeIcon
          },
          {
            key: 'annualPlan',
            value: t('main.annualPlan'),
            icon: annualPlanIcon
          },
          {
            key: 'monthlyPlan',
            value: t('main.monthlyPlan'),
            icon: monthlyPlanIcon
          },
          {
            key: 'dailyPlan',
            value: t('main.dailyPlan'),
            icon: dailyPlanIcon
          },
          {
            key: 'shiftsChange',
            value: t('main.shiftsChange'),
            icon: shiftChangeIcon
          },
          {
            key: 'users',
            value: t('main.users'),
            icon: usersIcon
          },
          {
            key: 'settings',
            value: t('main.settings'),
            icon: settingsIcon
          }
        ]}
        dropdownItems={dropdownItems}
        loginPage={<Login />}
      />
      <ChangePasswordModal ref={changePasswordRef} />
      <UserProfileModal ref={userProfileRef} />
    </ConfigProvider>
  );
};

export default Main;

import { useState } from 'react';
import { getSettings } from '../../../services/settings.service';

const useSettings = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [totalElements, setTotalElements] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const getAppSettings = async (payload) => {
        try {
            setLoading(true);
            const response = await getSettings(payload);
            setDataSource(response.data.content);
            setTotalElements(response.data.totalElements);
            setCurrentPage(payload.pagination.page);
        } catch (e) {
            setDataSource(null);
        } finally {
            setLoading(false);
        }
    };
    return {
        loading,
        dataSource,
        setDataSource,
        totalElements,
        currentPage,
        getAppSettings
    }
};

export default useSettings;

/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-vars */
import { message } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import StatusCode from '../../../services/statusCode';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import { getUsers, deleteUser, generateNewPassword, getUsersLicences, syncUsers, LogoutFromAll } from '../../../services/user.service';
import { getPagination, resetPagination } from '../../../util/pagination';

const PAGE_KEY = 'users';

const useUsers = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState(null);
    const [syncData, setSyncData] = useState(null);
    const [licenseSource, setlicenseSource] = useState(null);
    const [error, setError] = useState(null);
    const [totalElements, setTotalElements] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const { t } = useTranslation();

    const fetchUsers = async (payload) => {
      try {
        setLoading(true);
        const response = await getUsers(payload);
        setDataSource(response.data.content);
        setTotalElements(response.data.totalElements);
        setCurrentPage(payload.pagination.page);
      } catch (e) {
        setDataSource(null);
      } finally {
        setLoading(false)
      }
    }

    const syncUsersHandler = async () => {
      try {
        const res = await syncUsers();
        if (res.status === StatusCode.OK) {
          setSyncData(res?.data);
        }
      } catch (e) {
       console.log(e)
      }
    }
    const removeUser = async (record) => {
      (async function () {
        const res = await deleteUser(record.id);
        if (res.status === StatusCode.OK) {
          resetPagination(PAGE_KEY);
          const { page, pageSize } = getPagination(PAGE_KEY);
          fetchUsers({
            pagination: {
              page,
              size: pageSize
            }
          });
          message.success(t('users.deletionSuccess'));
        } else {
          message.error(t('users.deletionError'));
        }
      }());
    };
    const generatePassword = async (id) => {
        const res = await generateNewPassword(id);
        if (res.status === StatusCode.OK) {
          message.success(t('users.generatePasswordSuccess'));
        } else {
          message.error(t('users.generatePasswordError'));
        }
      };

      const fetchLicences = async (userId) => {
        try {
          setLoading(true);
          const response = await getUsersLicences(userId);
          setlicenseSource(response.data);
        } catch (e) {
          setlicenseSource(null);
        } finally {
          setLoading(false);
        }
      }
      const LogoutFromAllDevices = async (id) => {
        const res = await LogoutFromAll(id);
        if (res.status === StatusCode.OK) {
          message.success(t('users.logOutFromAllSuccess'));
        } else {
          message.error(t('users.logOutFromAllError'));
        }
      };

      const showDeleteUserModal = (user) => {
        if (user?.syncStatus === 'NOT_FOUND_USER') {
          confirmModal({
            centered: true,
            icon: <QuestionOutlined />,
            title: t('users.delete'),
            content: t('users.deleteQuestion'),
            okText: t('buttons.yes'),
            cancelText: t('buttons.no'),
            onOk() {
              removeUser(user);
            },
          });
        } else {
          message.error(t('users.canNotDeleteUser'));
        }
      };

    return {
        fetchUsers,
        syncUsersHandler,
        syncData,
        loading,
        error,
        dataSource,
        currentPage,
        totalElements,
        generatePassword,
        showDeleteUserModal,
        fetchLicences,
        licenseSource,
        LogoutFromAllDevices
    }
}

export default useUsers;

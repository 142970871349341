/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import NoPermissionImage from '../../../assets/images/icons/noPermission.png';
import BhansaLogo from '../../../assets/images/bhansa-blue.svg'
import { Container, ImageDiv, Text, NoPermissionSpan, ContactSpan } from './NoPermissionView.style';

const NoPermissionView = () => {
    const { t } = useTranslation();
  return (
    <Container>
      <ImageDiv backgroundImage={NoPermissionImage} />
      <Text>
        <NoPermissionSpan>
          {t('noPermission.noAcces')}
        </NoPermissionSpan>
        <ContactSpan>
          {t('noPermission.contactAdministrator')}
        </ContactSpan>
      </Text>
    </Container>
  )
}

export default NoPermissionView;

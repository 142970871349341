import styled from 'styled-components';

export const DropdownContainer = styled.div`
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
  border-radius: 5px;
  width: 180px;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
`;

export const ColumnsContainer = styled.div`
  display: flex;
`;

export const Column = styled.div`
  width: 50%;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #7070704d;
`;

export const ColumnHeading = styled.div`
  height: 35px;
  color: #6B6B6B;
  padding-top: 5px;
  border-bottom: 1px solid #7070704d;
`;

export const SectorCell = styled.div`
  border-bottom: 1px solid #7070704d;
  color: black;
  transition: all 0.5s;
  font-weight: 600;
  &:hover {
    background-color: #0073AE;
    color: white;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  margin-top: -1px;
`;

export const ActionButton = styled.div`
  height: 25px;
  width: 100%;
  color: #A2A2A2;
  padding: 2px 0;
  transition: all 0.2s;
  & div > div > svg {
    width: 15px;
    margin-top: -2px;
  }
  &.disabled {
    background-color: #E1E1E1;
    cursor: not-allowed;
  }
  &.enabled {
    background-color: #0073AE;
    color: white;
    &:hover {
      background-color: #4d9dc6;
    }
  }
`;

import { Dropdown, message } from 'antd';
import styled from 'styled-components';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import { QuestionOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useAuthStateValue } from '../../../../context/AuthContext';
import revertIcon from '../../../../assets/images/icons/awesome-undo.svg';
import removeIcon from '../../../../assets/images/icons/xmark-solid.svg';
import userIcon from '../../../../assets/images/icons/user-alt.svg';
import userRemoveIcon from '../../../../assets/images/icons/user-xmark-solid.svg';
import confirmModal from '../../../../core/components/modals/ConfirmModal';

const SheetContextMenu = (props) => {
  const [open, setOpen] = useState();
  const {
    controllerId,
    date,
    modifiedData,
    setModifiedData,
    ATCU,
    children,
    isWeekend,
    multiselect,
    isPartOfMultiselect,
    enabled,
    isSatco,
    isShiftManager,
    sheetData,
    roleName,
    controllerFullName,
    numberOfDays,
    mainTableRef
  } = props;
  const { loggedUser } = useAuthStateValue();
  const { t } = useTranslation();

  const shiftTypeId = children?.props?.children?.props?.shiftType?.id;
  const absenceTypeId = children?.props?.children?.props?.absenceType?.id;
  const hasRole = children?.props?.children?.props?.hasRole;

  const modifiedCell = modifiedData?.[modifiedData?.length - 1]?.find((entry) => entry?.controllerId === controllerId && entry?.day === date.getDate());

  const controllerDataInOriginalData = sheetData?.controllers?.find((el) => el?.id === controllerId);
  const cellShiftInOriginalData = controllerDataInOriginalData?.shifts?.find((el) => el?.date === date?.getDate())?.shiftId;
  const cellAbsenceInOriginalData = controllerDataInOriginalData?.absences?.find((el) => el?.date === date?.getDate())?.absenceId;

  const findLeastLoadedShiftManager = (controllerId, date) => {
    const rolesPerEmployee = [];
    sheetData?.controllers?.forEach((controller) => {
      if (controller?.role === 'SHIFT_MANAGER' || controller?.role === 'SATCO') {
        for (let i = 1; i <= numberOfDays; i += 1) {
          const shiftInSheetData = controller?.shifts?.find((el) => el.date === i);
          const latestChangeValue = modifiedData ? modifiedData?.[modifiedData?.length - 1]?.find((changeEntry) => changeEntry?.controllerId === controller.id && changeEntry?.day === i) : null;
          const matchInRolesPerEmployee = rolesPerEmployee.find((el) => el?.controllerId === controller?.id);
          if (!matchInRolesPerEmployee) {
            rolesPerEmployee.push({
              controllerId: controller?.id,
              controllerName: controller?.firstName + ' ' + controller?.lastName,
              count: 0,
              matchingShiftAndDate: false
            });
          }
          if (i === date && (latestChangeValue ? latestChangeValue?.newState?.shiftId === shiftTypeId : shiftInSheetData?.shiftId === shiftTypeId)) {
            if (matchInRolesPerEmployee) matchInRolesPerEmployee.matchingShiftAndDate = true;
          }
          if (latestChangeValue) {
            if ((latestChangeValue?.newState?.isShiftManager || latestChangeValue?.newState?.isSatco)) {
              if (matchInRolesPerEmployee) matchInRolesPerEmployee.count += 1;
            }
          } else if (shiftInSheetData && (shiftInSheetData?.isShiftManager || shiftInSheetData?.isSatco)) {
            if (matchInRolesPerEmployee && !latestChangeValue) matchInRolesPerEmployee.count += 1;
          }
        }
      }
    });
    const leastLoaded = {
      controllerId: null,
      controllerName: null,
      count: null,
    };
    rolesPerEmployee?.forEach((controller) => {
      if (controller?.controllerId !== controllerId && controller?.matchingShiftAndDate) {
        if (!leastLoaded?.count) {
          leastLoaded.controllerId = controller.controllerId;
          leastLoaded.controllerName = controller.controllerName;
          leastLoaded.count = controller.count;
        } else if (controller?.count < leastLoaded?.count) {
          leastLoaded.controllerId = controller.controllerId;
          leastLoaded.controllerName = controller.controllerName;
          leastLoaded.count = controller.count;
        }
      }
    });
    return leastLoaded;
  };

  const shiftClickHandler = (event) => {
    const newShiftId = parseInt(event.target.getAttribute('data-shiftId'), 10);
    if (isPartOfMultiselect) {
        const multiselectChanges = [];
        multiselect?.forEach((cell) => {
        const controllerFullName = mainTableRef?.current?.childNodes?.[1]?.childNodes?.[cell?.[0] - 1]?.childNodes?.[0]?.innerHTML;
        const controllerId = sheetData?.controllers?.find((controller) => (controller?.firstName + ' ' + controller?.lastName) === controllerFullName)?.id;
        const controllerOriginalSheetData = sheetData?.controllers?.find((controller) => (controller?.firstName + ' ' + controller?.lastName) === controllerFullName);
        const oldStateShift = controllerOriginalSheetData?.shifts?.find((el) => el?.date === cell?.[1]);
        const oldStateAbsence = controllerOriginalSheetData?.absences?.find((el) => el?.date === cell?.[1]);
        const oldStateRole = oldStateShift?.isShiftManager ? 'SHIFT_MANAGER' : (oldStateShift?.isSatco ? 'SATCO' : null);
        if (oldStateShift?.id !== newShiftId) {
          multiselectChanges.push({
            day: cell?.[1],
            controllerId,
            type: (!!oldStateShift || !!oldStateAbsence) ? 'CHANGE' : 'ADD',
            oldState: {
              shiftId: oldStateShift ? oldStateShift?.shiftId : null,
              absenceId: oldStateAbsence ? oldStateAbsence?.absenceId : null,
              isShiftManager: oldStateRole === 'SHIFT_MANAGER',
              isSatco: oldStateRole === 'SATCO'
            },
            newState: {
              shiftId: newShiftId,
              absenceId: null,
              isShiftManager: false,
              isSatco: false
            }
          });
        }
      });
      setModifiedData((prevState) => {
        const changesToKeep = [];
        prevState?.[prevState?.length - 1]?.forEach((el) => {
          if (multiselectChanges?.find((change) => change?.controllerId === el?.controllerId && change?.day === el?.day)) {
            // skip
          } else changesToKeep.push(el);
        });
        return [
          ...(prevState ? prevState : []),
          [
            ...(changesToKeep ? changesToKeep : []),
            ...multiselectChanges
          ]
        ];
      });
    } else if (newShiftId !== shiftTypeId) {
        if (hasRole) {
          const leastLoadedManager = findLeastLoadedShiftManager(controllerId, date.getDate());
          if (leastLoadedManager?.controllerId) {
            confirmModal({
              centered: true,
              icon: <QuestionOutlined />,
              title: 'Izmjena smjene ' + (roleName === 'SHIFT_MANAGER' ? 'šefa smjene ' : (roleName === 'SATCO' ? 'SATCO-a ' : null)),
              content: (
                <span>
                  Izmjenom smjene dužnost
                  {roleName === 'SHIFT_MANAGER' ? <strong> šefa smjene </strong> : <strong> SATCO-a </strong>}
                  za odabrani datum automatski će preći na kontrolora
                  <strong>{' ' + leastLoadedManager?.controllerName}</strong>
                  .
                  <br />
                  <br />
                  Da li ste sigurni da želite napraviti ovu izmjenu?
                </span>
              ),
              okText: t('buttons.yes'),
              cancelText: t('buttons.no'),
              onOk: () => {
                setModifiedData((prevState) => {
                  const changesToKeep = [];
                  prevState?.[prevState?.length - 1]?.forEach((el) => {
                    if ((el.controllerId === controllerId || el?.controllerId === leastLoadedManager?.controllerId) && el.day === date.getDate()) {
                      // skip
                    } else changesToKeep.push(el);
                  });
                  return [
                    ...(prevState ? prevState : []),
                    [
                      ...(changesToKeep ? changesToKeep : []),
                      {
                        day: date.getDate(),
                        controllerId: leastLoadedManager?.controllerId,
                        type: 'CHANGE',
                        oldState: {
                          shiftId: cellShiftInOriginalData,
                          absenceId: cellAbsenceInOriginalData,
                          isShiftManager: false,
                          isSatco: false
                        },
                        newState: {
                          shiftId: shiftTypeId,
                          absenceId: null,
                          isShiftManager: roleName === 'SHIFT_MANAGER',
                          isSatco: roleName === 'SATCO'
                        }
                      },
                      {
                        day: date.getDate(),
                        controllerId,
                        type: 'CHANGE',
                        oldState: {
                          shiftId: cellShiftInOriginalData,
                          absenceId: cellAbsenceInOriginalData,
                          isShiftManager: !!(hasRole && roleName === 'SHIFT_MANAGER'),
                          isSatco: !!(hasRole && roleName === 'SATCO')
                        },
                        newState: {
                          shiftId: newShiftId,
                          absenceId: null,
                          isShiftManager: false,
                          isSatco: false
                        }
                      }
                    ]
                  ]
                })
              }
            });
          } else {
            setModifiedData((prevState) => {
              const changesToKeep = [];
              prevState?.[prevState?.length - 1]?.forEach((el) => {
                if (el?.controllerId === controllerId && el?.day === date.getDate()) {
                  // skip
                } else changesToKeep.push(el);
              });
              return [
                ...(prevState ? prevState : []),
                [
                  ...(changesToKeep ? changesToKeep : []),
                  {
                    day: date.getDate(),
                    controllerId,
                    type: 'CHANGE',
                    oldState: {
                      shiftId: cellShiftInOriginalData,
                      absenceId: cellAbsenceInOriginalData,
                      isShiftManager: !!(hasRole && roleName === 'SHIFT_MANAGER'),
                      isSatco: !!(hasRole && roleName === 'SATCO')
                    },
                    newState: {
                      shiftId: newShiftId,
                      absenceId: null,
                      isShiftManager: false,
                      isSatco: false
                    }
                  }
                ]
              ];
            });
          }
        } else {
          setModifiedData((prevState) => {
            const changesToKeep = [];
            prevState?.[prevState?.length - 1]?.forEach((el) => {
              if (el?.controllerId === controllerId && el?.day === date.getDate()) {
                // skip
              } else changesToKeep.push(el);
            });
            return [
              ...(prevState ? prevState : []),
              [
                ...(changesToKeep ? changesToKeep : []),
                {
                  day: date.getDate(),
                  controllerId,
                  type: (cellAbsenceInOriginalData || cellAbsenceInOriginalData === 0 || cellShiftInOriginalData || cellShiftInOriginalData === 0) ? 'CHANGE' : 'ADD',
                  oldState: {
                    shiftId: cellShiftInOriginalData,
                    absenceId: cellAbsenceInOriginalData,
                    isShiftManager: !!(hasRole && roleName === 'SHIFT_MANAGER'),
                    isSatco: !!(hasRole && roleName === 'SATCO')
                  },
                  newState: {
                    shiftId: newShiftId,
                    absenceId: null,
                    isShiftManager: false,
                    isSatco: false
                  }
                }
              ]
            ];
          });
        }
      }
    setOpen(false);
  };

  const absenceClickHandler = (event) => {
    setOpen(false);
    const newAbsenceId = parseInt(event.target.getAttribute('data-absenceId'), 10);
    const newAbsenceDetails = loggedUser?.absenceTypes?.find((el) => el?.id === newAbsenceId);
    if (isPartOfMultiselect) {
      const multiselectChanges = [];
      let withWeekdayExceptions = false;
      multiselect?.forEach((cell) => {
        const controllerFullName = mainTableRef?.current?.childNodes?.[1]?.childNodes?.[cell?.[0] - 1]?.childNodes?.[0]?.innerHTML;
        const controllerId = sheetData?.controllers?.find((controller) => (controller?.firstName + ' ' + controller?.lastName) === controllerFullName)?.id;
        const controllerOriginalSheetData = sheetData?.controllers?.find((controller) => (controller?.firstName + ' ' + controller?.lastName) === controllerFullName);
        const oldStateShift = controllerOriginalSheetData?.shifts?.find((el) => el?.date === cell?.[1]);
        const oldStateAbsence = controllerOriginalSheetData?.absences?.find((el) => el?.date === cell?.[1]);
        const oldStateRole = oldStateShift?.isShiftManager ? 'SHIFT_MANAGER' : (oldStateShift?.isSatco ? 'SATCO' : null);
        const cellDate = new Date(date.getFullYear(), date.getMonth(), cell?.[1]);
        const isWeekend = cellDate?.getDay() === 6 || cellDate?.getDay() === 0;

        if ((oldStateAbsence?.id !== newAbsenceId) && (newAbsenceDetails?.activeOn === 'ALL_DAYS' || (isWeekend && newAbsenceDetails?.activeOn === 'WEEKEND') || (!isWeekend && newAbsenceDetails?.activeOn === 'WEEKDAY'))) {
          multiselectChanges.push({
            day: cell?.[1],
            controllerId,
            type: (!!oldStateShift || !!oldStateAbsence) ? 'CHANGE' : 'ADD',
            oldState: {
              shiftId: oldStateShift ? oldStateShift?.shiftId : null,
              absenceId: oldStateAbsence ? oldStateAbsence?.absenceId : null,
              isShiftManager: oldStateRole === 'SHIFT_MANAGER',
              isSatco: oldStateRole === 'SATCO'
            },
            newState: {
              shiftId: null,
              absenceId: newAbsenceId,
              isShiftManager: false,
              isSatco: false
            }
          })
        } else withWeekdayExceptions = true;
      });
      if (multiselectChanges?.length > 0) {
        setModifiedData((prevState) => {
          const changesToKeep = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (multiselectChanges?.find((change) => change?.controllerId === el?.controllerId && change?.day === el?.day)) {
              // skip
            } else changesToKeep.push(el);
          });
          return [
            ...(prevState ? prevState : []),
            [
              ...(changesToKeep ? changesToKeep : []),
              ...multiselectChanges
            ]
          ];
        });
      }
      if (newAbsenceDetails?.activeOn !== 'ALL_DAYS' && withWeekdayExceptions) {
        message.warning(newAbsenceDetails?.activeOn === 'WEEKDAY' ? t('monthlyPlan.absenceOnlyWeekdays') : t('monthlyPlan.absenceOnlyWeekend'));
      }
    } else if (newAbsenceId !== absenceTypeId) {
      if (hasRole) {
        const leastLoadedManager = findLeastLoadedShiftManager(controllerId, date.getDate());
        if (leastLoadedManager?.controllerId) {
          confirmModal({
            centered: true,
            icon: <QuestionOutlined />,
            title: 'Slanje ' + (roleName === 'SHIFT_MANAGER' ? 'šefa smjene ' : (roleName === 'SATCO' ? 'SATCO-a ' : null)) + ' na odsustvo',
            content: (
              <span>
                Kontrolor koga želite poslati na odsustvo na odabrani dan obavlja dužnost
                {roleName === 'SHIFT_MANAGER' ? <strong> šefa smjene </strong> : <strong> SATCO-a </strong>}
                .
                <br />
                <br />
                Slanjem na odsustvo ta dužnost za odabrani dan i smjenu automatski će preći na kontolora
                <strong>
                  {' ' + leastLoadedManager?.controllerName}
                </strong>
                .
                <br />
                <br />
                Da li ste sigurni da želite napraviti ovu izmjenu?
              </span>
            ),
            okText: t('buttons.yes'),
            cancelText: t('buttons.no'),
            onOk: () => {
              setModifiedData((prevState) => {
                const changesToKeep = [];
                prevState?.[prevState?.length - 1]?.forEach((el) => {
                  if ((el.controllerId === controllerId || el?.controllerId === leastLoadedManager?.controllerId) && el.day === date.getDate()) {
                    // skip
                  } else {
                    changesToKeep.push(el);
                  }
                });
                return [
                  ...(prevState ? prevState : []),
                  [
                    ...(changesToKeep ? changesToKeep : []),
                    {
                      day: date.getDate(),
                      controllerId: leastLoadedManager?.controllerId,
                      type: 'CHANGE',
                      oldState: {
                        shiftId: cellShiftInOriginalData,
                        absenceId: cellAbsenceInOriginalData,
                        isShiftManager: false,
                        isSatco: false
                      },
                      newState: {
                        shiftId: shiftTypeId,
                        absenceId: null,
                        isShiftManager: roleName === 'SHIFT_MANAGER',
                        isSatco: roleName === 'SATCO'
                      }
                    },
                    {
                      day: date.getDate(),
                      controllerId,
                      type: 'CHANGE',
                      oldState: {
                        shiftId: cellShiftInOriginalData,
                        absenceId: cellAbsenceInOriginalData,
                        isShiftManager: roleName === 'SHIFT_MANAGER',
                        isSatco: roleName === 'SATCO'
                      },
                      newState: {
                        shiftId: null,
                        absenceId: newAbsenceId,
                        isShiftManager: false,
                        isSatco: false
                      }
                    }
                  ]
                ];
              });
            }
          });
        } else {
          setModifiedData((prevState) => {
            const changesToKeep = [];
            prevState?.[prevState?.length - 1]?.forEach((el) => {
              if (el?.controllerId === controllerId && el?.day === date.getDate()) {
                // skip
              } else changesToKeep.push(el);
            });
            return [
              ...(prevState ? prevState : []),
              [
                ...(changesToKeep ? changesToKeep : []),
                {
                  day: date.getDate(),
                  controllerId,
                  type: (cellAbsenceInOriginalData || cellAbsenceInOriginalData === 0 || cellShiftInOriginalData || cellShiftInOriginalData === 0) ? 'CHANGE' : 'ADD',
                  oldState: {
                    shiftId: cellShiftInOriginalData,
                    absenceId: cellAbsenceInOriginalData,
                    isShiftManager: roleName === 'SHIFT_MANAGER',
                    isSatco: roleName === 'SATCO'
                  },
                  newState: {
                    shiftId: null,
                    absenceId: newAbsenceId,
                    isShiftManager: false,
                    isSatco: false
                  }
                }
              ]
            ];
          });
        }
      } else {
        setModifiedData((prevState) => {
          const changesToKeep = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.controllerId === controllerId && el?.day === date.getDate()) {
              // skip
            } else changesToKeep.push(el);
          });
          return [
            ...(prevState ? prevState : []),
            [
              ...(changesToKeep ? changesToKeep : []),
              {
                day: date.getDate(),
                controllerId,
                type: (cellAbsenceInOriginalData || cellAbsenceInOriginalData === 0 || cellShiftInOriginalData || cellShiftInOriginalData === 0) ? 'CHANGE' : 'ADD',
                oldState: {
                  shiftId: cellShiftInOriginalData,
                  absenceId: cellAbsenceInOriginalData
                },
                newState: {
                  shiftId: null,
                  absenceId: newAbsenceId
                }
              }
            ]
          ]
        });
      }
    }
  };

  const renderShifts = () => {
    const shiftCellsArray = [];
    loggedUser?.shiftTypes?.[ATCU]?.forEach((shiftType) => {
      shiftCellsArray.push(<ShiftCell data-shiftId={shiftType.id} onClick={shiftClickHandler}>{shiftType.name}</ShiftCell>);
    });
    return shiftCellsArray;
  }

  const renderLeaves = () => {
    const leaveCellsArray = [];
    loggedUser?.absenceTypes?.forEach((absenceType) => {
      if (isPartOfMultiselect) {
        leaveCellsArray.push(<AbsenceCell data-absenceId={absenceType.id} onClick={absenceClickHandler}>{absenceType.name}</AbsenceCell>);
      } else if (isWeekend) {
         if (absenceType?.activeOn === 'WEEKEND') leaveCellsArray.push(<AbsenceCell data-absenceId={absenceType.id} onClick={absenceClickHandler}>{absenceType.name}</AbsenceCell>);
         if (absenceType?.activeOn === 'ALL_DAYS') leaveCellsArray.push(<AbsenceCell data-absenceId={absenceType.id} onClick={absenceClickHandler}>{absenceType.name}</AbsenceCell>);
      } else {
        if (absenceType?.activeOn === 'WEEKDAY') leaveCellsArray.push(<AbsenceCell data-absenceId={absenceType.id} onClick={absenceClickHandler}>{absenceType.name}</AbsenceCell>);
        if (absenceType?.activeOn === 'ALL_DAYS') leaveCellsArray.push(<AbsenceCell data-absenceId={absenceType.id} onClick={absenceClickHandler}>{absenceType.name}</AbsenceCell>);
      }
    });
    return leaveCellsArray;
  }

  const handleOpenChange = (flag) => {
    setOpen(flag);
  }

  const revertChangesHandler = () => {
   const cellMostRecentChange = modifiedData?.[modifiedData?.length - 1]?.find((el) => el?.controllerId === controllerId && el?.day === date.getDate());
   setModifiedData((prevState) => {
     const newState = [];
     let secondMostRecentChange;
     prevState?.forEach((step) => {
       const modifiedStep = [];
       const stepHasMostRecentChange = step?.find((el) => JSON.stringify(el) === JSON.stringify(cellMostRecentChange));
       step?.forEach((change) => {
         if (change?.controllerId === controllerId && change?.day === date.getDate() && JSON.stringify(change) !== JSON.stringify(cellMostRecentChange)) {
           secondMostRecentChange = change;
         }
         if (JSON.stringify(change) !== JSON.stringify(cellMostRecentChange)) {
           modifiedStep.push(change);
         }
         if (stepHasMostRecentChange && secondMostRecentChange) modifiedStep.push(secondMostRecentChange);
       });
       if (modifiedStep?.length > 0) newState.push(modifiedStep);
     });
     return newState;
   })
   setOpen(false);
  };

  const removeShiftHandler = () => {
    if (isPartOfMultiselect) {
      const multiselectChanges = [];
      multiselect?.forEach((cell) => {
        const controllerFullName = mainTableRef?.current?.childNodes?.[1]?.childNodes?.[cell?.[0] - 1]?.childNodes?.[0]?.innerHTML;
        const controllerId = sheetData?.controllers?.find((controller) => (controller?.firstName + ' ' + controller?.lastName) === controllerFullName)?.id;
        const controllerOriginalSheetData = sheetData?.controllers?.find((controller) => (controller?.firstName + ' ' + controller?.lastName) === controllerFullName);
        const oldStateShift = controllerOriginalSheetData?.shifts?.find((el) => el?.date === cell?.[1]);
        const oldStateAbsence = controllerOriginalSheetData?.absences?.find((el) => el?.date === cell?.[1]);
        const oldStateRole = oldStateShift?.isShiftManager ? 'SHIFT_MANAGER' : (oldStateShift?.isSatco ? 'SATCO' : null);

        if (oldStateShift?.id || oldStateAbsence?.id) {
          multiselectChanges.push({
            day: cell?.[1],
            controllerId,
            type: 'REMOVE',
            oldState: {
              shiftId: oldStateShift ? oldStateShift?.shiftId : null,
              absenceId: oldStateAbsence ? oldStateAbsence?.absenceId : null,
              isShiftManager: oldStateRole === 'SHIFT_MANAGER',
              isSatco: oldStateRole === 'SATCO'
            },
            newState: {
              shiftId: null,
              absenceId: null,
              isShiftManager: false,
              isSatco: false
            }
          });
        }
      });
      setModifiedData((prevState) => {
        const changesToKeep = [];
        prevState?.[prevState?.length - 1]?.forEach((el) => {
          if (multiselectChanges?.find((change) => change?.controllerId === el?.controllerId && change?.day === el?.day)) {
            // skip
          } else changesToKeep.push(el);
        });
        return [
          ...(prevState ? prevState : []),
          [
            ...(changesToKeep ? changesToKeep : []),
            ...multiselectChanges
          ]
        ];
      });
    } else if (hasRole) {
        const leastLoadedRoleController = findLeastLoadedShiftManager(controllerId, date.getDate());
        if (leastLoadedRoleController?.controllerId) {
          confirmModal({
            centered: true,
            icon: <QuestionOutlined />,
            title: 'Uklanjanje smjene',
            content: (
              <span>
                Uklanjanjem smjene sa odabranog datuma, dužnost
                {roleName === 'SHIFT_MANAGER' ? <strong> šefa smjene </strong> : <strong> SATCO-a </strong>}
                automatski će biti prebačena na kontrolora
                <strong>{' ' + leastLoadedRoleController?.controllerName}</strong>
                .
                <br />
                <br />
                Da li ste sigurni da želite izvršiti ovu operaciju?
              </span>
            ),
            okText: t('buttons.yes'),
            cancelText: t('buttons.no'),
            onOk: () => {
              setModifiedData((prevState) => {
                const changesToKeep = [];
                prevState?.[prevState?.length - 1]?.forEach((el) => {
                  if ((el.controllerId === controllerId || el?.controllerId === leastLoadedRoleController?.controllerId) && el.day === date.getDate()) {
                    // skip
                  } else {
                    changesToKeep.push(el);
                  }
                });
                return [
                  ...(prevState ? prevState : []),
                  [
                    ...(changesToKeep ? changesToKeep : []),
                    {
                      day: date.getDate(),
                      controllerId,
                      type: 'REMOVE',
                      oldState: {
                        shiftId: shiftTypeId,
                        absenceId: null,
                        isShiftManager: roleName === 'SHIFT_MANAGER',
                        isSatco: roleName === 'SATCO'
                      },
                      newState: {
                        shiftId: null,
                        absenceId: null,
                        isShiftManager: false,
                        isSatco: false
                      }
                    },
                    {
                      day: date.getDate(),
                      controllerId: leastLoadedRoleController?.controllerId,
                      type: 'CHANGE',
                      oldState: {
                        shiftId: shiftTypeId,
                        absenceId: null,
                        isShiftManager: false,
                        isSatco: false
                      },
                      newState: {
                        shiftId: shiftTypeId,
                        absenceId: null,
                        isShiftManager: roleName === 'SHIFT_MANAGER',
                        isSatco: roleName === 'SATCO'
                      }
                    }
                  ]
                ];
              });
            }
          });
        } else {
          setModifiedData((prevState) => {
            const changesToKeep = [];
            prevState?.[prevState?.length - 1]?.forEach((el) => {
              if (el?.controllerId === controllerId && el?.day === date.getDate()) {
                // skip
              } else changesToKeep.push(el);
            });
            return [
              ...(prevState ? prevState : []),
              [
                ...(changesToKeep ? changesToKeep : []),
                {
                  day: date.getDate(),
                  controllerId,
                  type: 'CHANGE',
                  oldState: {
                    shiftId: shiftTypeId,
                    absenceId: null,
                    isShiftManager: roleName === 'SHIFT_MANAGER',
                    isSatco: roleName === 'SATCO'
                  },
                  newState: {
                    shiftId: null,
                    absenceId: null,
                    isShiftManager: false,
                    isSatco: false
                  }
                }
              ]
            ];
          });
        }
      } else {
        setModifiedData((prevState) => {
          const changesToKeep = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.controllerId === controllerId && el?.day === date.getDate()) {
              // skip
            } else changesToKeep.push(el);
          });
          return [
            ...(prevState ? prevState : []),
            [
              ...(changesToKeep ? changesToKeep : []),
              {
                day: date.getDate(),
                controllerId,
                type: 'REMOVE',
                oldState: {
                  shiftId: shiftTypeId,
                  absenceId: absenceTypeId
                },
                newState: {
                  shiftId: null,
                  absenceId: null,
                  isShiftManager: false
                }
              }
            ]
          ]
        });
      }
    setOpen(false);
  }

  const toggleRoleHandler = () => {
    setOpen(false);
    if (hasRole) {
      const leastLoadedRoleController = findLeastLoadedShiftManager(controllerId, date.getDate());
      if (leastLoadedRoleController?.controllerId) {
        confirmModal({
          centered: true,
          icon: <QuestionOutlined />,
          title: 'Postavljanje šefa smjene',
          content: (
            <span>
              Da li ste sigurni da za odabrani datum želite ukloniti dužnost
              {roleName === 'SHIFT_MANAGER' ? <strong> šef smjene </strong> : <strong> satco </strong>}
              sa kontrolora
              <strong>{' ' + controllerFullName}</strong>
              ?
              <br />
              <br />
              Dužnost će automatski biti prebačena na kontrolora
              <strong>
                {' ' + leastLoadedRoleController?.controllerName}
              </strong>
              .
            </span>
          ),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: () => {
            setModifiedData((prevState) => {
              const changesToKeep = [];
              prevState?.[prevState?.length - 1]?.forEach((el) => {
                if ((el?.controllerId === controllerId || el?.controllerId === leastLoadedRoleController?.controllerId) && el?.day === date.getDate()) {
                  // skip
                } else changesToKeep.push(el);
              });
              return [
                ...(prevState ? prevState : []),
                [
                  ...(changesToKeep ? changesToKeep : []),
                  {
                    day: date.getDate(),
                    controllerId,
                    type: 'CHANGE',
                    oldState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: roleName === 'SHIFT_MANAGER',
                      isSatco: roleName === 'SATCO'
                    },
                    newState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: false,
                      isSatco: false
                    }
                  },
                  {
                    day: date.getDate(),
                    controllerId: leastLoadedRoleController.controllerId,
                    type: 'CHANGE',
                    oldState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: false,
                      isSatco: false
                    },
                    newState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: roleName === 'SHIFT_MANAGER',
                      isSatco: roleName === 'SATCO'
                    }
                  }
                ]
              ];
            });
          }
        });
      } else {
        setModifiedData((prevState) => {
          const changesToKeep = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.controllerId === controllerId && el?.day === date.getDate()) {
              // skip
            } else changesToKeep.push(el);
          });
          return [
            ...(prevState ? prevState : []),
            [
              ...(changesToKeep ? changesToKeep : []),
              {
                day: date.getDate(),
                controllerId,
                type: 'CHANGE',
                oldState: {
                  shiftId: shiftTypeId,
                  absenceId: null,
                  isShiftManager: roleName === 'SHIFT_MANAGER',
                  isSatco: roleName === 'SATCO'
                },
                newState: {
                  shiftId: shiftTypeId,
                  absenceId: null,
                  isShiftManager: false,
                  isSatco: false
                }
              }
            ]
          ];
        });
      }
    } else {
      const matchingRoleUserInSheetData = sheetData?.controllers?.find((controllerEl) => {
        const matchingEntry = controllerEl?.shifts?.find((shiftEl) => shiftEl.date === date.getDate() && shiftEl.shiftId === shiftTypeId && (shiftEl.isShiftManager || shiftEl.isSatco));
        const entryDeleted = !!modifiedData?.[modifiedData?.length - 1]?.find((el) => {
          return (el?.day === date.getDate() && el?.controllerId === controllerEl?.id)
          && ((el?.newState?.shiftId !== matchingEntry?.shiftId)
              || (el?.newState?.shiftId === matchingEntry?.shiftId && !el?.newState?.isSatco && !el?.newState?.isShiftManager));
        });
        return (!!matchingEntry && !entryDeleted);
      });
      const matchingRoleUserInModifiedData = modifiedData?.[modifiedData?.length - 1]?.find((modifiedEl) => modifiedEl.day === date.getDate() && modifiedEl.newState.shiftId === shiftTypeId && (modifiedEl.newState.isShiftManager || modifiedEl.newState.isSatco));
      const matchingUserInSheetData = sheetData?.controllers?.find((el) => el?.id === matchingRoleUserInModifiedData?.controllerId);
      const matchingUserFullName = matchingUserInSheetData?.firstName + ' ' + matchingUserInSheetData?.lastName;
      if (matchingRoleUserInSheetData || matchingRoleUserInModifiedData) {
        confirmModal({
          centered: true,
          icon: <QuestionOutlined />,
          title: 'Postavljanje šefa smjene',
          content: (
            <span>
              Kontrolor
              {matchingRoleUserInModifiedData ? (<strong>{' ' + matchingUserFullName + ' '}</strong>) : (<strong>{' ' + matchingRoleUserInSheetData?.firstName + ' ' + matchingRoleUserInSheetData?.lastName + ' '}</strong>)}
              je već postavljen kao
              {roleName === 'SHIFT_MANAGER' ? <strong> šef smjene </strong> : <strong> satco </strong>}
              za odabrani datum.
              <br />
              <br />
              Da li ste sigurni tu ulogu za odabrani datum želite prenijeti na korisnika
              <strong>{' ' + controllerFullName}</strong>
              ?
            </span>
          ),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: () => {
            setModifiedData((prevState) => {
              const changesToKeep = [];
              prevState?.[prevState?.length - 1]?.forEach((el) => {
                if (el?.controllerId === controllerId && el?.day === date.getDate()) {
                  // skip
                } else changesToKeep.push(el);
              });
              return [
                ...(prevState ? prevState : []),
                [
                  ...(changesToKeep ? changesToKeep : []),
                  {
                    day: date.getDate(),
                    controllerId: matchingRoleUserInModifiedData ? matchingRoleUserInModifiedData?.controllerId : matchingRoleUserInSheetData?.id,
                    type: 'CHANGE',
                    oldState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: roleName === 'SHIFT_MANAGER',
                      isSatco: roleName === 'SATCO'
                    },
                    newState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: false,
                      isSatco: false
                    }
                  },
                  {
                    day: date.getDate(),
                    controllerId,
                    type: 'CHANGE',
                    oldState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: false,
                      isSatco: false
                    },
                    newState: {
                      shiftId: shiftTypeId,
                      absenceId: null,
                      isShiftManager: roleName === 'SHIFT_MANAGER',
                      isSatco: roleName === 'SATCO'
                    }
                  }
                ]
              ]
            });
          },
        })
      } else {
        setModifiedData((prevState) => {
          const changesToKeep = [];
          prevState?.[prevState?.length - 1]?.forEach((el) => {
            if (el?.controllerId === controllerId && el?.day === date.getDate()) {
              // skip
            } else changesToKeep.push(el);
          });
          return [
            ...(prevState ? prevState : []),
            [
              ...(changesToKeep ? changesToKeep : []),
              {
                day: date.getDate(),
                controllerId,
                type: 'CHANGE',
                oldState: {
                  shiftId: shiftTypeId,
                  absenceTypeId: null,
                  isShiftManager: false,
                  isSatco: false
                },
                newState: {
                  shiftId: shiftTypeId,
                  absenceTypeId: null,
                  isShiftManager: roleName === 'SHIFT_MANAGER',
                  isSatco: roleName === 'SATCO'
                }
              }
            ]
          ];
        });
      }
    }
  }

  return (
    <Dropdown
      open={open}
      onOpenChange={handleOpenChange}
      disabled={!enabled}
      dropdownRender={() => {
        return (
          <DropdownContainer>
            <ColumnsContainer>
              <Column>
                <ColumnHeading>SMJENE</ColumnHeading>
                {renderShifts()}
              </Column>
              <Column>
                <ColumnHeading>ODSUSTVA</ColumnHeading>
                {renderLeaves()}
              </Column>
            </ColumnsContainer>
            <ButtonsContainer>
              <ActionButton onClick={(!isPartOfMultiselect && modifiedCell) ? revertChangesHandler : null} className={(!isPartOfMultiselect && modifiedCell) ? 'enabled' : 'disabled'}><ReactSVG src={revertIcon} /></ActionButton>
              {((isSatco || isShiftManager) && !isPartOfMultiselect) && <AddRoleButton onClick={shiftTypeId !== undefined ? toggleRoleHandler : null} hasRole={hasRole} className={shiftTypeId !== undefined ? 'enabled' : 'disabled'}><ReactSVG src={hasRole ? userRemoveIcon : userIcon} /></AddRoleButton>}
              <ActionButton onClick={shiftTypeId !== undefined || absenceTypeId !== undefined ? removeShiftHandler : null} className={shiftTypeId !== undefined || absenceTypeId !== undefined ? 'enabled' : 'disabled'}><ReactSVG src={removeIcon} /></ActionButton>
            </ButtonsContainer>
          </DropdownContainer>
        )
      }}
      trigger={['contextMenu']}
      {...props}
    >
      {props.children}
    </Dropdown>
  );
};

const DropdownContainer = styled.div`
  background-color: white;
  box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
  border-radius: 5px;
  width: 180px;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
`;

const ColumnsContainer = styled.div`
  display: flex;
`;

const Column = styled.div`
  width: 50%;
  text-align: center !important;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #7070704d;
`;

const ColumnHeading = styled.div`
  height: 35px;
  color: #6B6B6B;
  padding-top: 5px;
  border-bottom: 1px solid #7070704d;
`;

const ShiftCell = styled.div`
  border-bottom: 1px solid #7070704d;
  color: black;
  transition: all 0.5s;
  &:hover {
    background-color: #0073AE;
    color: white;
  }
`;

const AbsenceCell = styled.div`
  border-bottom: 1px solid #7070704d;
  color: black;
  transition: all 0.5s;
  &:hover {
    background-color: #0073AE;
    color: white;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  margin-top: -1px;
`;

export const AddRoleButton = styled.div`
  height: 25px;
  width: 100%;
  color: #A2A2A2;
  padding: 2px 0;
  transition: all 0.2s;
  &.disabled {
    background-color: #E1E1E1;
    cursor: not-allowed;
  }
  &.enabled {
    background-color: #0073AE;
    color: white;
    &:hover {
      background-color: #4d9dc6;
    }
  }
  & div > div > svg {
    width: ${(props) => (props?.hasRole ? '20px' : '15px')};
  }
`;

const ActionButton = styled.div`
  height: 25px;
  width: 100%;
  color: #A2A2A2;
  padding: 2px 0;
  transition: all 0.2s;
  & div > div > svg {
    width: 15px;
    margin-top: -2px;
  }
  &.disabled {
    background-color: #E1E1E1;
    cursor: not-allowed;
  }
  &.enabled {
    background-color: #0073AE;
    color: white;
    &:hover {
      background-color: #4d9dc6;
    }
  }
`;

export default SheetContextMenu;

export const getPagination = (pageKey) => {
    const pagination = JSON.parse(localStorage.getItem('pagination'));
    return pagination[pageKey];
};

export const resetPagination = (pageKey) => {
    let pagination = JSON.parse(localStorage.getItem('pagination'));
    if (!pagination || !pagination[pageKey]) {
        pagination = {
            ...pagination,
            [pageKey]: {
                page: 0,
                pageSize: 10
            }
        };
    }
    pagination[pageKey].page = 0;
    localStorage.setItem('pagination', JSON.stringify(pagination));
};

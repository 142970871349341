import { useTranslation } from 'react-i18next';
import { Container, Panel, AppInfoContainer } from './Login.style';
import backgroundImage from '../../assets/images/air_traffic_control_tower_sunset.png';
import LoginPanel from './loginPanel/LoginPanel';
import DateTimeWidget from './dateTimeWidget/DateTimeWidget';

const Login = () => {
  const { t } = useTranslation();

  return (
    <Container backgroundImage={backgroundImage}>
      <DateTimeWidget />
      <Panel>
        <LoginPanel app="App" />
        <AppInfoContainer>
          <h1>{t('login.crewManagement')}</h1>
          {t('login.loginToCrewManagementSystem')}
        </AppInfoContainer>
      </Panel>
    </Container>
  );
};

export default Login;

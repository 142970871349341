import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 2px 5px;
  z-index: 999;
  position: absolute;
  left: ${(props) => (props?.placementX === 'right' ? props?.left : (props?.left - 170))}px;
  top: ${(props) => (props?.placementY === 'bottom' ? props?.top : (props?.top - 50))}px;
  visibility: ${(props) => (props?.visible ? 'visible' : 'hidden')};
`;

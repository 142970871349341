import styled from 'styled-components';
import { Layout } from 'antd';

export const Username = styled.div`
  display: flex;
  font-size: 18px;
  justify-content: right;
  align-items: center;
  height: 20px;
  user-select: none;
 // font-family: MontserratWeb, regular, sans-serif !important;
`;

export const UserRole = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  height: 12px;
  justify-content: flex-end;
  user-select: none;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  cursor: auto;
`;

export const StyledLayoutHeader = styled(Layout.Header)`
  display: flex;
  background-color: ${({ theme: { colors } }) => colors.grayColors.panelBackgroundGray};
  color: white;
  justify-content: space-between;
  display: flex;
  line-height: ${({ height }) => height + 'px'};
  padding: 0 16px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 5;
  height: ${({ height }) => height + 'px'};
`;

export const RightSideBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 15px;
  border-radius: 0.5rem;
  cursor: pointer;
  color: ${({ theme: { colors } }) => (colors.grayColors.gray)};
`;

export const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SideMenuLogo = styled.img`
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  width: 168px;
  height: 50px;
`;

/* eslint-disable arrow-body-style */
/* eslint-disable import/no-named-as-default */
import api from './base.service';

export const getSettings = (payload) => {
    const params = {
        ...payload.pagination,
        ...payload.filters,
        sort: payload?.sort?.sort ? payload.sort.sort : 'keySetting,asc'
        };
    return api().get('/api/settings', { params });
};

export const editSettings = (obj, id) => {
    return api().patch(`/api/settings/${id}`, {
        valueSetting: obj.valueSetting,
    });
};

export const getSettingsById = (key) => {
    return api().get(`/api/settings/${key}`);
};

export const getSettingsByGroup = (prefix) => {
    return api().get(`/api/settings/group/${prefix}`);
};

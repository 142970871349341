/* eslint-disable quote-props */
/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import { useState, useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { QuestionOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Tooltip } from 'antd';
import { NotificationWrapper, NotificationHeader, NotificationContent, NotificationButton, NotificationFooter, StyledTrashCan, NotificationPanel, NotificationButtonWrapper, CreatedTime } from './AllNotificationsView.style';
import { Panel } from '../../../core/components/panel/Panel';
import trashCan from '../../../assets/images/icons/icon_trash-can.svg';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import { archiveNotification, archiveAllNotifications } from '../../../services/notifications.service';
import { useNotifications } from '../notificationsPanel/hooks/notifications';
import { useAuthStateValue } from '../../../context/AuthContext';

const PAGE_KEY = 'allNotificationsView';

export const AllNotificationsView = ({ seeAllNotifications, setSeeAllNotifications }) => {
  const { t } = useTranslation();

  const { loggedUser, setLoggedUser } = useAuthStateValue();

  const { fetchNotifications, deleteNotification, onNotificationRemove, onAllNotificationsRemove, loading, dataSource } = useNotifications();

  useEffect(() => {
    const userId = loggedUser?.id;
    fetchNotifications(userId);
  }, []);
  const notificationsTitle = t('allNotificationsView.title');
  const notificationsSubtitle = '(' + dataSource?.length + ')';
  const trashButton = t('allNotificationsView.trash');
  const backButton = t('allNotificationsView.back');

  const themeContext = useContext(ThemeContext);

  const callBackFunction = () => {
    setSeeAllNotifications(!seeAllNotifications);
  }

  return (
    <Panel
      title={notificationsTitle}
      subTitle={notificationsSubtitle}
      hasBackgroundColor={false}
      arrowSignVisible={false}
      notificationsPanel
      actionBoxVisible
      childrenActionBox={(
        <NotificationButtonWrapper>
          <NotificationButton onClick={callBackFunction}>
            &lt;&nbsp;
            {backButton}
          </NotificationButton>
          <NotificationButton onClick={onAllNotificationsRemove}>
            <StyledTrashCan src={trashCan} />
            {trashButton}
          </NotificationButton>
        </NotificationButtonWrapper>
      )}
    >

      <NotificationPanel>
        {dataSource?.map((item, index) => {
          const colorFunction = {
            "HIGH": themeContext.colors.mainColors.red,
            "MEDIUM": themeContext.colors.mainColors.orange,
            "LOW": themeContext.colors.mainColors.green
          }

          const dateAndTime = (moment(item.created).format('DD.MM.YYYY HH:mm:ss'));
          // const date = (moment(item.created).format('DD.MM.YYYY'));
          // const dateAndTime = date + ' &nbsp' + item.created.slice(11, 19);
          const content = item?.content;
          const stripHtmlTags = (html) => {
            return html.replace(/<\/?[^>]+(>|$)/g, "");
          };

          return (
            <NotificationWrapper key={index}>
              <NotificationHeader style={{ backgroundColor: colorFunction[item.priority] }} />
              <Tooltip title={stripHtmlTags(content)} placement="top">
                <NotificationContent dangerouslySetInnerHTML={{ __html: item?.content }} />
              </Tooltip>
              <NotificationFooter>
                <CreatedTime dangerouslySetInnerHTML={{ __html: dateAndTime }} />
                <StyledTrashCan
                  src={trashCan}
                  onClick={() => {
                    onNotificationRemove(dataSource[index])
                  }}
                />
              </NotificationFooter>
            </NotificationWrapper>
          )
        })}
      </NotificationPanel>
    </Panel>
  )
}

import { useState } from 'react';
import { getWeeklySchedule } from '../../../../services/weeklySchedule.service';

export const useWeeklySchedule = () => {
    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState();

    const fetchWeeklySchedule = async (userId, date, numberOfDays) => {
        try {
            setLoading(true);
            const response = await getWeeklySchedule(userId, date, numberOfDays);
            setDataSource(response.data);
        } catch (e) {
            setDataSource(null);
        } finally {
            setLoading(false);
        }
    }

    return {
        fetchWeeklySchedule,
        loading,
        dataSource
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { BasicHeader, BasicHeaderRightSide, Title } from './Header.style';

const Header = ({ title, items, buttons, children, filter }) => {
  const array = !!(children && children.length);
  return (
    <BasicHeader>
      <Title>
        {title}
        {filter}
      </Title>
      <BasicHeaderRightSide>
        {items}
        {buttons
          && buttons.map(({ text, ...rest }, index) => (
            <Button key={index} {...rest}>
              {text}
            </Button>
              ))}
        {array
          ? children.filter((child) => child.props.id !== 'filter')
          : children?.props?.id === 'filter'
          ? null
          : children}
      </BasicHeaderRightSide>
    </BasicHeader>
  )
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.element,
  buttons: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]),
  noPadding: PropTypes.bool
};

export default Header;

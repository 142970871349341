import styled from 'styled-components';

export const ObligationCardWrapper = styled.div`
  position: absolute;
  left: ${(props) => ((props?.timeFromInteger - props?.shiftTimeFrom - props?.leftModified + props?.positionModified) * (props?.hoursCellWidth - 1.2))}px;
  right: ${(props) => (props?.shiftTimeTo - props?.timeToInteger - props?.rightModified - props?.positionModified) * (props?.hoursCellWidth - 1.2)}px;
  width: auto;
  height: 100%;
  max-width: 100%;
  opacity: ${(props) => (props?.isDragging ? '0.6' : '1')};
  background-color: ${(props) => (props?.sectorRoleShortName === 'EXE' ? '#78D59A' : (props?.sectorRoleShortName === 'PLC' ? '#cdb1ca' : 'gray'))};
  // background-color: ${(props) => (props?.color)};
  border-radius: 5px;
  border: 1px solid #C9C9C9;
  box-shadow: ${(props) => (props?.modified ? '0px 0px 2px 2px rgba(219,90,81,0.70)' : 'none')};
  -moz-box-shadow: ${(props) => (props?.modified ? '0px 0px 2px 2px rgba(219,90,81,0.70)' : 'none')};
  -webkit-box-shadow: ${(props) => (props?.modified ? '0px 0px 2px 2px rgba(219,90,81,0.70)' : 'none')};
  z-index: ${(props) => (props?.isDragging ? '999' : 'auto')};
  box-sizing: border-box;
  transition: 0.5s background-color;
  cursor: ${(props) => (props?.sliding ? 'w-resize' : (props?.isDragging ? 'grabbing' : (props?.editModeOn ? 'grab' : 'pointer')))};
  display: flex;
  flex-direction: column;
  justify-content: center;
  filter: ${(props) => (props?.sliding || props?.isDragging ? 'brightness(120%)' : '')};
  -webkit-filter: ${(props) => (props?.sliding || props?.isDragging ? 'brightness(110%)' : '')};
  -moz-filter: ${(props) => (props?.sliding || props?.isDragging ? 'brightness(110%)' : '')};
  &:hover {
    filter: ${(props) => (props?.editModeOn ? 'brightness(120%)' : '')};
    -webkit-filter: ${(props) => (props?.editModeOn ? 'brightness(110%)' : '')};;
    -moz-filter: ${(props) => (props?.editModeOn ? 'brightness(110%)' : '')};
  }
`;

export const LeftResizeBox = styled.div`
  height: 35px;
  width: 5px;
  position: absolute;
  left: 0;
  cursor: ${(props) => (props?.editModeOn ? 'w-resize' : 'pointer')};
  background-color: ${(props) => (props?.sliding ? 'rgba(0, 0, 0, 0.1)' : 'none')};
  &:hover {
    background-color: ${(props) => (props?.editModeOn ? 'rgba(0, 0, 0, 0.1)' : 'none')};
  }
`;

export const RightResizeBox = styled.div`
  height: 35px;
  width: 5px;
  position: absolute;
  right: 0;
  cursor: ${(props) => (props?.editModeOn ? 'w-resize' : 'pointer')};
  background-color: ${(props) => (props?.sliding ? 'rgba(0, 0, 0, 0.1)' : 'none')};
  &:hover {
    background-color: ${(props) => (props?.editModeOn ? 'rgba(0, 0, 0, 0.1)' : 'none')};
  }
`;

export const SectorTitleBox = styled.div`
  text-align: center;
  color: black;
  font-weight: 600;
  // font-size: ${(props) => (props?.length === 0.5 ? '8px' : (props?.length === 1 ? '10px' : '80%'))};
  font-size: ${(props) => {
    if (props?.length === 0.5 && props?.charLength < 5) {
      return '50%';
    }
    if (props?.length === 0.5 && props?.charLength > 4) {
      return '6px';
    }
    if (props?.length === 1 && props?.charLength < 5) {
      return '70%';
    }
    if (props?.length === 1 && props?.charLength === 5) {
      return '55%';
    }
    if (props?.length === 1 && props?.charLength === 6) {
      return '50%;'
    }
    if (props?.length === 1 && props?.charLength > 6) {
      return '45%';
    }
    return '80%';
  }};
  text-orientation: ${(props) => (props?.length === 0.5 ? 'upright' : 'mixed')};
  writing-mode: ${(props) => (props?.length === 0.5 ? 'vertical-rl' : 'horizontal-tb')};
  margin: 0 auto;
  letter-spacing: ${(props) => (props?.length === 0.5 ? '-2px' : 'auto')};
`;

export const RoleTypeBox = styled.div`
  text-align: center;
  color: #707070;
  font-size: ${(props) => (props?.halfHourLong ? '2px' : '10px')};
  line-height: 12px;
  display: ${(props) => (props?.length === 0.5 ? 'none' : 'initial')};
`;

export const DragOverlay = styled.div`
  width: calc(100% - 10px);
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  position: absolute;
  z-index: 998;
`;

export const WarningSymbol = styled.div`
  width: 14px;
  height: 14px;
  position: absolute;
  top: ${(props) => (props?.duration <= 1 ? '16px' : '5px')};
  right: ${(props) => (props?.duration <= 1 ? '4px' : '5px')};
  color: red;
  line-height: 10px;
  z-index: 999;
`;

export const StartTimeText = styled.div`
  position: absolute;
  height: 10px;
  font-size: ${(props) => (props?.length === 0.5 ? '8px' : '9px')};
  line-height: ${(props) => (props?.length === 0.5 ? '8px' : '9px')};;
  pointer-events: none;
  width: 35px;
  text-align: center;
  left: -12px;
  top: 13px;
  // background-color: #ffffc9;
  z-index: 1;
  transform: rotate(-90deg);
  // display: ${(props) => (props?.length === 0.5 ? 'none' : 'auto')};
`;

export const EndTimeText = styled.div`
  position: absolute;
  height: 10px;
  width: 35px;
  font-size: ${(props) => (props?.length === 0.5 ? '8px' : '9px')};
  line-height: ${(props) => (props?.length === 0.5 ? '8px' : '9px')};;
  pointer-events: none;
  // background-color: #ffd7c9;
  text-align: center;
  right: -13px;
  bottom: 13px;
  z-index: 3;
  transform: rotate(90deg);
  // display: ${(props) => (props?.length === 0.5 ? 'none' : 'auto')};
`;

import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useEffect, useState, useRef } from 'react';
import { Spin } from 'antd';
import { Page } from '../Page';
import PlanThumbnail from './planThumbnail/PlanThumbnail';
import { AnnualPlansFlexbox, NoAnnualPlansMessage, NoAnnualPlansWrapper } from './AnnualPlan.style';
import { FilterSelectInput, HeaderActionButtonTwo } from '../Page.styled';
import refreshIcon from '../../assets/images/icons/rotate-right-solid.svg';
import { useAuthStateValue } from '../../context/AuthContext';
import { useAnnualPlans } from './hooks/annualPlan';
import faceFrown from '../../assets/images/icons/face-meh-solid.svg';
import CreateAnnualPlanModal from './modals/CreateAnnualPlanModal';
import { useMonthlyPlans } from '../monthlyPlan/hooks/monthlyPlan';

const PAGE_KEY = 'annualPlan';

const AnnualPlan = () => {
  const { loggedUser } = useAuthStateValue();
  const { t } = useTranslation();
  const [location, setLocation] = useState(loggedUser?.locationsData[0]?.value);
  const [permissionStatus, setPermissionStatus] = useState(null);
  const [addNewPlanStatus, setAddNewPlanStatus] = useState(false);
  const createAnnualPlanModalRef = useRef();

  const { fetchAnnualPlans, dataSourceAnnualPlan, loading } = useAnnualPlans();
  const { fetchMonthlyPlans } = useMonthlyPlans();

  let threeYearsExist = false;
  let currentYearExists = false;
  let nextYearExists = false;
  let yearAfterNextExists = false;
  const currentYear = new Date().getFullYear();
  dataSourceAnnualPlan?.map((annualPlan) => {
    if (annualPlan?.year === currentYear) {
      currentYearExists = true;
    }
    if (annualPlan?.year === currentYear + 1) {
      nextYearExists = true;
    }
    if (annualPlan?.year === currentYear + 2) {
      yearAfterNextExists = true;
    }
  })

  threeYearsExist = currentYearExists && nextYearExists && yearAfterNextExists;
  const existingYears = [];
  if (!currentYearExists) {
    existingYears.push({
      key: currentYear,
      value: currentYear,
      label: currentYear
  });
  }
  if (!nextYearExists) {
    existingYears.push({
      key: currentYear + 1,
      value: currentYear + 1,
      label: currentYear + 1
  });
  }
  if (!yearAfterNextExists) {
    existingYears.push({
      key: currentYear + 2,
      value: currentYear + 2,
      label: currentYear + 2
  });
  }

  const refresh = () => {
    fetchAnnualPlans(location);
  }

  useEffect(() => {
    fetchAnnualPlans(location);
  }, [location]);

  const locationFunction = (value) => {
    setLocation(value);
  }

  let emptyAnnualPlans = false;
  if (dataSourceAnnualPlan?.length === 0) {
    emptyAnnualPlans = true;
  }
  const noAnnualPlans = t('annualPlan.noAnnualPlansMessage');
  const matchForSelectedLocation = loggedUser?.locationsData?.find((item) => item.identificator === location);

  useEffect(() => {
    setPermissionStatus(loggedUser?.localPermissions[matchForSelectedLocation?.id]?.annualPlan);
    if (permissionStatus === 'APPROVE_AND_ACTIVATE') {
      setAddNewPlanStatus(true);
    } else if (permissionStatus === 'DISPLAY') {
      setAddNewPlanStatus(false);
    } else if (permissionStatus === 'GENERATE_AND_MODIFY') {
      setAddNewPlanStatus(true);
    } else if (permissionStatus === 'NONE') {
      setAddNewPlanStatus(false);
    }
  }, [location, permissionStatus]);

  const showCreateAnnualPlanDialog = () => {
    createAnnualPlanModalRef.current.showDialog();
  };
  return (
    <Page
      title={t('annualPlan.title')}
      pageKey={PAGE_KEY}
      headerVisible
      headerActions={(
        <>
          {addNewPlanStatus
          && (
          <HeaderActionButtonTwo onClick={showCreateAnnualPlanDialog} disabled={threeYearsExist} style={{ width: '250px' }}>
            +
            {' ' + t('annualPlan.createAnnualPlan')}
          </HeaderActionButtonTwo>
          )}
          <HeaderActionButtonTwo onClick={refresh} style={{ width: '130px' }}>
            {t('annualPlan.refresh')}
            <ReactSVG src={refreshIcon} style={{ marginLeft: '10px', marginTop: '3px', color: '#585858' }} />
          </HeaderActionButtonTwo>
        </>
      )}
      filter={(
        <FilterSelectInput
          defaultValue={loggedUser?.locationsData[0]?.name}
          options={loggedUser?.locationsData}
          onChange={locationFunction}
        />
        )}
      content={(!loading) ? (
        <>
          {(!emptyAnnualPlans)
          ? (
            <AnnualPlansFlexbox>
              {dataSourceAnnualPlan?.map((annualPlan, index) => {
            return (
              <PlanThumbnail
                key={index}
                year={annualPlan.year}
                versions={annualPlan.versions}
                location={location}
                fetchAnnualPlans={fetchAnnualPlans}
                fetchMonthlyPlans={fetchMonthlyPlans}
              />
            );
          })}
            </AnnualPlansFlexbox>
          ) : (
            <NoAnnualPlansWrapper>
              <ReactSVG src={faceFrown} style={{ color: 'gray' }} />
              <NoAnnualPlansMessage>{noAnnualPlans}</NoAnnualPlansMessage>
            </NoAnnualPlansWrapper>
          )}
          <CreateAnnualPlanModal
            ref={createAnnualPlanModalRef}
            fetchAnnualPlans={fetchAnnualPlans}
            existingYears={existingYears}
          />
        </>
      ) : (
        <Spin spinning={loading} />)}
    />
  );
}

export default AnnualPlan;

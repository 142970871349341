import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  //border: 1px solid red;
  align-items: flex-start;
  padding-right: 5px;
  box-sizing: border-box;
  cursor: pointer;
`;

export const SheetHeadingContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #efefef;
  width: 100%;
  padding-bottom: 5px;
  display: flex;
  // border: 1px solid blue;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  z-index: 2;
  box-sizing: border-box;
`;

export const SheetBodyContainer = styled.div`
  width: 100%;
  display: flex;
  // border: 1px solid blue;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  box-sizing: border-box;
`;

export const SheetRow = styled.div`
  width: 100%;
  display: flex;
  height: 40px;
  justify-content: space-between;
  box-sizing: border-box;
  gap: 5px;
  padding: 0 !important;
`;

export const DayTitleCell = styled.div`
  background-color: #5F5F5F;
  width: 160px;
  color: white;
  border-radius: 5px;
  border: 1px solid #C5C5C5;
  font-weight: 600;
  text-align: center;
  line-height: 35px;
`;

export const VersionCell = styled.div`
  // background-color: #5f5f5f;
  width: 160px;
  color: #262626;
  border-radius: 5px;
  // border: 1px solid #C5C5C5;
  font-weight: 500;
  text-align: center;
  line-height: 35px;
  z-index: 3;
`;

export const EmployeeNameCell = styled.div`
  font-family: MontserratWeb-SemiBold;
  width: 160px;
  text-wrap: no-wrap;
  line-height: 40px;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px;
  background-color: ${(props) => (props?.isShiftManager ? '#F6C8BD' : 'none')};
  border: 1px solid #C5C5C5;
  padding: 0 5px;
  text-align: left !important;
  font-weight: 500;
  position: relative;
  color: #393939;
  &.selected {
    color: white;
    background: transparent !important;
    border: 0px !important;
  }
  transition: all 0.5s;
`;

export const EmptyNameCell = styled.div`
  font-family: MontserratWeb-SemiBold;
  width: 160px;
  text-wrap: no-wrap;
  line-height: 40px;
  color: white;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 5px;
  background-color: #DFDFDF;
  text-align: left !important;
  transition: all 0.5s;
`;

export const EmployeeTimelineCell = styled.div`
  width: calc(100% - 160px);
  height: 100%;
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
`;

export const EmptyTimelineCell = styled.div`
  width: calc(100% - 160px);
  height: 100%;
  border-radius: 5px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #C5C5C5;
  overflow: hidden;
`;

export const HourCaptionCell = styled.div`
  height: 40px;
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  width: auto;
  flex: 1;
  text-align: center;
  line-height: 40px;
  overflow-x: visible;
`

export const HalfHourCell = styled.div`
  background-color: #0F8F3E;
  height: 35px;
  border: 1px solid #C5C5C5;
  border-radius: 5px;
  width: auto;
  flex: 1;
  text-align: center;
  line-height: 35px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;

export const HalfHour = styled.div`
  width: 50%;
  height: 100%;
  &:first-of-type {
    border-right: 1px solid #C5C5C5;
  }
`;

export const TwoHoursCellContainer = styled.div`
  width: calc(100% - 160px);
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0px;
`;

export const TwoHoursCell = styled.div`
  height: 35px;
  width: 100%;
`;

export const TwoHoursCellTickContainer = styled.div`
  width: 50%;
  height: 50%;
  // border-right: 1px solid #474648;
  // border-left: 1px solid #474648;
  margin-top: 20%;
  box-sizing: border-box;
`;

export const GenerateBox = styled.div`
  width: 400px;
  height: 250px;
  background-color: #efefef;
  border-radius: 5%;
  position: absolute;
  padding-top: 50px;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #8f8f8f;
  text-align: center;
  line-height: 40px;
`;

export const HourLabelsCell = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
`;

export const HourTicksCell = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  flex-basis: 0;
  align-items: flex-end;
  & : first-child {
    border-left: 1px solid black;
  }
`;

export const HourTick = styled.div`
  border-right: 1px solid black;
  flex: 1 1 0;
  height: 10px;
`;

export const HalfHourTick = styled.div`
  border-right: 1px solid black;
  border-left: none !important;
  margin-top: 5px;
  height: 5px;
  width: 50%;
`;

export const HourCaptionWrapper = styled.div`
  height: 20px;
  width: 100%;
`;

export const HourCaption = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: 50%;
  line-height: 30px;
`;

export const FirstHourCaption = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  margin-left: -50%;
  line-height: 30px;
  position:absolute;
`;

export const LocationMark = styled.div`
  width: 16px;
  height: 16px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  right: 5px;
  top: 12px;
  font-size: 8px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
`;

export const VerticalBarsContainer = styled.div`
    width: calc(100% - 290px);
    height: calc(100% - 200px);
    display: flex;
    position: fixed;
    left: 185px;

    & :nth-child(odd) {
      background: linear-gradient(90deg, #E1E1E1 50%, #D9D9D9 50%);
    }
    & :nth-child(even) {
      background: linear-gradient(90deg, #D9D9D9 50%, #E1E1E1 50%);
    }
`;

export const VerticalLinesContainer = styled.div`
  width: calc(100% - 290px);
  height: calc(100% - 200px);
  display: flex;
  position: fixed;
  left: 185px;
  z-index: 0;
  pointer-events: none
`;

export const VerticalBar = styled.div`
  width: ${(props) => (props?.hourCellWidth)}px;
  height: 100%;
  left: ${(props) => (props?.value * props?.hourCellWidth)}px;
  top: 0;
  z-index: 1;
  // border-left: 1px solid white;
`;

export const VerticalLine = styled.div`
  width: ${(props) => (props?.hourCellWidth)}px;
  height: 100%;
  left: ${(props) => (props?.value * props?.hourCellWidth)}px;
  top: 0;
  border-right: ${(props) => (props?.width)}px solid white;
  z-index: 1;
`;

export const HalfHourVerticalLine = styled.div`
  width: 50%;
  height: 100%;
  border-right: 1px solid white;
`;

/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line no-unused-vars
import { Redirect, useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useMonthlyPlans } from './hooks/monthlyPlan';
import { Page } from '../Page';
import PlanThumbnail from './planThumbnail/PlanThumbnail';
import { MonthlyPlansFlexbox, NoMonthlyPlansWrapper, NoMonthlyPlansMessage, Text, MonthlyPlanTitle } from './MonthlyPlan.style';
import { FilterSelectInput, FilterSelectInputTwo, HeaderActionButtonTwo } from '../Page.styled';
import refreshIcon from '../../assets/images/icons/rotate-right-solid.svg';
import { useAuthStateValue } from '../../context/AuthContext';
import { useAnnualPlans } from '../annualPlan/hooks/annualPlan';
import faceFrown from '../../assets/images/icons/face-meh-solid.svg';

const PAGE_KEY = 'monthlyPlan';

 export const MonthlyPlan = () => {
    const { loggedUser } = useAuthStateValue();
    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();
    const { atcu, year, version } = useParams();

    const { fetchMonthlyPlans, dataSourceMonthly, loadingMonthlyPlans, fetchScheduleAll, dataScheduleAll } = useMonthlyPlans();
    const { fetchAnnualPlans, dataSourceAnnualPlan, dataSourceAnnualPlanChange, loading } = useAnnualPlans({ year });

    let paramsAreValid;
    let locationExists = null;
    let yearExists = null;
    let versionExists = null;
    const history = useHistory();

    if (atcu) {
      for (let i = 0; i < loggedUser?.locationsData?.length; i += 1) {
        if (loggedUser?.locationsData[i]?.value === atcu) {
            locationExists = true;
            break;
          } else {
            locationExists = false;
          }
        }

        for (let i = 0; i < dataSourceAnnualPlan?.length; i += 1) {
          if (dataSourceAnnualPlan[i]?.year === window.parseInt(year)) {
            yearExists = true;
            break;
          } else {
            yearExists = false;
          }
        }
        for (let i = 0; i < dataSourceAnnualPlanChange?.length; i += 1) {
          if (dataSourceAnnualPlanChange[i]?.version === window.parseInt(version)) {
            versionExists = true;
              break;
            } else {
              versionExists = false;
            }
          }

        if (atcu === undefined || year === undefined || version === undefined) {
          paramsAreValid = null;
        } else {
          paramsAreValid = locationExists && yearExists && versionExists;
        }
      }

  if (paramsAreValid === false) {
    // history.push('/404');
  }

  let planVersion;
  dataSourceAnnualPlanChange?.map((annualPlan) => {
    if (annualPlan?.year === year) {
      planVersion = 'all';
    }
  });

  const optionsArray = [];
  dataSourceAnnualPlan?.forEach((item) => {
    optionsArray.push({
      key: item.year,
      value: item.year,
      label: item.year
    })
  });

  useEffect(() => {
    if (atcu) fetchAnnualPlans(atcu);
  }, [atcu]);

  useEffect(() => {
    if (atcu && year) {
      fetchMonthlyPlans(atcu, year, 'all')
    }
  }, [year]);

  useEffect(() => {
    // fetchScheduleAll(location, selectedPlanYear);
    if (!atcu && !year && !version) {
      history.push('/monthlyPlan/ATCU1/' + currentYear + '/all');
    }
    fetchMonthlyPlans(atcu, year, version);
  }, [atcu, year, version]);

  const locationChangeHandler = (value) => {
    history.push('/monthlyPlan/' + value + '/' + currentYear + '/all');
  }

    const yearChangeHandler = (value) => {
      history.push('/monthlyPlan/' + atcu + '/' + value + '/all');
    }

    const versionChangeHandler = (value) => {
      history.push('/monthlyPlan/' + atcu + '/' + year + '/' + value);
    };

    const refresh = () => {
      fetchAnnualPlans(atcu);
      fetchMonthlyPlans(atcu, year, version);
    }

    let emptyMonthlyPlans = false;
    if (dataSourceMonthly === null) {
      emptyMonthlyPlans = true;
    }

    const noMonthlyPlans = t('monthlyPlan.noMonthlyPlansMessage');

    let defaultLocation;
    if (atcu != null) {
      defaultLocation = atcu;
    } else {
      defaultLocation = loggedUser?.locationsData[0]?.name;
    }

    const versionTitle = version === 'all' ? 'Sve' : 'Verzija ' + version;
    const userId = loggedUser?.id;
      const matchForSelectedLocation = loggedUser?.locationsData?.find((item) => item.identificator === atcu);
      const matchForAllowSelect = loggedUser?.localPermissions[matchForSelectedLocation?.key]?.monthlyPlan;
      let allowSelect = true;
      if (matchForAllowSelect === 'DISPLAY') {
        allowSelect = false;
      } else if (matchForAllowSelect === 'NONE') {
        allowSelect = false;
      }
      const title = (<MonthlyPlanTitle>{t('monthlyPlan.title')}</MonthlyPlanTitle>)
    return (
      <Page
        title={title}
        pageKey={PAGE_KEY}
        headerVisible
        headerActions={(
          <>
            <Text>{t('monthlyPlan.annualPlanText')}</Text>
            <FilterSelectInputTwo
              value={year}
              options={optionsArray}
              onChange={yearChangeHandler}
              style={{ marginRight: '-3px' }}
            />
            {allowSelect
            && (
              <FilterSelectInputTwo
                value={versionTitle}
                onChange={versionChangeHandler}
                options={dataSourceAnnualPlanChange}
                style={{ marginRight: '-3px' }}
              />
              )}
            <HeaderActionButtonTwo onClick={refresh}>
              {t('monthlyPlan.refresh')}
              <ReactSVG src={refreshIcon} style={{ marginLeft: '10px', marginTop: '3px', color: '#585858' }} />
            </HeaderActionButtonTwo>

          </>
        )}
        filter={(
          <FilterSelectInput
            defaultValue={defaultLocation}
            options={loggedUser?.locationsData}
            onChange={locationChangeHandler}
          />
          )}
        content={(!loadingMonthlyPlans && !loading) ? (
          (!emptyMonthlyPlans)
          ? (
            <MonthlyPlansFlexbox>
              {dataSourceMonthly?.map((monthlyPlan, index) => {
              return (
                <PlanThumbnail
                  monthlyPlanRefreshHandler={refresh}
                  annualPlanVersion={version}
                  userId={userId}
                  atcu={atcu}
                  key={index}
                  month={monthlyPlan?.month}
                  year={monthlyPlan?.year}
                  versions={monthlyPlan?.versions}
                  version={monthlyPlan?.versions?.[0]?.version}
                />
              );
            })}
            </MonthlyPlansFlexbox>
          ) : (
            <NoMonthlyPlansWrapper>
              <ReactSVG src={faceFrown} style={{ color: 'gray' }} />
              <NoMonthlyPlansMessage>{noMonthlyPlans}</NoMonthlyPlansMessage>
            </NoMonthlyPlansWrapper>
        )
        ) : (
          <Spin spinning={loading} />)}
      />
    );
}

import React from 'react';
import { object, string } from 'yup';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import withModal from '../../../core/components/withModals';
import AForm from '../../../core/components/form/AForm';
import { editSettings } from '../../../services/settings.service';
import FormModalStyled from '../../../core/components/modals/FormModalStyled';
import ATextArea from '../../../core/components/inputs/textArea/ATextArea';
import StatusCode from '../../../services/statusCode';

const SettingsFormModal = (props) => {
  const { modalData, modalProps, closeDialog, dataSource, setDataSource } = props;

  const { t } = useTranslation();

  const formFields = [
    {
      name: t('settings.value'),
      id: 'valueSetting',
      render: <ATextArea rows={4} />,
    },
  ];

  const min = 1;
  const max = 256;

  const validationSchema = object().shape({
    valueSetting: string()
      .required(t('errors.required'))
      .min(min, `${t('errors.minLength')} ${min}`)
      .max(max, `${t('errors.maxLength')} ${max}`),
  });

  const modalEditHandler = async (obj, setLoading) => {
    try {
      setLoading(true);
      const response = await editSettings(obj, modalData.id);
      if (response.status === StatusCode.OK) {
        props.onSave();
        props.closeDialog();
        message.success(t('messages.editOk'));
      } else {
        message.error(t('messages.addNotOk'));
      }
    } catch (err) {
      setLoading(false);
      if (err.status === StatusCode.CONFLICT) {
        const translatedErrors = err.data.map((error) => t(`companies.${error}`, error));
        if (translatedErrors) {
          message.error(
            t('errors.uniqueFields', { fields: translatedErrors?.join(',') })
          );
        } else {
          message.error(t('errors.operationFailed'));
        }
      } else message.error(t('errors.operationFailed'));
    }
  };

  return (
    <FormModalStyled
      width={520}
      footer={[]}
      centered
      maskClosable={false}
      {...modalProps}
      title={t('settings.edit')}
    >
      <AForm
        fields={formFields}
        okButtonText={t('buttons.confirm')}
        modalData={modalData}
        validationSchema={validationSchema}
        dataSource={dataSource}
        setDataSource={setDataSource}
        closeDialog={closeDialog}
        modalEditHandler={modalEditHandler}
      />
    </FormModalStyled>
  );
};

SettingsFormModal.propTypes = {
  modalData: PropTypes.any,
  modalProps: PropTypes.any,
  closeDialog: PropTypes.func,
  dataSource: PropTypes.array,
  setDataSource: PropTypes.func,
  customAddHandler: PropTypes.func,
  customEditHandler: PropTypes.func,
};

export default withModal()(SettingsFormModal);

import styled from 'styled-components';
import { Input } from 'antd';

const ATextInput = styled(Input)`
  width: 300px;
  border-radius: 5px;
  height: auto;
`;

export default ATextInput;

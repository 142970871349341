/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { Tooltip, message } from 'antd';
import { InfoCircleOutlined, QuestionOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { getPagination, resetPagination } from '../../util/pagination';
import Datatable from '../../core/components/datatable/Datatable';
import useUsersColumns from './hooks/user.column';
import { Page } from '../Page';
import useUser from './hooks/user';
import UserFormModal from './modals/UserFormModal';
import UserPermissionsModal from './modals/UserPermissionsModal';
import { useAuthStateValue } from '../../context/AuthContext';
import { HeaderActionButtonTwo } from '../Page.styled';
import refreshIcon from '../../assets/images/icons/rotate-right-solid.svg';
import confirmModal from '../../core/components/modals/ConfirmModal';
import ChangeAtcuModal from './modals/ChangeAtcuModal';
import { formater } from '../../util/util';

const PAGE_KEY = 'users';

const Users = () => {
  const userFormModalRef = useRef();
  const userPermissionsModalRef = useRef();
  const changeAtcuModalRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false);
  const [permissionsModalData, setPermissionsModalData] = useState(null);
  const [userFormModalData, setUserFormModalData] = useState(null);
  const [syncDataResponse, setSyncDataResponse] = useState(null);
  const { t } = useTranslation();
  const { loggedUser } = useAuthStateValue();

  const pageTitle = t('main.users');

  const showData = (record) => {
    setUserFormModalData(record);
    setModalOpen((prev) => !prev);
  };

  const showPermissionsModal = (record) => {
    setPermissionsModalData(record);
    setPermissionsModalOpen(true);
  }

  const closePermissionsModal = () => {
    setPermissionsModalData(null);
    setPermissionsModalOpen(false);
  }

  const { loading, dataSource, totalElements, currentPage, fetchUsers, showDeleteUserModal, generatePassword, syncUsersHandler, syncData } = useUser();

  const { columns } = useUsersColumns(
      {
        pageKey: PAGE_KEY,
        userFormModalRef,
        showData,
        showDeleteUserModal,
        changeAtcuModalRef,
        generatePassword,
        showPermissionsModal,
        userAdministrationPermission: loggedUser.globalPermissions.settingUserAccountsDataAndStaffPermissions === 'USERS_ADMINISTRATION',
        permissionsAdministrationPermission: loggedUser.globalPermissions.settingUserAccountsDataAndStaffPermissions === 'USERS_ADMINISTRATION'
      }
  );
  const { formatDate } = formater();

  useEffect(() => {
    resetPagination(PAGE_KEY);
    const { page, pageSize } = getPagination(PAGE_KEY);
    fetchUsers({ pagination: { page, size: pageSize } });
    syncUsersHandler();
  }, []);
  const paginationHandler = (payload) => {
    fetchUsers({
      pagination: {
        page: payload.page,
        size: payload.pageSize
      },
      filters: payload.filters,
      sort: payload.sort
    });
  };
  const refresh = () => {
    resetPagination(PAGE_KEY);
    const { page, pageSize } = getPagination(PAGE_KEY);
    fetchUsers({ pagination: { page, size: pageSize } });
    syncUsersHandler();
  //  message.success(t('users.refreshSuccess'));
  }

  const syncUserHandler = () => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('users.synchronizatonTitle'),
      content: t('users.synchronizationQuestion'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: () => {
        syncUsersHandler();
        message.success(t('users.syncSuccess'));
      },
    });
  }
  const saveHandler = () => {
    resetPagination(PAGE_KEY);
    const { page, pageSize } = getPagination(PAGE_KEY);
    fetchUsers({ pagination: { page, size: pageSize } });
  }
  function addLeadingZero(n) {
    return n < 10 ? `0${n}` : n;
}
  const date = new Date(syncData?.updated);
  const day = date?.getDate();
  const month = date?.toLocaleString('sr-Latn-RS', { month: 'long' });
  const hours = date?.getHours();
  const minutes = date?.getMinutes();
  const seconds = date?.getSeconds();
  const time = addLeadingZero(hours) + ':' + addLeadingZero(minutes) + ':' + addLeadingZero(seconds);

  const capMonth = () => {
    return month?.charAt(0)?.toUpperCase() + month?.slice(1);
  }
  const capitalizeMonth = capMonth(month);
  const year = date?.getFullYear();
  const dateString = day + '. ' + capitalizeMonth + ' ' + year + '.';
  const syncDataRes = syncData?.response;

  useEffect(() => {
    if (syncDataRes) {
      const parsedData = JSON.parse(syncDataRes);
      setSyncDataResponse(parsedData);
    }
  }, [syncDataRes]);
  const infoData = (
    <InfoWrapper>
      <span style={{ textAlign: 'center' }}>{t('users.lastSync')}</span>
      <span>
        {t('users.sync')}
        {':    '}
        {syncData?.status === 'SUCCESS' ? 'Uspješno' : ''}
        {syncData?.status === 'ERROR' ? 'Greška' : ''}
        {syncData?.status === 'STARTED' ? 'U toku' : ''}
      </span>
      <span>
        {t('users.syncStartBy')}
        {':    '}
        {syncData?.userFirstName + ' ' + syncData?.userLastName}
      </span>
      <span>
        {t('users.syncDate')}
        {':    '}
        {dateString + '  ' + time}
      </span>
      <span style={{ borderTop: '1px solid white' }}>{t('users.result')}</span>
      <ul>
        <li>
          {' '}
          {t('users.newUsers')}
          {'    '}
          {syncDataResponse?.newUsersCount}

        </li>
        <li>
          {t('users.editedUsers')}
          {'    '}
          {syncDataResponse?.editUsersCount}
        </li>
        <li>
          {t('users.notEditedUsers')}
          {'    '}
          {syncDataResponse?.notEditUsersCount}
        </li>
        <li>
          {t('users.notFoundedUsers')}
          {'    '}
          {syncDataResponse?.notFoundUsersCount}
        </li>
      </ul>
    </InfoWrapper>
)
  return (
    <Page
      title={pageTitle}
      pageKey={PAGE_KEY}
      headerVisible
      headerActions={(
        <>
          <HeaderActionButtonTwo onClick={refresh} style={{ width: '130px' }}>
            {t('users.refresh')}
            <ReactSVG src={refreshIcon} style={{ marginLeft: '10px', marginTop: '3px', color: '#585858' }} />
          </HeaderActionButtonTwo>
          {/* <HeaderActionButtonTwo onClick={syncUserHandler} style={{ width: '130px' }}> */}
          {/*  {t('users.synchronize')} */}
          {/* </HeaderActionButtonTwo> */}
          <Tooltip placement="bottomRight" title={infoData} overlayStyle={{ maxWidth: '600px' }}>
            <InfoCircleOutlined style={{ fontSize: '24px', color: '#393939' }} />
          </Tooltip>
        </>
      )}
      content={(
        <>
          <Datatable
            dtKey={PAGE_KEY}
            uniqueColumn="id"
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            total={totalElements}
            currentPage={currentPage}
            onPagination={paginationHandler}
            serverSideFiltering
            serverSideSorting
            dataFetchingFunction={fetchUsers}
          />
          <UserFormModal
            ref={userFormModalRef}
            dataSource={userFormModalData}
          />
          <UserPermissionsModal
            ref={userPermissionsModalRef}
            open={permissionsModalOpen}
            onCancel={closePermissionsModal}
            dataSource={permissionsModalData}
          />
          <ChangeAtcuModal
            ref={changeAtcuModalRef}
            dataSource={dataSource}
            onSave={saveHandler}
          />
        </>
      )}
    />
  )
}

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  span {
    padding: 2px;
  }
`;

export default Users;

import { Select } from 'antd';
import styled from 'styled-components';

const SelectSearchInput = styled(Select)`
  &.ant-select {
    height: 32px;
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 15px;
    min-width: 150px;
    width: 100%;
    margin-left: 10px;

    span.ant-select-selection-placeholder {
      font-weight: 400;
    }

    .ant-select-clear {
      margin-right: 20px !important;
    }

    .ant-select-selection-item {
      margin-top: 0px;
      height: 26px;
      border-radius: 20px;
    }

    .ant-select-selector {
      height: 32px;
      border: none;
      outline: none;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 4px;

      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 20px;
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        font-size: 12px;
        width: 100%;
        border-radius: 4px;
      }
    }

    .ant-select-selection-item-content {
      padding-left: 5px;
      padding-right: 5px;
    }

    .ant-select-selection-item-remove {
      padding-top: 2px;
      flex: unset !important;

      span {
        display: flex;
        align-items: center;
      }
    }

    .ant-select-clear {
      margin-right: 8px;
      font-size: 14px;
    }
  }
`;

export default SelectSearchInput;

import styled from 'styled-components';
import { Select, Spin } from 'antd';

export const Wrapper = styled.div`
  display: flex;
`;

export const Container = styled.div`
  padding: 20px;
  width: 100%;
`;

export const RightSideMenu = styled.div`
  width: ${(props) => (props?.changelogexpanded ? 225 : 72)}px;
  height: 100vh;
  background-color: ${(props) => (props?.changelogexpanded ? '#dbdbdb' : '#0073AE')};
  /* background-color: #0073AE; */
  position: fixed;
  right: 0;
  z-index: 99;
  transition: 0.5s width;
`;

export const Header = styled.div`
  width: calc(100% - 72px);
  overflow: hidden;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  user-select: none;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BackButton = styled.div`
  width: 47px;
  height: 47px;
  border-radius: 100%;
  border: 1px solid #7D7D7D;
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
  color: #5F5F5F;
`;

export const BackButtonText = styled.div`
  font-size: 24px;
  padding: 5px 10px;
  color: #393939;
`;

export const TitleContainer = styled.div`
 display: flex;
 flex-direction: column;
`;

export const DayTitleBox = styled.div`
  background-color: white;
  border-radius: 20px;
  margin-top: 1px;
  width: 490px;
  height: 66px;
  text-align: center;
  font-size: 22px;
  overflow: hidden;
  padding: 15px;
  font-weight: 600;
  box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
  position: relative;
  & > a > div {
  text-decoration: none;
  color: #5F5F5F !important;
  }
`;

export const PreviousDayArrow = styled.div`
width: 40px;
height: 100%;
position: absolute;
display: flex;
flex-direction: column;
justify-content: space-around;
left: 0;
top: 0;
cursor: pointer;
overflow: hidden;
& > div > div > svg {
  height: 20px;
  overflow: hidden;
  margin-top: 10px;
}
&:hover {
  background-color: #eaeaea;
}
`;

export const NextDayArrow = styled.div`
width: 40px;
height: 100%;
position: absolute;
display: flex;
flex-direction: column;
justify-content: space-around;
right: 0;
top: 0;
cursor: pointer;
& > div > div > svg {
  height: 20px;
  overflow: hidden;
  margin-top: 10px;
}
&:hover {
  background-color: #eaeaea;
}
`;

export const PlanStatusBox = styled.div`
  width: 342px;
  height: 28px;
  border-radius: 0 0 10px 10px;
  margin: 0 auto;
  color: white;
  text-align: center;
  padding-top: 2px;
  font-weight: 500;
`;

export const StatusBoxSpinner = styled(Spin)`
  height: 10px;
  margin-top: -10px;
  .ant-spin-dot-item {
    background-color: white;
  }
`;

export const SheetContainer = styled.div`
  width: calc(100% - 72px);
  height: calc(100vh - 140px);
  padding-top: 15px;
`;

export const VersionDropdownContainer = styled.div`
 width: 300px;
 padding-top: 10px;
 display; flex;
 text-align: right;
`;

export const SideMenuContainer = styled.div`
  display: flex;
  width: 72px;
  position: fixed;
  bottom: 0px;
  padding: 15px 13px;
  flex-direction: column;
  gap: 10px;
`;

export const FilterSelectInputTwo = styled(Select)`
    &.ant-select {
    border-radius: 20px;
    width: 240px !important;
    overflow: hidden;
    opacity: ${(props) => (props?.changelogexpanded ? '0' : '1')};
    visibility: ${(props) => (props?.changelogexpanded ? 'hidden' : 'visible')};
    border: 1px solid gainsboro;
    width: 130px;
    box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
    .ant-select-selector {
      border: none;
      outline: none;
      height: 30px;
      padding-left: 25px;
      white-space: inherit;
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          outline: none;
          border: none;
        }
      }
      .ant-select-selection-item {
        display: flex;
        font-size: 16px;
        width: 100%;
      }
    }
  }
  margin-right: 15px;
  @media only screen and (max-width: 1700px) {
    margin-right: 12px;
  }
`;

export const MenuItem = styled.div`
  width: 45px;
  height: 40px;
  border-radius: 7px;
  border: 2px solid white;
  text-align: center;
  padding-top: 5px;
  color: white;
  cursor: pointer;
  & svg {
    width: 25px;
  }
  &:hover {
    background-color: white;
    color: #0073AE;
  }
  &.disabled {
    color: rgba(255, 255, 255, 0.5) !important;
    border-color: rgba(255, 255, 255, 0.5);
  }
  &.disabled:hover {
    color: rgba(0, 115, 174, 0.5) !important;
  }
`;

/* eslint-disable default-case */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-unused-vars */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionOutlined, SyncOutlined, CheckCircleOutlined, PlusCircleOutlined, ExclamationCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { ReactSVG } from 'react-svg';
import { Tooltip } from 'antd';
import { UserAtcuEnum } from '../enums/userAtcuEnum';
import { UserSpecialRoleEnum } from '../enums/userSpecialRoleEnum';
import confirmModal from '../../../core/components/modals/ConfirmModal';
import visitCardIcon from '../../../assets/images/icons/address-card-solid.svg';
import deleteIcon from '../../../assets/images/icons/icon_trash-can.svg';
import userPassGenerateIcon from '../../../assets/images/icons/user-lock.svg';
import userPermissionsIcon from '../../../assets/images/icons/user-shield-solid.svg';
import createdUser from '../../../assets/images/icons/user-gear-solid.svg';
import changeAtcu from '../../../assets/images/icons/plane-solid.svg';

const useUsersColumns = ({ pageKey, generatePassword, showDeleteUserModal, userFormModalRef, showPermissionsModal, showData, changeAtcuModalRef, userAdministrationPermission, permissionsAdministrationPermission }) => {
  const { t } = useTranslation();
  const syncStatus = (record) => {
    switch (record) {
      case 'NOT_EDIT_USER': // EXIST_USER
        return (
        <Tooltip placement="right" title={t('users.notEditUser')}>
            <CheckCircleOutlined style={{ fontSize: '22px', color: 'green', paddingLeft: '30%', paddingTop: '5%', height: '30px', width: '45px' }} />
        </Tooltip>
        );
      case 'NEW_USER':
        return (
          <Tooltip placement="right" title={t('users.newUser')}>
            <PlusCircleOutlined style={{ fontSize: '22px', color: 'blue', paddingLeft: '30%', paddingTop: '5%', height: '30px', width: '45px' }} />
          </Tooltip>
        );
      case 'NOT_FOUND_USER':
        return (
          <Tooltip placement="right" title={t('users.notFoundUser')}>
            <CloseCircleOutlined style={{ fontSize: '22px', color: 'red', paddingLeft: '30%', paddingTop: '5%', height: '30px', width: '45px' }} />
          </Tooltip>
        );
      case 'EDIT_USER':
        return (
          <Tooltip placement="right" title={t('users.editUser')}>
            <ExclamationCircleOutlined style={{ fontSize: '22px', color: 'orange', paddingLeft: '30%', paddingTop: '5%', height: '30px', width: '45px' }} />
          </Tooltip>
        );
      case 'MANUALLY_CREATED_USER': // CREATED_USER
        return (
          <Tooltip placement="right" title={t('users.manuallyCreatedUser')}>
            <ReactSVG src={createdUser} style={{ color: 'gray', paddingLeft: '30%', marginBottom: '-5px', marginRight: '27px', height: '30px', width: '45px' }} />
          </Tooltip>
        );
    }
  }
  const columns = useMemo(
    () => [
      // {
      //   title: <>
      //     <SyncOutlined style={{ fontSize: '22px', color: '#393939', margin: '0px 0px 0px 5px' }} />
      //          </>,
      //   dataIndex: 'syncStatus',
      //   name: t('users.syncStatus'),
      //   key: 'status',
      //   sort: 'string',
      //   width: 30,
      //   columnVisible: true,
      //   render: (record) => syncStatus(record)
      // },
      {
        title: t('users.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        type: 'string',
        filter: 'string',
        sort: 'string',
        width: 300,
        columnVisible: true
      },
      {
        title: t('users.fatherName'),
        dataIndex: 'fatherName',
        key: 'fatherName',
        type: 'string',
        filter: 'string',
        sort: 'string',
        width: 300,
        columnVisible: false
      },
      {
        title: t('users.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
        type: 'string',
        filter: 'string',
        sort: 'string',
        width: 300,
        columnVisible: true
      },
      // {
      //   title: t('users.JMB'),
      //   dataIndex: 'uniqueIdentificationNumber',
      //   key: 'uniqueIdentificationNumber',
      //   type: 'string',
      //   sort: 'string',
      //   filter: 'string',
      //   width: 300,
      //   columnVisible: false
      // },
      {
        title: t('users.email'),
        dataIndex: 'email',
        key: 'email',
        type: 'string',
        sort: 'string',
        filter: 'string',
        width: 450,
        columnVisible: true
      },

      {
        title: t('users.created'),
        dataIndex: 'created',
        key: 'created',
        type: 'dateRange',
        sort: 'dateRange',
        filter: 'dateRange',
        displayFormat: 'DD.MM.YYYY. HH:mm:ss',
        width: 300,
        columnVisible: false
      },
      {
        title: t('users.location'),
        dataIndex: 'scheduledAtcuIdentificator',
        key: 'scheduledAtcuIdentificator',
        type: 'string',
        filter: 'select',
        sort: 'select',
        width: 300,
        columnVisible: true,
        render: (record) => {
          return (record);
        },
        filterOptions: Object.keys(UserAtcuEnum).map((type) => {
          return {
            id: type,
            key: type,
            value: type,
            title: t('user.' + type)
          };
        })
      },
      {
        title: t('users.role'),
        dataIndex: 'specialRole',
        key: 'specialRoles',
        type: 'string',
        sort: 'select',
        filter: 'select',
        width: 200,
        columnVisible: true,
        render: (record) => {
          if (record === 'NONE') {
            return ''
          }
          if (record === 'SATCO') {
            return 'Satco'
          }
          if (record === 'SHIFT_MANAGER') {
            return 'Šef smjene'
          }
          return record;
        },
        filterOptions: Object.keys(UserSpecialRoleEnum).map((type) => {
          return {
            id: type,
            key: type,
            value: type,
            title: t('user.' + type)
          };
        })
      },
      {
        title: t('users.updated'),
        dataIndex: 'updated',
        key: 'updated',
        type: 'dateRange',
        sort: 'dateRange',
        filter: 'dateRange',
        displayFormat: 'DD.MM.YYYY. HH:mm:ss',
        width: 300,
        columnVisible: true
      },
      {
        dataIndex: 'action',
        key: 'action',
        width: 170,
        columnVisible: true,
        actionItems: [
          permissionsAdministrationPermission && {
            icon: userPermissionsIcon,
            onClick: (record) => {
              showPermissionsModal(record);
            },
            label: t('user.editPermissions'),
            tooltip: t('user.editPermissions')
          },
          permissionsAdministrationPermission && {
            icon: changeAtcu,
            onClick: (record) => {
              changeAtcuModalRef.current.showDialog(record);
            },
            tooltip: t('buttons.changeAtcuAndRole'),
          },
          {
            isActive: true,
            onClick: (record) => {
              userFormModalRef.current.showDialog(
                showData(record)
              );
            },
            tooltip: t('buttons.visit'),
            icon: visitCardIcon,
          },
          userAdministrationPermission && {
            isActive: true,
            onClick: (record) => {
              showDeleteUserModal(record);
            },
            textProps: {
              title: t('users.delete'),
              content: t('users.deleteQuestion'),
            },
            tooltip: t('buttons.delete'),
            icon: deleteIcon,
          },
          userAdministrationPermission && {
            icon: userPassGenerateIcon,
            onClick: (record) => {
              confirmModal({
                centered: true,
                icon: <QuestionOutlined />,
                title: t('users.generatePassword'),
                content: t('users.generateNewPasswordQuestion'),
                okText: t('buttons.yes'),
                cancelText: t('buttons.no'),
                onOk: () => generatePassword(record.id),
              });
            },
            label: t('user.generateNewPassword'),
            tooltip: t('buttons.generatePassword'),
          },
        ],
        editItem: {
          isActive: true,
          onClick: (record) => {
            userFormModalRef.current.showDialog({
              ...record
            });
          },
          tooltip: t('buttons.visit'),
        },
        deleteItem: {
          isActive: true,
          onConfirmDelete(record) {
            showDeleteUserModal(record);
          },
          textProps: {
            title: t('users.delete'),
            content: t('users.deleteQuestion'),
          },
          tooltip: t('buttons.delete'),
        },
        moreItems: [
          {
            icon: userPassGenerateIcon,
            onClick: (record) => {
              confirmModal({
                centered: true,
                icon: <QuestionOutlined />,
                title: t('users.generatePassword'),
                content: t('users.generateNewPasswordQuestion'),
                okText: t('buttons.yes'),
                cancelText: t('buttons.no'),
                onOk: () => generatePassword(record.id),
              });
            },
            label: t('user.generateNewPassword'),
            tooltip: t('buttons.generatePassword'),
          },
        ],
      },
    ],
    [t, pageKey]
  );

  return { columns };
};

export default useUsersColumns;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import {
  getScheduleContent,
  getScheduleVersions,
  getScheduleDemand,
  getPlanStatus
} from '../../../services/monthlyPlan.service';
import { sendShiftChangeRequest, getShiftChangeRequests, sendShiftRequestResponse, getAllShifChangeRequests } from '../../../services/shiftsChange.service';

const useShiftsChange = ({ atcu, year, month, userId }) => {
  const [loading, setLoading] = useState();
  const [version, setVersion] = useState();
  const [dataSource, setDataSource] = useState();
  const [demand, setDemand] = useState();
  const [requestList, setRequestList] = useState();
  const [optimalNumberOfWorkingHours, setOptimalNumberOfWorkingHours] = useState(null);
  const [approvedRequestsList, setApprovedRequestsList] = useState(null);
  const [error, setError] = useState();

  const { t } = useTranslation();

  const getAllRequests = async (userId) => {
    try {
      setLoading(true);
      const res = await getShiftChangeRequests(userId);
      setRequestList(res?.data);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setError(t('shiftsChange.fetchFailed'));
    } finally {
      setLoading(false);
    }
  };

  const getApprovedRequests = async () => {
    setLoading(true);
    try {
      const res = await getAllShifChangeRequests(version?.id);
      setApprovedRequestsList(res?.data);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const sendRequest = async (payload, setFormLoading) => {
    setLoading(true);
    try {
      await sendShiftChangeRequest(payload);
      message.success(t('shiftsChange.requestSuccess'));
      await getAllRequests(userId);
    } catch (e) {
      message.error(e?.data ? e?.data : t('shiftsChange.requestFail'));
      console.log(e);
      setFormLoading(false);
    } finally {
      setLoading(false);
      setFormLoading(false);
    }
  };

  const respondToRequest = async (requestId, userId, response) => {
    try {
      setLoading(true);
      await sendShiftRequestResponse({
        id: requestId,
        userId,
        replacementShiftResponse: response
      });
      if (response === 'APPROVED') {
        message.success(t('shiftsChange.approveSuccess'));
      } else if (response === 'REJECTED') {
        message.success(t('shiftsChange.rejectSuccess'));
      }
      getAllRequests(userId);
    } catch (e) {
      console.log(e);
      if (response === 'APPROVED') {
        message.error(t('shiftsChange.approveFail'));
      } else if (response === 'REJECTED') {
        message.error(t('shiftsChange.rejectFail'));
      }
    } finally {
      setLoading(false);
    }
  };

  const getCurrentMonthScheduleVersions = async () => {
    setLoading(true);
    try {
      const res = await getScheduleVersions(atcu, year, month);
      let latestVersion;
      res?.data?.[0]?.versions?.forEach((version) => {
        if (!latestVersion) {
          latestVersion = {
            version: version?.version,
            id: version?.id
          }
        } else if (version?.version < latestVersion) {
          latestVersion = {
            version: version?.version,
            id: version?.id
          }
        }
      });
      setVersion(latestVersion);
    } catch (e) {
      console.log(e);
      setLoading(false);
      setError(t('shiftsChange.fetchFailed'));
    }
  };

  const getCurrentMonthSchedule = async () => {
    setLoading(true);
    setDataSource(null);
    try {
      const res = await getScheduleContent(atcu, year, month, version?.version);
      setDataSource(res?.data);
    } catch (e) {
      console.log(e);
      setDataSource(null);
      setLoading(false);
      setError(t('shiftsChange.fetchFailed'));
    }
  };

  const getCurrentMonthDemand = async () => {
    setLoading(true);
    setDemand(null);
    try {
      const res = await getScheduleDemand(atcu, year, month, version?.version);
      setDemand(res?.data?.shifts);
    } catch (e) {
      console.log(e);
      setDemand(null);
      setLoading(false);
      setError(t('shiftsChange.fetchFailed'));
    }
  }

  const getScheduleStatus = async () => {
    setLoading(true);
    try {
      const res = await getPlanStatus(atcu, year, month, version?.version);
      setOptimalNumberOfWorkingHours(res?.data?.optimalNumberOfWorkingHours);
    } catch (e) {
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (version) {
      getCurrentMonthSchedule();
    }
  }, [version]);

  useEffect(() => {
    if (dataSource && version) {
      getCurrentMonthDemand();
      getAllRequests(userId);
      getApprovedRequests();
      getScheduleStatus();
    }
  }, [dataSource]);

  return {
    loading,
    dataSource,
    version,
    getCurrentMonthScheduleVersions,
    getCurrentMonthSchedule,
    optimalNumberOfWorkingHours,
    demand,
    sendRequest,
    requestList,
    approvedRequestsList,
    respondToRequest,
    error
  };
};

export default useShiftsChange;

/* eslint-disable arrow-body-style */
// eslint-disable-next-line import/no-named-as-default
import api from './base.service';
import { formater } from '../util/util';

export const getUsers = (payload) => {
  if (payload?.filters?.userGroup) {
    payload.filters.userGroupId = payload.filters.userGroup;
    payload.filters.userGroup = null;
  }
  if (payload?.filters?.createdFrom && payload?.filters?.createdTo) {
    payload.filters.createdFrom = formater().formatDate(payload.filters.createdFrom);
    payload.filters.createdTo = formater().formatDate(payload.filters.createdTo);
  }
  if (payload?.filters?.updatedFrom && payload?.filters?.updatedTo) {
    payload.filters.updatedFrom = formater().formatDate(payload.filters.updatedFrom);
    payload.filters.updatedTo = formater().formatDate(payload.filters.updatedTo);
  }
    const params = {
        ...payload.pagination,
        ...payload.filters,
        sort: payload?.sort?.sort ? payload.sort.sort : 'created,desc'
      };
    return api().get('/api/users', { params });
};

export const addUser = (user) => {
  return api().post('/api/users', user);
};

export const getUserById = (user, id) => {
  return api().get(`/api/users/${id}`, user);
 };

export const editUser = (user, id) => {
  return api().put(`/api/users/${id}`, user);
};

export const deleteUser = (id) => {
 return api().delete(`/api/users/${id}`);
};

export const changePassword = (obj, id) => {
   return api().patch(`/api/users/${id}/password`, obj);
};

export const generateNewPassword = (id) => {
   return api().patch(`/api/users/${id}/password-generate`);
};

export const getUsersLicences = (userId) => {
  return api().get(`/api/user-licenses/${userId}`)
};

export const changeScheduledAtcu = (id, newAtcu, newRole) => {
  const params = {
    newAtcu,
    newRole
  }
  return api().patch(`/api/users/${id}/change-scheduled-atcu?newAtcu=` + params?.newAtcu + `&newRole=${params.newRole}`);
};

export const syncUsers = () => {
  return api().get('/api/sync/last-sync-info');
};

export const LogoutFromAll = (id) => {
  return api().patch(`/api/users/${id}/invalidate-logins`);
}

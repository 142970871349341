import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import { QuestionOutlined } from '@ant-design/icons';
import {
  BackButton,
  BackButtonContainer,
  BackButtonText,
  CloseRequestListIcon,
  Container,
  CreateRequestInfoBox,
  Header,
  ListItemActionBox,
  ListItemActionButton,
  ListItemCommentContainer,
  ListItemControllerName,
  ListItemDate,
  ListItemShiftContainer,
  ListItemShiftName,
  ListItemShiftsContainer,
  ListSwapIcon,
  MenuItem,
  MonthTitleBox, NoRequestsLabel,
  PlanStatusBox,
  RequestListContainer,
  RequestListItem,
  RequestListItemCaret,
  RequestListItemsContainer,
  RequestListTitle,
  RequestListTitleContainer,
  RequestResponseBox,
  RequestStatusLabel, RequestTimestampsBox,
  RightSideMenu,
  SelectYourShiftText,
  SheetContainer,
  SideMenuContainer,
  StatusBoxSpinner,
  SwapIconContainer,
  TitleContainer,
  UserResponseLabel,
  Wrapper
} from './ShiftsChange.style';
import arrowBackIcon from '../../assets/images/icons/arrow-back.svg';
import BHANSALogo from '../../assets/images/bhansa-logo-notext-inverted.svg';
import useShiftsChange from './hooks/useShiftsChange';
import MonthlySheet from '../detailedMonthlySchedule/monthlySheet/MonthlySheet';
import { useAuthStateValue } from '../../context/AuthContext';
import createIcon from '../../assets/images/icons/plus-solid.svg';
import listIcon from '../../assets/images/icons/list-solid.svg';
import selectIcon from '../../assets/images/icons/square-check-regular.svg';
import undoIcon from '../../assets/images/icons/square-undo-icon.svg';
import sendIcon from '../../assets/images/icons/send-icon.svg';
import cancelIcon from '../../assets/images/icons/cancelIcon.svg';
import swapIcon from '../../assets/images/icons/awesome-exchange-alt.svg';
import ShiftChangeRequestModal from './modals/ShiftChangeRequestModal';
import confirmModal from '../../core/components/modals/ConfirmModal';
import closeIcon from '../../assets/images/icons/xmark-solid.svg';
import approveIcon from '../../assets/images/icons/Icon awesome-check-circle.svg';
import rejectIcon from '../../assets/images/icons/Icon material-cancel.svg';
import dropDownIcon from '../../assets/images/icons/caret-right.svg';

const ShiftsChange = () => {
  const { t } = useTranslation();
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const { loggedUser } = useAuthStateValue();
  const [shiftChangeMode, setShiftChangeMode] = useState(false);
  const [myShift, setMyShift] = useState();
  const [elseShift, setElseShift] = useState();
  const [selectedCell, setSelectedCell] = useState();
  const [createRequestModalOpen, setCreateRequestModalOpen] = useState(false);
  const [requestListExpanded, setRequestListExpanded] = useState(false);
  const [expandedRequestKeys, setExpandedRequestKeys] = useState([]);

  const locationIdentificator = loggedUser?.extendUser?.locationIdentificator;
  const locationId = loggedUser?.locationsData?.find((el) => el?.value === locationIdentificator)?.id;

  const {
    getCurrentMonthScheduleVersions,
    loading,
    dataSource,
    version,
    demand,
    sendRequest,
    requestList,
    approvedRequestsList,
    respondToRequest,
    error,
    optimalNumberOfWorkingHours
  } = useShiftsChange({ atcu: locationIdentificator, year, month, userId: loggedUser?.id });

  const cellSelectHandler = (payload) => {
    setSelectedCell(payload);
  };

  const submitRequestHandler = (payload, setLoading) => {
    setCreateRequestModalOpen(false);
    sendRequest(payload, setLoading);
    setShiftChangeMode(false);
    setMyShift(null);
    setElseShift(null);
  }

  const closeRequestModalHandler = () => {
    setCreateRequestModalOpen(false);
  };

  const approveRequestHandler = (request) => {
    confirmModal({
      centered: true,
      icon: <ReactSVG src={approveIcon} style={{ color: '#0F8F3E', float: 'left', marginRight: '10px' }} />,
      title: t('shiftsChange.approveRequest'),
      content: t('shiftsChange.approveRequestConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: () => {
        respondToRequest(request?.id, loggedUser?.id, 'APPROVED');
        getCurrentMonthScheduleVersions();
      }
    });
  };

  const rejectRequestHandler = (request) => {
    confirmModal({
      centered: true,
      icon: <ReactSVG src={rejectIcon} style={{ color: '#C44536', float: 'left', marginRight: '10px' }} />,
      title: t('shiftsChange.rejectRequest'),
      content: t('shiftsChange.rejectRequestConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: () => {
        respondToRequest(request?.id, loggedUser?.id, 'REJECTED');
        getCurrentMonthScheduleVersions();
      }
    });
  }

  useEffect(() => {
    getCurrentMonthScheduleVersions();
  }, []);

  const menuItems = [
    {
      key: 'create',
      icon: createIcon,
      title: t('shiftsChange.createNewRequest'),
      onClick: () => {
        setShiftChangeMode(true);
      },
      disabled: shiftChangeMode,
      visibleInModes: ['shiftsChange']
    },
    {
      key: 'send',
      icon: sendIcon,
      title: t('shiftsChange.sendRequest'),
      onClick: () => {
        setCreateRequestModalOpen(true);
      },
      disabled: !shiftChangeMode || (shiftChangeMode && (!myShift || !elseShift)),
      visibleInModes: ['shiftsChange']
    },
    {
      key: 'cancel',
      icon: cancelIcon,
      title: t('shiftsChange.cancel'),
      onClick: () => {
        confirmModal({
          centered: true,
          icon: <QuestionOutlined />,
          title: t('shiftsChange.areYouSure'),
          content: t('shiftsChange.requestCancel'),
          okText: t('buttons.yes'),
          cancelText: t('buttons.no'),
          onOk: () => {
            setShiftChangeMode(false);
            setMyShift(null);
            setElseShift(null);
          }
        })
      },
      disabled: !shiftChangeMode,
      visibleInModes: ['shiftsChange']
    },
    {
      title: t('shiftsChange.selectCell'),
      key: 'selectCell',
      icon: selectIcon,
      onClick: () => {
        if (shiftChangeMode) {
          if (!myShift && !elseShift) {
            setMyShift(selectedCell);
          } else if (myShift && !elseShift) {
            setElseShift(selectedCell);
          }
        }
      },
      disabled: !shiftChangeMode
          || (!myShift && !elseShift && (selectedCell?.controllerId !== parseInt(loggedUser?.id, 10) || (selectedCell?.controllerId === parseInt(loggedUser?.id, 10) && !selectedCell?.shiftId)))
          || ((myShift && !elseShift) && (selectedCell?.controllerId === parseInt(loggedUser?.id, 10) || !selectedCell?.shiftId))
          || (requestList?.find((el) => ((el?.day1 === selectedCell?.day && el?.userApplicant?.id === selectedCell?.controllerId) || (el?.day2 === selectedCell?.day && el?.userRecipientRequest?.id === selectedCell?.controllerId))))
          || (!!myShift && !!elseShift),
      visibleInModes: ['shiftsChange']
    },
    {
      title: t('shiftsChange.undoSelect'),
      key: 'undoSelect',
      icon: undoIcon,
      onClick: () => {
        if (myShift && elseShift) {
          setElseShift(null);
        } else if (myShift && !elseShift) {
          setMyShift(null);
        }
      },
      disabled: !shiftChangeMode || (shiftChangeMode && !myShift && !elseShift),
      visibleInModes: ['shiftsChange']
    },
    {
      key: 'requestList',
      icon: listIcon,
      title: t('shiftsChange.requestList'),
      onClick: () => {
        setRequestListExpanded(true);
      },
      disabled: false,
      visibleInModes: ['shiftsChange']
    }
  ];

  const renderMonthlySheetComponent = useCallback(() => {
    return (
      <MonthlySheet
        ATCU={locationIdentificator}
        dataSource={dataSource}
        mode="shiftsChange"
        version={version?.version}
        loading={loading}
        year={year}
        month={month}
        demand={demand}
        modifiedData={[]}
        cellSelectHandler={cellSelectHandler}
        myShift={myShift}
        elseShift={elseShift}
        createShiftChangeModeOn={shiftChangeMode}
        shiftChangeRequests={requestList}
        approvedRequestsList={approvedRequestsList}
        optimalNumberOfWorkingHours={optimalNumberOfWorkingHours}
      />
    );
  }, [dataSource, requestList, demand, version, year, month, myShift, elseShift, shiftChangeMode, loading]);

  return (
    <Wrapper>
      <Container>
        <Header>
          <BackButtonContainer>
            <Link to="/dashboard" style={{ textDecoration: 'none' }}>
              <BackButton><ReactSVG src={arrowBackIcon} /></BackButton>
            </Link>
            <BackButtonText>
              {t('main.shiftsChange')}
              {' /'}
              <b>
                {' '}
                {'ATCU ' + locationIdentificator.substring(4)}
              </b>
            </BackButtonText>
          </BackButtonContainer>
          <TitleContainer>
            <MonthTitleBox>
              {t('monthlyPlan.' + month)}
              {' '}
              {year}
            </MonthTitleBox>
            <PlanStatusBox style={{ backgroundColor: error ? '#C44536' : (shiftChangeMode ? '#C44536' : 'green') }}>
              {!!error && error}
              {!error && (loading ? <StatusBoxSpinner /> : (shiftChangeMode ? ((myShift && elseShift) ? t('shiftsChange.requestReady') : t('shiftsChange.newRequestCreation')) : t('shiftsChange.shiftsChange')))}
            </PlanStatusBox>
          </TitleContainer>
          <CreateRequestInfoBox isVisible={shiftChangeMode}>
            <SelectYourShiftText className={(!myShift && !elseShift) ? 'pulsatingText' : ''}>{myShift ? (myShift?.controllerFullName + ' (' + myShift?.shiftName + ') ' + new Date(year, month - 1, myShift?.day).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })) : t('shiftsChange.selectYourShift')}</SelectYourShiftText>
            <SwapIconContainer visible={!!myShift}><ReactSVG src={swapIcon} /></SwapIconContainer>
            <SelectYourShiftText className={(myShift && !elseShift) ? 'pulsatingText' : ''}>{myShift ? ((elseShift ? (elseShift?.controllerFullName + ' (' + elseShift?.shiftName + ') ' + new Date(year, month - 1, elseShift?.day).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })) : t('shiftsChange.selectShiftForChange'))) : ''}</SelectYourShiftText>
          </CreateRequestInfoBox>
        </Header>
        <SheetContainer>
          {renderMonthlySheetComponent()}
        </SheetContainer>
      </Container>
      <RightSideMenu requestListExpanded={requestListExpanded}>
        {!requestListExpanded
          && (
            <>
              <ReactSVG width={82} src={BHANSALogo} />
              <SideMenuContainer>
                {menuItems.map((menuItem, index) => {
                  return (
                    <Tooltip key={'menu-item-' + index} placement="left" title={menuItem.title}>
                      <MenuItem className={(menuItem?.disabled || !dataSource || loading) ? 'disabled' : ''} onClick={(menuItem?.disabled || !dataSource || loading) ? null : menuItem?.onClick} key={menuItem.key}>
                        <ReactSVG src={menuItem.icon} />
                      </MenuItem>
                    </Tooltip>
                  )
                })}
              </SideMenuContainer>
            </>
        )}
        {
          requestListExpanded && (
            <>
              <RequestListContainer>
                <RequestListTitleContainer>
                  <RequestListTitle>{t('shiftsChange.changeShiftsRequestList')}</RequestListTitle>
                  <CloseRequestListIcon onClick={() => setRequestListExpanded(false)} src={closeIcon} />
                </RequestListTitleContainer>
                <RequestListItemsContainer>
                  {
                    requestList?.length === 0 && <NoRequestsLabel>{t('shiftsChange.noRequests')}</NoRequestsLabel>
                  }
                  {requestList?.length > 0 && requestList?.map((request) => {
                    const expanded = expandedRequestKeys?.find((el) => el === request?.id);
                    return (
                      <RequestListItem key={request?.id} expanded={expanded}>
                        <RequestStatusLabel>
                          <RequestListItemCaret
                            expanded={expanded}
                            onClick={() => {
                              if (expanded) {
                                setExpandedRequestKeys((prevState) => {
                                  return prevState.filter((el) => el !== request?.id);
                                });
                              } else {
                                setExpandedRequestKeys((prevState) => {
                                  return [
                                    ...prevState,
                                    request?.id
                                  ];
                                });
                              }
                            }}
                            src={dropDownIcon}
                            beforeInjection={(svg) => {
                              svg.classList.add('svg-class-name')
                              svg.setAttribute('style', 'height: 20px;margin-left: 10px;margin-top: 5px;')
                            }}
                          />
                          {t('shiftsChange.requestNumber') + ': ' + request?.id}
                        </RequestStatusLabel>
                        <ListItemShiftsContainer>
                          <ListItemShiftContainer>
                            <ListItemControllerName>{request?.userApplicant?.firstName + ' ' + request?.userApplicant?.lastName}</ListItemControllerName>
                            <ListItemShiftName>{request?.shiftConfigName1}</ListItemShiftName>
                            <ListItemDate>{new Date(year, month - 1, request?.day1).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItemDate>
                          </ListItemShiftContainer>
                          <ListSwapIcon
                            src={swapIcon}
                            beforeInjection={(svg) => {
                              svg.classList.add('svg-class-name')
                              svg.setAttribute('style', 'width: 15px; height: 41px;')
                            }}
                          />
                          <ListItemShiftContainer>
                            <ListItemControllerName>
                              {request?.userRecipientRequest?.firstName + ' ' + request?.userRecipientRequest?.lastName}
                            </ListItemControllerName>
                            <ListItemShiftName>{request?.shiftConfigName2}</ListItemShiftName>
                            <ListItemDate>{new Date(year, month - 1, request?.day2).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })}</ListItemDate>
                          </ListItemShiftContainer>
                        </ListItemShiftsContainer>
                        <ListItemCommentContainer>
                          <p>
                            <b>{t('shiftsChange.explanation')}</b>
                            <br />
                            {request?.comment}
                          </p>
                        </ListItemCommentContainer>
                        <RequestTimestampsBox>
                          {t('shiftsChange.created') + ': '}
                          {new Date(request?.created).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                          <br />
                          {t('shiftsChange.updated') + ': '}
                          {new Date(request?.updated).toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}
                        </RequestTimestampsBox>
                        <RequestResponseBox>
                          <b>
                            {request?.userRecipientRequest?.firstName + ' ' + request?.userRecipientRequest?.lastName + ': '}
                          </b>
                          <br />
                          <UserResponseLabel status={request?.responseOfRecipientRequest}>{t('shiftsChangeResponse.' + request?.responseOfRecipientRequest)}</UserResponseLabel>
                          <br />
                          <b>{t('shiftsChange.scheduleCoordinator') + ': '}</b>
                          <br />
                          <UserResponseLabel status={request?.responseOfSchedulingCoordinator}>{t('shiftsChangeResponse.' + request?.responseOfSchedulingCoordinator)}</UserResponseLabel>
                        </RequestResponseBox>
                        {
                          ((loggedUser?.localPermissions?.[locationId]?.changeWorkShift === 'DISPLAY_AND_APPROVE_WORK_SHIFT_CHANGE' && request?.responseOfSchedulingCoordinator === 'NONE')
                              || (request?.userRecipientRequest?.id === parseInt(loggedUser?.id, 10) && request?.responseOfRecipientRequest === 'NONE'))
                            && (
                            <ListItemActionBox>
                              <Tooltip title={t('shiftsChange.approve')}>
                                <ListItemActionButton type="approve" onClick={() => approveRequestHandler(request)}>
                                  <ReactSVG
                                    src={approveIcon}
                                    beforeInjection={(svg) => {
                                      svg.classList.add('svg-class-name')
                                      svg.setAttribute('style', 'width: 20px;margin-top: 8px;')
                                    }}
                                  />
                                </ListItemActionButton>
                              </Tooltip>
                              <Tooltip title={t('shiftsChange.reject')}>
                                <ListItemActionButton type="reject" onClick={() => rejectRequestHandler(request)}>
                                  <ReactSVG
                                    src={rejectIcon}
                                    beforeInjection={(svg) => {
                                      svg.classList.add('svg-class-name')
                                      svg.setAttribute('style', 'width: 20px;margin-top: 8px;')
                                    }}
                                  />
                                </ListItemActionButton>
                              </Tooltip>
                            </ListItemActionBox>
                          )
                        }
                      </RequestListItem>
                    );
                  })}
                </RequestListItemsContainer>
              </RequestListContainer>
            </>
          )
        }
      </RightSideMenu>
      <ShiftChangeRequestModal
        open={createRequestModalOpen}
        onCancel={closeRequestModalHandler}
        closeDialog={closeRequestModalHandler}
        submitHandler={submitRequestHandler}
        myShift={myShift}
        elseShift={elseShift}
        month={month}
        year={year}
        version={version}
      />
    </Wrapper>
  );
};

export default ShiftsChange;

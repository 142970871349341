import { memo } from 'react';
import { Tooltip } from 'antd';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import SheetContextMenu from '../contextMenu/SheetContextMenu';
import { StyledSheetCell, ShiftChangeSymbol, StandbyIcon, ZSymbol, WarningSymbol, BadgeSymbol } from './Cell.style';
import swapIcon from '../../../../assets/images/icons/awesome-exchange-alt.svg';
import standbyIcon from '../../../../assets/images/icons/clock-regular.svg';
import zIcon from '../../../../assets/images/icons/z-solid.svg';
import warningIcon from '../../../../assets/images/icons/triangle-exclamation-solid.svg';
import userIcon from '../../../../assets/images/icons/user-alt.svg';
import { useAuthStateValue } from '../../../../context/AuthContext';
import { VALIDATION_RULES } from '../../../../util/monthlyScheduleValidationConfig';

const Cell = memo((props) => {
  const {
    date,
    // rowIndex,
    numberOfDays,
    sheetData,
    cellValidation,
    demand,
    cellValueInOriginalData,
    cellValueInModifiedData,
    previousMonthValidation,
    nextMonthValidation,
    latestChangeValue,
    controllerId,
    controllerFullName,
    isShiftManager,
    isSatco,
    multiselect,
    isSelected,
    isPartOfMultiselect,
    isDisabled,
    isMatterOfShiftChangeRequest,
    wasMatterOfShiftChangeRequest,
    loggedUserHasEditPermissions,
    modifiedData,
    setModifiedData,
    moveHighlightBars,
    mainTableRef,
    myShift,
    elseShift
  } = props;

  const { t } = useTranslation();
  const { loggedUser } = useAuthStateValue();
  const { location, mode } = useParams();

  const loggedUserLocation = loggedUser?.extendUser?.locationIdentificator;
  const ATCU = (mode === 'shiftsChange' || !mode) ? loggedUserLocation : location;

  const cellFinalValue = cellValueInModifiedData ? cellValueInModifiedData?.newState : (cellValueInOriginalData ? cellValueInOriginalData : null);
  const shiftType = cellFinalValue?.shiftId ? loggedUser?.shiftTypes?.[ATCU]?.find((el) => el?.id === cellFinalValue?.shiftId) : null;
  const absenceType = (cellFinalValue?.absenceId || cellFinalValue?.absenceId === 0) ? loggedUser?.absenceTypes?.find((el) => el?.id === cellFinalValue?.absenceId) : null;

  const cellWarnings = {
    exceededMaxWorkingDaysInARow:
      (cellFinalValue?.shiftId)
      && (cellValidation.workDaysStreak === VALIDATION_RULES.MAX_WORKING_DAYS_IN_CYCLE)
      && (cellValidation.restDaysStreak === 0),
    breakingTheRestAfterNightShift:
      (cellFinalValue?.shiftId)
      && ((cellValidation?.previousCellWasNightShift)
        || (cellValidation?.nightShiftRestDaysStreak > 0 && cellValidation?.nightShiftRestDaysStreak < VALIDATION_RULES.REST_DAYS_AFTER_NIGHT_SHIFT)
      ),
    breakingTheRestAfterNoonShift:
      shiftType?.name?.startsWith('J')
      && cellValidation.previousCellWasNoonShift,
    breakingTheNoAbsenceAfterNightShiftRule:
      (cellValidation?.previousCellWasNightShift)
      && (!!cellFinalValue?.absenceId)
      && (absenceType?.name !== 'BO')
      && (absenceType?.name !== 'B'),
    breakingTheRestAfterNightShiftFromPrevMonth: (!!cellFinalValue?.shiftId)
      && (
          (date.getDate() === 1 && (previousMonthValidation?.lastCellShiftName === 'N' || previousMonthValidation?.penultCellShiftName === 'N'))
          || (date.getDate() === 2 && (previousMonthValidation?.lastCellShiftName === 'N'))
          ),
    firstDaysInNextMonthShouldBeRestingAfterMaxWorkingDaysInARow:
      (!!cellFinalValue?.shiftId && date.getDate() === numberOfDays)
      && (cellValidation?.workDaysStreak === (VALIDATION_RULES.MAX_WORKING_DAYS_IN_CYCLE - 1))
      && (!!nextMonthValidation?.firstCellShiftId),
    isNightShiftAtMonthEndWithoutEnoughRestInFirstDaysOfNextMonth:
      (shiftType?.name === 'N')
      && ((date.getDate() === numberOfDays && (!!nextMonthValidation?.firstCellShiftId || !!nextMonthValidation?.secondCellShiftId))
        || ((date.getDate() === (numberOfDays - 1)) && (!!nextMonthValidation?.firstCellShiftId))
      ),
    isNoonShiftAtMonthEndWithoutEnoughRestInFirstDaysOfNextMonth:
      (date?.getDate() === numberOfDays && shiftType?.name?.startsWith('P'))
      && (nextMonthValidation?.firstCellShiftName?.startsWith('J'))
  };

  const renderWarnings = () => {
    const warnings = []
    Object.entries(cellWarnings).forEach((el) => {
      if (el[1] === true) {
        warnings.push(<li>{t('monthlyScheduleWarnings.' + el[0])}</li>);
      }
    });
    return warnings?.length > 0 ? (<ul>{warnings}</ul>) : null;
  }

  const cellHasWarnings = !!Object.entries(cellWarnings)?.find((el) => el[1] === true);

  return (
    <SheetContextMenu
      enabled={mode === 'edit'}
      date={date}
      key={'main-cell' + controllerFullName + date}
      sheetData={sheetData}
      isWeekend={date.getDay() === 6 || date.getDay() === 0}
      demand={demand}
      hasWarnings={cellHasWarnings}
      ATCU={ATCU}
      numberOfDays={numberOfDays}
      controllerId={controllerId}
      controllerFullName={controllerFullName}
      isPartOfMultiselect={isPartOfMultiselect}
      multiselect={multiselect}
      modifiedData={modifiedData}
      setModifiedData={setModifiedData}
      isShiftManager={isShiftManager}
      isSatco={isSatco}
      roleName={ATCU === 'ATCU1' ? 'SHIFT_MANAGER' : (ATCU === 'ATCU2' ? 'SATCO' : null)}
      mainTableRef={mainTableRef}
    >
      <Tooltip title={renderWarnings()}>
        <StyledSheetCell
          sheetMode={mode}
          isWeekend={date.getDay() === 6 || date.getDay() === 0}
          key={date.date}
          isDisabled={isDisabled}
          isSelected={isSelected}
          isModified={!!cellValueInModifiedData}
          alreadyMatterOfRequest={isMatterOfShiftChangeRequest}
          onContextMenu={isDisabled ? null : moveHighlightBars}
          className={'col-' + (date.getDate()) + ' ' + (cellHasWarnings ? 'hasWarning' : '') + (((controllerId === myShift?.controllerId && date.getDate() === myShift?.day) || (controllerId === elseShift?.controllerId && date.getDate() === elseShift?.day)) ? 'isSelectedForShiftSwap' : '')}
          modifiedCell={!!latestChangeValue}
          type={shiftType ? 'SHIFT' : (absenceType ? 'ABSENCE' : 'NONE')}
          shiftType={shiftType}
          absenceType={absenceType}
          hasRole={cellFinalValue?.isShiftManager || cellFinalValue?.isSatco}
          role={cellFinalValue?.isShiftManager ? 'SHIFT_MANAGER' : (cellFinalValue?.isSatco ? 'SATCO' : 'NONE')}
          onClick={isDisabled ? null : moveHighlightBars}
        >
          {isMatterOfShiftChangeRequest && <ShiftChangeSymbol><ReactSVG src={swapIcon} /></ShiftChangeSymbol>}
          {absenceType?.name === 'SB' && <StandbyIcon><ReactSVG src={standbyIcon} /></StandbyIcon>}
          {((wasMatterOfShiftChangeRequest || isMatterOfShiftChangeRequest) || (mode !== 'shiftsChange' && loggedUserHasEditPermissions && (wasMatterOfShiftChangeRequest || isMatterOfShiftChangeRequest))) && <ZSymbol pending={isMatterOfShiftChangeRequest}><ReactSVG src={zIcon} /></ZSymbol>}
          {shiftType?.name ? shiftType?.name : (absenceType ? absenceType.name : '')}
          {(absenceType?.name !== 'SB' && cellHasWarnings) && <WarningSymbol><ReactSVG src={warningIcon} /></WarningSymbol>}
          {(cellFinalValue?.isShiftManager || cellFinalValue?.isSatco) && (<BadgeSymbol><ReactSVG src={userIcon} /></BadgeSymbol>)}
        </StyledSheetCell>
      </Tooltip>
    </SheetContextMenu>
  );
}, (prevProps, newProps) => {
  return (
    (prevProps?.cellValueInModifiedData === newProps?.cellValueInModifiedData)
    && (prevProps?.isPartOfMultiselect === newProps?.isPartOfMultiselect)
    && (prevProps?.isSelected === newProps?.isSelected)
    && (JSON.stringify(prevProps?.cellValidation) === JSON.stringify(newProps?.cellValidation))
    && (JSON.stringify(prevProps?.previousMonthValidation) === JSON.stringify(newProps?.previousMonthValidation))
    && (prevProps?.myShift === newProps?.myShift)
    && (prevProps?.elseShift === newProps?.elseShift)
    && (prevProps?.isMatterOfShiftChangeRequest === newProps?.isMatterOfShiftChangeRequest)
    && (prevProps?.sheetMode === newProps?.sheetMode)
    && (prevProps?.isDisabled === newProps?.isDisabled)
  );
});

export default Cell;

import { Button } from 'antd';
import styled from 'styled-components';
import { ReactSVG } from 'react-svg';

export const SeeAllNotificationsButton = styled(Button)`
    background-color: ${({ theme: { colors } }) => (colors.mainColors.blue)};
    color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
    padding: 5% 40% 5% 40%;
    // float: right;
    width: 120px;
    display: flex;
    justify-content: center;
    span {
        align-self: center;
    }
`;

export const NewestNotificationsWrapper = styled.div`
    flex-wrap: wrap;
    flex-direction: row;
    display: inline-flex;
    justify-content: start;
    gap: 11px;
    align-content: baseline;
    width: 100%;
`;

export const NotificationWrapper = styled.div`
    background-color: ${({ theme: { colors } }) => (colors.mainColors.white)};
    color: ${({ theme: { colors } }) => (colors.mainColors.black)};
    font-size: 16px;
    border-radius: 10px;
    box-shadow: 10px 10px 5px rgb(0 0 0/ 0.04);
    display: flex;
    flex-direction: column;
    position: relative;
    //margin-right: 20px;
    // dodate izmjene za verziju 1 aplikacije
    // width: 24.5%;
    width: 24.49%;
    height: 20vh;
   // margin-bottom: 15px;
    /* @media only screen and (max-width: 1700px) {
        // width: 24.4%;
        width: 24.34%;
    } */
    @media only screen and (max-width: 1400px) {
       width: 24%;
       font-size: 14px;
    }
    @media only screen and (min-width: 1400px) and (max-width: 1550px) {
       width: 24.2%;
       font-size: 14px;
    }
    @media only screen and (min-width: 1550px) and (max-width: 1700px) {
       width: 24.34%;
    }
    @media only screen and (min-width: 1700px) and (max-width: 1900px) {
       width: 24.41%;
    }
    @media only screen and (min-width: 1900px) {
       width: 24.49%;
    }
    :last-child{
        margin-right: 0px !important;
    }
`;

export const NotificationHeader = styled.div`
    border-radius: 10px 10px 0px 0px;
    height: 20px;
`;

export const NotificationContent = styled.div`
    margin: 10px;
    overflow: hidden;
    height: 13vh;
`;

export const NotificationFooter = styled.div`
    position: absolute;
    bottom: 5px;
    right: 10px;
    display: flex;
    // justify-content: space-between; 
    gap: 10px;
`;

export const StyledTrashCan = styled(ReactSVG)`
    cursor: pointer;
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
`;

export const CreatedTime = styled.div`
    color: ${({ theme: { colors } }) => (colors.grayColors.mediumGray)};
    font-size: 14px;
`;

export const NoNotificationsMessage = styled.div`
    color: ${({ theme: { colors } }) => (colors.mainColors.black)};
    font-size: 20px;
    margin-top: 20px;
`;

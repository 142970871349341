import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from '../core/components/header/Header';
import { usePageContext } from './context/PageContextProvider';
import { PageContent } from './Page.styled'
/* eslint-disable react/jsx-props-no-spreading */
export const
  Page = ({ title, filter, content, pageKey, headerActions, headerVisible }) => {
    const { setKey } = usePageContext();

    useEffect(() => {
      setKey(pageKey);
    }, [pageKey, setKey]);

    return (
      <PageContent>
        {
          // eslint-disable-next-line operator-linebreak
          headerVisible &&
          // eslint-disable-next-line react/jsx-wrap-multilines
          <Header title={title} noPadding filter={filter}>
            {headerActions ? headerActions : <div />}
          </Header>
        }
        {content}
      </PageContent>
    )
  };

Page.propTypes = {
  headerActions: PropTypes.any,
  content: PropTypes.any,
  filter: PropTypes.any,
  title: PropTypes.string,
  pageKey: PropTypes.string,
  headerVisible: PropTypes.bool
};

/* eslint-disable*/
import axios from 'axios';
import StatusCode from './statusCode';
import config from '../util/config';
import {refresh} from "./auth.service";
import {ref} from "yup";

let refreshingUnderway = false;

const promises = [];

export const api = (dontUseAuthorizationHeader) => {
    const baseConfig = {
        baseURL: config.BASE_ENDPOINT_URL
    };
    const createInstance = (baseConfig) => {
        const instance = axios.create(baseConfig);
        instance.defaults.headers.common['Content-Type'] = 'application/json';
        instance.interceptors.request.use(
            async (config) => {
                if (dontUseAuthorizationHeader) return config;

                const token = await sessionStorage.getItem('accessToken');
                if (token && config.url !== '/login') {
                    config.headers = {
                        ...config.params,
                        Authorization: `Bearer ${token}`
                    };
                    config.headers['Content-Type'] = 'application/json';
                }
                return config;
            },
            (error) => {
                Promise.reject(error);
            }
        );
        return instance;
    };

    const instance = createInstance(baseConfig);

    const invalidateOnRefreshError = () =>{
        sessionStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        delete instance.defaults.headers.common.Authorization;
        window.location.href = '/';
    };


    instance.interceptors.response.use(
        async (response) => {
            return response;
        },
        async (error) => {

            if (error.config.url !== '/refresh') {

                const originalRequest = error.config;

                switch (error.response.status) {
                    case StatusCode.UNAUTHORIZED:
                        if(!originalRequest._retry){
                            originalRequest._retry = true;

                            if(!refreshingUnderway) {
                                refreshingUnderway = true;

                                const {
                                    data: { accessToken, refreshToken }
                                } = await refresh({
                                    accessToken: sessionStorage.getItem('accessToken'),
                                    refreshToken: localStorage.getItem('refreshToken')
                                });

                                sessionStorage.setItem('accessToken', accessToken);
                                localStorage.setItem('refreshToken', refreshToken);
                                instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                                originalRequest.headers['Authorization'] = `Bearer ${accessToken}`;
                                refreshingUnderway = false;

                                return instance(originalRequest);
                            }  else {
                                return new Promise((resolve, reject) => {
                                    setTimeout(() => resolve(instance(originalRequest)), 500);
                                });

                                //TODO try to figure out solution that is not time based
                            }

                        };break;
                    default:
                        return Promise.reject(error);
                }
            } else if (error.response.status === StatusCode.UNAUTHORIZED) {
                invalidateOnRefreshError();
            }
        }
    );

    return instance;
};

export default api;

/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionOutlined } from '@ant-design/icons';
import { getAllAnnualPlans, setApproveStatus, removeAnnualPlan } from '../../../services/annualPlan.service';
import { useAuthStateValue } from '../../../context/AuthContext';

export const useAnnualPlans = (props) => {
    const [loading, setLoading] = useState(false);
    const [dataSourceAnnualPlan, setDataSourceAnnualPlan] = useState();
    const [dataSourceAnnualPlanChange, setDataSourceAnnualPlanChange] = useState();
    const { loggedUser } = useAuthStateValue();
    const { t } = useTranslation();
    const userId = loggedUser?.id;

    const fetchAnnualPlans = async (atcu) => {
        try {
            setLoading(true);
            const response = await getAllAnnualPlans(atcu);
            setDataSourceAnnualPlan(response?.data);

            const versionArray = [];
            response?.data?.forEach((item) => {
                if (item?.year === parseInt(props?.year, 10)) {
                  item?.versions?.forEach((element) => {
                    versionArray?.push({
                      year: element.year,
                      id: element.id,
                      version: element.version,
                      status: element.status,
                      label: 'Verzija ' + element.version,
                      value: element.version,
                      title: element.version
                    })
                  })
                }
            });
            const newList = { value: 'all', label: 'Sve', version: 'all' }
            versionArray?.unshift(newList);
              setDataSourceAnnualPlanChange(versionArray);
            } catch (e) {
              setDataSourceAnnualPlan(null);
            } finally {
              setLoading(false);
            }
        }

    const patchApproveStatus = async (id, status) => {
        try {
            setLoading(true);
            const response = await setApproveStatus(id, status);
            setDataSourceAnnualPlan(response.data);
        } catch (e) {
            setDataSourceAnnualPlan(null);
        } finally {
            setLoading(false);
        }
    }

    return {
        fetchAnnualPlans,
        patchApproveStatus,
        loading,
        dataSourceAnnualPlan,
        dataSourceAnnualPlanChange
    }
}

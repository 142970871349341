/* eslint-disable */
import {v4 as uuidv4} from 'uuid';

export const addLoadingMessage = (stateCallback, type, message) => {
    stateCallback((messageArray) => [
            ...messageArray,
            {
                type,
                message
            }
        ]);
};

export const preventKeyboardScrolling = (event) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp' || event.key === 'PageDown' || event.key === 'PageUp') {
        event.preventDefault()
    }
};

export const hoursCountToTimeString = (count, subtractOneMinute) => {
    function addLeadingZero(n) {
        return n < 10 ? `0${n}` : n;
    }
    let hours = Math.floor(count);
    let minutes = 60 * (count - Math.floor(count));
    if (subtractOneMinute && minutes === 0) {
        hours = hours === 0 ? 23 : (hours - 1);
        minutes = 59;
    }
    else if (subtractOneMinute && minutes !== 0) minutes -= 1;
    if (hours >= 24) hours -= 24;

    return addLeadingZero(hours) + ':' + addLeadingZero(minutes) + ':' + '00'
};

export const timeStringToHoursCount = (timeString, addOneMinute) => {
    let hours = parseInt(timeString?.split(':')?.[0], 10);
    const minutes = addOneMinute ? ((parseInt(timeString?.split(':')?.[1]) + 1) / 60) : (parseInt(timeString?.split(':')?.[1]) / 60);
    return hours + minutes;
};

export const columnNumberToExcelColumnName = (columnNumber) => {
  if (columnNumber <= 26) return String.fromCharCode(64 + columnNumber);
  else {
    const numberOfPrefixChars = Math.floor(columnNumber / 26);
    let columnName = '';
    for (let i = 1; i <= numberOfPrefixChars; i += 1) {
      columnName = columnName + 'A';
    }
    return columnName + (String.fromCharCode(64 + (columnNumber - (numberOfPrefixChars * 26))))
  }
};

export const formater = () => {
    const formatNumber = (value) => {
        if (value !== null) {
            return parseFloat(value).toFixed(2);
        }
        return value;
    };

    const formatDate = (date) => {
        function addLeadingZero(n) {
            return n < 10 ? `0${n}` : n;
        }
        const dateDate = new Date(date);
        let dateString = '';
        dateString += `${addLeadingZero(dateDate.getFullYear())}-${addLeadingZero(
            dateDate.getMonth() + 1
        )}-${addLeadingZero(dateDate.getDate())}`;
        dateString += ` ${addLeadingZero(dateDate.getHours())}:${addLeadingZero(dateDate.getMinutes())}:${addLeadingZero(
            dateDate.getSeconds()
        )}`;
        return dateString;
    };

    return { formatNumber, formatDate };
};

export const checkAndSetDeviceId = async () => {
    let deviceId = await localStorage.getItem('device_id');
    if(!deviceId) await localStorage.setItem('device_id', uuidv4());
};

export const getBrowserName = () => {

    var isOpera = (!!window.opr && !!window.opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if(isOpera) return "Opera";

    // Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if(isFirefox) return "Firefox";

    // Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    if(isSafari) return "Safari";

    // Internet Explorer 6-11
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    if(isIE) return "InternetExplorer";

    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if(isEdge) return "Edge";
    // Chrome 1 - 79
    var isChrome = !!window.chrome;

    var isEdgeChromium = isChrome && (navigator.userAgent.indexOf("Edg") != -1);
    if(isEdgeChromium) return "Edge";
    else if(isChrome) return "Chrome";

    return "Unknown";
};

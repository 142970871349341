import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { ReactSVG } from 'react-svg';
import {
  MainWrapper,
  SheetHeadingContainer,
  SheetRow,
  EmployeeNameCell,
  SheetBodyContainer,
  VersionCell,
  EmployeeTimelineCell,
  TwoHoursCellContainer,
  EmptyNameCell,
  EmptyTimelineCell,
  GenerateBox,
  HourTick,
  HourTicksCell,
  HourLabelsCell,
  HourCaptionWrapper,
  LocationMark,
  HourCaption,
  FirstHourCaption,
  VerticalBar,
  VerticalBarsContainer,
  VerticalLinesContainer,
  VerticalLine,
  HalfHourTick
} from './DailySheet.style';
import ShiftContainer from './shiftContainer/ShiftContainer';
import notFoundIcon from '../../../assets/images/icons/file-not-found.svg';
import DailySheetTooltip from './tooltip/DailySheetTooltip';
import { timeStringToHoursCount } from '../../../util/util';

const DailySheet = (props) => {
  const [hourCellWidth, setHourCellWidth] = useState();
  const [timeLineGapWidth, setTimeLineGapWidth] = useState();
  const [windowSize, setWindowSize] = useState();
  const [numberOfEmptyRows, setNumberOfEmptyRows] = useState(0);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipContent, setTooltipContent] = useState(null);

  const { dataSource, version, modifiedData, setModifiedData, loading, editModeOn, latestVersionIsSelected, intervals, dayPeriod } = props;
  const { t } = useTranslation();

  const hourCellRef = useRef();
  const timeLineRef = useRef();
  const mainSheetRef = useRef();
  let numberOfRenderedRows = 0;

  let interval;
  let numberOfHours;
  if (dayPeriod === 'ALL') {
    numberOfHours = 24 - intervals?.morningEarliest + intervals?.nightLatest;
    interval = [intervals?.morningEarliest, intervals?.nightLatest];
  } else if (dayPeriod === 'MORNING') {
    numberOfHours = intervals?.morningLatest - intervals.morningEarliest;
    interval = [intervals?.morningEarliest, intervals.morningLatest];
  } else if (dayPeriod === 'NOON') {
    numberOfHours = intervals?.noonLatest - intervals?.noonEarliest;
    interval = [intervals?.noonEarliest, intervals?.noonLatest];
  } else if (dayPeriod === 'NIGHT') {
    numberOfHours = 24 - intervals?.nightEarliest + intervals?.nightLatest;
    interval = [intervals?.nightEarliest, intervals.nightLatest];
  }

  const renderSheetRows = () => {
    const rowsArray = [];
    dataSource?.controllers?.forEach((el, index) => {
      const shiftStartTime = timeStringToHoursCount(el?.shift?.timeFrom, false);
      if (
          (dayPeriod === 'ALL')
          || (dayPeriod === 'MORNING' && shiftStartTime < 12)
          || (dayPeriod === 'NOON' && (shiftStartTime >= 12 && shiftStartTime < 18))
          || (dayPeriod === 'NIGHT' && shiftStartTime >= 18)
      ) {
        rowsArray.push(
          <SheetRow>
            <EmployeeNameCell isShiftManager={el?.role !== 'NONE'}>
              {el?.firstName + ' ' + el?.lastName}
              <LocationMark>{el?.shift?.scheduledAtcu?.abbreviation}</LocationMark>
            </EmployeeNameCell>
            <EmployeeTimelineCell ref={index === 0 ? timeLineRef : null}>
              <ShiftContainer
                obligations={el?.obligations}
                controllerId={el?.id}
                timeFrom={el?.shift?.timeFrom}
                timeTo={el?.shift?.timeTo}
                hourCellWidth={hourCellWidth}
                timeLineWidth={timeLineGapWidth}
                modifiedData={modifiedData}
                setModifiedData={setModifiedData}
                setTooltipVisible={setTooltipVisible}
                setTooltipContent={setTooltipContent}
                atcu={el?.shift?.scheduledAtcu?.identificator}
                interval={interval}
                dataSource={dataSource}
                editModeOn={editModeOn}
              />
            </EmployeeTimelineCell>
          </SheetRow>
        );
      }
    });
    numberOfRenderedRows = rowsArray?.length;
    return (<>{rowsArray}</>);
  };

  const renderEmptySheetRows = () => {
    const emptyRowsArr = [];
    for (let i = 0; i < numberOfEmptyRows; i += 1) {
      emptyRowsArr.push(
        <SheetRow>
          <EmptyNameCell />
          <EmptyTimelineCell />
        </SheetRow>
      )
    }
    return emptyRowsArr;
  }

  useEffect(() => {
    setHourCellWidth(hourCellRef?.current?.offsetWidth + (dayPeriod === 'NOON' ? 0.3 : 0));
    setTimeLineGapWidth(timeLineRef?.current?.clientWidth / 21);
    const minimumNumberOfRowsToRender = Math.round(mainSheetRef?.current?.offsetHeight / 45) - 1;
    setNumberOfEmptyRows(minimumNumberOfRowsToRender - numberOfRenderedRows);
  }, [dataSource, windowSize, dayPeriod]);

  const renderVerticalBars = () => {
    const elementsArray = [];
    if (!interval) {
      for (let i = 0; i <= 23; i += 1) {
        elementsArray.push(<VerticalBar hourCellWidth={hourCellWidth} value={i} />)
      }
    } else if (interval) {
      for (let i = interval?.[0]; i <= ((interval?.[0] + numberOfHours) - 1); i += 1) {
        elementsArray.push(<VerticalBar hourCellWidth={hourCellWidth} value={i} />)
      }
    }
    return (
      <VerticalBarsContainer>
        {elementsArray}
      </VerticalBarsContainer>
    )
  }

  const renderVerticalLines = () => {
    const elementsArray = [];
    if (!interval) {
      for (let i = 0; i <= 23; i += 1) {
        elementsArray.push(<VerticalLine width={dayPeriod === 'ALL' ? 1 : 3} hourCellWidth={hourCellWidth} value={i} />);
      }
    } else if (interval) {
      for (let i = interval?.[0]; i <= ((interval?.[0] + numberOfHours) - 1); i += 1) {
        elementsArray.push(<VerticalLine width={dayPeriod === 'ALL' ? 1 : 3} hourCellWidth={hourCellWidth} value={i} />);
      }
    }
    return (
      <VerticalLinesContainer>
        {elementsArray}
      </VerticalLinesContainer>
    )
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', () => {
      setWindowSize(window.innerWidth);
    });
    document.addEventListener('contextmenu', (event) => event.preventDefault());
  }, []);

  const renderHeaderRows = () => {
    const ticksArray = [];
    const hourCaptionsArr = [];

    if (!interval) {
      for (let i = 0; i <= 23; i += 1) {
        ticksArray.push(<HourTick ref={i === 0 ? hourCellRef : null} />);
        hourCaptionsArr.push(
          <HourCaptionWrapper>
            {
                i === 0 && (
                  <FirstHourCaption>00</FirstHourCaption>
                )
              }
            <HourCaption>
              {i < 23 ? ((i + 1)?.toString()?.length === 1 ? ('0' + (i + 1).toString()) : (i + 1)) : '00'}
            </HourCaption>
          </HourCaptionWrapper>
        );
      }
    } else if (interval) {
      for (let i = interval?.[0]; i <= ((interval?.[0] + numberOfHours) - 1); i += 1) {
        ticksArray.push(<HourTick ref={i === interval?.[0] ? hourCellRef : null}><HalfHourTick /></HourTick>);
        hourCaptionsArr.push(
          <HourCaptionWrapper>
            {
              i === interval?.[0] && (
                <FirstHourCaption>{interval?.[0]?.toString()?.length === 1 ? ('0' + interval?.[0]?.toString()) : interval?.[0]}</FirstHourCaption>
              )
            }
            <HourCaption>
              {i < 23 ? ((i + 1)?.toString()?.length === 1 ? ('0' + (i + 1).toString()) : (i + 1)) : ((i - 23)?.toString()?.length === 1 ? ('0' + (i - 23)) : (i - 23))}
            </HourCaption>
          </HourCaptionWrapper>
        );
      }
    }

    return (
      <>
        <SheetRow>
          <VersionCell>
            ver.
            {version}
          </VersionCell>
          <TwoHoursCellContainer>
            <HourLabelsCell>
              {hourCaptionsArr}
            </HourLabelsCell>
            <HourTicksCell>
              {ticksArray}
            </HourTicksCell>
          </TwoHoursCellContainer>
        </SheetRow>
      </>
    );
  };

  return (
    <MainWrapper id="mainWrapper" ref={mainSheetRef}>
      <SheetHeadingContainer>
        {renderHeaderRows()}
      </SheetHeadingContainer>
      <SheetBodyContainer>
        {(!!dataSource && (!editModeOn || (editModeOn && latestVersionIsSelected))) && renderVerticalBars()}
        {(!!dataSource && (!editModeOn || (editModeOn && latestVersionIsSelected))) && renderSheetRows()}
        {renderEmptySheetRows()}
        {renderVerticalLines()}
      </SheetBodyContainer>
      {(!loading && (props?.notGenerated || props?.nonExistingVersion || (!latestVersionIsSelected && editModeOn))) && (
        <GenerateBox>
          <ReactSVG src={notFoundIcon} style={{ width: '100px', margin: '0 auto' }} />
          {props?.notGenerated ? t('dailySchedule.noVersionGenerated') : (props?.nonExistingVersion ? t('dailySchedule.versionDoesntExist') : t('dailySchedule.onlyLatestVersionIsEditable'))}
        </GenerateBox>
      )}
      <DailySheetTooltip visible={tooltipVisible} content={tooltipContent} sheetRef={mainSheetRef} />
    </MainWrapper>
  );
};

export default DailySheet;

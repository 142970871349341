import styled from 'styled-components';

export const BasicHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 0;
`;

export const BasicHeaderRightSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin: 0;
  padding: 0;
  gap: 12px;
`;

export const Title = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 24px;
  line-height: 1.35;
  user-select: none;
  gap: 39px;
  @media only screen and (max-width: 1500px) {
    gap: 10px;
  }
  /* @media only screen and (max-width: 1500px) {
    font-size: 22px;
    gap: 10px
  }
  @media only screen and (max-width: 1300px) {
    font-size: 16px;
    gap: 10px
  } */
`;

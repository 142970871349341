/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { QuestionOutlined } from '@ant-design/icons';
import { Page } from '../Page';
import DailyCard from './dailyPlanCard/DailyPlanCard';
import { DailyPlanWrapper, WeekDayWrapper, WeekDay, DailyCardWrapper } from './DailyPlan.style';
import { HeaderActionButtonTwo, FilterSelectInputSmall } from '../Page.styled';
import refreshIcon from '../../assets/images/icons/rotate-right-solid.svg';
import { useAuthStateValue } from '../../context/AuthContext';
import useDailyPlan from './hooks/useDailyPlan';
import confirmModal from '../../core/components/modals/ConfirmModal';

const PAGE_KEY = 'dailyPlan';

const mockedYears = [
    {
        key: 2023,
        value: 2023,
        label: '2023'
    },
    {
        key: 2024,
        value: 2024,
        label: '2024'
    }
];

const DailyPlan = () => {
  const [year, setYear] = useState(new Date()?.getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const { loggedUser } = useAuthStateValue();
  const { dataSource, loading, getAllSchedulesInMonth, generateSchedule } = useDailyPlan();

  const previousMonth = month === 1 ? 12 : month - 1;
  const nextMonth = month === 12 ? 1 : month + 1;
  const currentMonthNumberOfDays = new Date(year, month, 0).getDate();
  const previousMonthNumberOfDays = new Date(month === 1 ? year - 1 : year, month - 1, 0).getDate();
  const firstDayOfCurrentMonthIndex = new Date(year, month - 1, 1).getDay();
  const firstDayOfCurrentMonth = () => {
    if (firstDayOfCurrentMonthIndex === 0) return 7;
    return firstDayOfCurrentMonthIndex;
  };
  const numberOfDaysFromPreviousMonthToShow = firstDayOfCurrentMonth() - 1;
  const firstDayOfPreviousMonthToShow = previousMonthNumberOfDays - numberOfDaysFromPreviousMonthToShow - 1;

  const { t } = useTranslation();

  useEffect(() => {
    getAllSchedulesInMonth({ year, month });
  }, [year, month]);

  const refresh = () => {
    getAllSchedulesInMonth({ year, month })
  };

  const yearChangeHandler = (value) => {
    setYear(value);
  };

  const monthChangeHandler = (value) => {
    setMonth(value);
  }

  const generateDailyScheduleHandler = (year, month, day) => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('dailySchedule.generateDailySchedule'),
      content: t('dailySchedule.generateConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: async (closeFn) => {
        closeFn();
        await generateSchedule({ year, month, day, userId: loggedUser?.id })
        refresh();
      }
    });
  }

  const renderWeekDaysTitles = () => {
    const titlesArray = [];
    for (let i = 1; i <= 7; i += 1) {
      titlesArray.push(<WeekDay>{t('daysInWeek.' + i)}</WeekDay>)
    }
    return (<>{titlesArray}</>);
  };

  const generateMonthSelectValues = () => {
    const monthsArray = [];
    for (let i = 1; i <= 12; i += 1) {
      monthsArray.push({
        key: i,
        value: i,
        label: t('monthlyPlan.' + i)
      })
    }
    return monthsArray;
  }

  const renderDailyPlanCards = () => {
    const dailyPlanCardsArray = [];
    if (dataSource) {
      for (let i = 1; i <= 42; i += 1) {
        if (numberOfDaysFromPreviousMonthToShow !== 0 && i <= numberOfDaysFromPreviousMonthToShow) {
          dailyPlanCardsArray.push(
            <DailyCard
              key={i}
              day={firstDayOfPreviousMonthToShow + i + 1}
              month={previousMonth}
              currentMonth={month}
              year={year}
            />
          );
        } else if (i <= (currentMonthNumberOfDays + numberOfDaysFromPreviousMonthToShow)) {
          const matchingDataSourceRecord = dataSource?.find((el) => el?.year === year && el?.month === month && el?.day === i - numberOfDaysFromPreviousMonthToShow);
          dailyPlanCardsArray.push(
            <DailyCard
              key={i}
              day={i - numberOfDaysFromPreviousMonthToShow}
              versions={matchingDataSourceRecord?.versions}
              status="test"
              month={month}
              currentMonth={month}
              year={year}
              loading={loading}
              generateDailyScheduleHandler={generateDailyScheduleHandler}
            />
          );
        } else if (i > (currentMonthNumberOfDays + numberOfDaysFromPreviousMonthToShow)) {
          dailyPlanCardsArray.push(
            <DailyCard
              key={i}
              day={i - numberOfDaysFromPreviousMonthToShow - currentMonthNumberOfDays}
              month={nextMonth}
              currentMonth={month}
              year={year}
            />
          );
        }
      }
    }
    return (<>{dailyPlanCardsArray}</>);
  };

  return (
    <Page
      title={t('dailyPlan.title')}
      pageKey={PAGE_KEY}
      headerVisible
      headerActions={(
        <>
          <HeaderActionButtonTwo onClick={refresh} style={{ width: '130px' }}>
            {t('monthlyPlan.refresh')}
            <ReactSVG src={refreshIcon} style={{ marginLeft: '10px', marginTop: '3px', color: '#585858' }} />
          </HeaderActionButtonTwo>
          <FilterSelectInputSmall
            defaultValue={new Date()?.getFullYear()}
            options={mockedYears}
            onChange={yearChangeHandler}
          />
          <FilterSelectInputSmall
            defaultValue={new Date().getMonth() + 1}
            options={generateMonthSelectValues()}
            onChange={monthChangeHandler}
          />
        </>
    )}
      content={(
        <DailyPlanWrapper>
          <WeekDayWrapper>
            {renderWeekDaysTitles()}
          </WeekDayWrapper>
          <DailyCardWrapper>
            {renderDailyPlanCards()}
          </DailyCardWrapper>
        </DailyPlanWrapper>
      )}
    />
  );
}

export default DailyPlan;

import { useCallback, useEffect, useState, useRef } from 'react';
import { EmployeeNameCell, TableComponent } from '../MonthlySheet.style';
import {
  HorizontalHighLightBar,
  HoursNumberCell,
  SheetHeadingContainer,
  ShiftTypeCell,
  SidebarCell,
  SideBarFooter,
  SideBarFooterCell,
  SideBarFooterMask,
  SideExpandContainer,
  SiderHeaderBackgroundMask,
  TitleCell,
  VerticalHighLightBar,
  VerticalHighLightBarTip
} from './SideBar.style';
import { preventKeyboardScrolling } from '../../../../util/util';
import { useAuthStateValue } from '../../../../context/AuthContext';

const SheetSideBar = (props) => {
  const {
    expanded,
    dataSource,
    ATCU,
    modifiedData,
    siderSheetRef,
    scrollHandler,
    workingHoursNumber,
    kLWorkingHoursNumber,
    highlightTipHandler,
    headerMaskVisible,
    footerMaskVisible,
    demand,
    daysInMonthNumber
  } = props;

  const [highlightBarsPosition, setHighlightBarsPosition] = useState();
  const [selectedRow, setSelectedRow] = useState();
  // eslint-disable-next-line no-unused-vars
  const [selectedColumn, setSelectedColumn] = useState();

  const headerRef = useRef();
  const columnsNumber = headerRef.current?.children && (Array.from(headerRef.current?.children).length - 1);
  const cellWidth = headerRef.current?.children && headerRef.current?.children?.[2].offsetWidth;

  const { loggedUser } = useAuthStateValue();

  const renderHeaderData = useCallback(() => {
    const cellsArray = [];
    // TODO className={index === selectedColumn ? 'selected' : ''}
    loggedUser?.shiftTypes?.[ATCU]?.forEach((shiftType) => {
      if (demand?.find((shift) => shift?.id === shiftType?.id)) cellsArray.push(<ShiftTypeCell color={shiftType?.color} key={'sider-header-' + shiftType?.name}>{shiftType?.name}</ShiftTypeCell>)
    })
    loggedUser.absenceTypes.forEach((absence) => {
      if (absence.counts) cellsArray.push(<ShiftTypeCell color="#FCF1A3" key={'sider-header-' + absence?.name}>{absence?.name}</ShiftTypeCell>)
    });
    return (
      <tr ref={headerRef}>
        <HoursNumberCell>
          Br. sati KL:
          { kLWorkingHoursNumber }
        </HoursNumberCell>
        {cellsArray}
      </tr>
    )
  }, [demand]);

  useEffect(() => {
   setHighlightBarsPosition(null);
   setSelectedRow(null);
   setSelectedColumn(null);
   highlightTipHandler(null);
  }, [expanded]);

  const moveSidebarHighlightBars = (event) => {
    const column = Array.from(event.target.classList).find((className) => className.startsWith('sidebar-column')).substring(15);
    setSelectedColumn(parseInt(column, 10));
    setSelectedRow(parseInt(event.target?.parentNode?.id?.substring(10), 10));
    const top = event.target.getBoundingClientRect().top + siderSheetRef?.current?.scrollTop;
    const left = event.target.offsetLeft;
    setHighlightBarsPosition({
      top,
      left
    });
    highlightTipHandler(top);
  }

  const renderSideBarTableRows = () => {
    const rowsArray = [];
    dataSource?.controllers?.forEach((controller, controllerIndex) => {
      const employeeShifts = [];
      const employeeAbsences = [];
      const shiftCells = [];
      const absenceCells = [];

      for (let dayInMonth = 1; dayInMonth <= daysInMonthNumber; dayInMonth += 1) {
        const matchingModifiedDataEntry = modifiedData?.[modifiedData?.length - 1]?.find((entry) => entry?.day === dayInMonth && entry?.controllerId === controller.id)
        const matchingDataSourceEntry = controller.shifts.find((entry) => entry?.date === dayInMonth);
        if (matchingModifiedDataEntry) {
          if (employeeShifts?.[matchingModifiedDataEntry?.newState?.shiftId]) {
            employeeShifts[matchingModifiedDataEntry?.newState?.shiftId] += 1;
          } else {
            employeeShifts[matchingModifiedDataEntry?.newState?.shiftId] = 1;
          }
        } else if (employeeShifts?.[matchingDataSourceEntry?.shiftId]) {
            employeeShifts[matchingDataSourceEntry?.shiftId] += 1;
          } else {
            employeeShifts[matchingDataSourceEntry?.shiftId] = 1;
          }
      }

      for (let dayInMonth = 1; dayInMonth <= daysInMonthNumber; dayInMonth += 1) {
        const matchingModifiedDataEntry = modifiedData?.[modifiedData?.length - 1]?.find((entry) => entry?.day === dayInMonth && entry?.controllerId === controller.id)
        const matchingDataSourceEntry = controller.absences.find((entry) => entry?.date === dayInMonth);
        if (matchingModifiedDataEntry) {
          if (employeeAbsences?.[matchingModifiedDataEntry?.newState?.absenceId]) {
            employeeAbsences[matchingModifiedDataEntry?.newState?.absenceId] += 1;
          } else {
            employeeAbsences[matchingModifiedDataEntry?.newState?.absenceId] = 1;
          }
        } else if (employeeAbsences?.[matchingDataSourceEntry?.absenceId]) {
          employeeAbsences[matchingDataSourceEntry?.absenceId] += 1;
        } else {
          employeeAbsences[matchingDataSourceEntry?.absenceId] = 1;
        }
      }

      loggedUser?.shiftTypes?.[ATCU]?.forEach((shiftType, shiftIndex) => {
        if (demand?.find((demandShift) => demandShift?.id === shiftType?.id)) {
          shiftCells.push(<SidebarCell key={'shift-cell-' + shiftIndex} className={'sidebar-column-' + shiftIndex} onClick={moveSidebarHighlightBars}>{employeeShifts[shiftType.id] ? employeeShifts[shiftType.id] : '0'}</SidebarCell>)
        }
      });

      loggedUser?.absenceTypes?.forEach((absenceType, absenceIndex) => {
        if (absenceType.counts) {
          absenceCells.push(<SidebarCell key={'absence-cell-' + absenceIndex} className={'sidebar-column-' + (absenceIndex + shiftCells.length + 2)} onClick={moveSidebarHighlightBars}>{employeeAbsences[absenceType.id] ? employeeAbsences[absenceType.id] : '0'}</SidebarCell>)
        }
      });

      rowsArray.push(
        <tr key={'sider-row-' + controllerIndex} id={'sider-row-' + controllerIndex}>
          <EmployeeNameCell
            className={controllerIndex === selectedRow ? 'selected' : ''}
            key={'sidebar-emp-cell-' + controller?.firstName + '-' + controller?.lastName}
          >
            {controller.firstName + ' ' + controller.lastName}
          </EmployeeNameCell>
          {shiftCells}
          {absenceCells}
        </tr>
      )
    });

    return <tbody>{rowsArray}</tbody>;
  }

  return (
    <SideExpandContainer
      id="siderSheet"
      expanded={expanded ? 1 : 0}
      columnsNumber={columnsNumber}
      onScroll={scrollHandler}
      ref={siderSheetRef}
      tabIndex={0}
      onKeyDown={preventKeyboardScrolling}
    >
      <SiderHeaderBackgroundMask visible={headerMaskVisible} columnsNumber={columnsNumber} />
      <HorizontalHighLightBar style={{ display: highlightBarsPosition?.top ? 'inline-block' : 'none', top: highlightBarsPosition?.top ? (highlightBarsPosition?.top - 145) : null }} />
      <VerticalHighLightBar cellSize={cellWidth} height={siderSheetRef.current?.offsetHeight} position={highlightBarsPosition?.left} style={{ display: highlightBarsPosition?.left ? 'inline-block' : 'none' }} />
      <VerticalHighLightBarTip cellSize={cellWidth} style={{ display: highlightBarsPosition?.left ? 'inline-block' : 'none', marginLeft: highlightBarsPosition?.left, height: '45px', marginTop: '-40px' }} />
      <TableComponent style={{ marginTop: '-80px', width: columnsNumber ? (180 + (columnsNumber * 40)) : null }}>
        <SheetHeadingContainer>
          <tr>
            <HoursNumberCell>
              Br. sati:
              {workingHoursNumber()}
            </HoursNumberCell>
            <TitleCell colSpan={columnsNumber ? columnsNumber : 'NaN'}>BR. RADNIH DANA PO SMJENAMA/ODSUSTVIMA</TitleCell>
          </tr>
          {renderHeaderData()}
        </SheetHeadingContainer>
        {renderSideBarTableRows()}
        <SideBarFooter>
          <tr>
            <SideBarFooterCell colSpan={columnsNumber ? (columnsNumber + 1) : 'NaN'} />
          </tr>
        </SideBarFooter>
      </TableComponent>
      <SideBarFooterMask columnsNumber={columnsNumber ? columnsNumber : 'NaN'} visible={footerMaskVisible} expanded={expanded ? 1 : 0} />
    </SideExpandContainer>
  )
};

export default SheetSideBar;

import { Menu } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';
import {
    SelectedMenuItem,
    StyledMenu,
    StyledPopover,
    SettingsIcon,
    SettingsBox,
    StyledIcon,
    MenuItemBox
} from './HeaderDropdown.style';
import { useApplicationStateValue } from '../../context/ApplicationContext';

const { SubMenu } = Menu;

const menuHeaderDropdown = (menuItems, languages, selectedLanguage) => {
  const onClick = ({ key }) => {
    const item = menuItems.find((i) => i.key === key);
    if (item && item.callback) item.callback();
    if (languages.find((language) => language.key === key)) {
      const item1 = languages.find((i) => i.key === key);
      if (item1 && item1.callback) {
        item1.callback();
      }
    }
  };
  return (
    <StyledMenu onClick={onClick} selectedKeys={[]}>
      {menuItems?.map(({ key, icon, value, type }) => {
        if (type === 'select') {
          return (
            <SubMenu
              trigger="click"
              key={value}
              icon={icon}
              title={value}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {languages.map((language) => (language.key === selectedLanguage ? (
                  // eslint-disable-next-line max-len
                <SelectedMenuItem key={language.key}>{language.value}</SelectedMenuItem>
                  ) : (
                    <Menu.Item key={language.key}>{language.value}</Menu.Item>
                  )))}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={key}>
            <MenuItemBox>
              {/* <StyledIcon icon={icon} /> */}
              <StyledIcon>
                <ReactSVG src={icon} style={{ alignSelf: 'center', paddingTop: '20%' }} />
              </StyledIcon>
              <span>{value}</span>
            </MenuItemBox>
          </Menu.Item>
        );
        })}
    </StyledMenu>
  );
};

const HeaderDropdown = ({ user, menuItems, languages }) => {
    const { language } = useApplicationStateValue();

    if (!user) return null;

    return (
      <StyledPopover placement="topRight" content={menuHeaderDropdown(menuItems, languages, language)}>
        <SettingsBox>
          <SettingsIcon />
        </SettingsBox>
      </StyledPopover>
    );
};

HeaderDropdown.propTypes = {
    user: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string
    }).isRequired,
    menuItems: PropTypes.array,
    languages: PropTypes.array
};

export default HeaderDropdown;

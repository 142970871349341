import { useState, useEffect } from 'react';
import { getScheduleContent, getScheduleVersions } from '../../../services/monthlyPlan.service';

const usePreviousAndNextMonthValidation = (location, year, month) => {
  const [previousMonthVersion, setPreviousMonthVersion] = useState(null);
  const [nextMonthVersion, setNextMonthVersion] = useState(null);
  const [previousMonthData, setPreviousMonthData] = useState(null);
  const [nextMonthData, setNextMonthData] = useState(null);
  const [previousMonthValidationData, setPreviousMonthValidationData] = useState(null);
  const [nextMonthValidationData, setNextMonthValidationData] = useState(null);

  const getPreviousMonthVersions = async () => {
    try {
      const previousMonth = parseInt(month, 10) === 1 ? 12 : (parseInt(month, 10) - 1);
      const previousMonthYear = parseInt(month, 10) === 1 ? (parseInt(year, 10) - 1) : year;
      const res = await getScheduleVersions(location, previousMonthYear, previousMonth);
      let latestActiveVersion;
      res?.data?.[0]?.versions?.forEach((version) => {
        if (!latestActiveVersion) {
          latestActiveVersion = version?.version;
        } else if (version?.version > latestActiveVersion) latestActiveVersion = version?.version;
      });
      setPreviousMonthVersion(latestActiveVersion);
    } catch (e) {
      console.log(e);
    }
  };

  const getNextMonthVersions = async () => {
    try {
      const nextMonth = parseInt(month, 10) === 12 ? 1 : (parseInt(month, 10) + 1);
      const nextMonthYear = parseInt(month, 10) === 12 ? (parseInt(year, 10) + 1) : year;
      const res = await getScheduleVersions(location, nextMonthYear, nextMonth);
      let latestActiveVersion;
      res?.data?.[0]?.versions?.forEach((version) => {
        if (!latestActiveVersion) {
          latestActiveVersion = version?.version;
        } else if (version?.version > latestActiveVersion) latestActiveVersion = version?.version;
      });
      setNextMonthVersion(latestActiveVersion);
    } catch (e) {
      console.log(e);
    }
  };

  const getPreviousMonthData = async () => {
    try {
      const previousMonth = parseInt(month, 10) === 1 ? 12 : (parseInt(month, 10) - 1);
      const previousMonthYear = parseInt(month, 10) === 1 ? (parseInt(year, 10) - 1) : year;
      const res = await getScheduleContent(location, previousMonthYear, previousMonth, previousMonthVersion);
      setPreviousMonthData(res?.data);
    } catch (e) {
      console.log(e);
      setPreviousMonthData(null);
    }
  };

  const getNextMonthData = async () => {
  try {
    const nextMonth = parseInt(month, 10) === 12 ? 1 : (parseInt(month, 10) + 1);
    const nextMonthYear = parseInt(month, 10) === 12 ? (parseInt(year, 10) + 1) : year;
    const res = await getScheduleContent(location, nextMonthYear, nextMonth, nextMonthVersion);
    setNextMonthData(res?.data);
  } catch (e) {
    console.log(e);
    setNextMonthData(null);
  }
  };

  useEffect(() => {
    setPreviousMonthVersion(null);
    setNextMonthVersion(null);
    setPreviousMonthValidationData(null);
    setNextMonthValidationData(null);
  }, [year, month]);

  useEffect(() => {
    if (previousMonthVersion !== null && nextMonthVersion !== null) {
      getPreviousMonthData();
      getNextMonthData();
    }
  }, [previousMonthVersion, nextMonthVersion]);

  useEffect(() => {
    if (previousMonthData || nextMonthData) {
      const NUMBER_OF_DAYS_IN_PREVIOUS_MONTH = new Date(parseInt(previousMonthData?.year, 10), parseInt(previousMonthData?.month, 10), 0).getDate();
      // const NUMBER_OF_DAYS_IN_NEXT_MONTH = new Date(parseInt(nextMonthData?.year, 10), parseInt(nextMonthData?.month, 10), 0).getDate();
      const previousMonthValidationDataArr = [];
      const nextMonthValidationDataArr = [];
      // PREVIOUS MONTH
      previousMonthData?.controllers?.forEach((controller) => {
        const controllerValidationData = {
          controllerId: controller?.id,
          controllerName: controller?.firstName + ' ' + controller?.lastName,
          workStreak: 0,
          lastCellShiftId: null,
          lastCellShiftName: null,
          penultCellShiftId: null,
          penultCellShiftName: null,
        }
        for (let i = 1; i <= NUMBER_OF_DAYS_IN_PREVIOUS_MONTH; i += 1) {
          const shiftOnDay = controller?.shifts?.find((el) => el?.date === i);
          const absenceOnDay = controller?.absences?.find((el) => el?.date === i);
          if (shiftOnDay) {
            controllerValidationData.workStreak += 1;
          } else if (!shiftOnDay || absenceOnDay) {
            controllerValidationData.workStreak = 0;
          }
          if (i === NUMBER_OF_DAYS_IN_PREVIOUS_MONTH - 1) {
            controllerValidationData.penultCellShiftId = shiftOnDay?.shiftId;
            controllerValidationData.penultCellShiftName = shiftOnDay?.shiftName;
          }
          if (i === NUMBER_OF_DAYS_IN_PREVIOUS_MONTH) {
            controllerValidationData.lastCellShiftId = shiftOnDay?.shiftId;
            controllerValidationData.lastCellShiftName = shiftOnDay?.shiftName;
          }
        }
        previousMonthValidationDataArr.push(controllerValidationData);
      });
      setPreviousMonthValidationData(previousMonthValidationDataArr);
      // Next month validation data mapping
      nextMonthData?.controllers?.forEach((controller) => {
        const shiftOnFirstDay = controller?.shifts?.find((el) => el?.date === 1);
        const shiftOnSecondDay = controller?.shifts?.find((el) => el?.date === 2);
        // const absenceOnFirstDay = controller?.absences?.find((el) => el?.date === 1);
        // const absenceOnSecondDay = controller?.absences?.find((el) => el?.date === 2);
        const controllerValidationData = {
          controllerId: controller?.id,
          controllerName: controller?.firstName + ' ' + controller?.lastName,
          firstCellShiftId: shiftOnFirstDay ? shiftOnFirstDay?.shiftId : null,
          firstCellShiftName: shiftOnFirstDay ? shiftOnFirstDay?.shiftName : null,
          secondCellShiftId: shiftOnSecondDay ? shiftOnSecondDay?.shiftId : null,
          secondCellShiftName: shiftOnSecondDay ? shiftOnSecondDay?.shiftName : null
        };
        nextMonthValidationDataArr.push(controllerValidationData);
      });
      setNextMonthValidationData(nextMonthValidationDataArr);
    }
  }, [previousMonthData, nextMonthData]);

  return {
    getPreviousMonthVersions,
    getNextMonthVersions,
    getPreviousMonthData,
    getNextMonthData,
    previousMonthValidationData,
    nextMonthValidationData
  }
}

export default usePreviousAndNextMonthValidation;

import styled from 'styled-components';
import { Menu, Popover } from 'antd';
import theme from '../../theme/theme';
import SettingsImage from '../../assets/images/icons/settings.svg';

export const MenuItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const StyledIcon = styled.div`
  display: flex;
  background: url(${(props) => props.icon});
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
`;

export const StyledMenu = styled(Menu)`
  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
`;

export const SettingsBox = styled.div`
  display: flex;
  align-items: center;
`;

export const SettingsIcon = styled.div`
  display: flex;
  background-image: url(${SettingsImage});
  background-repeat: no-repeat;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  aling-items: center;
  justify-content: center;
  position: relative;
  right: 10px;
  transition: all 0.5s;
  &:hover {
    transform: rotate(90deg);
  }
`;
const CustomPopover = ({ className, children, ...restProps }) => (
  <Popover overlayClassName={className} {...restProps}>
    {children}
  </Popover>
);
export const StyledPopover = styled(CustomPopover)`
  .ant-popover-inner-content {
    padding: 0;
    border-radius: 5px !important;
  }
  .ant-popover-inner {
    border-radius: 5px !important;
    width: 200px;
  }
  .ant-menu {
    border-radius: 5px !important;
  }
  .ant-menu-vertical .ant-menu-item:hover {
    background-color: ${({ theme: { colors } }) => colors.mainColors.blue};
    color: white;
    // border-radius: 5px 5px 0px 0px !important;
  }
  .ant-menu-vertical .ant-menu-item {
    //border-radius: 5px;
    margin: 0;
    border-bottom: 1px solid #D1D1D1;
    text-align: center;
  }
  .ant-menu-vertical .ant-menu-item:hover {
    color: white;
    svg {
      color: white !important;
    }
  }
  .ant-menu-vertical .ant-menu-item:first-child {
    border-radius: 5px 5px 0px 0px;
  }
  .ant-menu-vertical .ant-menu-item:first-child:hover {
    border-radius: 5px 5px 0px 0px;
  }
  .ant-menu-vertical .ant-menu-item:last-child {
    border-radius: 0px 0px 5px 5px;
  }
  .ant-menu-vertical .ant-menu-item:last-child:hover {
    border-radius: 0px 0px 5px 5px;
  }

  .ant-menu-title-content {
    svg {
    display: flex;
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    color: gray;
    }
  }
  .ant-menu-title-content:hover {
    color: white !important;
    svg {
      color: white !important;
    }

  }
`;

export const SelectedMenuItem = styled(Menu.Item)`
  background-color: ${theme.colors.primaryHover};
`;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';

const ActiveFilterBox = ({ filterKey, name, value, clearFilterHandler }) => (
  <Container>
    <Name>{name}</Name>
    :
    <Value>{value}</Value>
    <StyledCloseOutlined onClick={() => clearFilterHandler(filterKey)} />
  </Container>
    );

const Container = styled.div`
  background: #727c83;
  //background-color: ${({ theme: { colors } }) => colors.grayDark};
  color: #fff;
  border-radius: 15px;
  padding: 6px 10px;
  font-size: 13px;
  cursor: default;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.gray};
  }
`;

const Name = styled.span`
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.bold};
`;

const Value = styled.span``;

const StyledCloseOutlined = styled(CloseOutlined)`
  // color: #fff;
  cursor: pointer;
  margin-left: 6px;
`;

ActiveFilterBox.propTypes = {
    filterKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    clearFilterHandler: PropTypes.func.isRequired
};

export default ActiveFilterBox;

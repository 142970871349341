/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react';
import moment from 'moment';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Dropdown, Menu, Popover, Select, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import { ReactSVG } from 'react-svg';
import {
    GenericTableFilterContainer,
    GenericTableFilterTitle,
    SearchColumnBox,
    SearchColumnInputBox,
    StyledTable,
    SearchButtonBox,
    ActiveFiltersContainer,
    HoveredActionBox,
    HoveredItemContainer,
    NoDataBox,
    NoDataMessageBox,
    NoDataIconBox,
    StyledMehOutlined,
    StyledMoreOutlined,
    StyledMoreButton,
    ClearAllFiltersBox,
    ColumnManagementBox,
    ColumnManagementItemBox,
    StyledBarsOutlined,
    ActionIcon,
    ActionBox,
    ColumnManagementInnerBox,
    CloseIconNumberBox,
    StyledCloseOutlined,
    NoDataInnerBox,
    LoadingSpin
} from './Datatable.style';
import TextSearchInput from './searchInputs/TextSearchInput';
import ActiveFilterBox from './ActiveFilterBox';
import DateRangePickerSearchInput from './searchInputs/DateRangePickerSearchInput';
import NumberSearchInput from './searchInputs/NumberSearchInput';
import SearchButton from './SearchButton';
import SelectSearchInput from './searchInputs/SelectSearchInput';
import ACheckbox from '../inputs/checkbox/ACheckBox';
import ResizableTitle from './ResizableTitle';
import LoadingMessageCode from '../../../util/loadingMessageCode';
import deleteIcon from '../../../assets/images/icons/icon_trash-can-filter.svg';
import { formater } from '../../../util/util';
import Cell from './Cell';

const { Option } = Select;

const ACTION_BUTTONS_NUMBER = 6;
// number of action items are hard coded so if you want more or less acion items you need to change it there
// this change will only trigger the menu
const propsNotToFormatAsNumber = ['phoneNumber'];

const rowHoverCssClass = 'rowHover';
const rowActionSelectedCssClass = 'rowActionSelected';

class Datatable extends React.Component {
    components = {
        header: {
            cell: ResizableTitle
        }
    };

    constructor(props) {
        super(props);
        this.datatableWrapperRef = React.createRef();
        this.state = {
            filters: [],
            sorters: {},
            openedFilters: [],
            autoFocusSearchInputKey: null,
            actionRowClicked: null,
            globalFilterText: null,
            columns: this.props.columns.map((col) => ({
                ...col,
                visible: true,
                width: col.width || 2000 / this.props.columns.length
            }))
        };
    }

    handleServerSideFiltering = _.debounce(() => {
        const filterParams = {};
        let sortingParams = {};

        this.state.filters.forEach((filter) => {
            if (filter.type === 'dateRange') {
                filterParams[filter.dataIndex + 'From'] = filter.from;
                filterParams[filter.dataIndex + 'To'] = filter.to;
            } else if (filter?.mode === 'multiple') {
                if (filter.value.length > 1) {
                    filterParams[filter.dataIndex] = filter.value.toString();
                } else {
                    filterParams[filter.dataIndex] = filter.value[0];
                }
            } else {
                filterParams[filter.dataIndex] = filter.value;
            }
        });

        if (this.state.sorters[Object.keys(this.state.sorters)[0]] === 'ascend') {
            sortingParams = { sort: Object.keys(this.state.sorters)[0] + ',asc' };
        } else if (this.state.sorters[Object.keys(this.state.sorters)[0]] === 'descend') {
            sortingParams = { sort: Object.keys(this.state.sorters)[0] + ',desc' };
        } else {
            sortingParams = { sort: null };
        }

        this.props.dataFetchingFunction({
            pagination: {
                page: 0,
                size: this.getPaginationSize()
            },
            filters: filterParams,
            sort: sortingParams
        });
    }, 500);

    componentDidMount() {
        if (this.datatableWrapperRef.current) {
            const columns = JSON.parse(localStorage.getItem('columns'));
            const columnsToRender = columns ? columns[this.props.dtKey] : null;
            let numberOfRenderedColumns = 0;
            const isActionColumnInUse = this.props.columns.findIndex((col) => col.dataIndex === 'action') !== -1;
            if (columnsToRender) {
                columnsToRender.forEach((col) => {
                    if (col.isVisible && this.props.columns.findIndex((c) => c.dataIndex === col.dataIndex) !== -1) {
                        numberOfRenderedColumns += 1;
                    }
                });
            } else if (isActionColumnInUse) {
                numberOfRenderedColumns = this.props.columns.length - 1;
            } else {
                numberOfRenderedColumns = this.props.columns.length;
            }

            const width = !isActionColumnInUse
                ? this.datatableWrapperRef.current.offsetWidth - 40
                : this.datatableWrapperRef.current.offsetWidth - 150;

            const preparedColumns = [...this.props.columns];

            if (!isActionColumnInUse) {
                preparedColumns.push({
                    dataIndex: 'action'
                });
            }

            this.setState({
                ...this.state,
                isActionColumnInUse,
                columns: preparedColumns.map((col) => {
                    let isColumnVisible = true;
                    if (col.dataIndex === 'action') isColumnVisible = true;
                    else if (!columnsToRender) isColumnVisible = true;
                    else {
                        const localStorageColumn = columns[this.props.dtKey].find((ctr) => ctr.dataIndex === col.dataIndex);
                        if (localStorageColumn) {
                            isColumnVisible = localStorageColumn.isVisible;
                        // eslint-disable-next-line no-unused-vars
                        } else isColumnVisible = true;
                    }

                    return {
                        ...col,
                        visible: col.columnVisible,
                        width:
                            (col.width || width / numberOfRenderedColumns) < 500 ? col.width || width / numberOfRenderedColumns : 500
                    };
                })
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.serverSideFiltering && prevState.filters !== this.state.filters) {
            this.handleServerSideFiltering();
        }
        if (this.props.serverSideSorting && prevState.sorters !== this.state.sorters) {
            let sortingParams = {};
            const filterParams = {};
            this.state.filters.forEach((filter) => {
                if (filter.type === 'dateRange') {
                    filterParams[filter.dataIndex + 'From'] = filter.from;
                    filterParams[filter.dataIndex + 'To'] = filter.to;
                } else {
                    filterParams[filter.dataIndex] = filter.value;
                }
            });
            if (this.state.sorters[Object.keys(this.state.sorters)[0]] === 'ascend') {
                sortingParams = { sort: Object.keys(this.state.sorters)[0] + ',asc' };
            } else if (this.state.sorters[Object.keys(this.state.sorters)[0]] === 'descend') {
                sortingParams = { sort: Object.keys(this.state.sorters)[0] + ',desc' };
            } else {
                sortingParams = { sort: null };
            }
            this.props.dataFetchingFunction({
                pagination: {
                    page: 0,
                    size: this.getPaginationSize()
                },
                filters: filterParams,
                sort: sortingParams
            });
        }
    }

    getFilteringProps = (
        dataIndex,
        key,
        filter,
        title,
        options,
        dataFormat,
        mode,
        displayFormat,
        isComplexObject,
        propertyValue
    ) => {
        let filterFunction;
        let titleJsx;
        const filterObj = this.state.filters?.find((f) => f.dataIndex === dataIndex);
        let filteredValue;

        const textInputFilterChangeHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (!e.target.value || e.target.value.trim() === '') {
                const filters = [...this.state.filters.filter((f) => f.key !== key)];
                this.setState({
                    filters
                });
                return;
            }

            const filters = [...this.state.filters];
            const filter = filters.find((f) => f.key === key);
            if (filter) {
                filter.value = e.target.value;
            } else {
                filters.push({
                    value: e.target.value,
                    title,
                    dataIndex,
                    key,
                    type: 'string'
                });
            }
            this.setState({
                filters
            });
        };

        const numberInputFilterChangeHandler = (value) => {
            if (!value) {
                const filters = [...this.state.filters.filter((f) => f.key !== key)];
                this.setState({
                    filters
                });
                return;
            }
            const filters = [...this.state.filters];
            const filter = filters.find((f) => f.key === key);
            if (filter) {
                filter.value = value.toFixed(2);
            } else {
                filters.push({
                    type: 'number',
                    value: value.toFixed(2),
                    title,
                    dataIndex,
                    key
                });
            }
            this.setState({
                filters
            });
        };

        const dateInputFilterChangeHandler = (value) => {
            const filters = [...this.state.filters];
            const filter = filters.find((f) => f.key === key);
            if (filter) {
                filter.from = value[0];
                filter.to = moment(value[1]).hours(23).minutes(59).seconds(59);
            } else {
                filters.push({
                    from: value[0],
                    to: moment(value[1]).hours(23).minutes(59).seconds(59),
                    type: 'dateRange',
                    dataIndex,
                    title,
                    key
                });
            }
            this.setState({
                filters,
                openedFilters: this.state.openedFilters.filter((of) => of !== key)
            });
        };

        const selectInputFilterChangeHandler = (value, option) => {
            // on clear handler
            if (!option || option.length === 0) {
                const filters = [...this.state.filters.filter((f) => f.key !== key)];
                this.setState({
                    filters
                });
                return;
            }
            // on pick new option handler
            const filters = [...this.state.filters];
            const filter = filters.find((f) => f.dataIndex === dataIndex);
            if (filter) {
                if (mode && mode === 'multiple') {
                    filter.value = option.map((o) => o.value);
                    filter.key = option.map((o) => o.key);
                    filter.valueTitle = option.map((o) => o.children);
                } else {
                    filter.value = option.value;
                    filter.key = option.key;
                    filter.valueTitle = option.children;
                }
            } else if (mode && mode === 'multiple') {
                filters.push({
                    value: option.map((o) => o.value),
                    key: option.map((o) => o.key),
                    valueTitle: option.map((o) => o.children),
                    type: 'select',
                    title,
                    dataIndex,
                    mode
                });
            } else {
                filters.push({
                    key: option.key,
                    value: option.value,
                    valueTitle: option.children,
                    type: 'select',
                    title,
                    dataIndex,
                    mode
                });
            }

            this.setState({
                filters
            });
        };

        let width = null;

        switch (filter) {
            case 'string':
                filteredValue = filterObj?.value;
                width = this.state.columns.find((col) => dataIndex === col.dataIndex)?.width - 45.5;
                titleJsx = (
                  <GenericTableFilterContainer>
                    {this.state.openedFilters.includes(dataIndex) ? (
                      <SearchColumnInputBox>
                        <TextSearchInput
                          style={{
                                        width
                                    }}
                          onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                          placeholder={title}
                          autoFocus={this.state.autoFocusSearchInputKey === dataIndex}
                          onFocus={() => this.searchInputFocusHandler(dataIndex)}
                          onBlur={() => this.searchInputBlurHandler(dataIndex)}
                          onPressEnter={() => this.searchInputBlurHandler(dataIndex)}
                          value={filterObj?.value}
                          onChange={textInputFilterChangeHandler}
                          suffix={(
                            <CloseOutlined
                              onClick={(e) => this.searchInputBlurHandler(e, dataIndex)}
                              style={{ color: 'rgba(0,0,0,.45)' }}
                            />
                                      )}
                        />
                      </SearchColumnInputBox>
                        ) : (
                          <SearchColumnBox>
                            <GenericTableFilterTitle>{title}</GenericTableFilterTitle>
                            <SearchButtonBox>
                              <SearchButton clickHandler={(e) => this.openSearchInputHandler(e, dataIndex)} />
                            </SearchButtonBox>
                          </SearchColumnBox>
                        )}
                  </GenericTableFilterContainer>
                );
                filterFunction = (value, record) => {
                    if (!value || value === '') return true;
                    if (!record[dataIndex]) return false;
                    if (isComplexObject && propertyValue) {
                        const properties = propertyValue.split('.');
                        const columnValue = this.getNestedPropertyValueFromObject(record[dataIndex], properties);
                        if (columnValue) {
                            return columnValue.toLowerCase().includes(value.toLowerCase());
                        }
                        return false;
                    }
                    return record[dataIndex]?.toString().toLowerCase().includes(value?.toString().toLowerCase());
                };
                break;
            case 'dateRange':
                if (filterObj?.from && filterObj?.to) {
                    const from = filterObj?.from.startOf('day').toDate().getTime();

                    filteredValue = from + '#' + filterObj?.to.toDate().getTime();
                }
                titleJsx = (
                  <GenericTableFilterContainer>
                    {this.state.openedFilters.includes(dataIndex) ? (
                      <SearchColumnInputBox>
                        <div
                          tabIndex="-1"
                          onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                        >
                          <DateRangePickerSearchInput
                            allowClear={false}
                            autoFocus={this.state.autoFocusSearchInputKey === dataIndex}
                            onFocus={() => this.searchInputFocusHandler(dataIndex)}
                            separator="-"
                            value={[filterObj?.from, filterObj?.to]}
                            format={displayFormat || 'DD.MM.YYYY.'}
                            onChange={dateInputFilterChangeHandler}
                          />
                        </div>
                      </SearchColumnInputBox>
                        ) : (
                          <SearchColumnBox>
                            <GenericTableFilterTitle>{title}</GenericTableFilterTitle>
                            <SearchButtonBox>
                              <SearchButton clickHandler={(e) => this.openSearchInputHandler(e, dataIndex)} />
                            </SearchButtonBox>
                          </SearchColumnBox>
                        )}
                  </GenericTableFilterContainer>
                );
                filterFunction = (value, record) => {
                    if (value) {
                        const [from, to] = value.split('#');
                        const fromDate = moment(new Date(parseInt(from, 10)));
                        const toDate = moment(new Date(parseInt(to, 10)));

                        const recordValue = record[dataIndex];
                        if (dataFormat) {
                            if (moment(recordValue, dataFormat, true).isBetween(fromDate, toDate)) return true;
                        } else if (moment(recordValue).isBetween(fromDate, toDate)) return true;
                        return false;
                    }
                    return true;
                };
                break;
            case 'number':
                filteredValue = filterObj?.value;
                titleJsx = (
                  <GenericTableFilterContainer>
                    {this.state.openedFilters.includes(dataIndex) ? (
                      <SearchColumnInputBox>
                        <NumberSearchInput
                          onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                          placeholder={title}
                          autoFocus={this.state.autoFocusSearchInputKey === dataIndex}
                          onFocus={() => this.searchInputFocusHandler(dataIndex)}
                          value={filterObj?.value}
                          onChange={numberInputFilterChangeHandler}
                          onBlur={() => this.searchInputBlurHandler(dataIndex)}
                          suffix={(
                            <CloseOutlined
                              onClick={(e) => this.searchInputBlurHandler(e, dataIndex)}
                              style={{ color: 'rgba(0,0,0,.45)' }}
                            />
                                      )}
                        />
                        <CloseIconNumberBox
                          onClick={(e) => {
                                        this.searchInputBlurHandler(e, dataIndex);
                                    }}
                        >
                          <StyledCloseOutlined />
                        </CloseIconNumberBox>
                      </SearchColumnInputBox>
                        ) : (
                          <SearchColumnBox>
                            <GenericTableFilterTitle>{title}</GenericTableFilterTitle>
                            <SearchButtonBox>
                              <SearchButton clickHandler={(e) => this.openSearchInputHandler(e, dataIndex)} />
                            </SearchButtonBox>
                          </SearchColumnBox>
                        )}
                  </GenericTableFilterContainer>
                );
                filterFunction = (value, record) => {
                    if (!value || value === '') return true;
                    let number = Number.parseFloat(value);
                    if (!isNaN(number)) return number === Number.parseFloat(record[dataIndex]);
                    let operator = value.slice(0, 1);
                    number = Number.parseFloat(value.slice(1));
                    if (!isNaN(number)) {
                        if (operator === '<') return Number.parseFloat(record[dataIndex]) < number;
                        if (operator === '>') return Number.parseFloat(record[dataIndex]) > number;
                        if (operator === '=') return number === Number.parseFloat(record[dataIndex]);
                        return false;
                    }
                    operator = value.slice(0, 2);
                    number = Number.parseFloat(value.slice(2));
                    if (!isNaN(number)) {
                        if (operator === '<=') return Number.parseFloat(record[dataIndex]) <= number;
                        if (operator === '>=') return Number.parseFloat(record[dataIndex]) >= number;
                    }
                    return false;
                };
                break;
            case 'select':
                if (isComplexObject) {
                    if (mode && mode === 'multiple') {
                        filteredValue = filterObj?.key.join('#');
                    } else filteredValue = filterObj?.key;
                } else if (mode && mode === 'multiple') {
                    filteredValue = filterObj?.key.join('#');
                } else {
                    filteredValue = filterObj?.key;
                }

                titleJsx = (
                  <GenericTableFilterContainer>
                    {this.state.openedFilters.includes(dataIndex) ? (
                      <SearchColumnInputBox>
                        <SelectSearchInput
                          defaultOpen
                          showArrow
                          mode={mode === 'multiple' ? mode : null}
                          allowClear
                          onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                          placeholder={title}
                          value={filterObj?.value}
                          autoFocus={this.state.autoFocusSearchInputKey === dataIndex}
                          onFocus={() => this.searchInputFocusHandler(dataIndex)}
                          onBlur={() => this.searchInputBlurHandler(dataIndex)}
                          onChange={selectInputFilterChangeHandler}
                          maxTagCount={0}
                          maxTagPlaceholder={`${filterObj?.value.length} ${this.props.t('items')}`}
                        >
                          {options?.map((option) => (
                            <Option key={option.id} value={option.value}>
                              {option.title}
                            </Option>
                                        ))}
                        </SelectSearchInput>
                      </SearchColumnInputBox>
                        ) : (
                          <SearchColumnBox>
                            <GenericTableFilterTitle>{title}</GenericTableFilterTitle>
                            <SearchButtonBox>
                              <SearchButton clickHandler={(e) => this.openSearchInputHandler(e, dataIndex)} />
                            </SearchButtonBox>
                          </SearchColumnBox>
                        )}
                  </GenericTableFilterContainer>
                );
                filterFunction = (value, record) => {
                    if (!value || value === '') return true;
                    if (isComplexObject) {
                        if (mode && mode === 'multiple') {
                            return value.split('#').includes(record[dataIndex]?.id.toString());
                        }
                        return record[dataIndex].id === value;
                    }
                    if (mode && mode === 'multiple') {
                        return value.split('#').includes(record[dataIndex]?.toString());
                    }
                    return value === record[dataIndex] || value === record[dataIndex].toString();
                };
                break;
            default:
                filterFunction = () => (value, record) => {
                    if (!value || value === '') return true;
                    return record[dataIndex].toLowerCase().includes(value.toLowerCase());
                };
        }

        return {
            title: titleJsx,
            filters: [],
            filteredValue: [filteredValue || ''],
            onFilter: this.props.serverSideFiltering ? null : filterFunction
        };
    };

    getSortingProps = (key, dataIndex, sort, ind, propertyValue, isComplexObject, filterOptions) => {
        let sortingFunction;
        switch (sort) {
            case 'string':
                sortingFunction = (a, b) => {
                    if (!a[dataIndex]) return 1;
                    if (!b[dataIndex]) return -1;
                    if (isComplexObject && propertyValue) {
                        const properties = propertyValue.split('.');
                        const aColumnValue = this.getNestedPropertyValueFromObject(a[dataIndex], properties);
                        const bColumnValue = this.getNestedPropertyValueFromObject(b[dataIndex], properties);

                        if (!aColumnValue) return 1;
                        if (!bColumnValue) return -1;
                        return aColumnValue.localeCompare(bColumnValue);
                    }
                    return a[dataIndex]?.toString().localeCompare(b[dataIndex]?.toString());
                };
                break;
            case 'number':
                sortingFunction = (a, b) => {
                    if (!a[dataIndex] || isNaN(a[dataIndex])) return 1;
                    if (!b[dataIndex] || isNaN(b[dataIndex])) return -1;
                    return a[dataIndex] - b[dataIndex];
                };
                break;
            case 'date':
                sortingFunction = (a, b) => {
                    if (!a[dataIndex]) return 1;
                    if (!b[dataIndex]) return -1;
                    const momentA = moment(a[dataIndex]);
                    const momentB = moment(b[dataIndex]);
                    if (momentA > momentB) return 1;
                    if (momentA < momentB) return -1;
                    return 0;
                };
                break;
            case 'select':
                if (isComplexObject) {
                    sortingFunction = (a, b) => {
                        if (!a[dataIndex]) return 1;
                        if (!b[dataIndex]) return -1;
                        return a[dataIndex][propertyValue].localeCompare(b[dataIndex][propertyValue]);
                    };
                } else {
                    sortingFunction = (a, b) => {
                        const aValueName = filterOptions.find((fo) => fo.id === a[dataIndex])?.value;
                        const bValueName = filterOptions.find((fo) => fo.id === b[dataIndex])?.value;
                        return aValueName?.localeCompare(bValueName);
                    };
                }
                break;
            default:
                sortingFunction = () => 0;
        }
        const handleResize = (index) => (e, { size }) => {
                    this.setState(({ columns }) => {
                        const nextColumns = [...columns];
                        nextColumns[index] = {
                            ...nextColumns[index],
                            width: size.width > 160 ? (size.width < 500 ? size.width : 500) : 160
                        };
                        return { columns: nextColumns };
                    });
                };
        return {
            sorter: this.props.serverSideSorting ? () => {} : sortingFunction,
            sortOrder: this.state.sorters[key] || false,
            onHeaderCell: (column) => ({
                    width: column.width,
                    onResize: handleResize(ind),
                    onClick: () => {
                        let nextValue;
                        if (this.state.sorters[key] === 'ascend') nextValue = 'descend';
                        else if (this.state.sorters[key] === 'descend') nextValue = false;
                        else nextValue = 'ascend';
                        const sorters = {};
                        sorters[key] = nextValue;
                        this.setState({
                            sorters
                        });
                    }
                })
        };
    };

    getNestedPropertyValueFromObject(item, properties) {
        let currentItem = '';
        const { formatNumber } = formater();
        try {
            if (item && item instanceof Object) {
                currentItem = item;
                properties.forEach((property) => {
                    currentItem = currentItem[property];
                });
            }
        } catch (error) {
            console.error('Item cannot be parsed.');
        }

        let formatAsNumber = true;
        propsNotToFormatAsNumber.forEach((property) => {
            if (properties.includes(property)) formatAsNumber = false;
        });

        if (formatAsNumber && formatNumber(currentItem) !== 'NaN') {
            currentItem = formatNumber(currentItem);
        }

        return currentItem;
    }

    getPaginationSize() {
        const pagination = JSON.parse(localStorage.getItem('pagination'));
        if (this.state.setTablePageSize) {
            this.state.setTablePageSize(
                pagination && pagination[this?.props?.dtKey] ? pagination[this?.props?.dtKey].pageSize : 10
            );
        }
        return pagination && pagination[this?.props?.dtKey] ? pagination[this?.props?.dtKey].pageSize : 10;
    }

    generateColumns = (columns) => {
        const clearFilterHandler = (dataIndex) => {
            const filters = [...this.state.filters.filter((f) => f.dataIndex !== dataIndex)];
            this.setState({
                filters
            });
        };

        columns = columns.map((stateCol) => {
            const propsCol = this.props.columns?.find((propCol) => propCol.dataIndex === stateCol.dataIndex);
            return {
                ...stateCol,
                filterOptions: propsCol?.filterOptions
            };
        });

        const clearAllFilters = () => {
            this.setState({
                ...this.state,
                filters: []
            });
        };

        const configuration = {
            title: (
              <ActiveFiltersContainer hide={this.state.filters.length === 0}>
                {this.state.filters?.map((filterItem) => {
                        let value = null;
                        if (typeof filterItem === 'object' && filterItem !== null) {
                            switch (filterItem.type) {
                                case 'string':
                                    value = filterItem.value;
                                    break;
                                case 'number':
                                    value = filterItem.value;
                                    break;
                                case 'select':
                                    if (filterItem.mode && filterItem.mode === 'multiple') {
                                        value = filterItem.valueTitle.join(', ');
                                    } else value = filterItem.valueTitle;
                                    break;
                                case 'dateRange':
                                    value = filterItem.from.format(columns.filterFormat || 'DD.MM.YYYY.')
                                        + ' - '
                                        + filterItem.to.format(columns.filterFormat || 'DD.MM.YYYY.');
                                    break;
                                default:
                                    break;
                            }
                        }
                        return (
                          <ActiveFilterBox
                            key={filterItem.dataIndex}
                            filterKey={filterItem.dataIndex}
                            clearFilterHandler={clearFilterHandler}
                            name={filterItem.title}
                            value={value}
                          />
                        );
                    })}
                <ClearAllFiltersBox onClick={clearAllFilters}>
                  <ActionIcon>
                    <ReactSVG src={deleteIcon} />
                  </ActionIcon>
                </ClearAllFiltersBox>
              </ActiveFiltersContainer>
            ),
            children: [
                ...columns.map(
                    (
                        {
                            dataIndex,
                            key,
                            title,
                            sort,
                            filter,
                            filterOptions,
                            render,
                            dataFormat,
                            displayFormat,
                            mode,
                            isCustomRender,
                            deleteItem,
                            editItem,
                            actionItems = [],
                            type,
                            width,
                            propertyValue,
                            showHover,
                            isComplexObject = false,
                            ...rest
                        },
                        ind
                    ) => {
                        if (ind === columns.length - 1) {
                            const changeColumnStatusHandler = (key, visible) => {
                                this.setState((state) => {
                                    const newColumns = [...state.columns];
                                    const column = newColumns.find((col) => col.key === key);
                                    if (column) {
                                        column.visible = visible;
                                    }
                                    let localStorageColumns = JSON.parse(localStorage.getItem('columns'));
                                    if (!localStorageColumns) localStorageColumns = {};

                                    let numberOfRenderedColumns = 0;
                                    const columns = JSON.parse(localStorage.getItem('columns'));
                                    const columnsToRender = columns ? columns[this.props.dtKey] : null;
                                    if (columnsToRender) {
                                        columnsToRender.forEach((col) => {
                                            if (col.isVisible && this.props.columns.findIndex((c) => c.key === col.key) !== -1) {
                                                numberOfRenderedColumns += 1;
                                            }
                                        });
                                    } else if (this.state.isActionColumnInUse) {
                                        numberOfRenderedColumns = this.props.columns.length - 1;
                                    } else {
                                        numberOfRenderedColumns = this.props.columns.length;
                                    }

                                    if (visible) numberOfRenderedColumns += 1;
                                    else numberOfRenderedColumns -= 1;

                                    if (numberOfRenderedColumns === 0) {
                                        return this.state;
                                    }

                                    const width = !this.state.isActionColumnInUse
                                        ? this.datatableWrapperRef.current.offsetWidth - 40
                                        : this.datatableWrapperRef.current.offsetWidth - 150;

                                    localStorageColumns[this.props.dtKey] = this.props.columns.map((column) => {
                                        const preparedColumn = newColumns.find((nc) => nc.key === column.key);
                                        return {
                                            key: column.key,
                                            isVisible: preparedColumn && preparedColumn.visible
                                        };
                                    });
                                    localStorageColumns[this.props.dtKey] = localStorageColumns[this.props.dtKey].filter((lsc) => lsc.key !== 'action');

                                    localStorage.setItem('columns', JSON.stringify(localStorageColumns));

                                    return {
                                        ...state,
                                        columns: newColumns.map((nc) => ({
                                                ...nc,
                                                width: width / numberOfRenderedColumns
                                            }))
                                    };
                                });
                            };

                            const retObj = (
                              <ColumnManagementBox>
                                <Popover
                                  title={this.props.t('datatable.columnManagementTitle')}
                                  placement="bottomLeft"
                                  trigger="click"
                                  content={(
                                    <ColumnManagementInnerBox>
                                      {this.props.columns
                                                    .filter((col) => col.key !== 'action')
                                                    .map((col) => {
                                                        const disabled = this.state.columns.filter((column) => column.visible && column.key !== 'action')
                                                                .length === 1 && this.state.columns.find((column) => column.key === col.key).visible;
                                                        return (
                                                          <ColumnManagementItemBox key={col.key}>
                                                            <ACheckbox
                                                              disabled={disabled}
                                                              onChange={(e) => {
                                                                        changeColumnStatusHandler(col.key, e.target.checked);
                                                                    }}
                                                              checked={this.state.columns.find((column) => column.key === col.key)?.visible}
                                                            >
                                                              {typeof col.title === 'string' ? col.title : col.name}
                                                            </ACheckbox>
                                                          </ColumnManagementItemBox>
                                                        );
                                                    })}
                                    </ColumnManagementInnerBox>
                                          )}
                                >
                                  <StyledBarsOutlined />
                                </Popover>
                              </ColumnManagementBox>
                            );

                            const config = {
                                render
                            };

                            const generateMoreItemsJsx = (moreItems, record) => {
                                const menu = (
                                  <Menu>
                                    {moreItems.map((item) => (
                                      <Menu.Item
                                        onClick={() => {
                                                        this.setState({
                                                            actionRowClicked: record
                                                        });
                                                        item.onClick(record, this.actionClickEndHandler.bind(this));
                                                    }}
                                        key={Math.random()}
                                      >
                                        {/* {item.icon} */}
                                        {typeof item.label === 'string' ? item.label : item.label(record)}
                                      </Menu.Item>
                                            ))}
                                  </Menu>
                                );

                                if (moreItems.length === 1) {
                                    return (
                                      <Tooltip
                                        title={
                                                typeof moreItems[0]?.tooltip === 'string'
                                                    ? moreItems[0]?.tooltip
                                                    : moreItems[0]?.tooltip(record)
                                            }
                                      >
                                        <ActionBox
                                          onClick={() => {
                                                    this.setState({
                                                        actionRowClicked: record
                                                    });
                                                    moreItems[0].onClick(record, this.actionClickEndHandler.bind(this));
                                                }}
                                        >
                                          <ActionIcon>
                                            <ReactSVG src={moreItems[0].icon} />
                                          </ActionIcon>
                                        </ActionBox>
                                      </Tooltip>
                                    );
                                }

                                return (
                                  <Dropdown
                                    overlay={menu}
                                    trigger="click"
                                    onVisibleChange={(visible) => {
                                            this.setState({
                                                ...this.state,
                                                isDropdownVisible: visible
                                            });
                                        }}
                                  >
                                    <StyledMoreButton shape="circle" icon={<StyledMoreOutlined />} />
                                  </Dropdown>
                                );
                            };

                            config.render = (text, record, index) => {
                                const value = record[dataIndex];
                                if (this.state.hoveredRecord?.id === record.id) {
                                    if (isCustomRender && render) return render(value, record, index);
                                    const spliced = [];
                                    actionItems.forEach((item, index) => {
                                        if (index !== 0 && index !== 1) {
                                            spliced.push(item);
                                        }
                                    });

                                    return (
                                      <HoveredItemContainer>
                                        <HoveredActionBox>
                                          {record?.state !== 'PUBLISHED' && actionItems[0] && record?.state !== 'PUBLISHING_UNDERWAY' && (
                                            <Tooltip
                                              title={
                                                            typeof actionItems[0]?.tooltip === 'string'
                                                                ? actionItems[0]?.tooltip
                                                                : actionItems[0]?.tooltip(record)
                                                        }
                                            >
                                              <ActionBox
                                                onClick={() => {
                                                                this.setState({
                                                                    actionRowClicked: record
                                                                });
                                                                actionItems[0].onClick(record, this.actionClickEndHandler.bind(this));
                                                            }}
                                              >
                                                <ActionIcon>
                                                  <ReactSVG src={actionItems[0].icon} />
                                                </ActionIcon>
                                              </ActionBox>
                                            </Tooltip>
                                                )}
                                          {actionItems[1] && record?.state !== 'PUBLISHING_UNDERWAY' && (
                                            <Tooltip
                                              title={
                                                            typeof actionItems[1]?.tooltip === 'string'
                                                                ? actionItems[1]?.tooltip
                                                                : actionItems[1]?.tooltip(record)
                                                        }
                                            >
                                              <ActionBox
                                                onClick={() => {
                                                                this.setState({
                                                                    actionRowClicked: record
                                                                });
                                                                actionItems[1].onClick(record, this.actionClickEndHandler.bind(this));
                                                            }}
                                              >
                                                <ActionIcon>
                                                  <ReactSVG src={actionItems[1].icon} />
                                                </ActionIcon>
                                              </ActionBox>
                                            </Tooltip>
                                                )}
                                          {actionItems[2] && record?.state !== 'PUBLISHING_UNDERWAY' && (
                                            <Tooltip
                                              title={
                                                            typeof actionItems[2]?.tooltip === 'string'
                                                                ? actionItems[2]?.tooltip
                                                                : actionItems[2]?.tooltip(record)
                                                        }
                                            >
                                              <ActionBox
                                                onClick={() => {
                                                                this.setState({ actionRowClicked: record });
                                                                actionItems[2].onClick(record, this.actionClickEndHandler.bind(this));
                                                            }}
                                              >
                                                <ActionIcon>
                                                  <ReactSVG src={actionItems[2].icon} />
                                                </ActionIcon>
                                              </ActionBox>
                                            </Tooltip>
                                                )}
                                          {actionItems[3] && record?.state !== 'PUBLISHING_UNDERWAY' && (
                                            <Tooltip
                                              title={
                                                            typeof actionItems[3]?.tooltip === 'string'
                                                                ? actionItems[3]?.tooltip
                                                                : actionItems[3]?.tooltip(record)
                                                        }
                                            >
                                              <ActionBox
                                                onClick={() => {
                                                                this.setState({
                                                                    actionRowClicked: record
                                                                });
                                                                actionItems[3].onClick(record, this.actionClickEndHandler.bind(this));
                                                            }}
                                              >
                                                <ActionIcon>
                                                  <ReactSVG src={actionItems[3].icon} />
                                                </ActionIcon>
                                              </ActionBox>
                                            </Tooltip>
                                                )}
                                          {actionItems[4] && record?.state !== 'PUBLISHING_UNDERWAY' && (
                                          <Tooltip
                                            title={
                                                            typeof actionItems[4]?.tooltip === 'string'
                                                                ? actionItems[4]?.tooltip
                                                                : actionItems[4]?.tooltip(record)
                                                        }
                                          >
                                            <ActionBox
                                              onClick={() => {
                                                                this.setState({
                                                                    actionRowClicked: record
                                                                });
                                                                actionItems[4].onClick(record, this.actionClickEndHandler.bind(this));
                                                            }}
                                            >
                                              <ActionIcon>
                                                <ReactSVG src={actionItems[4].icon} />
                                              </ActionIcon>
                                            </ActionBox>
                                          </Tooltip>
                                                )}
                                          {actionItems.length > ACTION_BUTTONS_NUMBER - 1 && generateMoreItemsJsx(spliced, record)}
                                        </HoveredActionBox>
                                      </HoveredItemContainer>
                                    );
                                }
                            };

                            return {
                                dataIndex,
                                title: retObj,
                                showSorterTooltip: false,
                                fixed:
                                    this.state.isActionColumnInUse
                                    && (editItem?.selected || deleteItem?.selected || actionItems?.length > 0)
                                    && 'right',
                                width: 50,
                                ...config,
                                ...rest
                            };
                        }

                        const config = {
                            render
                        };

                        if (type === 'select') {
                            config.render = (text, record) => {
                                const value = record[dataIndex];
                                return isComplexObject ? (
                                  <>{value ? value[propertyValue] : ''}</>
                                ) : (
                                  <>{filterOptions?.find((fo) => fo.id === record[dataIndex])?.value}</>
                                );
                            };
                        }

                        if (displayFormat) {
                            const formatDate = (value) => {
                                if (dataFormat) return moment(value, dataFormat);
                                return moment(value);
                            };
                            config.render = (text, record, index) => {
                                let value = record[dataIndex];
                                value = value ? formatDate(value) : record[dataIndex];
                                if (!value) return null;
                                if (render) return render(value, record, index);
                                return <>{value.format(displayFormat)}</>;
                            };
                        }

                        if (type === 'string') {
                            config.render = (text, record, index) => (
                              <Cell
                                key={index}
                                text={render ? render(text) : text}
                                record={record}
                                columnTitle={title}
                                index={index}
                                showHover={showHover}
                              />
                                );
                        }

                        if (type === 'string' && isComplexObject && propertyValue) {
                            config.render = (text, record) => {
                                const value = record[dataIndex];
                                const properties = propertyValue.split('.');

                                return <Cell text={value ? this.getNestedPropertyValueFromObject(value, properties) : ''} />;
                            };
                        }

                        return {
                            dataIndex,
                            title,
                            showSorterTooltip: false,
                            ...(sort
                                ? this.getSortingProps(key, dataIndex, sort, ind, propertyValue, isComplexObject, filterOptions)
                                : {}),
                            ...(filter
                                ? this.getFilteringProps(
                                    key,
                                    dataIndex,
                                    filter,
                                    title,
                                    filterOptions,
                                    dataFormat,
                                    mode,
                                    displayFormat,
                                    isComplexObject,
                                    propertyValue
                                )
                                : {}),
                            ...config,
                            ...rest,
                            width
                        };
                    }
                )
            ]
        };

        if (Object.keys(this.state.filters).length > 0) {
            return [configuration];
        }
        return configuration.children;
    };

    globalFilter(dataSource, globalFilterText) {
        return dataSource.filter((row) => row.name === globalFilterText);
    }

    searchInputFocusHandler(dataIndex) {
        this.setState({
            ...this.state,
            autoFocusSearchInputKey: dataIndex
        });
    }

    searchInputBlurHandler(dataIndex) {
        if (dataIndex === this.state.autoFocusSearchInputKey) {
            this.setState({
                ...this.state,
                autoFocusSearchInputKey: null,
                openedFilters: this.state.openedFilters.filter((of) => of !== dataIndex)
            });
        }
    }

    openSearchInputHandler(e, dataIndex) {
        this.setState((state) => ({
                ...state,
                autoFocusSearchInputKey: dataIndex,
                openedFilters: [dataIndex]
            }));
        if (dataIndex !== 'globalTableSearch') {
            e.stopPropagation();
            e.preventDefault();
        }
    }

    calculateLastColumnWidth() {
        const actionColumn = this.props.columns.find((col) => col.dataIndex === 'action');
        if (actionColumn) {
            let numberOfButtons = 0;
            if (actionColumn.actionItems?.length > ACTION_BUTTONS_NUMBER - 1) {
                numberOfButtons = ACTION_BUTTONS_NUMBER;
            } else {
                numberOfButtons = actionColumn.actionItems?.length;
            }
            return numberOfButtons * 50 + 'px';
        }
        return '40px';
    }

    generateErrorMessage() {
        const errorMessage = this.props.loadingMessages?.find((lm) => lm.type === LoadingMessageCode.BREAKING_ERROR_EXIST);
        return errorMessage
            ? errorMessage?.message || this.props.t('datatable.fetchDataError')
            : this.props.t('datatable.noData');
    }

    actionClickEndHandler() {
        this.setState({
            actionRowClicked: null
        });
    }

    render() {
        const {
            dataSource,
            className,
            occupiedHeight,
            headerTitle,
            advancedDatatableFilter,
            dtKey,
            loading,
            uniqueColumn,
            ...rest
        } = this.props;

        return (
          <div ref={this.datatableWrapperRef} style={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
            <StyledTable
              {...rest}
              activeFiltersOn={this.state.filters?.length > 0}
              rowKey={(obj) => obj.identificator || obj.id}
              loading={loading}
            //   locale={{
            //             emptyText: loading ? (
            //               <NoDataBox>
            //                 <NoDataInnerBox>
            //                   <LoadingSpin size="large" />
            //                 </NoDataInnerBox>
            //               </NoDataBox>
            //             ) : (
            //               <NoDataBox>
            //                 <NoDataInnerBox>
            //                   <NoDataIconBox>
            //                     <StyledMehOutlined />
            //                   </NoDataIconBox>
            //                   <NoDataMessageBox>{this.generateErrorMessage()}</NoDataMessageBox>
            //                 </NoDataInnerBox>
            //               </NoDataBox>
            //             )
            //         }}
              onRow={(record) => ({
                            onMouseEnter: () => {
                                this.setState({
                                    ...this.state,
                                    hoveredRecord: record,
                                    actionRowClicked: null
                                });
                            },
                            onMouseLeave: () => {
                                this.setState((state) => {
                                    if (!state.isDropdownVisible) {
                                        return {
                                            ...this.state,
                                            hoveredRecord: null
                                        };
                                    }
                                    return state;
                                });
                            }
                        })}
              columns={this.generateColumns(this.state.columns.filter((col) => col.visible))}
              dataSource={
                        this.state.globalFilterText ? this.globalFilter(dataSource, this.state.globalFilterText) : dataSource
                    }
              occupiedHeight={occupiedHeight}
              pagination={{
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                        defaultPageSize: this.getPaginationSize(),
                        total: this.props?.total,
                        current: this.props?.currentPage + 1,
                        onChange: (page, pageSize) => {
                            if (this.props.onPagination) {
                                const filterParams = {};
                                let sortingParams = {};

                                this.state.filters.forEach((filter) => {
                                    if (filter.type === 'dateRange') {
                                        filterParams[filter.dataIndex + 'From'] = filter.from;
                                        filterParams[filter.dataIndex + 'To'] = filter.to;
                                    } else {
                                        filterParams[filter.dataIndex] = filter.value;
                                    }
                                });

                                if (this.state.sorters[Object.keys(this.state.sorters)[0]] === 'ascend') {
                                    sortingParams = { sort: Object.keys(this.state.sorters)[0] + ',asc' };
                                } else if (this.state.sorters[Object.keys(this.state.sorters)[0]] === 'descend') {
                                    sortingParams = { sort: Object.keys(this.state.sorters)[0] + ',desc' };
                                } else {
                                    sortingParams = { sort: null };
                                }

                                this.props.onPagination({
                                    page: page - 1,
                                    pageSize,
                                    filters: filterParams,
                                    sort: sortingParams
                                });
                            }
                            let pagination = JSON.parse(localStorage.getItem('pagination'));
                            if (!pagination) pagination = {};
                            pagination[dtKey] = {
                                page: page - 1,
                                pageSize
                            };
                            localStorage.setItem('pagination', JSON.stringify(pagination));
                        }
                    }}
              isGlobalSearchOpen={this.state.openedFilters.includes('globalTableSearch') ? 1 : 0}
                    // paginationSize={this.getPaginationSize()}
              lastColumnWidth={this.calculateLastColumnWidth()}
              components={this.components}
              className={`datatable generic-table ${className}`}
              rowClassName={(record) => {
                        const classes = [];
                        if (!uniqueColumn) {
                            if (record.id === this.state.hoveredRecord?.id) {
                                classes.push(rowHoverCssClass);
                            }
                            if (this.state.actionRowClicked?.id === record.id) {
                                classes.push(rowActionSelectedCssClass);
                            }
                            return classes.join(' ');
                        }
                        if (this.state.hoveredRecord && record[uniqueColumn] === this.state.hoveredRecord[uniqueColumn]) {
                            classes.push(rowHoverCssClass);
                        }
                        if (this.state.actionRowClicked?.[uniqueColumn] === record[uniqueColumn]) {
                            classes.push(rowActionSelectedCssClass);
                        }
                        return classes.join(' ');
                    }}
            />
          </div>
        );
    }
}

Datatable.propTypes = {
    uniqueColumn: PropTypes.string,
    dataSource: PropTypes.array,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    loadingMessages: PropTypes.array,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            dataIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired, // reserver value is action
            uniqueColumn: PropTypes.string, // default id
            type: PropTypes.oneOf(['string', 'number', 'select', 'dateRange', 'date']),
            sort: PropTypes.oneOf(['string', 'number', 'select', 'date']),
            filter: PropTypes.oneOf(['string', 'number', 'select', 'dateRange', 'date']),
            propertyValue: PropTypes.string, // Used only for select column type to determine options value title which will be displayed to user,
            mode: PropTypes.string, // Supported values: multiple or null. If this property is not set, default select mode is single
            isComplexObject: PropTypes.bool, // Used only for select, default false, define if there is complex object for select column - If true, need to define property value
            showHover: PropTypes.bool, // default false
            filterOptions: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
                    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
                    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
                    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
                })
            ),
            dataFormat: PropTypes.string, // Format in which
            displayFormat: PropTypes.string, // Format in which row field values will be displayed for column type [date, dateRange]. Default is DD.MM.YYYY.
            editItem: PropTypes.shape({
                selected: PropTypes.bool, // default false
                onClick: PropTypes.func
            }),
            deleteItem: PropTypes.shape({
                selected: PropTypes.bool, // default false
                onConfirmDelete: PropTypes.func,
                textProps: PropTypes.shape({
                    title: PropTypes.string,
                    content: PropTypes.string,
                    confirm: PropTypes.string,
                    cancel: PropTypes.string
                })
            }),
            moreItems: PropTypes.arrayOf(
                PropTypes.shape({
                    icon: PropTypes.string.isRequired,
                    onClick: PropTypes.func.isRequired,
                    label: PropTypes.string.isRequired
                })
            ),
            isCustomRender: PropTypes.bool, // default false
            render: PropTypes.func, // render must be set when isCustomRender is set to true
            width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]) // column width
        })
    ),
    className: PropTypes.string,
    occupiedHeight: PropTypes.number,
    headerTitle: PropTypes.string,
    advancedDatatableFilter: PropTypes.func,
    dtKey: PropTypes.string.isRequired,
    t: PropTypes.func,
    actionRow: PropTypes.object
};

export default withTranslation()(Datatable);

export const usersPermissionsValues = [
    'NONE',
    'DISPLAY',
    'USERS_ADMINISTRATION'
];

export const settingsPermissionsValues = [
    'NONE',
    'DISPLAY_AND_MODIFY'
];

export const reportsPermissionsValues = [
    'NONE',
    'GENERATE_AND_DISPLAY'
];

export const annualPlanPermissionsValues = [
  'NONE',
  'DISPLAY',
  'GENERATE_AND_MODIFY',
  'APPROVE_AND_ACTIVATE'
];

export const monthlyPlanPermissionsValues = [
  'NONE',
  'DISPLAY',
  'GENERATE_AND_MODIFY',
  'APPROVE_AND_ACTIVATE'
];

export const dailyPlanPermissionsValues = [
  'NONE',
  'DISPLAY',
  'GENERATE_AND_MODIFY',
  'APPROVE_AND_ACTIVATE'
];

export const changeWorkShiftsPermissionsValues = [
  'NONE',
  'REQUEST_FOR_CHANGE_OF_WORK_SHIFTS',
  'DISPLAY_AND_APPROVE_WORK_SHIFT_CHANGE'
];

/* eslint-disable react/jsx-indent */
import PropTypes from 'prop-types';
import { PanelWrapper, PanelHeader, PanelHeaderTitle, PanelSubTitle, PanelHeaderActionBox, PanelContent, Sign } from './Panel.style';

// eslint-disable-next-line no-unused-vars
export const Panel = ({ title, subTitle, hasBackgroundColor, children, arrowSignVisible, childrenActionBox, actionBoxVisible, padding, notificationsPanel = false }) => {
  return (
    <PanelWrapper>
      <PanelHeader notificationsPanel={notificationsPanel}>
        <PanelHeaderTitle>
          {title + ' '}
          {arrowSignVisible && (<Sign>&gt;&nbsp;</Sign>)}
          <PanelSubTitle>{subTitle}</PanelSubTitle>
        </PanelHeaderTitle>
        {actionBoxVisible && (
          <PanelHeaderActionBox>
            {childrenActionBox}
          </PanelHeaderActionBox>
        )}
      </PanelHeader>
      <PanelContent className={!hasBackgroundColor ? 'coloredDiv' : ''}>
        {children}
      </PanelContent>
    </PanelWrapper>
  )
}
// style={{ padding: padding ? padding : '20px' }}
Panel.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  hasBackgroundColor: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]),
  arrowSignVisible: PropTypes.bool,
  childrenActionBox: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]),
  actionBoxVisible: PropTypes.bool,
  padding: PropTypes.string
}

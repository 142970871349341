import styled from 'styled-components';

export const DailyPlanWrapper = styled.div`
display: flex;
flex-direction: column;
position: relative;
gap: 10px;
height: 100%;
@media only screen and (max-width: 1700px) {
    height: 100%;
    }
`;

export const WeekDayWrapper = styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: space-between;
//Potrebno doraditi
padding-right: 19px;
`;

export const WeekDay = styled.div`
font-size: 15px;
// Potrebno doraditi
user-select: none;
width: 12.8%;
font-weight: 500;
box-sizing: border-box;
text-align: center;
color: ${({ theme: { colors } }) => (colors.grayColors.darkGray)};
@media only screen and (max-width: 2200px) {
        width: width: 12.8%;;
        font-size: 15px;
    }
@media only screen and (max-width: 1700px) {
    width: 12.8%;
    font-size: 15px;
    }
`;

export const DailyCardWrapper = styled.div`
display: flex;
flex-wrap: wrap;
justify-content: space-between;
gap: 2.7vh;
padding-right: 10px;
height: 100%;
`;

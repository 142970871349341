import styled from 'styled-components';
import { Checkbox } from 'antd';

const ACheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    flex-direction: row-reverse;
    width: 200px;
    height: 35px;
    display: flex;
    align-items: center;
    position: relative;

    :hover {
      .ant-checkbox-inner {
        border-color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    span:last-child {
      position: absolute;
      left: 4px;
    }
    .ant-checkbox {
      position: absolute;
      right: 35px;
      top: 8px;

      .ant-checkbox-inner {
        border-radius: 10px;
      }
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${({ theme: { colors } }) => colors.primary};
        border-color: ${({ theme: { colors } }) => colors.primary};
      }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: ${({ theme: { colors } }) => colors.primary};
    }

    user-select: none;
  }
`;

export default ACheckbox;

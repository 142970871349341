import styled from 'styled-components';
import { InputNumber } from 'antd';

const NumberSearchInput = styled(InputNumber)`
  border-radius: 15px;
  outline: none;
  border-color: #d9d9d9;

  &&.ant-input-number {
    width: 100%;
    min-width: 130px;
    .ant-input-number-handler-wrap {
      border-radius: 15px;
    }
    .ant-input-number-input-wrap {
      width: 70%;
    }
  }

  :hover,
  :active,
  :focus {
    border-color: #d9d9d9;
    box-shadow: 0px 0px 2px 1px rgba(40, 40, 40, 0.15);
  }

  .ant-input-number-handler-up:hover,
  .ant-input-number-handler-down:hover {
    height: 55% !important;

    svg {
      color: ${({ theme: { colors } }) => colors.primary};
    }
  }

  &.ant-input-number-focused {
    box-shadow: none;
    outline: none;
    overflow: hidden;
  }
`;

export default NumberSearchInput;

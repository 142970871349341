import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { object, string } from 'yup';
import FormModalStyled from '../../../core/components/modals/FormModalStyled';
import withModal from '../../../core/components/withModals';
import swapIcon from '../../../assets/images/icons/awesome-exchange-alt.svg';
import AForm from '../../../core/components/form/AForm';
import ATextArea from '../../../core/components/inputs/textArea/ATextArea';

const ShiftChangeRequestModal = (props) => {
  const {
    myShift,
    elseShift,
    month,
    year,
    version,
    closeHandler,
    submitHandler,
    closeDialog
  } = props;

  const { t } = useTranslation();

  const formFields = [
    {
      key: 'explanation',
      id: 'explanation',
      name: t('shiftsChange.explanation'),
      render: <ATextArea rows={4} style={{ width: '100%' }} />
    }
  ];

  const validationSchema = object().shape({
    explanation: string()
        .required(t('errors.required'))
        .min(3, `${t('errors.minLength')} ${3}`)
        .max(300, `${t('errors.maxLength')} ${300}`),
  });

  const sendRequestHandler = (payload, setLoading) => {
    const params = {
      userApplicantId: myShift?.controllerId,
      userRecipientRequestId: elseShift?.controllerId,
      monthlyScheduleId: version?.id,
      day1: myShift?.day,
      shiftConfigId1: myShift?.shiftId,
      day2: elseShift?.day,
      shiftConfigId2: elseShift?.shiftId,
      comment: payload?.explanation
    };
    submitHandler(params, setLoading);
    closeHandler();
  };

  const myShiftDate = new Date(year, month - 1, myShift?.day);
  const elseShiftDate = new Date(year, month - 1, elseShift?.day);

  const myShiftDayInWeek = myShiftDate?.getDay();
  const elseShiftDayInWeek = elseShiftDate?.getDay();

  return (
    <FormModalStyled
      title={t('shiftsChange.sendRequestTitle')}
      centered
      maskClosable={false}
      width={520}
      {...props}
    >
      <Container>
        <ShiftsContainer>
          <ShiftThumbnail>
            <ControllerName>{myShift?.controllerFullName}</ControllerName>
            <DateCaption>
              {t('daysInWeek.' + myShiftDayInWeek).toUpperCase().substring(0, 3) + ', ' + myShiftDate.toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })}
            </DateCaption>
            <ShiftName>{myShift?.shiftName}</ShiftName>
          </ShiftThumbnail>
          <SwapIcon
            src={swapIcon}
            beforeInjection={(svg) => {
                svg.classList.add('svg-class-name')
                svg.setAttribute('style', 'width: 52px; height: 41px;')
            }}
          />
          <ShiftThumbnail>
            <ControllerName>{elseShift?.controllerFullName}</ControllerName>
            <DateCaption>
              {t('daysInWeek.' + elseShiftDayInWeek).toUpperCase().substring(0, 3) + ', ' + elseShiftDate.toLocaleDateString('sr-rs', { year: 'numeric', month: '2-digit', day: '2-digit' })}
            </DateCaption>
            <ShiftName>{elseShift?.shiftName}</ShiftName>
          </ShiftThumbnail>
        </ShiftsContainer>
        <AForm
          fields={formFields}
          validationSchema={validationSchema}
          okButtonText={t('shiftsChange.send')}
          verticallyPositionedLabels
          closeDialog={closeDialog}
          modalEditHandler={sendRequestHandler}
        />
      </Container>
    </FormModalStyled>
  );
};

const Container = styled.div`
  padding-right: 20px;
`;

const ShiftsContainer = styled.div`
  color: #5F5F5F;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-top: 20px;
`;

const ShiftThumbnail = styled.div`
  width: 48%;
  height: 162px;
  border: 1px solid #D9D9D9;
  border-radius: 5%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ControllerName = styled.div`
  font-size: 16px;
  line-height: 19px;
`;

const DateCaption = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
`;

const ShiftName = styled.div`
  font-size: 41px;
  font-weight: 600;
  line-height: 50px;
`;

const SwapIcon = styled(ReactSVG)`
  width: 10%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
`;

export default withModal()(ShiftChangeRequestModal);

import { Redirect, useParams } from 'react-router';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';
import { saveAs } from 'file-saver';
import {
    Wrapper,
    Container,
    Header,
    BackButtonContainer,
    BackButtonText,
    BackButton,
    TitleContainer,
    MonthTitleBox,
    PlanStatusBox,
    VersionDropdownContainer,
    FilterSelectInputTwo,
    RightSideMenu,
    SideMenuContainer,
    MenuItem,
    SheetContainer,
    PreviousMonthArrow,
    NextMonthArrow,
    StatusBoxSpinner,
    ChangelogContainer,
    ChangelogTitleContainer
} from './MonthlyDetailedView.style';
import arrowBackIcon from '../../assets/images/icons/arrow-back.svg';
import BHANSALogo from '../../assets/images/bhansa-logo-notext-inverted.svg';
import saveIcon from '../../assets/images/icons/awesome-save.svg';
import savePlusIcon from '../../assets/images/icons/awesome-save-plus.svg';
import revertIcon from '../../assets/images/icons/awesome-undo.svg';
import confirmIcon from '../../assets/images/icons/confirm-icon.svg';
import cancelIcon from '../../assets/images/icons/material-cancel.svg';
import sendIcon from '../../assets/images/icons/send-icon.svg';
import changelogIcon from '../../assets/images/icons/report-icon.svg';
import spreadsheetIcon from '../../assets/images/icons/xls-icon.svg';
// import printIcon from '../../assets/images/icons/print-icon.svg';
import closeIcon from '../../assets/images/icons/xmark-solid.svg';
import planStatusTypes from '../../util/planStatusTypes.json';
import MonthlySheet from './monthlySheet/MonthlySheet';
import caretLeftIcon from '../../assets/images/icons/caret-left.svg';
import caretRightIcon from '../../assets/images/icons/caret-right.svg';
import useDetailedMonthlySchedule from './hooks/useDetailedMonthlySchedule';
import {
    submitMonthlyPlan,
    approveMonthlySchedule,
    rejectMonthlySchedule,
    getScheduleVersions,
    saveModifiedMonthlySchedule,
    saveMonthlyScheduleAsNewVersion
} from '../../services/monthlyPlan.service';
import { useAuthStateValue } from '../../context/AuthContext';
import confirmModal from '../../core/components/modals/ConfirmModal';
import ChangeLog from './changeLog/ChangeLog';
import usePreviousAndNextMonthValidation from './hooks/usePreviousAndNextMonthValidation';
import { columnNumberToExcelColumnName, timeStringToHoursCount } from '../../util/util';

const MonthlyDetailedView = (props) => {
    const [modifiedData, setModifiedData] = useState();
    const [changelogExpanded, setChangelogExpanded] = useState(false);
    const { location, year, annualPlanVersion, month, version, mode } = useParams();

    const { loggedUser } = useAuthStateValue();

    const { t } = useTranslation();
    const {
      dataSource,
      setDataSource,
      loading,
      getMonthlySchedule,
      getScheduleStatus,
      getMonthlyScheduleVersions,
      shiftSwapRequestList,
      getAllShiftSwapRequests,
      setVersions,
      versions,
      planStatus,
      optimalNumberOfWorkingHours,
      setPlanStatus,
      getDemand,
      setDemand,
      demand,
      getChangelog,
      changeLog
    } = useDetailedMonthlySchedule();

    const locationId = loggedUser?.locationsData.find((el) => el?.value === location)?.id;

    const {
      getPreviousMonthVersions,
      getNextMonthVersions,
      previousMonthValidationData,
      nextMonthValidationData
    } = usePreviousAndNextMonthValidation(location, year, month, version);

    const previousMonth = month === '1' ? 12 : (parseInt(month, 10) - 1);
    const previousMonthYear = month === '1' ? (parseInt(year, 10) - 1) : year;

    const nextMonth = month === '12' ? 1 : (parseInt(month, 10) + 1);
    const nextMonthYear = month === '12' ? (parseInt(year, 10) + 1) : year;

    const paramsAreValid = (/^\d+$/.test(year))
        && (/^\d+$/.test(month))
        && parseInt(month, 10) < 13
        && year.startsWith('2') && year.length < 5
        && (mode === 'view' || (mode === 'edit' && (loggedUser?.localPermissions?.[locationId]?.monthlyPlan === 'APPROVE_AND_ACTIVATE' || loggedUser?.localPermissions?.[locationId]?.monthlyPlan === 'GENERATE_AND_MODIFY')));

    if (!paramsAreValid) {
        return <Redirect push to="/404" />;
    }

    let correspondingPlanStatus;
    if (planStatus && planStatus !== 'PLAN_NOT_FOUND') {
        correspondingPlanStatus = planStatusTypes.monthly.find((el) => el.key === planStatus);
    } else {
        correspondingPlanStatus = {
            headerColor: '#C44536'
        }
    }

    const approveHandler = async () => {
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('monthlyPlan.planApproval'),
        content: t('monthlyPlan.planApprovalConfirm'),
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        onOk: async () => {
          const scheduleId = versions?.find((el) => el.value === parseInt(version, 10))?.id;
          try {
            await approveMonthlySchedule(scheduleId, loggedUser?.id);
            message.success(t('monthlyPlan.planApprovalSuccess'));
            setModifiedData(null);
            setPlanStatus(null);
            props.history.push('/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + version + '/' + mode);
            getScheduleStatus(location, year, month, version);
          } catch (e) {
            console.log(e);
            message.error(t('monthlyPlan.planApprovalFail'));
          }
        }
      });
    };

    const rejectHandler = async () => {
      confirmModal({
        centered: true,
        icon: <QuestionOutlined />,
        title: t('monthlyPlan.planRejection'),
        content: t('monthlyPlan.planRejectionConfirm'),
        okText: t('buttons.yes'),
        cancelText: t('buttons.no'),
        onOk: async () => {
          const scheduleId = versions?.find((el) => el.value === parseInt(version, 10))?.id;
          try {
            await rejectMonthlySchedule(scheduleId, loggedUser?.id);
            message.success(t('monthlyPlan.planRejectionSuccess'));
            setModifiedData(null);
            setPlanStatus(null);
            props.history.push('/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + version + '/' + mode);
            getScheduleStatus(location, year, month, version);
          } catch (e) {
            console.log(e);
            message.error(t('monthlyPlan.planRejectionFail'));
          }
        }
      });
    }

    const saveHandler = async () => {
        confirmModal({
            centered: true,
            icon: <QuestionOutlined />,
            title: t('monthlyPlan.saveChanges'),
            content: t('monthlyPlan.saveChangesAreYouSure'),
            okText: t('buttons.yes'),
            cancelText: t('buttons.no'),
            onOk: async () => {
              const scheduleId = versions?.find((el) => el.value === parseInt(version, 10))?.id;
              const payload = {
                changes: [
                    ...(modifiedData ? modifiedData?.[modifiedData?.length - 1] : [])
                ]
              };
              try {
                await saveModifiedMonthlySchedule(scheduleId, loggedUser?.id, payload);
                setModifiedData(null);
                setPlanStatus(null);
                props.history.push('/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + version + '/' + mode);
                getScheduleStatus(location, year, month, version);
                message.success(t('monthlyPlan.changesSaveSuccess'));
              } catch (e) {
                console.log(e);
                  message.error(t('monthlyPlan.changesSaveFail'));
              }
            },
        });
    }

    const saveAsNewVersionHandler = () => {
        confirmModal({
            centered: true,
            icon: <QuestionOutlined />,
            title: t('monthlyPlan.saveChanges'),
            content: t('monthlyPlan.saveChangesAsNewAreYouSure'),
            okText: t('buttons.yes'),
            cancelText: t('buttons.no'),
            onOk: async () => {
                const scheduleId = versions?.find((el) => el.value === parseInt(version, 10))?.id;
                const payload = {
                    changes: [
                        ...(modifiedData ? modifiedData?.[modifiedData?.length - 1] : [])
                    ]
                };
                try {
                    const res = await saveMonthlyScheduleAsNewVersion(scheduleId, loggedUser?.id, payload);
                    message.success(t('monthlyPlan.changesSaveAsNewSuccess'));
                    props.history.push('/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + res?.data + '/' + mode);
                } catch (e) {
                    console.log(e);
                    message.error(t('monthlyPlan.changesSaveAsNewFail'));
                }
            },
        });
    }

  const submitForReviewHandler = () => {
    confirmModal({
      centered: true,
      icon: <QuestionOutlined />,
      title: t('monthlyPlan.planSubmit'),
      content: t('monthlyPlan.planSubmitConfirm'),
      okText: t('buttons.yes'),
      cancelText: t('buttons.no'),
      onOk: async () => {
        const scheduleId = versions?.find((el) => el.value === parseInt(version, 10))?.id;
        try {
          await submitMonthlyPlan(scheduleId, loggedUser?.id);
          message.success(t('monthlyPlan.planSubmitSuccess'));
          setModifiedData(null);
          setPlanStatus(null);
          props.history.push('/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + version + '/' + mode);
          getScheduleStatus(location, year, month, version);
        } catch (e) {
          console.log(e);
          message.error(t('monthlyPlan.planSubmitFail'));
        }
      }
    });
  };

  const getSheetData = (numberOfDaysInMonth) => {
    const controllerRows = [];
    const demandSituation = {};
    const perShiftArray = [];
    const totalHoursArray = [];
    demand?.forEach((el) => {
      const perDayArray = [];
      for (let i = 1; i <= numberOfDaysInMonth; i += 1) perDayArray.push(0);
      demandSituation[el?.name] = perDayArray;
    });
    dataSource?.controllers?.forEach((controller, index) => {
      const controllerRow = [];
      const daysPerShift = {};
      let controllerTotalHours = 0;
      demand?.forEach((el) => {
        daysPerShift[el?.name] = 0;
      });
      controllerRow.push(controller?.firstName + ' ' + controller?.lastName);
      controllerRow.push(index);
      controllerRows.push(controllerRow);
      for (let i = 1; i <= numberOfDaysInMonth; i += 1) {
        const shiftOnDay = controller?.shifts?.find((el) => el?.date === i);
        const absenceOnDay = controller?.absences?.find((el) => el?.date === i);
        if (shiftOnDay) {
          const shiftDetails = loggedUser?.shiftTypes?.[location]?.find((el) => el?.name === shiftOnDay?.shiftName);
          const shiftTimeFrom = timeStringToHoursCount(shiftDetails?.timeFrom, false);
          const shiftTimeTo = timeStringToHoursCount(shiftDetails?.timeTo, true);
          const shiftDuration = (shiftTimeTo > shiftTimeFrom) ? (shiftTimeTo - shiftTimeFrom) : ((shiftTimeTo + 24) - shiftTimeFrom);
          controllerTotalHours += shiftDuration;
          controllerRow.push(shiftOnDay?.shiftName);
          if (demandSituation[shiftOnDay.shiftName]) {
            demandSituation[shiftOnDay.shiftName][i - 1] += 1;
          }
          if (daysPerShift?.[shiftOnDay?.shiftName]) daysPerShift[shiftOnDay.shiftName] += 1;
          else daysPerShift[shiftOnDay?.shiftName] = 1;
        } else if (absenceOnDay) {
          const absenceDetails = loggedUser?.absenceTypes?.find((el) => el?.name === absenceOnDay?.absenceName);
          if (absenceDetails?.counts) controllerTotalHours += 8;
          controllerRow.push(absenceOnDay?.absenceName);
          if (daysPerShift?.[absenceOnDay?.absenceName]) daysPerShift[absenceOnDay.absenceName] += 1;
          else daysPerShift[absenceOnDay?.absenceName] = 1;
        } else controllerRow.push(' ');
      }
      totalHoursArray.push([controllerTotalHours]);
      perShiftArray.push(daysPerShift);
    });

    const demandRows = [];
      // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(demandSituation)) {
      demandRows.push([
        key,
        ...value
      ]);
    }

    let usedShiftsAndAbsences = [];
    perShiftArray?.forEach((el) => {
      const thisControllerUsedShiftsAndAbsences = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(el)) {
        thisControllerUsedShiftsAndAbsences.push(key);
      }
      if (thisControllerUsedShiftsAndAbsences?.length > usedShiftsAndAbsences?.length) usedShiftsAndAbsences = thisControllerUsedShiftsAndAbsences;
    });
    usedShiftsAndAbsences.sort((a, b) => {
      if (a === 'J' && b !== 'J') return -1;
      if (a.startsWith('J') && (b !== 'J' || !b.startsWith('J'))) return -1;
      if (a.startsWith('M') && (!b.startsWith('J') && !b.startsWith('M'))) return -1;
      if (a.startsWith('P') && (!b.startsWith('J') && !b.startsWith('M') && !b.startsWith('P'))) return -1;
      if (a === 'P' && (b !== 'P' && b.startsWith('P'))) return -1;
      if (a === 'N' && (!b.startsWith('J') && (!b.startsWith('M') && !b.startsWith('P')))) return -1;
    });

    const daysPerShift = [];

    perShiftArray.forEach((el) => {
      const controllerArray = [];
      usedShiftsAndAbsences.forEach((element) => {
        controllerArray.push((el?.[element] && el?.[element] > 0) ? el?.[element] : ' ');
      });
      daysPerShift.push(controllerArray);
    });

    const shiftsDetailsArray = [];

    demand?.forEach((el) => {
      shiftsDetailsArray.push([el?.name + '  SMIJENA  ' + el?.timeFrom + ' - ' + el?.timeTo]);
    });

    return {
      demandRows,
      controllerRows,
      usedShiftsAndAbsences,
      daysPerShift,
      totalHoursArray,
      shiftsDetailsArray
    };
  };

  const exportSpreadsheetHandler = () => {
    const numberOfDaysInMonth = new Date(year, month, 0).getDate();
    const dayNumbersArray = [];
    const daysArray = [];
    let numberOfWorkingDays = 0;
    for (let i = 1; i <= numberOfDaysInMonth; i += 1) {
      const date = new Date(year, month - 1, i);
      if (date.getDay() !== 0 && date.getDay() !== 6) numberOfWorkingDays += 1;
      dayNumbersArray.push(i);
      daysArray.push(t('daysInWeek.' + date.getDay()).toString().substring(0, 3).toUpperCase());
    }
    const { demandRows, controllerRows, usedShiftsAndAbsences, daysPerShift, totalHoursArray, shiftsDetailsArray } = getSheetData(numberOfDaysInMonth);

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet = workbook.sheet(0).name(t('main.monthlyPlan'));
      sheet.cell('A1').value('BR. RADNIH DANA');
      sheet.cell('A2').value('BROJ SATI');
      sheet.cell('A3').value('BROJ SATI KL');
      sheet.cell('B1').value(numberOfWorkingDays);
      sheet.cell('B2').value(numberOfWorkingDays * 8);
      sheet.cell('B3').value(optimalNumberOfWorkingHours);
      sheet.cell('C2').value([dayNumbersArray, daysArray])
      const monthEndColumn = columnNumberToExcelColumnName(numberOfDaysInMonth + 2);
      const perShiftSectionStartColumn = columnNumberToExcelColumnName(numberOfDaysInMonth + 3);
      const totalNumbersCountColumn = columnNumberToExcelColumnName(numberOfDaysInMonth + 3 + usedShiftsAndAbsences?.length);
      sheet.cell(perShiftSectionStartColumn + '2').value([usedShiftsAndAbsences]);
      sheet.cell(totalNumbersCountColumn + '2').value('Ukupno sati');
      sheet.cell(totalNumbersCountColumn + '4').value(totalHoursArray);
      const endColumn = columnNumberToExcelColumnName(sheet.usedRange().value()?.[0]?.length);
      sheet.cell(perShiftSectionStartColumn + '4').value(daysPerShift);
      sheet.column('A').width(25).style({ bold: 'true' });
      sheet.range('B1:' + endColumn + (controllerRows?.length + 4 + demandRows?.length)).style('horizontalAlignment', 'center');
      sheet.range('A2:' + endColumn + '3').style('borderStyle', 'double');
      sheet.range('B1:B' + (controllerRows?.length + 3 + demandRows?.length)).style({ leftBorderStyle: 'double', rightBorderStyle: 'double' });
      sheet.range('A' + (controllerRows?.length + 3) + ':' + monthEndColumn + (controllerRows?.length + 3)).style({ bottomBorderStyle: 'double' });
      sheet.cell('B1').style('borderStyle', 'double');
      sheet.cell('A4').value(controllerRows);
      sheet.cell('B' + (controllerRows?.length + 4)).value(demandRows);
      sheet.range('B' + (controllerRows?.length + demandRows?.length + 3) + ':' + monthEndColumn + (controllerRows?.length + demandRows?.length + 3)).style({ bottomBorderStyle: 'double' });
      sheet.range(monthEndColumn + '2:' + monthEndColumn + (controllerRows?.length + demandRows?.length + 3)).style({ rightBorderStyle: 'double' });
      sheet.range(perShiftSectionStartColumn + (controllerRows?.length + 3) + ':' + endColumn + (controllerRows?.length + 3)).style({ bottomBorderStyle: 'double' });
      sheet.range(perShiftSectionStartColumn + (controllerRows?.length + demandRows?.length + 3) + ':' + endColumn + (controllerRows?.length + demandRows?.length + 3)).style({ bottomBorderStyle: 'double' });
      sheet.range(totalNumbersCountColumn + '4:' + totalNumbersCountColumn + (controllerRows?.length + 3)).style({ borderStyle: 'double' })
      sheet.range(totalNumbersCountColumn + (controllerRows?.length + 3) + ':' + totalNumbersCountColumn + (controllerRows?.length + demandRows?.length + 3)).style({ rightBorderStyle: 'double' });
      sheet.cell(perShiftSectionStartColumn + (controllerRows?.length + 4)).value(shiftsDetailsArray).style({ horizontalAlignment: 'left' });
      sheet.range(totalNumbersCountColumn + '2:' + totalNumbersCountColumn + '3').merged(true).style({ verticalAlignment: 'center', wrapText: 'true' });
      sheet.range('L1:V1').merged(true).style('fill', 'bfbfbf');
      sheet.cell('L1').value(t('monthlyPlan.' + month) + ' ' + year + ' ' + t('dailySchedule.version') + ' ' + version + ' - ' + correspondingPlanStatus?.title?.sr?.toUpperCase()).style({ fontColor: 'ffffff', bold: true, fill: correspondingPlanStatus?.headerColor.substring(1) });
      return workbook.outputAsync().then((res) => {
        saveAs(res, (t('main.monthlyPlan').toString() + ' - ' + (t('monthlyPlan.' + month)) + ' ' + year + ' ' + t('dailySchedule.version') + ' ' + version));
      })
    });
  };

  const undoLastChange = () => {
    setModifiedData((prevState) => {
      const newStateArr = [...prevState];
      newStateArr.pop();
      return newStateArr;
    });
  };

    const menuItems = [
        {
            key: 'save',
            icon: saveIcon,
            title: t('monthlyPlan.saveToCurrentVersion'),
            onClick: saveHandler,
            disabled: !modifiedData || !modifiedData?.[0] || planStatus === 'APPROVED_AND_ACTIVE',
            visibleInModes: ['edit']
        },
        {
            key: 'saveplus',
            icon: savePlusIcon,
            title: t('monthlyPlan.saveAsNewVersion'),
            onClick: saveAsNewVersionHandler,
            disabled: planStatus !== 'APPROVED_AND_ACTIVE' || !modifiedData || !modifiedData?.[0],
            visibleInModes: ['edit']
        },
        {
            key: 'revert',
            icon: revertIcon,
            title: t('monthlyPlan.undoLastChange'),
            onClick: undoLastChange,
            disabled: !modifiedData || !modifiedData?.[0],
            visibleInModes: ['edit']
        },
        {
            key: 'send',
            icon: sendIcon,
            onClick: submitForReviewHandler,
            title: t('monthlyPlan.sendToReview'),
            disabled: planStatus !== 'GENERATED' && planStatus !== 'MANUALLY_CHANGED' && planStatus !== 'NOT_APPROVED',
            visibleInModes: ['edit']
        },
        {
            key: 'export',
            icon: spreadsheetIcon,
            onClick: exportSpreadsheetHandler,
            title: t('monthlyPlan.exportXls'),
            disabled: loading || !dataSource || loggedUser?.globalPermissions?.permissionReport !== 'GENERATE_AND_DISPLAY',
            visibleInModes: ['edit', 'view']
        }
    ];

    if (loggedUser?.localPermissions?.[locationId]?.monthlyPlan === 'APPROVE_AND_ACTIVATE' || loggedUser?.localPermissions?.[locationId]?.monthlyPlan === 'GENERATE_AND_MODIFY') {
      menuItems.push({
        key: 'changeLog',
        icon: changelogIcon,
        onClick: () => {
          setChangelogExpanded(true);
        },
        title: t('monthlyPlan.changeLog'),
        visibleInModes: ['edit', 'view']
      });
    }

    if (loggedUser?.localPermissions?.[locationId]?.monthlyPlan === 'APPROVE_AND_ACTIVATE') {
      menuItems.splice(
        2,
        0,
        {
           key: 'confirm',
           icon: confirmIcon,
           onClick: approveHandler,
           title: t('monthlyPlan.approveSchedule'),
           disabled: planStatus !== 'PENDING_APPROVAL' || loggedUser?.localPermissions?.[locationId]?.monthlyPlan !== 'APPROVE_AND_ACTIVATE',
           visibleInModes: ['edit']
        },
        {
           key: 'cancel',
           icon: cancelIcon,
           onClick: rejectHandler,
           title: t('monthlyPlan.rejectSchedule'),
           disabled: planStatus !== 'PENDING_APPROVAL' || loggedUser?.localPermissions?.[locationId]?.monthlyPlan !== 'APPROVE_AND_ACTIVATE',
           visibleInModes: ['edit']
        }
      )
    }

    useEffect(() => {
      getScheduleStatus(location, year, month, version);
      setDataSource(null);
      setDemand(null);
      setVersions(null);
      getMonthlyScheduleVersions(location, year, month);
      getChangelog(location, year, month, version);
    }, [year, month, version])

    useEffect(() => {
      if (planStatus && planStatus !== 'PLAN_NOT_FOUND') {
        getDemand(location, year, month, version);
        getChangelog(location, year, month, version);
        getAllShiftSwapRequests(versions?.find((el) => el?.value === parseInt(version, 10))?.id);
      }
    }, [planStatus]);

    useEffect(() => {
      if (demand) {
        getMonthlySchedule(location, year, month, version);
        getPreviousMonthVersions();
        getNextMonthVersions();
      }
    }, [demand]);

    const goToPreviousMonth = () => {
      const fetchVersions = async () => {
        const res = await getScheduleVersions(location, previousMonthYear, previousMonth);
        const previousMonthLatestVersion = res?.data?.[0]?.versions?.reverse()?.[res?.data?.[0]?.versions?.length - 1].version;
        const previousMonthAnnualPlanVersion = previousMonthYear === year ? annualPlanVersion : 'all';
        props.history.push('/monthlyPlan/' + location + '/' + previousMonthYear + '/' + previousMonthAnnualPlanVersion + '/' + previousMonth + '/' + previousMonthLatestVersion + '/' + mode);
      };
      fetchVersions();
    };

    const goToNextMonth = () => {
      const fetchVersions = async () => {
        const res = await getScheduleVersions(location, nextMonthYear, nextMonth);
        const nextMonthLatestVersion = res?.data?.[0]?.versions?.reverse()?.[res?.data?.[0]?.versions?.length - 1].version;
        const nextMonthAnnualPlanVersion = nextMonthYear === year ? annualPlanVersion : 'all';
        props.history.push('/monthlyPlan/' + location + '/' + nextMonthYear + '/' + nextMonthAnnualPlanVersion + '/' + nextMonth + '/' + nextMonthLatestVersion + '/' + mode);
      }
      fetchVersions();
    };

    const changeVersionHandler = (value) => {
      props.history.push('/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion + '/' + month + '/' + value + '/' + mode);
    };

     return (
       <Wrapper>
         <Container>
           <Header>
             <BackButtonContainer>
               <Link to={'/monthlyPlan/' + location + '/' + year + '/' + annualPlanVersion} style={{ textDecoration: 'none' }}>
                 <BackButton><ReactSVG src={arrowBackIcon} /></BackButton>
               </Link>
               <BackButtonText>
                 {t('main.monthlyPlan')}
                 {' /'}
                 <b>
                   {' '}
                   {location}
                 </b>
               </BackButtonText>
             </BackButtonContainer>
             <TitleContainer>
               <MonthTitleBox>
                 <PreviousMonthArrow onClick={goToPreviousMonth}>
                   <ReactSVG src={caretLeftIcon} />
                 </PreviousMonthArrow>
                 {t('monthlyPlan.' + month)}
                 {' '}
                 {year}
                 <NextMonthArrow onClick={goToNextMonth}>
                   <ReactSVG src={caretRightIcon} />
                 </NextMonthArrow>
               </MonthTitleBox>
               <PlanStatusBox style={{ backgroundColor: loading ? '#5F5F5F' : correspondingPlanStatus?.headerColor }}>
                 {loading ? <StatusBoxSpinner /> : correspondingPlanStatus?.title?.sr?.toUpperCase()}
               </PlanStatusBox>
             </TitleContainer>
             <VersionDropdownContainer>
               { versions && (
                 <FilterSelectInputTwo
                   defaultValue={versions.find((el) => el.value === parseInt(version, 10))}
                   options={versions}
                   onChange={changeVersionHandler}
                   changelogexpanded={changelogExpanded ? 1 : 0}
                 />
               )}
             </VersionDropdownContainer>
           </Header>
           <SheetContainer>
             <MonthlySheet
               ATCU={location}
               dataSource={dataSource}
               version={version}
               demand={demand}
               loading={loading}
               year={year}
               month={month}
               modifiedData={modifiedData}
               setModifiedData={setModifiedData}
               mode={mode}
               previousMonthValidationData={previousMonthValidationData}
               nextMonthValidationData={nextMonthValidationData}
               optimalNumberOfWorkingHours={optimalNumberOfWorkingHours}
               approvedRequestsList={shiftSwapRequestList}
             />
           </SheetContainer>
         </Container>
         <RightSideMenu changelogexpanded={changelogExpanded ? 1 : 0}>
           {
              changelogExpanded
                && (
                <ChangelogContainer>
                  <ChangelogTitleContainer>
                    Zapisnik izmjena
                    <ReactSVG onClick={() => { setChangelogExpanded(false) }} src={closeIcon} />
                  </ChangelogTitleContainer>
                  <ChangeLog changelogData={changeLog} />
                </ChangelogContainer>
              )
           }
           {!changelogExpanded && (
             <>
               <ReactSVG width={82} src={BHANSALogo} />
               <SideMenuContainer>
                 {menuItems.map((menuItem, index) => {
                     if (menuItem?.visibleInModes?.includes(mode)) {
                       return (
                         <Tooltip key={'menu-item-' + index} placement="left" title={menuItem.title}>
                           <MenuItem className={menuItem?.disabled ? 'disabled' : ''} onClick={menuItem?.disabled ? null : menuItem?.onClick} key={menuItem.key}>
                             <ReactSVG src={menuItem.icon} />
                           </MenuItem>
                         </Tooltip>
                       )
                     }
                 })}
               </SideMenuContainer>
             </>
           )}
         </RightSideMenu>
       </Wrapper>
    )
};

export default MonthlyDetailedView;
